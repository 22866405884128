import React from 'react';

import { CustomSelect, NOTIFICATION_TYPE } from 'components';

import { useGetBondProviders } from './useGetBondProviders';

export function BondProviderSelect({ form, ...rest }) {
  const {
    data: bondProviders,
    isLoading,
    isFetching,
  } = useGetBondProviders({
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  const options = React.useMemo(() => {
    return bondProviders.map((item) => ({
      label: item.companyName,
      value: item.id,
    }));
  }, [bondProviders]);

  return (
    <CustomSelect
      label="Select DOT Bond Provider"
      options={options}
      value={options.find((item) => item.value === form.values.bondProvider)}
      onChange={(v) => form.setFieldValue('bondProvider', v.value)}
      error={form.errors.bondProvider && form.touched.bondProvider ? form.errors.bondProvider : ''}
      placeholder="Select DOT Bond Provider"
      loading={isLoading || isFetching}
      hasBorder
      {...rest}
    />
  );
}
