import { ROUTES } from 'constants/routes';
import { getFullAddress } from 'helpers/address';
import React from 'react';
import { Edit, List } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Loading } from 'components';
import { useGetConsignee } from 'features/consignees/hooks/useGetConsignee';

export function ViewConsignee() {
  const { id } = useParams();
  const { data: consignee, isLoading, error } = useGetConsignee(id);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h2>Consignee Details</h2>
        <div className="d-flex gap-4">
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CONSIGNEES}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <List size={20} />
            List
          </Link>
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CONSIGNEES}/edit/${id}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <Edit size={20} />
            Edit
          </Link>
        </div>
      </div>
      <hr />
      <h5>Company Name</h5>
      <p>{consignee?.companyName}</p>
      <h5>Company Phone</h5>
      <p>{consignee?.companyPhone || '-'}</p>
      <h5>Tax ID</h5>
      <p>{consignee?.taxID || '-'}</p>
      <h5>Organization Code</h5>
      <p>{consignee?.user?.orgCode || '-'}</p>
      <h5>Contact Person</h5>
      <p>{`${consignee?.user?.firstName} ${consignee?.user?.lastName}`}</p>
      <h5>Contact Email</h5>
      <p>{consignee?.user?.email}</p>
      <h5>Contact Phone</h5>
      <p>{consignee?.user?.phoneNumber}</p>
      <h5>Contact Address</h5>
      <p>
        {getFullAddress([
          consignee?.user?.street1,
          consignee?.user?.city1,
          consignee?.user?.state1,
          consignee?.user?.zip1,
          consignee?.user?.country1,
        ])}
      </p>
    </div>
  );
}
