// The string constants should be identical with table name on backend
export const PHOTO_CATEGORY = 'photo_category';
export const HS_TARIFF = 'hs_tariff';
export const US_PORT_OF_ARRIVAL = 'us_port_of_arrival';

export const USA = 'United States';
export const CANADA = 'Canada';
export const MEXICO = 'Mexico';
export const USA_ALPHA_2 = 'US';
export const CANADA_ALPHA_2 = 'CA';
export const CANADA_ALPHA_3 = 'CAN';
export const MEXICO_ALPHA_2 = 'MX';

export const COUNTRIES = [
  { label: USA, value: USA_ALPHA_2 },
  { label: CANADA, value: CANADA_ALPHA_2 },
  { label: MEXICO, value: MEXICO_ALPHA_2 },
];

export const US_STATES = [
  { label: 'AL: Alabama', value: 'AL' },
  { label: 'AK: Alaska', value: 'AK' },
  { label: 'AZ: Arizona', value: 'AZ' },
  { label: 'AR: Arkansas', value: 'AR' },
  { label: 'CA: California', value: 'CA' },
  { label: 'CO: Colorado', value: 'CO' },
  { label: 'CT: Connecticut', value: 'CT' },
  { label: 'DE: Delaware', value: 'DE' },
  { label: 'FL: Florida', value: 'FL' },
  { label: 'GA: Georgia', value: 'GA' },
  { label: 'HI: Hawaii', value: 'HI' },
  { label: 'ID: Idaho', value: 'ID' },
  { label: 'IL: Illinois', value: 'IL' },
  { label: 'IN: Indiana', value: 'IN' },
  { label: 'IA: Iowa', value: 'IA' },
  { label: 'KS: Kansas', value: 'KS' },
  { label: 'KY: Kentucky', value: 'KY' },
  { label: 'LA: Louisiana', value: 'LA' },
  { label: 'ME: Maine', value: 'ME' },
  { label: 'MD: Maryland', value: 'MD' },
  { label: 'MA: Massachusetts', value: 'MA' },
  { label: 'MI: Michigan', value: 'MI' },
  { label: 'MN: Minnesota', value: 'MN' },
  { label: 'MS: Mississippi', value: 'MS' },
  { label: 'MO: Missouri', value: 'MO' },
  { label: 'MT: Montana', value: 'MT' },
  { label: 'NE: Nebraska', value: 'NE' },
  { label: 'NV: Nevada', value: 'NV' },
  { label: 'NH: New Hampshire', value: 'NH' },
  { label: 'NJ: New Jersey', value: 'NJ' },
  { label: 'NM: New Mexico', value: 'NM' },
  { label: 'NY: New York', value: 'NY' },
  { label: 'NC: North Carolina', value: 'NC' },
  { label: 'ND: North Dakota', value: 'ND' },
  { label: 'OH: Ohio', value: 'OH' },
  { label: 'OK: Oklahoma', value: 'OK' },
  { label: 'OR: Oregon', value: 'OR' },
  { label: 'PA: Pennsylvania', value: 'PA' },
  { label: 'RI: Rhode Island', value: 'RI' },
  { label: 'SC: South Carolina', value: 'SC' },
  { label: 'SD: South Dakota', value: 'SD' },
  { label: 'TN: Tennessee', value: 'TN' },
  { label: 'TX: Texas', value: 'TX' },
  { label: 'UT: Utah', value: 'UT' },
  { label: 'VT: Vermont', value: 'VT' },
  { label: 'VA: Virginia', value: 'VA' },
  { label: 'WA: Washington', value: 'WA' },
  { label: 'WV: West Virginia', value: 'WV' },
  { label: 'WI: Wisconsin', value: 'WI' },
  { label: 'WY: Wyoming', value: 'WY' },
];

export const CANADA_STATES = [
  {
    label: 'AB: Alberta',
    value: 'AB',
  },
  {
    label: 'BC: British Columbia',
    value: 'BC',
  },
  {
    label: 'MB: Manitoba',
    value: 'MB',
  },
  {
    label: 'NB: New Brunswick',
    value: 'NB',
  },
  {
    label: 'NL: Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'NT: Northwest Territories',
    value: 'NT',
  },
  {
    label: 'NS: Nova Scotia',
    value: 'NS',
  },
  {
    label: 'NU: Nunavut',
    value: 'NU',
  },
  {
    label: 'ON: Ontario',
    value: 'ON',
  },
  {
    label: 'PE: Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'QC: Quebec',
    value: 'QC',
  },
  {
    label: 'SK: Saskatchewan',
    value: 'SK',
  },
  {
    label: 'YT: Yukon Territory',
    value: 'YT',
  },
];

export const MEXICO_STATES = [
  {
    label: 'AGS: Aguascalientes',
    value: 'AGS',
  },
  {
    label: 'BC: Baja California',
    value: 'BC',
  },
  {
    label: 'BCS: Baja California Sur',
    value: 'BCS',
  },
  {
    label: 'CAM: Campeche',
    value: 'CAM',
  },
  {
    label: 'CHP: Chiapas',
    value: 'CHP',
  },
  {
    label: 'CHH: Chihuahua',
    value: 'CHH',
  },
  {
    label: 'COA: Coahuila',
    value: 'COA',
  },
  {
    label: 'COL: Colima',
    value: 'COL',
  },
  {
    label: 'Durango',
    value: 'DGO',
  },
  {
    label: 'GTO: Guanajuato',
    value: 'GTO',
  },
  {
    label: 'GRO: Guerrero',
    value: 'GRO',
  },
  {
    label: 'HID: Hidalgo',
    value: 'HID',
  },
  {
    label: 'JAL: Jalisco',
    value: 'JAL',
  },
  {
    label: 'MEX: México',
    value: 'MEX',
  },
  {
    label: 'MIC: Michoacán',
    value: 'MIC',
  },
  {
    label: 'MOR: Morelos',
    value: 'MOR',
  },
  {
    label: 'NAY: Nayarit',
    value: 'NAY',
  },
  {
    label: 'NL: Nuevo León',
    value: 'NL',
  },
  {
    label: 'OAX: Oaxaca',
    value: 'OAX',
  },
  {
    label: 'PUE: Puebla',
    value: 'PUE',
  },
  {
    label: 'QRO: Querétaro',
    value: 'QRO',
  },
  {
    label: 'QR: Quintana Roo',
    value: 'QR',
  },
  {
    label: 'SLP: San Luis Potosí',
    value: 'SLP',
  },
  {
    label: 'SIN: Sinaloa',
    value: 'SIN',
  },
  {
    label: 'SON: Sonora',
    value: 'SON',
  },
  {
    label: 'TAB: Tabasco',
    value: 'TAB',
  },
  {
    label: 'TAM: Tamaulipas',
    value: 'TAM',
  },
  {
    label: 'TLAX: Tlaxcala',
    value: 'TLAX',
  },
  {
    label: 'VER: Veracruz',
    value: 'VER',
  },
  {
    label: 'YUC: Yucatán',
    value: 'YUC',
  },
  {
    label: 'ZAC: Zacatecas',
    value: 'ZAC',
  },
];
