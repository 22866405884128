import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ROUTES } from 'constants/routes';
import moment from 'moment';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Table } from 'components';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';

import EntryStatus from './EntryStatus';
import VehicleTableActions from './VehicleTableActions';
import './styles.scss';

const VehicleTable = React.forwardRef(({ onCheckRowChange, onCheckAllChange }, ref) => {
  const { searchValues, data, isLoading, isFetching, refetch } = useSearchVehicles();

  const [, setSearchParams] = useSearchParams();

  const pagination = {
    total: data?.totalCount || 0,
    page: searchValues.filter.page,
    perPage: searchValues.filter.perPage,
    onPageChange: (page) => {
      setSearchParams({
        searchTerm: searchValues.searchTerm,
        page,
        perPage: searchValues.filter.perPage,
        statusFilters: searchValues.statusFilters,
        ...searchValues.sortOption,
      });
    },
    onPerPageChange: (perPage) => {
      setSearchParams({
        searchTerm: searchValues.searchTerm,
        page: 0,
        perPage,
        statusFilters: searchValues.statusFilters,
        ...searchValues.sortOption,
      });
    },
  };

  const handleUpdateSort = (data) => {
    setSearchParams({
      searchTerm: searchValues.searchTerm,
      page: searchValues.filter.page,
      perPage: searchValues.filter.perPage,
      statusFilters: searchValues.statusFilters,
      ...data,
    });
  };

  const columns = [
    {
      title: 'Vin',
      dataIndex: 'vin',
      sortable: true,
      clickable: true,
      render: (vehicle) => (
        <Link
          to={`${ROUTES.MAIN.VEHICLES.PROFILE.replace(':id', vehicle.id)}`}
          className="text-uppercase flex align-items-center gap-2"
        >
          {vehicle.vin} {vehicle.isReleased && <ErrorOutlineOutlinedIcon fontSize={'small'} />}
        </Link>
      ),
    },
    { title: 'Year', dataIndex: 'year', sortable: true },
    { title: 'Make', dataIndex: 'make', sortable: true },
    { title: 'Model', dataIndex: 'model', sortable: true },
    { title: 'PAPS', dataIndex: 'PAPSCode', sortable: true },
    {
      title: 'Customs Entry',
      dataIndex: 'VehicleEntryNumber',
      sortable: true,
      render: (vehicle) => vehicle.entryNumber,
    },
    {
      title: 'Entry Status',
      dataIndex: 'entryStatus',
      render: (vehicle) => (
        <EntryStatus status={vehicle.entryStatus} statusMessage={vehicle.entryStatusMessage} />
      ),
    },
    {
      title: 'Entry Date',
      dataIndex: 'entryDate',
      render: (vehicle) => <span>{moment.utc(vehicle.entryDate).format('MM/DD/YYYY')}</span>,
    },
    {
      title: 'Seller',
      dataIndex: 'exporter_code',
      sortable: true,
      render: (vehicle) => {
        if (vehicle.Exporter) return vehicle.Exporter?.companyName;
        return '';
      },
    },
    {
      title: 'Buyer',
      dataIndex: 'buyer_code',
      sortable: true,
      render: (vehicle) => {
        if (vehicle.Buyer) return vehicle.Buyer?.companyName;
        return '';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (vehicle) => {
        return vehicle.status || '';
      },
    },
    { title: 'Location', dataIndex: '', render: (vehicle) => vehicle.conformityLocation?.title },
    { title: 'Exporting Country', dataIndex: 'country', sortable: true },
    {
      title: 'Countdown',
      dataIndex: 'countdown',
      sortable: true,
      render: (vehicle) => {
        if (vehicle.countdown) return setCountdown(vehicle);
        return '';
      },
    },
    {
      title: 'NHTSA Ready',
      dataIndex: '',
      render: (vehicle) => (vehicle.nhtsaReady ? 'Ready' : 'Not Ready'),
    },
    searchValues.statusFilters.isArchived
      ? {}
      : {
          title: 'Actions',
          dataIndex: '',
          render: (vehicle) => <VehicleTableActions vehicle={vehicle} refresh={refetch} />,
        },
  ];

  const setCountdown = (vehicle) => {
    const time = moment().diff(vehicle.countdown, 'days');
    let daysLeft = 30 - time;

    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  };
  const handleCheckAllChange = () => {
    onCheckAllChange(data.data);
  };
  return (
    <Table
      ref={ref}
      data={data?.data || []}
      columns={columns}
      loading={isLoading || isFetching}
      sortOption={searchValues.sortOption}
      setSortOption={handleUpdateSort}
      pagination={pagination}
      checkable
      onCheckRowChange={onCheckRowChange}
      onCheckAllChange={handleCheckAllChange}
    />
  );
});

export default VehicleTable;
