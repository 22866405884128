import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentCategoryApi } from 'api';

export function useCreateDocumentCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-document-category'],
    mutationFn: async (data) => {
      return DocumentCategoryApi.create(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['document-categories']);
    },
  });
}
