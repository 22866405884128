import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import _variables from 'styles/_variables.scss';

import './styles.scss';

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: state.selectProps.background,
    borderRadius: '8px',
    borderColor: state.selectProps.borderColor,
    height: '45px',
  }),
  input: (base, state) => ({
    ...base,
    height: '100%',
    alignItems: 'baseline',
    color: state.selectProps.textColor,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.textColor,
  }),
  menu: (base, state) => ({
    ...base,
    background: state.selectProps.background,
    maxHeight: '300px',
    overflow: 'auto',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 6px',
    borderRadius: '8px',
    boxShadow: '0 5px 10px 4px rgba(0, 0, 0, 0.1)',
  }),
  menuList: (base, state) => ({
    ...base,
    color: state.selectProps.textColor,
  }),
  option: (base, state) => ({
    ...base,
    padding: '10px',
    borderRadius: '10px',
    color: state.isDisabled ? _variables['color-grey-1'] : state.selectProps.textColor,
    background: state.isDisabled
      ? undefined
      : state.isFocused
      ? state.selectProps.hoverBackground
      : '',
    '&:hover': {
      cursor: 'pointer',
      background: state.isDisabled ? undefined : state.selectProps.hoverBackground,
    },
    marginBottom: '8px',
    marginTop: '8px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  multiValue: (base) => ({
    ...base,
    background: _variables['color-primary'],
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: _variables['color-white'],
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: _variables['color-white'],
    '&:hover': {
      background: _variables['color-grey'],
      color: _variables['color-red'],
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '2px 20px',
  }),
};
const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control style={{ display: 'flex', alignItems: 'center' }} {...props}>
      {children[0]}
      {children[0].props.hasValue && (
        <ClearOutlinedIcon className="delete-icon" onClick={() => props.clearValue()} />
      )}
      {children[1]}
    </components.Control>
  );
};
export const CustomSelect = ({
  name,
  id,
  isLoading,
  label,
  placeholder,
  options = [],
  isSearchable,
  onChange,
  error = '',
  dark = false,
  hasBorder = false,
  defaultValue,
  canCreateable = false,
  onCreate,
  canBeDeleted = false,
  disabled = false,
  ...rest
}) => {
  const customComponents = canBeDeleted
    ? {
        IndicatorSeparator: () => null,
        Control: CustomControl,
      }
    : { IndicatorSeparator: () => null };

  const Component = !canCreateable ? Select : CreatableSelect;

  return (
    <div className={`custom-select-container`}>
      {label && (
        <label htmlFor={id} className="custom-select-label">
          {label}
        </label>
      )}
      <Component
        name={name}
        inputId={id}
        isDisabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable}
        components={customComponents}
        styles={customStyles}
        onChange={onChange}
        onCreateOption={onCreate}
        defaultValue={defaultValue}
        // Props for custom styling
        background={dark ? _variables['color-dark'] : _variables['color-dark-light']}
        hoverBackground={_variables['color-dark-1']}
        textColor={_variables['color-white']}
        borderColor={
          error ? _variables['color-red'] : !hasBorder ? 'transparent' : _variables['color-grey-1']
        }
        {...rest}
      />
      {error && <p className="mb-0 text-red">{error}</p>}
    </div>
  );
};
