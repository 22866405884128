import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from '../providers/authProvider';
import Auth from '../pages/auth';
import Main from '../pages/main';

const Routing = () =>  {
  const { user } = useContext(AuthContext);

  return (
    <BrowserRouter>
      {
        user ? (
          <Main/>
        ) : (
          <Auth/>
        )
      }
    </BrowserRouter>
  );
};

export default Routing;
