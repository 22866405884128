import React from 'react';

import { CustomSelect, NOTIFICATION_TYPE, Notification } from 'components';

import { useGetCustomsBrokers } from './useGetCustomsBrokers';

export function CustomsBrokerSelect({ form, ...rest }) {
  const {
    data: customsBrokers,
    isLoading,
    isFetching,
  } = useGetCustomsBrokers({
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  const options = React.useMemo(() => {
    return customsBrokers.map((item) => ({
      label: item.companyName,
      value: item.id,
      fileCode: item.fileCode, // For custom option label display
    }));
  }, [customsBrokers]);

  return (
    <CustomSelect
      label="Customs Broker"
      placeholder="Select your customs broker"
      options={options}
      formatOptionLabel={(item) => (
        <div className="d-flex justify-content-between align-items-center">
          <span>{item.label}</span>
          <span>{item.fileCode}</span>
        </div>
      )}
      value={options.find((item) => item.value === form.values.customsBroker)}
      onChange={(v) => form.setFieldValue('customsBroker', v.value)}
      error={
        form.errors.customsBroker && form.touched.customsBroker ? form.errors.customsBroker : ''
      }
      loading={isLoading || isFetching}
      hasBorder
      {...rest}
    />
  );
}
