import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router';
import { Button } from '../Button';

export const BackButton = () => {
  const navigateTo = useNavigate();

  return (
    <Button
      variant="link"
      onClick={() => navigateTo(-1)}
      data-testid="back-button"
    >
      <div className="d-flex align-items-center">
        <ChevronLeft className="mr-3" />
        <span className="ms-2">Back</span>
      </div>
    </Button>
  );
}
