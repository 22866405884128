import { Checkbox } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useMemo } from 'react';
import { isRegisteredImporter } from 'services/auth';
import { BUYER, CARRIER, REGISTER_IMPORTER, SELLER } from 'utils';

import { useGetBuyers } from 'features/buyers/hooks/useGetBuyers';
import { useGetTransporters } from 'features/carriers/hooks/useGetCarriers';
import { useGetCurrentUserRegisteredImporter } from 'features/registered-importers/useGetCurrentUserRegisteredImporter';
import { useGetExporters } from 'features/sellers/hooks/useGetExporters';

const query = {
  page: '0',
  perPage: '0',
  orderBy: 'companyName',
  order: 'ASC',
};

export function SharedUsersTable({ types, selectedUsers, setSelectedUsers, onToggleUser }) {
  const { user: curUser } = useContext(AuthContext);

  const {
    data: buyersData,
    isLoading: isLoadingBuyers,
    isFetching: isFetchingBuyers,
  } = useGetBuyers(query, {
    enabled: types.includes(BUYER),
  });

  const {
    data: sellersData,
    isLoading: isLoadingSellers,
    isFetching: isFetchingSellers,
  } = useGetExporters(query, {
    enabled: types.includes(SELLER),
  });

  const {
    data: carriersData,
    isLoading: isLoadingCarriers,
    isFetching: isFetchingCarriers,
  } = useGetTransporters(query, {
    enabled: types.includes(CARRIER),
  });

  const {
    data: currentUserRI,
    isLoading: isLoadingCurrentUserRI,
    isFetching: isFetchingCurrentUserRI,
  } = useGetCurrentUserRegisteredImporter({
    enabled: !isRegisteredImporter(curUser),
  });

  const users = useMemo(() => {
    let data = [];

    if (types.includes(BUYER) && buyersData?.data) {
      data = data.concat([
        ...buyersData.data.map((user) => ({
          id: user.id,
          name: user.buyer?.companyName || '',
          type: BUYER,
        })),
      ]);
    }
    if (types.includes(SELLER) && sellersData?.data) {
      data = data.concat([
        ...sellersData.data.map((user) => ({
          id: user.id,
          name: user.seller?.companyName || '',
          type: SELLER,
        })),
      ]);
    }
    if (types.includes(CARRIER) && carriersData?.data) {
      data = data.concat([
        ...carriersData.data.map((user) => ({
          id: user.id,
          name: user.carrier?.companyName || '',
          type: CARRIER,
        })),
      ]);
    }
    if (currentUserRI) {
      data = data.concat([
        {
          id: currentUserRI.id,
          name: currentUserRI.registerImporter?.fullName || '',
          type: REGISTER_IMPORTER,
        },
      ]);
    }
    return data;
  }, [buyersData, carriersData, sellersData, currentUserRI, types]);

  const isLoadingUsers =
    (isLoadingBuyers && isFetchingBuyers) ||
    (isLoadingCarriers && isFetchingCarriers) ||
    (isLoadingSellers && isFetchingSellers) ||
    (isLoadingCurrentUserRI && isFetchingCurrentUserRI);

  const handleChangeAllUser = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
  };

  return (
    <div className="px-4">
      {isLoadingUsers && (
        <div className="text-center mt-2">
          <div className="spinner-border" />
        </div>
      )}
      {!isLoadingUsers && (
        <table className="table table-striped table-sm mb-2" style={{ captionSide: 'top' }}>
          <caption className="h5 text-white text-center border-bottom">Users</caption>
          <thead>
            <tr>
              <th scope="col-auto">
                <Checkbox
                  sx={{ color: (theme) => theme.colors.white }}
                  checked={selectedUsers.length === users.length && users.length > 0}
                  onChange={() => handleChangeAllUser()}
                />
              </th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          {users.length > 0 ? (
            <tbody>
              {users.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      <Checkbox
                        sx={{ color: (theme) => theme.colors.white }}
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => onToggleUser(user.id)}
                      />
                    </td>
                    <td>{user.name}</td>
                    <td>{user.type}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={3}>
                  <div className="text-center">No users</div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  );
}
