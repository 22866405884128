import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchBar } from 'features/common/search/SearchBar';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';

const VehicleSearch = () => {
  const { searchValues, refetch } = useSearchVehicles();

  const [, setSearchParams] = useSearchParams();

  const handleSearch = React.useCallback(
    (debouncedSearchTerm) => {
      setSearchParams({
        searchTerm: debouncedSearchTerm,
        page: 0,
        perPage: searchValues.filter.perPage,
        statusFilters: searchValues.statusFilters,
        ...searchValues.sortOption,
      });
    },
    [searchValues, setSearchParams]
  );

  return <SearchBar fetch={refetch} onSearch={handleSearch} />;
};

export default VehicleSearch;
