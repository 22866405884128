import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { USA_ALPHA_2 } from 'constants/common';
import React from 'react';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';

const CheckoutForm = ({ intent }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://dev.vin2.com/payment/success/${intent.id}?type=payment`,
      },
    });

    if (result?.error) {
      Notification(NOTIFICATION_TYPE.DANGER, result.error.message);
    }
  };

  return (
    <div className="CardSection">
      <form aria-label="form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          options={{
            defaultValues: {
              email: 'foo@bar.com',
            },
          }}
        />

        <h3>Payment</h3>
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: 'John Doe',
                phone: '888-888-8888',
                address: {
                  postal_code: '10001',
                  country: USA_ALPHA_2,
                },
              },
            },
          }}
        />
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default CheckoutForm;
