import React from 'react';

import ConsigneesTable from 'features/consignees/components/table/ConsigneesTable';

const ListConsignees = () => {
  return (
    <>
      <h2 className="mb-4">Consignees</h2>
      <ConsigneesTable />
    </>
  );
};

export default ListConsignees;
