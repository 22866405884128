import React from 'react';
import { UserForm } from '../UserForm/UserForm';
import { BackButton, NOTIFICATION_TYPE, Notification } from '../../../../components';
import { useNavigate } from 'react-router';

export default function AddUser() {
  const navigate = useNavigate();

  const onSuccess = () => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'User was created successfully');
    navigate(-1);
  };

  const onError = (error) => {
    Notification(NOTIFICATION_TYPE.DANGER, error.message);
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row mb-4">
        <h1>Add User</h1>
      </div>

      <UserForm action="create" onSuccess={onSuccess} onError={onError} />
    </div>
  );
}
