import { AuthApi } from 'api';
import { useFormik } from 'formik';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { AppLogo } from 'components/AppLogo/AppLogo';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';

import './styles.scss';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const { setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  let { token } = useParams();

  const onSubmit = (values) => {
    const { password } = values;

    setLoading(true);

    AuthApi.ChangePassword(token, { password })
      .then(({ data }) => {
        setUser(data);
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Password reset successfully');
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <div className="login-form">
      <form onSubmit={form.handleSubmit}>
        <AppLogo className="mb-4 mx-auto d-block" />
        <h1 className="h3 mb-5 fw-normal text-center">Choose a new password</h1>

        <PasswordInput
          className="mb-4"
          label="New Password"
          placeholder="Password"
          hasBorder
          {...form.getFieldProps('password')}
          error={form.errors.password && form.touched.password ? form.errors.password : ''}
          data-testid="password"
        />

        <PasswordInput
          className="mb-4"
          label="Confirm Password"
          placeholder="Confirm password"
          data-testid="confirmPassword"
          hasBorder
          {...form.getFieldProps('confirmPassword')}
          error={
            form.errors.confirmPassword && form.touched.confirmPassword
              ? form.errors.confirmPassword
              : ''
          }
        />

        <div className="d-flex justify-content-end">
          <Button type="submit" className="w-50" loading={loading}>
            Reset Password
          </Button>
        </div>

        <p className="mt-3 mb-3 text-muted text-center">© 2017–{new Date().getFullYear()}</p>
      </form>
    </div>
  );
};

export default ResetPassword;
