import { DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function SharedVehiclesTable({ vehicles, onRemove }) {
  return (
    <table className="table table-striped table-sm mb-2" style={{ captionSide: 'top' }}>
      <caption className="h5 text-white text-center border-bottom">Vehicles</caption>
      <thead>
        <tr>
          <th scope="col-auto">Delete</th>
          <th scope="col">Vin</th>
          <th scope="col">Year</th>
          <th scope="col">Make</th>
          <th scope="col">Model</th>
        </tr>
      </thead>
      {vehicles.length > 0 ? (
        <tbody>
          {vehicles.map((vehicle) => {
            return (
              <tr key={vehicle.id}>
                <td>
                  <IconButton
                    sx={{ color: (theme) => theme.colors.white }}
                    onClick={(e) => onRemove(vehicle.id)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </td>
                <td>{vehicle.vin}</td>
                <td>{vehicle.year}</td>
                <td>{vehicle.make}</td>
                <td>{vehicle.model}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan={5}>
              <div className="text-center">No vehicles</div>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
}
