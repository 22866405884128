import classNames from 'classnames';
import React from 'react';
import { Eye, EyeOff } from 'react-feather';

import { IconButton } from 'components/IconButton';
import { Input } from 'components/Input';

import './styles.scss';

export function PasswordInput({ className, placeholder, ...rest }) {
  const [visible, setVisible] = React.useState(false);

  const ToggleIcon = visible ? EyeOff : Eye;

  return (
    <Input
      type={visible ? 'text' : 'password'}
      className={classNames('password-input', className)}
      placeholder={placeholder}
      endAdornment={
        <IconButton
          type="button"
          className="password-input__toggle-button"
          onClick={() => setVisible(!visible)}
          icon={<ToggleIcon className="password-input__toggle-icon" />}
        />
      }
      {...rest}
    />
  );
}
