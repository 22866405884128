import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import { AuthContext } from 'providers/authProvider';
import React, { useEffect, useState } from 'react';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';

import {
  term1Options,
  term2Options,
  term3Options,
  term4Options,
} from './StatementConformity.constants';
import StatementConformityTable from './StatementConformityTable';
import { useUpdateStatementConformity } from './hooks/useUpdateStatementConformity';
import { STATEMENT_COLUMNS } from './utils';

const StatementConformity = ({ vehicle, onUpdateDocument }) => {
  const [isOpen, setOpen] = useState(false);

  const { user } = React.useContext(AuthContext);

  useEffect(() => {
    const data = Object.keys(form.values);
    for (const key of data) {
      if (form.values[key] !== '') continue;
      const column = STATEMENT_COLUMNS.find((col) => col.column === key);
      if (column) {
        form.values[key] = column.default[vehicle.vehicleType];
      }
    }
  }, [vehicle.vehicleType]);

  const { loading, update } = useUpdateStatementConformity();

  const form = useFormik({
    initialValues: {
      vehicleType: vehicle.vehicleType,
      control_and_displays: vehicle?.statementOfConformity?.control_and_displays || '',
      transmission_Shift_sequence:
        vehicle?.statementOfConformity?.transmission_Shift_sequence || '',
      windshield_defrosting_defogging:
        vehicle?.statementOfConformity?.windshield_defrosting_defogging || '',
      windshield_wiping_washing: vehicle?.statementOfConformity?.windshield_wiping_washing || '',
      hydraulic_and_electric_brake_system:
        vehicle?.statementOfConformity?.hydraulic_and_electric_brake_system || '',
      brake_hoses: vehicle?.statementOfConformity?.brake_hoses || '',
      lamps_reflective_devices: vehicle?.statementOfConformity?.lamps_reflective_devices || '',
      new_pneumatic_tires: vehicle?.statementOfConformity?.new_pneumatic_tires || '',
      tire_selection_and_rims: vehicle?.statementOfConformity?.tire_selection_and_rims || '',
      air_brake_systems: vehicle?.statementOfConformity?.air_brake_systems || '',
      motorcycle_brake_systems: vehicle?.statementOfConformity?.motorcycle_brake_systems || '',
      motorcycle_controls_and_displays:
        vehicle?.statementOfConformity?.motorcycle_controls_and_displays || '',
      accelerator_control_systems:
        vehicle?.statementOfConformity?.accelerator_control_systems || '',
      electronic_stability_control:
        vehicle?.statementOfConformity?.electronic_stability_control || '',
      non_pneumatic_tires: vehicle?.statementOfConformity?.non_pneumatic_tires || '',
      school_bus_pedestrian_safety:
        vehicle?.statementOfConformity?.school_bus_pedestrian_safety || '',
      light_vehicle_brake_system: vehicle?.statementOfConformity?.light_vehicle_brake_system || '',
      tire_pressure_monitoring_system:
        vehicle?.statementOfConformity?.tire_pressure_monitoring_system || '',
      new_pneumatic_radial_tires: vehicle?.statementOfConformity?.new_pneumatic_radial_tires || '',
      occupant_protection_interior_impact:
        vehicle?.statementOfConformity?.occupant_protection_interior_impact || '',
      head_restraints: vehicle?.statementOfConformity?.head_restraints || '',
      impact_protection_from_steering:
        vehicle?.statementOfConformity?.impact_protection_from_steering || '',
      steering_control_rearward: vehicle?.statementOfConformity?.steering_control_rearward || '',
      glazing_materials: vehicle?.statementOfConformity?.glazing_materials || '',
      door_locks_and_retention_components:
        vehicle?.statementOfConformity?.door_locks_and_retention_components || '',
      seating_systems: vehicle?.statementOfConformity?.seating_systems || '',
      occupant_crash_protection: vehicle?.statementOfConformity?.occupant_crash_protection || '',
      seat_belt_assemblies: vehicle?.statementOfConformity?.seat_belt_assemblies || '',
      seat_belt_assemblies_anchorages:
        vehicle?.statementOfConformity?.seat_belt_assemblies_anchorages || '',
      windshield_mounting: vehicle?.statementOfConformity?.windshield_mounting || '',
      child_restraint_systems: vehicle?.statementOfConformity?.child_restraint_systems || '',
      side_impact_protection: vehicle?.statementOfConformity?.side_impact_protection || '',
      roof_crush_resistance: vehicle?.statementOfConformity?.roof_crush_resistance || '',
      bus_exists_window_retention:
        vehicle?.statementOfConformity?.bus_exists_window_retention || '',
      windshield_zone_intrusion: vehicle?.statementOfConformity?.windshield_zone_intrusion || '',
      school_bus_rollover_protection:
        vehicle?.statementOfConformity?.school_bus_rollover_protection || '',
      school_bus_joint_strength: vehicle?.statementOfConformity?.school_bus_joint_strength || '',
      school_bus_seating_crash_protection:
        vehicle?.statementOfConformity?.school_bus_seating_crash_protection || '',
      rear_impact_guards: vehicle?.statementOfConformity?.rear_impact_guards || '',
      child_restraint_anchorage_system:
        vehicle?.statementOfConformity?.child_restraint_anchorage_system || '',
      ejection_mitigation: vehicle?.statementOfConformity?.ejection_mitigation || '',
      fuel_system_integrity: vehicle?.statementOfConformity?.fuel_system_integrity || '',
      flammability_of_interior_materials:
        vehicle?.statementOfConformity?.flammability_of_interior_materials || '',
      fuel_system_integrity_of_cng_vehicle:
        vehicle?.statementOfConformity?.fuel_system_integrity_of_cng_vehicle || '',
      cng_fuel_container_integrity:
        vehicle?.statementOfConformity?.cng_fuel_container_integrity || '',
      electric_powered_vehicles: vehicle?.statementOfConformity?.electric_powered_vehicles || '',
      interior_trunk_release: vehicle?.statementOfConformity?.interior_trunk_release || '',
      platform_lift_installations:
        vehicle?.statementOfConformity?.platform_lift_installations || '',
      low_speed_vehicles: vehicle?.statementOfConformity?.low_speed_vehicles || '',
      vin_requirements: vehicle?.statementOfConformity?.vin_requirements || '',
      certification: vehicle?.statementOfConformity?.certification || '',
      bumper_standard: vehicle?.statementOfConformity?.bumper_standard || '',
      rearview_mirros: vehicle?.statementOfConformity?.rearview_mirros || '',
      hoot_latch_system: vehicle?.statementOfConformity?.hoot_latch_system || '',
      theft_protection: vehicle?.statementOfConformity?.theft_protection || '',
      motor_vehicle_brake_fluids: vehicle?.statementOfConformity?.motor_vehicle_brake_fluids || '',
      power_operated_window_systems:
        vehicle?.statementOfConformity?.power_operated_window_systems || '',
      new_pneumatic_tires2: vehicle?.statementOfConformity?.new_pneumatic_tires2 || '',
      tire_selection_and_rims2: vehicle?.statementOfConformity?.tire_selection_and_rims2 || '',
      rear_impact_protection: vehicle?.statementOfConformity?.rear_impact_protection || '',
      term_1: vehicle?.statementOfConformity?.term_1 || 0,
      term_2: vehicle?.statementOfConformity?.term_2 || 0,
      term_3: vehicle?.statementOfConformity?.term_3 || 0,
      term_4: vehicle?.statementOfConformity?.term_4 || 0,
    },
    onSubmit: (values) => {
      handleUpdateStatementConformity(values);
    },
  });

  const handleUpdateStatementConformity = async (data) => {
    if (vehicle.id === undefined) return;
    await update(vehicle.id, data, {
      onSuccess: (data) => {
        if (data.document) {
          onUpdateDocument(data.document);
        }

        Notification(NOTIFICATION_TYPE.SUCCESS, 'Statement of conformity is saved successfully.');
      },
      onFailed: (e) => {
        Notification(NOTIFICATION_TYPE.DANGER, e.message);
      },
    });
    window.scrollTo(0, 0);
    setOpen(false);
  };
  return (
    <div>
      <Button
        className="mb-4"
        type={'button'}
        onClick={() => setOpen(!isOpen)}
        data-testid="statement-btn"
      >
        Statement of Conformity
      </Button>
      {isOpen && (
        <form onSubmit={form.handleSubmit}>
          <div>
            <StatementConformityTable data={form.values} form={form} />

            <div className={'ps-4'}>
              <p className={'pt-3'}>
                <b>I state and provide substantiation that</b> (check only one):
              </p>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  {...form?.getFieldProps('term_1')}
                  onChange={(e) => {
                    form.setFieldValue('term_1', Number(e.target.value));
                  }}
                >
                  {term1Options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <p className={'pt-4'}>
                <b>I certify that</b> (check only one):
              </p>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  {...form?.getFieldProps('term_2')}
                  onChange={(e) => {
                    form.setFieldValue('term_2', Number(e.target.value));
                  }}
                >
                  {term2Options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <p className={'pt-4'}>
                <b>I certify that</b> (check only one):
              </p>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  {...form?.getFieldProps('term_3')}
                  onChange={(e) => {
                    form.setFieldValue('term_3', Number(e.target.value));
                  }}
                >
                  {term3Options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <p className={'pt-4'}>
                <b>
                  I certify that noncompliant motor vehicle equipment items that were removed from
                  this imported vehicle in this course of performing conformance modifications have
                  been
                </b>{' '}
                (check only one):
              </p>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  {...form?.getFieldProps('term_4')}
                  onChange={(e) => {
                    form.setFieldValue('term_4', Number(e.target.value));
                  }}
                >
                  {term4Options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <Button
            className="mt-2"
            type="submit"
            disabled={!hasPermission(user, { resource: 'vehicle', action: 'update' }) || loading}
            loading={loading}
          >
            Save
          </Button>
        </form>
      )}
    </div>
  );
};

export default StatementConformity;
