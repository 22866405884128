import Http from '../utilities/http';

export class ModalApi {
  static create(name, modalData) {
    return Http.post('/modals', { name, modalData });
  }

  static search(modalList) {
    return Http.get('/modals', { modalList });
  }
}