import { useQuery } from '@tanstack/react-query';
import { ImportersApi } from 'api';

/**
 *  Custom React Query hook for fetching RI users
 *
 * @param {{page: number, perPage: number, order: string, orderBy: string}} queryObj
 * @param @param {{ onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetUsers(queryObj, { onError } = {}) {
  return useQuery({
    queryKey: ['users', queryObj],
    queryFn: () => ImportersApi.search(queryObj).then((res) => res.data),
  });
}
