import React, { useState } from 'react';

import { BackButton } from '../../../components';
import VehicleForm from '../VehicleForm/VehicleForm';

export default function AddVehicleInfo() {
  const [vehicle, setVehicle] = useState(null);

  return (
    <div>
      <div className="mb-5">
        <BackButton />
      </div>

      <div className="row">
        <h1>New Import</h1>
      </div>

      <hr />

      <VehicleForm action="create" vehicle={vehicle} setVehicle={setVehicle} />
    </div>
  );
}
