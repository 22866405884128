import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { VehicleApi } from 'api';
import { ROUTES } from 'constants/routes';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { hasPermission } from 'services/auth';

import { ConfirmModal, MoreMenu, NOTIFICATION_TYPE, Notification } from 'components';

import './styles.scss';

const VehicleTableActions = ({ vehicle, refresh }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState();

  const { user } = useContext(AuthContext);

  const handleShowConfirm = (action) => {
    setAction(action);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setLoading(true);

    if (!action) {
      return;
    }

    const apiAction = action === 'remove' ? VehicleApi.delete : VehicleApi.activate;
    apiAction(vehicle.id)
      .then(() => {
        setLoading(false);
        setAnchorEl(null);
        Notification(
          NOTIFICATION_TYPE.SUCCESS,
          `Vehicle is ${action === 'remove' ? 'removed' : 'activated'} successfully.`
        );

        if (refresh) refresh();
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <MoreMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          onClick={() => handleShowConfirm('remove')}
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'delete' }) || loading}
          data-testid="delete-btn"
        >
          <DeleteOutlineIcon sx={{ mr: '1rem' }} />
          Delete
        </MenuItem>
        <MenuItem
          className="edit-vehicle"
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'update' })}
        >
          <Link
            to={ROUTES.MAIN.VEHICLES.EDIT.replace(':id', vehicle.id)}
            style={{ textDecoration: 'none' }}
          >
            <EditIcon sx={{ mr: '1rem' }} />
            Edit
          </Link>
        </MenuItem>
      </MoreMenu>

      {action ? (
        <ConfirmModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          title={action === 'remove' ? 'Remove Vehicle' : 'Activate Vehicle'}
          content={`Are you sure you want to ${action} this vehicle?`}
          onConfirm={handleConfirm}
        />
      ) : null}
    </>
  );
};

export default VehicleTableActions;
