import axios from 'axios';

import { APP_URL } from '../constants/config';

const http = axios.create({ baseURL: `${APP_URL}/` });

const request = (method, url, options) => {
  return http
    .request({
      ...options,
      method,
      url,
      withCredentials: true,
    })
    .catch(httpErrorHandler);
};

const httpErrorHandler = async (err) => {
  const response = err?.response;
  const data = response?.data;

  let message = 'Network Error!';

  if (
    response.request.responseType === 'arraybuffer' &&
    data?.toString() === '[object ArrayBuffer]'
  ) {
    const res = JSON.parse(new TextDecoder().decode(data));
    message = res?.message || message;
  } else {
    message = data?.message || message;
  }

  throw new Error(message);
};

const Http = {
  get(url, params = {}, headers = {}) {
    return request('GET', url, { params, headers });
  },
  post(url, body = {}, headers = {}, options = {}) {
    return request('POST', url, { data: body, headers, ...options });
  },
  put(url, body = {}, headers = {}) {
    return request('PUT', url, { data: body, headers });
  },
  patch(url, body = {}, headers = {}) {
    return request('PATCH', url, { data: body, headers });
  },
  delete(url, body = {}, headers = {}) {
    return request('DELETE', url, { data: body, headers });
  },
};

export default Http;
