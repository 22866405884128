import React, { useEffect, useRef } from 'react';
import { Trash2 as RemoveIcon } from 'react-feather';
import Signature_Pad from 'signature_pad';

import { IconButton } from 'components/IconButton';

import './styles.scss';

export const SignaturePad = ({ pad, setPad, className, onBeginStroke, onClear }) => {
  const canvasRef = useRef(null);

  const handleClear = () => {
    if (pad) {
      pad.clear();
      typeof onClear === 'function' && onClear();
    }
  };

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;

      const signaturePad = new Signature_Pad(canvas, {
        backgroundColor: '#fff',
        penColor: '#000',
        minWidth: 4,
        maxWidth: 5,
      });

      signaturePad.addEventListener('beginStroke', onBeginStroke);

      setPad(signaturePad);

      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        signaturePad.clear();
      };

      resizeCanvas();

      window.addEventListener('resize', resizeCanvas);

      return () => {
        window.removeEventListener('resize', resizeCanvas);
        signaturePad.removeEventListener('beginStroke', onBeginStroke);
      };
    }
  }, [canvasRef, onBeginStroke, setPad]);

  return (
    <div className="signature-pad-container">
      <div className="pad-wrapper">
        <canvas className={className} ref={canvasRef} />

        <IconButton
          className="pad-clear-button"
          onClick={handleClear}
          icon={<RemoveIcon className="clear-icon" />}
        />
      </div>
    </div>
  );
};
