import React from 'react';
import Header from './Header';
import MobileSidebar from './MobileSidebar';
import { VinScannerProvider } from '../../providers/vinScannerProvider';
import './styles.scss';

export default function MainLayout({ children }) {
  return (
    <VinScannerProvider>
      <div className="main-layout">
        <Header />

        <div className="container-fluid main-container">
          {children}
        </div>

        <MobileSidebar />
      </div>
    </VinScannerProvider>
  );
}

