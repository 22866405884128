import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentCategoryApi } from 'api';

export function useDeleteDocumentCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => DocumentCategoryApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['document-categories']);
    },
  });
}
