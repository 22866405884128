import clsx from 'clsx';
import { Draggable } from 'react-beautiful-dnd';

import './styles.scss';

export function DraggableItem({ draggableId, index, children }) {
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          className={clsx('dragger', {
            dragging: snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {children(provided.dragHandleProps)}
        </div>
      )}
    </Draggable>
  );
}
