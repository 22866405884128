import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Button } from '../Button';
import { Dialog } from '../Dialog';

export const ConfirmModal = ({
  show,
  handleClose,
  title = 'Confirmation',
  content,
  onConfirm,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      title={title}
    >
      <DialogContent sx={{ py: 7.5 }}>
        <DialogContentText
          sx={{ color: 'primary.contrastText', fontSize: '1.125rem' }}
        >
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          variant="dark"
        >
          No
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{ minWidth: '100px' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
