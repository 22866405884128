import { DialogActions, DialogContent } from '@mui/material';
import React from 'react';

import { Button, Dialog, Input } from 'components';

import { useModalData } from './useModalData';

export default function AddManufacturerModal({ name, setLastSaved, lastSaved, show, handleClose }) {
  const { isSaving, modalData, saveClick, onFieldChange } = useModalData({
    name,
    setLastSaved,
    lastSaved,
    handleClose,
  });

  return (
    <Dialog open={show} onClose={handleClose} title="Add Manufacturer">
      <DialogContent sx={{ py: 7.5 }}>
        <div>
          <Input
            label="Manufacturer"
            type="text"
            onChange={(e) => onFieldChange('name', e.target.value)}
            value={modalData.name || ''}
            data-testid="manufacturerName"
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="dark" onClick={handleClose}>
          Close
        </Button>

        <Button loading={isSaving} onClick={saveClick}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
