import Http from '../utilities/http';

export class PhotosApi {
  static find(id) {
    return Http.get(`/photos/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  static upload(data) {
    return Http.post('/photos/upload-photo', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static updateCategory(data) {
    return Http.patch('/photos/update-category', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static delete(id) {
    return Http.delete(`/photos/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static sort(data) {
    return Http.post('/photos/sort', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }
}
