import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import 'react-indiana-drag-scroll/dist/style.css';
import { reorder, sortItem } from 'utilities/dragDrop';

import { NOTIFICATION_TYPE, Notification } from 'components';
import { DraggableItem } from 'features/common/drag-drop/DraggableItem';
import { DroppableZone } from 'features/common/drag-drop/DroppableZone';
import { useSortVehiclePhotos } from 'features/photos/useSortVehiclePhotos';
import { useUpdatePhotoCategory } from 'features/photos/useUpdatePhotoCategory';

import { ChangeCategoryConfirmModal } from '../ChangeCategoryConfirmModal';
import { PhotoCard } from './PhotoCard';
import './styles.scss';

export function UploadedPhotos({ photos = [] }) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [droppedPhoto, setDroppedPhoto] = React.useState(null);

  const requiredPhotos = React.useMemo(() => {
    return photos.filter((d) => d.category?.isRequired).sort(sortItem);
  }, [photos]);

  const extraPhotos = React.useMemo(() => {
    return photos.filter((d) => !d.category?.isRequired).sort(sortItem);
  }, [photos]);

  const { mutate: sortVehiclePhotos } = useSortVehiclePhotos();
  const { mutate: updatePhotoCategory } = useUpdatePhotoCategory({
    onSuccess: (category) => {
      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `Successfully move "${category.name}" to NHTSA package.`
      );
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  const handleDrop = (dropItem) => {
    if (!dropItem.destination) {
      return;
    }

    const droppedPhoto = photos.find((d) => d.id === parseInt(dropItem.draggableId));

    if (dropItem.destination.droppableId === dropItem.source.droppableId) {
      // Sorting photos
      if (dropItem.source.index !== dropItem.destination.index) {
        let items;

        if (dropItem.destination.droppableId === 'requiredPhoto') {
          items = reorder(requiredPhotos, dropItem.source.index, dropItem.destination.index);
          items = items.map((item, index) => ({
            ...item,
            order: index + 1,
          }));
        } else {
          items = reorder(extraPhotos, dropItem.source.index, dropItem.destination.index);
          items = items.map((item, index) => ({
            ...item,
            order: index + 1,
          }));
        }

        sortVehiclePhotos({
          items: items.map((item) => ({ id: item.id, order: item.order })),
          vehicleId: droppedPhoto.vinCode,
        });
      }
    } else {
      // Changing photo's category
      const category = droppedPhoto?.category;

      // Prevent moving NHTSA required default photos
      if (
        dropItem.destination.droppableId === 'additionalPhoto' &&
        category?.isRequired &&
        !category?.canDelete
      ) {
        return Notification(NOTIFICATION_TYPE.DANGER, 'Cannot move default NHTSA required photos.');
      }

      setDroppedPhoto(droppedPhoto);
      setShowConfirm(true);
    }
  };

  const handleConfirm = () => {
    const category = droppedPhoto?.category;

    if (!category) return;

    updatePhotoCategory({
      id: category.id,
      isRequired: !category.isRequired,
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDrop}>
        <div>
          <h3 className="mb-4">NHTSA Required Photos</h3>
          <DroppableZone droppableId="requiredPhoto" type="photos">
            {requiredPhotos.map((item, index) => {
              return (
                <DraggableItem key={item.id} draggableId={item.id.toString()} index={index}>
                  {(dragHandleProps) => <PhotoCard dragHandleProps={dragHandleProps} item={item} />}
                </DraggableItem>
              );
            })}
          </DroppableZone>
        </div>
        <hr />
        <div>
          <h3 className="mb-4">Additional Photos</h3>
          <DroppableZone droppableId="additionalPhoto" type="photos">
            {extraPhotos.map((item, index) => {
              return (
                <DraggableItem key={item.id} draggableId={item.id.toString()} index={index}>
                  {(dragHandleProps) => <PhotoCard dragHandleProps={dragHandleProps} item={item} />}
                </DraggableItem>
              );
            })}
          </DroppableZone>
        </div>
        {!!droppedPhoto && (
          <ChangeCategoryConfirmModal
            show={showConfirm}
            onClose={() => setShowConfirm(false)}
            type="photo"
            categoryName={droppedPhoto.category.name}
            isCategoryRequired={droppedPhoto.category.isRequired}
            onConfirm={handleConfirm}
          />
        )}
      </DragDropContext>
    </>
  );
}
