import React from 'react';

import { CustomSelect, NOTIFICATION_TYPE, Notification } from 'components';

import { useGetConformityLocations } from '../hooks/useGetConformityLocations';

export function ConformityLocationSelect({ label, value, placeholder, onChange, error, ...rest }) {
  const { data, isLoading, isFetching } = useGetConformityLocations(
    {
      order: 'desc',
      orderBy: 'title',
    },
    {
      onError: (error) => {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      },
    }
  );

  const options = React.useMemo(() => {
    const conformityLocations = data.items;

    return conformityLocations.map((item) => ({
      label: item.title,
      value: item.id,
    }));
  }, [data.items]);

  return (
    <CustomSelect
      label={label}
      options={options}
      value={options.find((item) => item.value === value)}
      onChange={onChange}
      error={error}
      placeholder={placeholder}
      loading={isLoading || isFetching}
      hasBorder
      {...rest}
    />
  );
}
