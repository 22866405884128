import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { BuyerForm } from 'features/buyers/components/form/BuyerForm';
import { CreateDataModal } from 'features/vehicles/components/modals/CreateDataModal';

import { DataSelect } from '../components/DataSelect';

export function BuyerInfo({ form, lastSaved, setLastSaved }) {
  const { user } = React.useContext(AuthContext);

  const [showBuyerModal, setShowBuyerModal] = React.useState(false);

  const onSuccess = (data) => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Buyer was created successfully');

    setLastSaved({ Buyer: data });
    setShowBuyerModal(false);
  };

  const onError = (err) => {
    Notification(NOTIFICATION_TYPE.DANGER, err.message);
  };

  return (
    <>
      <div className="col-7 col-md-6">
        <DataSelect
          apiFieldName="Buyer"
          label="Importer *"
          placeholder="Select Importer"
          lastSaved={lastSaved}
          isCompanyNameSelect
          form={form}
          error={form.errors.buyer_code && form.touched.buyer_code ? form.errors.buyer_code : ''}
        />
      </div>
      <div className="col-5 col-md-6">
        {hasPermission(user, { resource: 'buyer', action: 'create' }) && (
          <>
            <Button type="button" onClick={() => setShowBuyerModal(true)}>
              + Add
            </Button>
            <CreateDataModal
              title="Add Buyer"
              show={showBuyerModal}
              handleClose={() => setShowBuyerModal(false)}
            >
              <BuyerForm action="quick-create" onSuccess={onSuccess} onError={onError} />
            </CreateDataModal>
          </>
        )}
      </div>
    </>
  );
}
