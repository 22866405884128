import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from '../../layout/AuthLayout';
import { ROUTES } from '../../constants/routes';
import Register from './Register';
import Login from './Login';
import {
  CustomsBrokerRelease,
  PricingAgreement,
  RIUserAgreement,
  TermsOfUse,
} from '../public';
import ResetPassword from "./ResetPassword";

const Auth = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path={ROUTES.HOME} element={<Login/>} />
        <Route path={ROUTES.AUTH.REGISTER} element={ <Register />} />
        <Route path={ROUTES.AUTH.PASSWORD_RESET} element={ <ResetPassword />} />

        <Route path={ROUTES.PUBLIC.RI_USER_AGREEMENT} element={<RIUserAgreement />} />
        <Route path={ROUTES.PUBLIC.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route path={ROUTES.PUBLIC.PRICING_AGREEMENT} element={<PricingAgreement />} />
        <Route path={ROUTES.PUBLIC.CUSTOMS_BROKER_RELEASE} element={<CustomsBrokerRelease />} />

        <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
      </Routes>
    </AuthLayout>
  )
};

export default Auth;
