import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PhotosApi } from 'api';

export function useDeleteVehiclePhoto() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-vehicle-photo'],
    mutationFn: (id) => PhotosApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['vehicle-photos']);
      queryClient.invalidateQueries(['photo-categories']);
    },
  });
}
