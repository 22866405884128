import ClearIcon from '@mui/icons-material/Clear';
import TodayIcon from '@mui/icons-material/Today';
import classnames from 'classnames';
import 'moment-timezone';
import React from 'react';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { IconButton } from '../IconButton';
import './styles.scss';

const CustomDatePicker = ({
  id = '',
  disabled = false,
  label,
  dark = false,
  hasBorder = false,
  dateFormat = 'MM/DD/YYYY',
  timeFormat = true,
  error = '',
  value,
  onChange = () => {},
  closeOnSelect = false,
  ...rest
}) => {
  const renderInput = (props) => {
    const handleClear = () => {
      props.onChange({ target: { value: '' } });
    };

    const displayedValue =
      props.value === '' ? (
        <span className="presentational-placeholder">{props.placeholder}</span>
      ) : (
        props.value
      );

    return (
      <div
        className={classnames('datepicker-input-wrapper', {
          error: !!error,
          bordered: hasBorder,
          dark: dark,
        })}
      >
        <input {...props} autoComplete="off" />
        <div className="presentational-input">{displayedValue}</div>
        {value ? (
          <IconButton
            className="datepicker-clear-button"
            icon={<ClearIcon />}
            onClick={handleClear}
          />
        ) : null}
        <div className="icon-wrapper">
          <TodayIcon />
        </div>
      </div>
    );
  };
  return (
    <div className="datepicker-container">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <DatePicker
        className={classnames('datepicker-wrapper', {
          dark: dark,
        })}
        inputProps={{ id, placeholder: dateFormat, disabled }}
        renderInput={renderInput}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        value={value}
        onChange={onChange}
        closeOnSelect={closeOnSelect}
        utc={true}
        displayTimeZone="UTC"
        {...rest}
      />
      {error && <p className="mb-0 text-red">{error}</p>}
    </div>
  );
};

export default CustomDatePicker;
