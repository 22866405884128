import Http from '../utilities/http';
import { Notification, NOTIFICATION_TYPE } from '../components';

export class CommonApi {
  static searchByTable(table) {
    return Http.get('/common', { table })
      .then((res) => {
        return res
      }).catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      })
  }
}
