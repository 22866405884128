import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { titleCase } from 'utilities';

import { Loading } from 'components';

import { useGetAllPermissions } from '../hooks/useGetAllPermissions';
import './styles.scss';

export function UserPermissions({ form }) {
  const { data: permissions, isLoading, error } = useGetAllPermissions();

  const _permissions = _.groupBy(permissions, 'resource');

  const handleChangePermission = (e) => {
    const value = Number(e.target.value);

    if (e.target.checked) {
      form.setFieldValue('permissionIds', [...form.values.permissionIds, value]);
    } else {
      form.setFieldValue(
        'permissionIds',
        form.values.permissionIds.filter((id) => id !== value)
      );
    }
  };

  return (
    <div>
      <details className="account_permissions-details__wrapper">
        <summary>
          <h2>Account Permissions</h2>
          <ChevronRight className="summary-icon" />
        </summary>
        <div className="account_permissions-details__content">
          {isLoading && <Loading />}
          {!isLoading && error && <p>{error?.message || 'Failed to load permissions'}</p>}
          {!isLoading &&
            !error &&
            Object.keys(_permissions).map((resource) => (
              <fieldset key={resource} className="account_permissions-details__fieldset">
                <legend>{resource}</legend>
                {_permissions[resource].map(({ id, action }) => (
                  <FormControlLabel
                    key={id}
                    value={id}
                    checked={form.values.permissionIds.includes(id)}
                    onChange={handleChangePermission}
                    label={_.capitalize(titleCase(action))}
                    control={<Checkbox />}
                  />
                ))}
              </fieldset>
            ))}
        </div>
      </details>
    </div>
  );
}
