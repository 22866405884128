export const BROKER = 'Customs Broker';
export const BOND_PROVIDER = 'DOT Bond Provider';
export const CONFORMITY_LOCATIONS = 'Conformity Locations';
export const BONDS_AND_WARRANTIES = 'Bonds and Warranties';
export const WARRANTY_PROVIDER = 'Policy Provider';
export const ACCOUNTS = 'Accounts';
export const PROFILE = 'Profile';
export const BUYERS = 'Buyers';
export const SELLERS = 'Sellers';
export const CARRIERS = 'Carriers';
export const CONSIGNEES = 'Consignees';
export const REGISTERED_IMPORTER = 'RegisteredImporter';

export const DESIGNATIONS = [
  {
    label: 'Owner',
    value: 'Owner',
  },
  {
    label: 'CEO',
    value: 'CEO',
  },
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'President',
    value: 'President',
  },
];
