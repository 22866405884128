import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * @typedef {import('react-bootstrap/Spinner').SpinnerProps['size']} SpinnerSize
 */

/**
 *
 * @param {{ size?: SpinnerSize }} props
 * @returns {JSX.Element}
 */
export const Loading = ({ size = 'md' }) => {
  return (
    <div className="d-flex justify-content-center" data-testid="loading">
      <Spinner animation="border" role="status" size={size}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
