export class ObjectHelper {
  static trimQuery(query) {
    if (!query || typeof query !== 'object') return query;

    if (Array.isArray(query)) {
      return query.map((item) => ObjectHelper.trimQuery(item));
    }

    const result = {};
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        (!Array.isArray(value) || value.length > 0)
      ) {
        result[key] = ObjectHelper.trimQuery(value);
      }
    });
    return result;
  }
}
