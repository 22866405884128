import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Trash2 } from 'react-feather';

import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { NOTIFICATION_TYPE, Notification } from 'components/Notification';

import './styles.scss';

export function ImageDropzone({
  placeholder = 'Drag and drop, or click the button to select an image file',
  onUploadFile,
  className,
}) {
  const [fileUrl, setFileUrl] = React.useState(null);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: 'image/*',
    onDropAccepted: (files) => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }

      const url = URL.createObjectURL(files[0]);
      setFileUrl(url);

      onUploadFile(files[0]);
    },
    onDropRejected: (fileRejections) => {
      Notification(NOTIFICATION_TYPE.DANGER, fileRejections[0].errors[0].message);
    },
  });

  const handleReset = () => {
    setFileUrl(null);
    onUploadFile(null);
  };

  return (
    <div className={classNames('image-dropzone', className)}>
      {!fileUrl ? (
        <Box
          {...getRootProps()}
          role="presentation"
          sx={{
            width: '100%',
            height: '100%',
            border: '1px dashed grey',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop file here ...</p>
          ) : (
            <>
              <label>{placeholder}</label>
              <Button onClick={open}>Select file</Button>
            </>
          )}
        </Box>
      ) : (
        <div className="image-preview">
          <img
            src={fileUrl}
            width="100%"
            height="100%"
            alt="imported file"
            className="image-preview__image"
          />
          <IconButton
            type="button"
            aria-label="clear"
            className="image-preview__clear-button"
            icon={<Trash2 size={20} className="image-preview__clear-button--icon" />}
            onClick={handleReset}
          />
        </div>
      )}
    </div>
  );
}
