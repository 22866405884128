import { REGISTERED_IMPORTER } from 'constants/userSetting';

export function isRegisteredImporter(user) {
  return user.type === REGISTERED_IMPORTER;
}

/**
 * @typedef {'vehicle' | 'buyer' | 'seller' | 'carrier' | 'consignee'} Resource
 */

/**
 * @typedef {'create' | 'read' | 'update' | 'delete' | 'submit_to_broker' | 'share'} Action
 */

/**
 * Check if user has permission to perform action on resource
 *
 * @param {Object} user
 * @param {Object} permission
 * @param {Resource} permission.resource
 * @param {Action} permission.action
 * @returns
 */
export function hasPermission(user, permission) {
  if (isRegisteredImporter(user)) {
    return true;
  }

  const { resource, action } = permission;

  return !!user.permissions.find(
    (p) => p.resource.toLowerCase() === resource && p.action.toLowerCase() === action
  );
}
