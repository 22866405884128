import React from 'react';
import { Link } from 'react-router-dom';

import { VehicleDocumentUpload, VehicleProfileColumn } from 'components';

import { UploadedDocuments } from './UploadedDocuments';
import './style.scss';

const DocumentsColumn = ({ vehicle = {}, documents, categories, className }) => {
  return (
    <VehicleProfileColumn title="Documents" className={className}>
      <VehicleDocumentUpload vehicle={vehicle} documents={documents} category={categories} />
      <hr />
      <UploadedDocuments documents={documents} />
      <Link to={`/vehicles/${vehicle.id}/ri-label`}>RI Label Print</Link>
    </VehicleProfileColumn>
  );
};

export default DocumentsColumn;
