import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import 'react-indiana-drag-scroll/dist/style.css';
import { reorder, sortItem } from 'utilities/dragDrop';

import { NOTIFICATION_TYPE, Notification } from 'components';
import { DraggableItem } from 'features/common/drag-drop/DraggableItem';
import { DroppableZone } from 'features/common/drag-drop/DroppableZone';
import { useSortVehicleDocuments } from 'features/documents/useSortVehicleDocuments';
import { useUpdateDocumentCategory } from 'features/documents/useUpdateDocumentCategory';

import { ChangeCategoryConfirmModal } from '../ChangeCategoryConfirmModal';
import DocumentItem from './DocumentItem';
import './style.scss';

export function UploadedDocuments({ documents = [] }) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [droppedDocument, setDroppedDocument] = React.useState(null);

  const requiredDocuments = React.useMemo(() => {
    return documents.filter((d) => d.category.isNHTSARequired).sort(sortItem);
  }, [documents]);

  const extraDocuments = React.useMemo(() => {
    return documents.filter((d) => !d.category.isNHTSARequired).sort(sortItem);
  }, [documents]);

  const { mutate: updateDocumentCategory } = useUpdateDocumentCategory({
    onSuccess: (category) => {
      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `Successfully move "${category.name}" to NHTSA package.`
      );
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  const { mutate: sortVehicleDocuments } = useSortVehicleDocuments();

  const handleDrop = (dropItem) => {
    if (!dropItem.destination) {
      return;
    }

    const droppedDocument = documents.find((d) => d.id === parseInt(dropItem.draggableId));

    if (dropItem.destination.droppableId === dropItem.source.droppableId) {
      // Sorting documents
      if (dropItem.source.index !== dropItem.destination.index) {
        let items;

        if (dropItem.destination.droppableId === 'requiredDocument') {
          items = reorder(requiredDocuments, dropItem.source.index, dropItem.destination.index);
          items = items.map((item, index) => ({
            ...item,
            order: index + 1,
          }));
        } else {
          items = reorder(extraDocuments, dropItem.source.index, dropItem.destination.index);
          items = items.map((item, index) => ({
            ...item,
            order: index + 1,
          }));
        }

        sortVehicleDocuments({
          items: items.map((item) => ({ id: item.id, order: item.order })),
          vehicleId: droppedDocument.vehicleId,
        });
      }
    } else {
      // Changing document's category
      const category = droppedDocument?.category;

      // Prevent moving NHTSA required default docs
      if (
        dropItem.destination.droppableId === 'additionalDocument' &&
        category?.isNHTSARequired &&
        !category?.canDelete
      ) {
        return Notification(
          NOTIFICATION_TYPE.DANGER,
          'Cannot move default NHTSA required documents.'
        );
      }

      setDroppedDocument(droppedDocument);
      setShowConfirm(true);
    }
  };

  const handleConfirm = () => {
    const category = droppedDocument?.category;

    if (!category) return;

    updateDocumentCategory({
      id: category.id,
      isNHTSARequired: !category.isNHTSARequired,
    });
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <div>
        <h3 className="mb-4">NHTSA Required Documents</h3>
        <DroppableZone droppableId="requiredDocument" type="documents" className="documents-list">
          {requiredDocuments.map((item, index) => {
            return (
              <DraggableItem key={item.id} draggableId={item.id.toString()} index={index}>
                {(dragHandleProps) => (
                  <DocumentItem dragHandleProps={dragHandleProps} item={item} />
                )}
              </DraggableItem>
            );
          })}
        </DroppableZone>
      </div>
      <hr />
      <div>
        <h3 className="mb-4">Additional Documents</h3>
        <DroppableZone droppableId="additionalDocument" type="documents" className="documents-list">
          {extraDocuments.map((item, index) => {
            return (
              <DraggableItem key={item.id} draggableId={item.id.toString()} index={index}>
                {(dragHandleProps) => (
                  <DocumentItem dragHandleProps={dragHandleProps} item={item} />
                )}
              </DraggableItem>
            );
          })}
        </DroppableZone>
      </div>
      {!!droppedDocument && (
        <ChangeCategoryConfirmModal
          show={showConfirm}
          onClose={() => setShowConfirm(false)}
          type="document"
          categoryName={droppedDocument.category.name}
          isCategoryRequired={droppedDocument.category.isNHTSARequired}
          onConfirm={handleConfirm}
        />
      )}
    </DragDropContext>
  );
}
