import * as Sentry from '@sentry/react';
import 'bootstrap';
import { render } from 'react-dom';

import App from './App';
import './index.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_DSN ||
      'https://2e850d01122a48aeb09f4600afc48ba6@o4505465617055744.ingest.sentry.io/4505471357353984',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', process.env.REACT_APP_URL || ''],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: parseFloat(process.env.REACT_APP_TRACES_SAMPLE_RATE) || 0.05, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_REPLAYS_SESSION_SAMPLE_RATE) || 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
      parseFloat(process.env.REACT_APP_REPLAYS_ON_ERROR_SAMPLE_RATE) || 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const rootElement = document.getElementById('root');

render(<App />, rootElement);
