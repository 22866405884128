import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BuyersApi } from 'api';

/**
 * Custom React Query hook that handles the creation of buyers.
 *
 * @param {"single" | "batch"} type
 * @param {{ onSuccess?: () => void; onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseMutationResult<any, unknown, any, unknown>}
 */
export function useCreateBuyers(type = 'single', { onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      if (type === 'batch') {
        return BuyersApi.batchAdd(data);
      }

      return BuyersApi.add(data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['buyers']);
      typeof onSuccess === 'function' && onSuccess(res.data);
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
