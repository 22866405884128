import { Link } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import './styles.scss';

export const Input = ({
  label,
  className = '',
  hasBorder = false,
  error = '',
  type,
  startIcon,
  iconHref,
  endAdornment,
  ...props
}) => {
  return (
    <div
      className={classNames(`custom-input-container ${className}`, {
        bordered: hasBorder,
        error: !!error,
        'password-input': type === 'password',
        'icon-input': startIcon,
      })}
    >
      {label && <p className="custom-input-label">{label}</p>}
      <div className="input-wrapper">
        {startIcon && (
          <Link href={iconHref} className="icon-show d-flex-center" data-testid="toggle-type-icon">
            {startIcon}
          </Link>
        )}
        <input className={classNames('custom-input')} type={type} {...props} />
        {endAdornment && <div className="end-adornment d-flex-center">{endAdornment}</div>}
      </div>
      {error && <p className="mb-0 text-red">{error}</p>}
    </div>
  );
};
