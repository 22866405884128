import React from "react";

export const TERMS_OF_USE = {
    title: 'Terms of Use',
    content: <>
        <p>
            <b>Terms of Use</b>
        </p>

        <p>
            <b>Effective Date</b>: August 20, 2024
        </p>

        <p>
            Welcome to vin2.com, a website operated by Vin2 Software, LLC ("Company," "we," "us," or "our").
            By accessing or using vin2.com (the "Site"), you agree to comply with and be bound by the following Terms of Use ("Terms").
            If you do not agree to these Terms, please do not use the Site.
        </p>

        <p>
            1. <b>Acceptance of Terms</b>
            <p className={"my-4"}>
            By using the Site, you affirm that you are of legal age to enter into these Terms and that you agree to abide by all applicable
            laws and regulations. If you are using the Site on behalf of an organization, you represent that you have the authority to bind
            that organization to these Terms.
            </p>
        </p>

        <p>
            2. <b>Changes to Terms</b>
            <p className={"my-4"}>
            We may modify these Terms at any time at our sole discretion. All changes will be effective immediately upon posting.
            Your continued use of the Site following the posting of revised Terms means that you accept and agree to the changes.
            </p>
        </p>

        <p>
            3. <b>Use of the Site</b>
            <p className={"my-4"}>
                <ul>
                    <li><b>License: </b>We grant you a non-exclusive, non-transferable, revocable license to access and use the Site for your personal or internal business use, subject to these Terms.</li>
                    <li><b>Prohibited Conduct: </b>You agree not to engage in any activity that could harm the Site, its performance, or other users, including but not limited to:
                        <ul>
                            <li>Using the Site for any unlawful purpose or in violation of any applicable law.</li>
                            <li>Attempting to gain unauthorized access to any portion or feature of the Site or any other systems or networks connected to the Site.</li>
                            <li>Using the Site to transmit viruses, worms, or other malicious code.</li>
                            <li>Interfering with or disrupting the Site or the servers or networks connected to the Site.</li>
                        </ul>
                    </li>
                </ul>
            </p>
        </p>

        <p>
            4. <b>User Accounts</b>
            <p className={"my-4"}>
            To access certain features of the Site, you may be required to create an account. You agree to provide accurate, current,
            and complete information and to keep this information up to date. You are responsible for maintaining the confidentiality of your account
            credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
            </p>
        </p>

        <p>
            5. <b>Intellectual Property</b>
            <p className={"my-4"}>
                <ul>
                    <li><b>Ownership: </b>
                        All content, features, and functionality on the Site, including but not limited to text, graphics, logos, icons, images, audio clips,
                        video clips, data compilations, and software, are the property of Vin2 Software, LLC or its licensors and are protected by intellectual property laws.
                    </li>
                    <li><b>License to Use Content: </b>
                        You are granted a limited license to access and use the Site and its content for personal or internal business purposes.
                        This license does not allow you to reproduce, modify, distribute, or otherwise use the content for commercial purposes without
                        our express written permission.
                    </li>
                </ul>
            </p>
        </p>

        <p>
            6. <b>Third-Party Links</b>
            <p className={"my-4"}>
            The Site may contain links to third-party websites or services that are not owned or controlled by Vin2 Software, LLC.
            We are not responsible for the content, privacy policies, or practices of any third-party websites or services.
            You acknowledge and agree that Vin2 Software, LLC shall not be liable for any damages or loss caused or alleged to be
            caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any
            third-party websites or services.
            </p>
        </p>

        <p>
            7. <b>Disclaimers</b> <br />
            <p className={"my-4"}>
                <ul>
                    <li><b>No Warranties: </b>
                        The Site is provided on an "as is" and "as available" basis, without any warranties
                        of any kind, either express or implied, including but not limited to implied warranties of merchantability,
                        fitness for a particular purpose, or non-infringement. We do not warrant that the Site will be uninterrupted,
                        error-free, or free of viruses or other harmful components.
                    </li>
                    <li><b>Limitation of Liability: </b>
                        To the fullest extent permitted by law, Vin2 Software, LLC, its affiliates,
                        and their respective officers, directors, employees, agents, and licensors will not be liable for any
                        indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether
                        incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses,
                        resulting from your access to or use of or inability to access or use the Site.
                    </li>
                </ul>
            </p>

        </p>

        <p>
            8. <b>Indemnification</b>
            <p className={"my-4"}>
                You agree to indemnify, defend, and hold harmless Vin2 Software, LLC and its affiliates,
                officers, directors, employees, agents, and licensors from and against any and all claims,
                damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or
                related to your use of the Site or violation of these Terms.
            </p>

        </p>

        <p>
            9. <b>Termination</b>
            <p className={"my-4"}>
                We may terminate or suspend your access to the Site, without prior notice or liability,
                for any reason whatsoever, including without limitation if you breach these Terms. Upon termination,
                your right to use the Site will immediately cease.
            </p>

        </p>

        <p>
            10. <b>Governing Law</b>
            <p className={"my-4"}>
                These Terms are governed by and construed in accordance with the laws of the State of Delaware,
                without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction
                of the courts located within the State of Delaware to resolve any dispute or claim arising from these Terms.
            </p>

        </p>

        <p>
            11. <b>Miscellaneous</b>
            <p className={"my-4"}>
                <ul>
                    <li><b>Entire Agreement:</b>
                        These Terms constitute the entire agreement between you and Vin2 Software,
                        LLC regarding your use of the Site and supersede any prior agreements or understandings.
                    </li>
                    <li><b>Severability:</b>
                        If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will continue in full force and effect.
                    </li>
                    <li><b>Waiver:</b>
                        The failure of Vin2 Software, LLC to enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
                    </li>
                </ul>
            </p>

        </p>

        <p>
            12. <b>Data Storage</b>
            <p className={"my-4"}>
                As part of our service offering, we include data storage for a period of 60 days from the date of data generation or upload ("Initial Storage Period"). After the Initial Storage Period, continued access to and storage of your data will require a separate storage subscription, which will be outlined in our Pricing Agreement. If you do not subscribe to our storage service after the Initial Storage Period, your data may be deleted or archived in accordance with our data retention policy.
                We are not responsible for any loss of data that occurs due to the expiration of the Initial Storage Period if no subscription is obtained.
            </p>

        </p>

        <p>
            13. <b>Contact Us</b> <br/>
            <p className={"my-4"}>If you have any questions about these Terms, please contact us at:</p>
            Vin2 Software, LLC<br/>
            1111b S Governors Avenue STE 21801<br/>
            Dover, DE, 19904<br/>
            United States<br/>
            Email: admin@vin2.com<br/>
        </p>
    </>
}

export const PRIVACY_POLICY = {
    title: 'Privacy policy',
    content: <>
        <p>
            <b>Effective Date: </b>August 20, 2024
        </p>

        <p>
            1. <b>Introduction</b>
            <p className={"my-4"}>
                Vin2 Software, LLC ("Company," "we," "us," or "our") is committed to protecting your privacy.
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when
                you visit our website [www.vin2.com] (the "Site") or use our services. Please read this Privacy Policy
                carefully.
                If you do not agree with the terms of this Privacy Policy, please do not access the Site.
            </p>

        </p>

        <p>
            2. <b>Information We Collect</b>
            <p className={"my-4"}>
                We may collect information about you in a variety of ways. The information we may collect on the Site
                includes:
            </p>

            <ul>
                <li><b>Personal Data: </b>
                    Personally identifiable information, such as your name, shipping address, email address, and
                    telephone number, and demographic information,
                    such as your age, gender, hometown, and interests, that you voluntarily give to us when you
                    register with the Site,
                    when you choose to participate in various activities related to the Site, such as online chat
                    and message boards.
                </li>
                <li><b>Derivative Data: </b>
                    Information our servers automatically collect when you access the Site,
                    such as your IP address, your browser type, your operating system, your access times,
                    and the pages you have viewed directly before and after accessing the Site.
                </li>
                <li><b>Financial Data: </b>
                    Financial information, such as data related to your payment method
                    (e.g., valid credit card number, card brand, expiration date) that
                    we may collect when you purchase, order, return, exchange,
                    or request information about our services from the Site.
                </li>
                <li><b>Mobile Device Data: </b>
                    Device information, such as your mobile device ID, model, and manufacturer,
                    and information about the location of your device,
                    if you access the Site from a mobile device.
                </li>
                <li><b>Third-Party Data: </b>
                    Information from third parties, such as personal information or network friends,
                    if you connect your account to the third party and
                    grant the Site permission to access this information.
                </li>
            </ul>
        </p>

        <p>
            3. <b>Use of Your Information</b>
            <p className={"my-4"}>
                Having accurate information about you permits us to provide you with a smooth, efficient, and customized
                experience.
                Specifically, we may use information collected about you via the Site to:
            </p>
            <ul>
                <li>Create and manage your account.</li>
                <li>Process your transactions.</li>
                <li>Provide customer service.</li>
                <li>Send you relevant marketing communications.</li>
                <li>Improve our website and services.</li>
                <li>Prevent fraudulent transactions and monitor against threats.</li>
                <li>Enforce our terms and conditions.</li>
            </ul>
        </p>

        <p>
            4. <b>Disclosure of Your Information</b>
            <p className={"my-4"}>
                We may share information we have collected about you in certain situations. Your information may be
                disclosed as follows:
            </p>
            <ul>
                <li><b>By Law or to Protect Rights: </b>
                    If we believe the release of information about you is necessary to respond to legal process, to
                    investigate
                    or remedy potential violations of our policies, or to protect the rights, property,
                    and safety of others, we may share your information as permitted or required by any applicable
                    law, rule, or regulation.
                </li>
                <li><b>Business Transfers: </b>
                    We may share or transfer your information in connection with, or during negotiations of, any
                    merger,
                    sale of company assets, financing, or acquisition of all or a portion of our business to another
                    company.
                </li>
                <li><b>Third-Party Service Providers: </b>
                    We may share your information with third parties that perform services for us or on our behalf,
                    including payment processing, data analysis, email delivery,
                    hosting services, customer service, and marketing assistance.
                </li>
            </ul>
        </p>

        <p>
            5. <b>Security of Your Information</b>
            <p className={"my-4"}>
                We use administrative, technical, and physical security measures to help protect your personal
                information. While we have taken reasonable steps
                to secure the personal information you provide to us, please be aware that despite our efforts, no
                security measures are perfect or impenetrable,
                and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>
        </p>

        <p>
            6. <b>Policy for Children</b>
            <p className={"my-4"}>
                We do not knowingly solicit information from or market to children under the age of 13. If we learn that
                we have collected personal
                information from a child under age 13 without verification of parental consent, we will delete that
                information as quickly as possible.
            </p>
        </p>

        <p>
            7. <b>Changes to This Privacy Policy</b>
            <p className={"my-4"}>
                We may update this Privacy Policy from time to time in order to reflect changes to our practices or for
                other operational,
                legal, or regulatory reasons. We will notify you of any changes by updating the
                "Effective Date" at the top of this Privacy Policy.
            </p>
        </p>

        <p>
            8. <b>Contact Us</b> <br/>
            <p className={"my-4"}>If you have questions or comments about this Privacy Policy, please contact us at:</p>
            Vin2 Software, LLC<br/>
            1111b S Governors Avenue STE 21801<br/>
            Dover, DE, 19904<br/>
            United States<br/>
            Email: admin@vin2.com<br/>
        </p>
    </>
}

export const PRICING_AGREEMENT = {
    title: 'Pricing their agreement',
    content: <>
        <p>
            <b>Effective Date: </b>August 20, 2024
        </p>

        <p>
            <p>
                This Pricing Agreement outlines the costs associated with using the services provided by Vin2 Software, LLC.
                By using our services, you agree to the pricing terms set forth below.
            </p>

        </p>

        <p>
            1. <b>Imported Vehicles Pricing</b>
            <p className={"my-4"}>
                The following pricing applies to the number of vehicles imported per month by a Registered Importer:
            </p>
            <table>
                <tr>
                    <th style={{width: '50%'}}><b>Imported Vehicles per Month</b></th>
                    <th style={{width: '50%'}}><b>Price per Vehicle / Registered Importer User</b></th>
                </tr>
                <tr>
                    <td>0-50</td>
                    <td>$15.00</td>
                </tr>
                <tr>
                    <td>51-100</td>
                    <td>$14.00</td>
                </tr>
                <tr>
                    <td>101-200</td>
                    <td>$12.00</td>
                </tr>
                <tr>
                    <td>201-400</td>
                    <td>$11.00</td>
                </tr>
                <tr>
                    <td>401-600</td>
                    <td>$10.00</td>
                </tr>
                <tr>
                    <td>601-1000</td>
                    <td>$9.00</td>
                </tr>
            </table>
        </p>

        <p>
            2. <b>Storage and Priority Support Subscription</b>
            <p className={"my-4"}>
                After the initial 60-day data storage period, continued data storage for up to 15 years and access
                to priority live support will require a monthly subscription.
                The subscription pricing is based on the number of vehicles imported per month by a Registered Importer:
            </p>
            <table>
                <tr>
                    <th style={{width: '50%'}}><b>Imported Vehicles per Month</b></th>
                    <th style={{width: '50%'}}><b>Monthly Subscription (Storage for 15 years and Priority Live
                        Support)</b></th>
                </tr>
                <tr>
                    <td>0-50</td>
                    <td>$119.99</td>
                </tr>
                <tr>
                    <td>51-100</td>
                    <td>$119.99</td>
                </tr>
                <tr>
                    <td>101-200</td>
                    <td>$119.99</td>
                </tr>
                <tr>
                    <td>201-400</td>
                    <td>$219.99</td>
                </tr>
                <tr>
                    <td>401-600</td>
                    <td>$219.99</td>
                </tr>
                <tr>
                    <td>601-1000</td>
                    <td>$319.99</td>
                </tr>
            </table>
        </p>

        <p>
            3. <b>Billing and Payment</b>
            <p className={"my-4"}>
                <ul>
                    <li><b>Billing Cycle: </b>
                        All charges will be billed on a monthly basis, the billing cycle will commence upon the signing of the agreement. Billing will occur at the beginning of each month,
                        covering the services provided during the previous month. The first invoice will be prorated if the agreement is signed mid-month.
                    </li>
                    <li><b>Payment Method: </b>
                        Payments will be processed via the payment method provided during account setup. (Stripe ACH) or wire Transfer.
                        You are responsible for ensuring that your payment information is up-to-date and accurate.
                    </li>
                    <li><b>Late Payments: </b>
                        In the event of a failed payment, we reserve the right to suspend or terminate your access to our services until payment is made in full.
                    </li>
                </ul>
            </p>
        </p>

        <p>
            4. <b>Modifications to Pricing</b>
            <p className={"my-4"}>
                <ul>
                    <li>
                        Fee Adjustments for Inflation: Vin2 Software, LLC reserves the right to adjust fees annually based on official inflation
                        rates or other legally recognized inflationary indexes. These adjustments may be made without requiring written consent from you.
                        We will provide at least 30 days' notice of any such fee adjustments via email or through our website.</li>
                    <li>
                        Other Pricing Modifications: Any other modifications to this pricing structure may only be made through a written agreement,
                        either physically signed or agreed upon via email, between you and Vin2 Software, LLC.
                        Such changes will take effect only once both parties have formally agreed to the modifications.
                    </li>
                </ul>
            </p>
        </p>

        <p>
            5. <b>Changes to Pricing</b>
            <p className={"my-4"}>
                Vin2 Software, LLC reserves the right to adjust the pricing for its services or introduce new fees at any time upon prior notice to you.
                Such changes will be effective upon your acceptance of the modified terms or as otherwise specified in the notice.
            </p>
        </p>

        <p>
            6. <b>Refund Policy</b>
            <p className={"my-4"}>
                All sales are final, and no refunds will be issued unless required by law. In cases of billing disputes,
                please contact us at <a href={"mailto:admin@vin2.com"}>[admin@vin2.com]</a> within 30 days of the charge to discuss any issues.
            </p>
        </p>

        <p>
            7. <b>Contact Us</b> <br/>
            <p className={"my-4"}>For any questions regarding this Pricing Agreement, please contact us at:</p>
            Vin2 Software, LLC<br/>
            1111b S Governors Avenue STE 21801<br/>
            Dover, DE, 19904<br/>
            United States<br/>
            Email: admin@vin2.com<br/>
        </p>
    </>
}

export const CUSTOMS_BROKER_RELEASE = {
    title: 'Customs-Broker Release of Information to Vin2 Software',
    content: <>
        <p>
            <b>Effective Date: </b>August 20, 2024
        </p>

        <p>
            This document serves as a formal authorization by the undersigned ("Client")
            for the release and disclosure of specific customs-related information to Vin2 Software, LLC ("Company").
            The Client acknowledges and agrees to the terms and conditions outlined below regarding the transfer of data
            between their
            designated customs broker and Vin2 Software, LLC.
        </p>

        <p>
            1. <b>Authorization for Information Release</b>
            <p className={"my-4"}>
                The Client hereby authorizes their designated customs broker to release and disclose to Vin2 Software,
                LLC the following information:
            </p>
            <ul>
                <li>Importer Security Filing (ISF) data</li>
                <li>Entry and entry summary documents</li>
                <li>Shipment tracking details</li>
                <li>Invoices and commercial documents related to customs processing</li>
                <li>Any other relevant data required for the processing and management of imported goods by Vin2
                    Software, LLC
                </li>
            </ul>

        </p>

        <p>
            2. <b>Purpose of Information Release</b>
            <p className={"my-4"}>
                The information provided to Vin2 Software, LLC will be used for the following purposes:
            </p>
            <ul>
                <li>
                    To facilitate the importation process and ensure compliance with U.S. Customs and Border
                    Protection (CBP) regulations.
                </li>
                <li>
                    To enable Vin2 Software, LLC to provide software solutions that enhance the efficiency and
                    accuracy of customs data management.
                </li>
                <li>
                    To allow Vin2 Software, LLC to assist in tracking and managing imported goods for the Client's
                    business operations.
                </li>
            </ul>
        </p>

        <p>
            3. <b>Standard Brokerage and Bond Services</b>
            <p className={"my-4"}>
                By default, Vin2 Software, LLC works with Cole International for customs brokerage services and
                International Bond & Marine for DOT bond services.
                These partnerships allow us to streamline the customs and importation processes for our clients.
            </p>
            <p>
                However, we understand that clients may have established relationships with other customs brokers and
                bond providers.
                We can connect with alternative brokers and warranty companies upon request. Please note that
                integrating with other providers may require
                some development work on both sides to ensure smooth and efficient data exchange.
            </p>
        </p>

        <p>
            4. <b>R.I. Warranty Policy</b>
            <p className={"my-4"}>
                The Client, as a Registered Importer (R.I.), agrees to provide a warranty that all vehicles imported
                comply with the safety,
                emissions, and other regulatory standards required by the U.S. Department of Transportation (DOT) and
                Environmental Protection Agency (EPA).
                The Client warrants that all data provided to Vin2 Software, LLC via the customs broker or directly is
                accurate, complete, and up-to-date. The Client understands that any
                inaccuracies or omissions in the data may result in regulatory non-compliance, for which the Client
                bears full responsibility.
            </p>
        </p>

        <p>
            5. <b>DOT Bond Policy</b>
            <p className={"my-4"}>
                The Client acknowledges that, as a Registered Importer, they are required to maintain a continuous
                importation bond with the U.S.
                Department of Transportation (DOT). This bond ensures compliance with all applicable vehicle safety
                standards and regulations. By default,
                Vin2 Software, LLC facilitates this process through International Bond & Marine. The Client agrees to
                provide Vin2 Software, LLC with proof of this bond upon request. The Client also understands that any
                failure to
                maintain this bond may result in legal and financial penalties, which will be the sole responsibility of
                the Client.
            </p>
        </p>

        <p>
            6. <b>Duration of Authorization</b>
            <p className={"my-4"}>
                This authorization is effective as of the date signed and will remain in effect until terminated by the
                Client in writing.
                The Client may revoke this authorization at any time by providing written notice to Vin2 Software, LLC,
                but such revocation will not affect any actions taken by Vin2 Software,
                LLC prior to receiving the revocation.
            </p>
        </p>

        <p>
            7. <b>Confidentiality and Data Protection</b>
            <p className={"my-4"}>
                Vin2 Software, LLC agrees to treat all information received under this authorization as confidential and
                will take reasonable measures to protect the data from unauthorized access, use, or disclosure. Vin2
                Software,
                LLC will use the information solely for the purposes outlined in this document and in compliance with
                applicable privacy laws and regulations.
            </p>
        </p>

        <p>
            8. <b>Liability</b>
            <p className={"my-4"}>
                The Client agrees that Vin2 Software, LLC shall not be liable for any direct, indirect, incidental, or consequential damages arising from the release, use, or mismanagement of
                the information provided by the customs broker, except in cases of gross negligence or willful misconduct by Vin2 Software, LLC
            </p>
        </p>

        <p>
            9. <b>Governing Law</b>
            <p className={"my-4"}>
                This release of information and the rights and obligations of the parties hereunder shall be governed by and construed in accordance
                with the laws of the State of Delaware, without regard to its conflict of law provisions.
            </p>
        </p>

        <p>
            10. <b>Contact Us</b> <br/>
            <p className={"my-4"}>For any questions or concerns regarding this authorization, please contact:</p>
            Vin2 Software, LLC<br/>
            1111b S Governors Avenue STE 21801<br/>
            Dover, DE, 19904<br/>
            United States<br/>
            Email: admin@vin2.com<br/>
        </p>
    </>
}
