import * as Yup from 'yup';

import { EPA_CODE_B, EPA_CODE_EE } from './VehicleForm.constants';

export const validationVinSchema = Yup.object().shape({
  vin: Yup.string().label('Vin').required(),
  entryDate: Yup.date()
    .label('Entry Date')
    .typeError(({ label }) => label + ' is not a valid date')
    .required(),
});

export const validationSchema = Yup.object().shape({
  // General Information
  vin: Yup.string().label('Vin').required(),
  manufacturer_code: Yup.number()
    .label('Manufaturer')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  year: Yup.string().label('Year').required(),
  make: Yup.string().label('Make').required(),
  model: Yup.string().label('Model').required(),
  countryOfOrigin: Yup.string().label('Country of Origin').required(),
  USSellingPrice: Yup.number()
    .label('US Selling Price')
    .typeError(({ label }) => label + ' should be number')
    .required(),
  miles: Yup.number()
    .label('Miles')
    .typeError(({ label }) => label + ' should be number')
    .required(),
  KM: Yup.number()
    .typeError(({ label }) => label + ' should be number')
    .required(),
  initialCertification: Yup.boolean().label('Initial Certification').required(),
  exporter_code: Yup.number()
    .label('Exporter')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  buyer_code: Yup.number()
    .label('Buyer')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  consignee_code: Yup.number()
    .label('Consignee')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  conformity_location_code: Yup.number()
    .label('Conformity Location')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  entryDate: Yup.date()
    .label('Entry Date')
    .typeError(({ label }) => label + ' is not a valid date')
    .required(),

  // Manufacturer Information
  manufacturerMonth: Yup.string()
    .label('Manufacturer Month')
    .matches(/^(0?[1-9]|1[012])$/, 'Invalid Month')
    .required(),
  manufacturerYear: Yup.string()
    .label('Manufacturer Year')
    .matches(/([0-9]{2})/, 'Invalid Year')
    .required(),
  weightOnLabel: Yup.string().label('Weight On Label').required(),
  GVWRLBS: Yup.number()
    .label('GVWRLBS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  GVWRKGS: Yup.number()
    .label('GVWRKGS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  frontGAWRLBS: Yup.number()
    .label('Front GAWRLBS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  frontGAWRKGS: Yup.number()
    .label('Front GAWRKGS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  rearGAWRLBS: Yup.number()
    .label('Rear GAWRLBS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  rearGAWRKGS: Yup.number()
    .label('Rear GAWRKGS')
    .typeError(({ label }) => label + ' is a required field')
    .required(),

  // Tire and Loading Information
  frontTireSize: Yup.string(),
  frontRimSize: Yup.string(),
  frontColdPSI: Yup.string(),
  frontAxleType: Yup.string(),
  rearTireSize: Yup.string(),
  rearRimSize: Yup.string(),
  rearColdPSI: Yup.string(),
  rearAxleType: Yup.string(),
  '2ndTireSize': Yup.string(),
  '2ndRimSize': Yup.string(),
  '2ndColdPSI': Yup.string(),
  '2ndAxleType': Yup.string(),
  '3rdTireSize': Yup.string(),
  '3rdRimSize': Yup.string(),
  '3rdColdPSI': Yup.string(),
  '3rdSingleorDual': Yup.string(),

  // Transporter Information
  transportationMode: Yup.string().label('Tranportation Mode').required(),
  transporter_code: Yup.number()
    .label('Carrier')
    .typeError(({ label }) => label + ' is required')
    .required(),
  SCACCode: Yup.string().label('SCACCode').required(),
  PAPSCode: Yup.string().label('PAPSCode').required(),

  // Customer Information
  notes: Yup.string(),

  // EPA Emission Information
  EPACode: Yup.string().label('EPA Code').required(),

  // DOT information
  dotBox: Yup.string().label('DOT Box').required(),
  engineFamily: Yup.string().when('EPACode', {
    is: (val) => val === EPA_CODE_B || val === EPA_CODE_EE,
    then: Yup.string().label('Engine Family').required(),
  }),
  vehicleType: Yup.string().label('Vehicle Type').required(),
  VSACode: Yup.string().label('VSA Code').required(),

  // Broker Information
  bondProvider: Yup.string()
    .label('Bond Provider')
    .required(({ label }) => label + ' Required Field. Please configure in user settings.'),
  warrantyProvider: Yup.string()
    .label('Warranty Provider')
    .required(({ label }) => label + ' Required Field. Please configure in user settings.'),
  country: Yup.string().label('Country').required(),
  tariff_code: Yup.number()
    .label('HS Tariff')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  portOfArrival: Yup.number()
    .label('Port of Arrival')
    .typeError(({ label }) => label + ' is a required field')
    .required(),
  theftProtection: Yup.boolean().label('Theft Protection').required().required(),
  status: Yup.string(),
  vehicle_id: Yup.number().nullable(),

  odometerDisplay: Yup.string().label('Odometer Display').required(),
});
