import { useMutation } from '@tanstack/react-query';
import { VehicleApi } from 'api';
import { useState } from 'react';

import { NOTIFICATION_TYPE, Notification } from 'components';

export default function useDeletePhoto(vehicle_id, setImages) {
  const [fieldName, setFieldName] = useState(null);
  const mutation = useMutation({
    mutationKey: ['deleteSalesOrder'],
    mutationFn: async ({ vehicle_id, photoCategory }) => {
      await VehicleApi.deleteVehiclePhoto(vehicle_id, photoCategory);
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
    onSuccess: (data, variables, context) => {
      Notification(NOTIFICATION_TYPE.SUCCESS, `Success delete ${variables.fieldName}`);
      setImages((prevImages) => ({ ...prevImages, [variables.fieldName]: null }));
    },
  });
  const handleDeletePhoto = (photoCategory, fieldName) => {
    if (mutation.isLoading)
      return Notification(NOTIFICATION_TYPE.DANGER, 'Deleting photo please wait...');
    setFieldName(fieldName);
    mutation.mutate({
      vehicle_id: vehicle_id,
      photoCategory: photoCategory,
      fieldName: fieldName,
    });
  };
  return {
    handleDeletePhoto,
    isLoading: mutation.isLoading,
    fieldName,
  };
}
