import React from 'react';
import { Input } from '../../../../components';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const TireLoadingInfo = ({ form }) => {
  return (
    <div>
      <h3 className="mb-4">Tire and Loading Information</h3>

      <h5 className="mb-3">Front Axle</h5>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Tire Size"
            {...form.getFieldProps('frontTireSize')}
            error={
              form.errors.frontTireSize && form.touched.frontTireSize
                ? form.errors.frontTireSize
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Rim Size"
            {...form.getFieldProps('frontRimSize')}
            error={
              form.errors.frontRimSize && form.touched.frontRimSize ? form.errors.frontRimSize : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Cold PSI"
            {...form.getFieldProps('frontColdPSI')}
            error={
              form.errors.frontColdPSI && form.touched.frontColdPSI ? form.errors.frontColdPSI : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <label id="front-axle-type" className="mb-1">
            Axle Type
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="front-axle-type"
                {...form?.getFieldProps('frontAxleType')}
                onChange={(e) => {
                  form.setFieldValue('frontAxleType', e.target.value);
                }}
                className="flex-row gap-2"
              >
                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                <FormControlLabel value="Dual" control={<Radio />} label="Dual" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <h5 className="mb-2">Rear Axle</h5>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Tire Size"
            {...form.getFieldProps('rearTireSize')}
            error={
              form.errors.rearTireSize && form.touched.rearTireSize ? form.errors.rearTireSize : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Rim Size"
            {...form.getFieldProps('rearRimSize')}
            error={
              form.errors.rearRimSize && form.touched.rearRimSize ? form.errors.rearRimSize : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Cold PSI"
            {...form.getFieldProps('rearColdPSI')}
            error={
              form.errors.rearColdPSI && form.touched.rearColdPSI ? form.errors.rearColdPSI : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <label id="rear-axle-type" className="mb-1">
            Axle Type
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="rear-axle-type"
                {...form?.getFieldProps('rearAxleType')}
                onChange={(e) => {
                  form.setFieldValue('rearAxleType', e.target.value);
                }}
                className="flex-row gap-2"
              >
                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                <FormControlLabel value="Dual" control={<Radio />} label="Dual" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <h5 className="mb-2">2nd Axle</h5>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Tire Size"
            {...form.getFieldProps('2ndTireSize')}
            error={
              form.errors['2ndTireSize'] && form.touched['2ndTireSize']
                ? form.errors['2ndTireSize']
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Rim Size"
            {...form.getFieldProps('2ndRimSize')}
            error={
              form.errors['2ndRimSize'] && form.touched['2ndRimSize']
                ? form.errors['2ndRimSize']
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Cold PSI"
            {...form.getFieldProps('2ndColdPSI')}
            error={
              form.errors['2ndColdPSI'] && form.touched['2ndColdPSI']
                ? form.errors['2ndColdPSI']
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <label id="2nd-axle-type" className="mb-1">
            Axle Type
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="2nd-axle-type"
                {...form?.getFieldProps('2ndAxleType')}
                onChange={(e) => {
                  form.setFieldValue('2ndAxleType', e.target.value);
                }}
                className="flex-row gap-2"
              >
                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                <FormControlLabel value="Dual" control={<Radio />} label="Dual" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <h5 className="mb-2">3rd Axle</h5>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Tire Size"
            {...form.getFieldProps('3rdTireSize')}
            error={
              form.errors['3rdTireSize'] && form.touched['3rdTireSize']
                ? form.errors['3rdTireSize']
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Rim Size"
            {...form.getFieldProps('3rdRimSize')}
            error={
              form.errors['3rdRimSize'] && form.touched['3rdRimSize']
                ? form.errors['3rdRimSize']
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Cold PSI"
            {...form.getFieldProps('3rdColdPSI')}
            error={
              form.errors['3rdColdPSI'] && form.touched['3rdColdPSI']
                ? form.errors['3rdColdPSI']
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <label id="3rd-axle-type" className="mb-1">
            Axle Type
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="3rd-axle-type"
                {...form?.getFieldProps('3rdAxleType')}
                onChange={(e) => {
                  form.setFieldValue('3rdAxleType', e.target.value);
                }}
                className="flex-row gap-2"
              >
                <FormControlLabel value="Single" control={<Radio />} label="Single" />
                <FormControlLabel value="Dual" control={<Radio />} label="Dual" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TireLoadingInfo;
