import * as Yup from 'yup';

export const importBuyersValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required Field'),
  companyPhone: Yup.number().typeError('should be number').required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  phoneNumber: Yup.number().typeError('should be number').required('Required Field'),
  email: Yup.string().required('Required Field').email('Invalid Email'),
  taxID: Yup.string().required('Required Field'),
});
