import { Link } from 'react-router-dom';
import { Menu } from 'react-feather';
import { CollapseMenu, IconButton } from '../../../components';

import './styles.scss';

export function MobileNav({ menus }) {
  return (
    <div className="mobile-menus">
      <IconButton icon={<Menu />} data-bs-toggle="dropdown" />

      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {menus.map((item, index) => {
          if (item.children) {
            return (
              <li key={index}>
                <CollapseMenu title={item.title} items={item.children} />
              </li>
            );
          } else {
            return (
              <li key={index}>
                <Link className="dropdown-item" to={item.link} key={index}>
                  {item.title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
