import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import React from 'react';

import { Button, Input } from 'components';
import { FocusError } from 'components/FocusError/FocusError';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { ConformityLocationSelect } from 'features/conformity-locations/components/ConformityLocationSelect';

import useUserForm from './UserForm.hook';
import { UserPermissions } from './UserPermissions';

export function UserForm({ action, initialValues, onSuccess, onError }) {
  const [form, isLoading] = useUserForm(action, initialValues, onSuccess, onError);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusError form={form} />
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="First Name"
            className="mb-3"
            type="text"
            placeholder="First Name"
            hasBorder
            {...form.getFieldProps('firstName')}
            error={form.errors.firstName && form.touched.firstName ? form.errors.firstName : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Last Name"
            className="mb-3"
            type="text"
            placeholder="Last Name"
            hasBorder
            {...form.getFieldProps('lastName')}
            error={form.errors.lastName && form.touched.lastName ? form.errors.lastName : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Phone"
            className="mb-3"
            type="text"
            placeholder="Phone"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.phoneNumber || ''}`}
            {...form.getFieldProps('phoneNumber')}
            error={
              form.errors.phoneNumber && form.touched.phoneNumber ? form.errors.phoneNumber : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Email Address"
            className="mb-3"
            type="email"
            placeholder="Email Address"
            hasBorder
            startIcon={<EmailOutlined />}
            iconHref={`mailto:${form.values.email || ''}`}
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <ConformityLocationSelect
            label="Location"
            placeholder="Select Location"
            value={form.values.location}
            onChange={(v) => form.setFieldValue('location', v.value)}
            error={form.errors.location && form.touched.location ? form.errors.location : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <PasswordInput
            label="Password"
            className="mb-3"
            placeholder="Password"
            hasBorder
            {...form.getFieldProps('password')}
            error={form.errors.password && form.touched.password ? form.errors.password : ''}
          />
        </div>
      </div>

      <hr />
      <UserPermissions form={form} />
      <hr />

      <div className="row mb-4">
        <div className="col-md-4">
          <Button type="submit" className="w-100" loading={isLoading}>
            Save
          </Button>
        </div>
        <div className="col-md-4" />
        <div className="col-md-4" />
      </div>
    </form>
  );
}
