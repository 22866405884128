import React from 'react';

import CarriersTable from 'features/carriers/components/table/CarriersTable';

const ListCarriers = () => {
  return (
    <>
      <h2 className="mb-4">Carriers</h2>
      <CarriersTable />
    </>
  );
};

export default ListCarriers;
