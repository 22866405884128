import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../providers/authProvider';
import { PaymentApi } from '../../../api';
import { Notification, NOTIFICATION_TYPE } from '../../../components';
import { ROUTES } from '../../../constants/routes';

const PaymentSuccess = () => {
  const { id } = useParams();
  const [type, setType] = useState('');

  const { setUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    if (id) {
      const type = new URLSearchParams(window.location.search).get('type');
      setType(type);
      PaymentApi.createPayment({ id, type })
        .then((res) => {
          Notification(NOTIFICATION_TYPE.SUCCESS, res.data.message);

          if (type === 'setup') {
            setUser((user) => ({ ...user, payment: res.data.payment }));
          }

          // Redirect to settings page
          timeoutId = window.setTimeout(() => {
            navigate(ROUTES.MAIN.USER_SETTINGS.INDEX);
          }, 3000);
        })
        .catch((err) => {
          Notification(NOTIFICATION_TYPE.DANGER, err.message);
        });
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [id, navigate, setUser]);

  return (
    <div className={'d-flex justify-content-center mt-4'}>
      {type === 'payment' ? (
        <h2>Your payment has been processed successfully.</h2>
      ) : (
        <h2>Success! Your payment method has been saved.</h2>
      )}
    </div>
  );
};

export default PaymentSuccess;
