import React from 'react';
import { dataURLToFile } from 'utilities/fileHelper';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { FocusError } from 'components/FocusError/FocusError';
import ImagePreview from 'components/ImagePreview/ImagePreview';

import { useProfileSettingsForm } from './ProfileSettingsForm.hook';
import { RegisteredImporterInfo } from './RegisteredImporterInfo/RegisteredImporterInfo';

/**
 * @typedef {(form: import('formik').FormikProps<any>, pad: import('signature_pad').SignaturePad | null, setPad: React.Dispatch<React.SetStateAction<import('signature_pad').SignaturePad>>, handleUploadSignature: (file: File) => void) => React.ReactNode} RenderAdditionalContent
 */

/**
 * @typedef {Object} ProfileSettingsFormProps
 * @property {import('@tanstack/react-query').UseMutationResult} mutation
 * @property {object} inititalValues
 * @property {RenderAdditionalContent} renderAdditionalContent
 */

/**
 *
 * @param {ProfileSettingsFormProps} props
 * @returns {JSX.Element}
 */
export function ProfileSettingsForm({ mutation, inititalValues, renderAdditionalContent }) {
  const [form, isLoading] = useProfileSettingsForm(mutation, inititalValues);
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const [pad, setPad] = React.useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.values.shouldUseExistingSignature) {
      if (form.values.newSignatureMode === 'draw') {
        if (!pad || pad.isEmpty()) {
          return Notification(NOTIFICATION_TYPE.DANGER, 'Please sign with your new signature!');
        }

        const signatureUrl = pad.toDataURL();
        const signature = dataURLToFile(signatureUrl, 'signature.png');
        form.setFieldValue('signature', signature);
      } else {
        if (!form.values.signature) {
          return Notification(NOTIFICATION_TYPE.DANGER, 'Please upload a signature image file!');
        }
      }
    }

    form.handleSubmit();
  };

  const handleChangeProfilePhoto = (e) => {
    const file = e.target.files[0];

    if (form.values.profilePhoto) {
      URL.revokeObjectURL(form.values.profilePhoto);
    }

    form.setFieldValue('profilePhoto', file);
    const url = URL.createObjectURL(file);
    setProfilePhoto(url);
  };

  const handleUploadSignature = (file) => {
    form.setFieldValue('signature', file);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FocusError form={form} />
      <RegisteredImporterInfo form={form} />
      <div className="row mb-3 align-items-end">
        <div className="col-md-6">
          <p>Profile Photo</p>
          <input
            className="form-control mb-1"
            type="file"
            name="profilePhoto"
            accept="image/*"
            onChange={handleChangeProfilePhoto}
          />
        </div>
        {profilePhoto && (
          <div className="col-md-6">
            <ImagePreview src={profilePhoto} alt="" />
          </div>
        )}
      </div>
      {typeof renderAdditionalContent === 'function' &&
        renderAdditionalContent(form, pad, setPad, handleUploadSignature)}
      <div className="row mb-3">
        <div className="col-md-2">
          <Button
            type="submit"
            className="w-100"
            loading={isLoading}
            disabled={!form.values.termsAgreed}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
}
