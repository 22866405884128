import React from 'react';
import { useNavigate } from 'react-router';

import { BackButton, NOTIFICATION_TYPE, Notification } from 'components';
import { BuyerForm } from 'features/buyers/components/form/BuyerForm';

export default function AddBuyer() {
  const navigate = useNavigate();

  const onSuccess = () => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Buyer was created successfully');
    navigate(-1);
  };

  const onError = (error) => {
    Notification(NOTIFICATION_TYPE.DANGER, error.message);
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row mb-4">
        <h1>Add Buyer (Importer)</h1>
      </div>

      <BuyerForm action="create" onSuccess={onSuccess} onError={onError} />
    </div>
  );
}
