import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { Upload } from 'react-feather';
import { hasPermission } from 'services/auth';
import { checkDuplicatePropertyValues } from 'utilities';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { CsvImportModal } from 'features/common/csv/csv-import/CsvImportModal';
import { useCreateConsignees } from 'features/consignees/hooks/useCreateConsignees';

import { importConsigneesValidationSchema } from '../form/ConsigneeForm.validator';

export function ConsigneesCsvImport({ columns, onCsvImportSuccess }) {
  const { user } = React.useContext(AuthContext);

  const [showCsvImportModal, setShowCsvImportModal] = React.useState(false);

  const { mutate: createConsignees, isLoading } = useCreateConsignees('batch', {
    onSuccess: () => {
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Import consignees successfully');
      setShowCsvImportModal(false);
      onCsvImportSuccess();
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  const fields = columns.map((column) => ({
    title: column.title,
    dataIndex: column.dataIndex,
    isRequired:
      !!importConsigneesValidationSchema.fields[column.dataIndex]?.exclusiveTests?.required,
  }));

  const handleSubmitImport = (rowsData) => {
    const fields = columns.map((column) => ({
      label: column.title,
      key: column.dataIndex,
    }));

    const data = rowsData.map((row) => {
      const rowData = {};

      fields.forEach((field) => {
        rowData[field.key] = row[field.label];
      });

      return rowData;
    });

    const transformedData = data.map((item) => ({
      ...item,
      address: {
        street: item.street1,
        city: item.city1,
        state: item.state1,
        zip: item.zip1,
        country: item.country1,
      },
    }));

    // Check for duplicate company names or email addresses
    if (checkDuplicatePropertyValues(transformedData, 'companyName')) {
      return Notification(NOTIFICATION_TYPE.DANGER, 'Duplicate company names are not allowed');
    }

    if (checkDuplicatePropertyValues(transformedData, 'email')) {
      return Notification(NOTIFICATION_TYPE.DANGER, 'Duplicate email addresses are not allowed');
    }

    transformedData.forEach((item) => {
      delete item.id;
      delete item.street1;
      delete item.city1;
      delete item.state1;
      delete item.zip1;
      delete item.country1;
    });

    createConsignees(transformedData);
  };

  return (
    <>
      <Button
        className="d-flex gap-2"
        disabled={!hasPermission(user, { resource: 'consignee', action: 'create' })}
        onClick={() => setShowCsvImportModal(true)}
      >
        <Upload size={20} /> Import CSV
      </Button>
      <CsvImportModal
        title="Import Consignees CSV"
        open={showCsvImportModal}
        onClose={() => setShowCsvImportModal(false)}
        onSubmit={handleSubmitImport}
        isSubmitting={isLoading}
        fields={fields}
      />
    </>
  );
}
