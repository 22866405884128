import React from 'react';
import { Upload } from 'react-feather';
import { checkDuplicatePropertyValues } from 'utilities';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { CsvImportModal } from 'features/common/csv/csv-import/CsvImportModal';

import { importUsersValidationSchema } from '../UserForm/UserForm.validator';
import { useCreateUsers } from '../hooks/useCreateUsers';

export function UsersCsvImport({ columns, onCsvImportSuccess }) {
  const [showCsvImportModal, setShowCsvImportModal] = React.useState(false);

  const { mutate: createUsers, isLoading } = useCreateUsers('batch', {
    onSuccess: () => {
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Import users successfully');
      setShowCsvImportModal(false);
      onCsvImportSuccess();
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  const fields = columns.map((column) => ({
    title: column.title,
    dataIndex: column.dataIndex,
    isRequired: !!importUsersValidationSchema.fields[column.dataIndex]?.exclusiveTests?.required,
  }));

  const handleSubmitImport = (rowsData) => {
    const fields = columns.map((column) => ({
      label: column.title,
      key: column.dataIndex,
    }));

    const data = rowsData.map((row) => {
      const rowData = {};

      fields.forEach((field) => {
        rowData[field.key] = row[field.label];
      });

      return rowData;
    });

    // Check for duplicate email addresses
    if (checkDuplicatePropertyValues(data, 'email')) {
      return Notification(NOTIFICATION_TYPE.DANGER, 'Duplicate email addresses are not allowed');
    }

    createUsers(data);
  };

  return (
    <>
      <Button className="d-flex gap-2" onClick={() => setShowCsvImportModal(true)}>
        <Upload size={20} /> Import CSV
      </Button>
      <CsvImportModal
        title="Import Users CSV"
        open={showCsvImportModal}
        onClose={() => setShowCsvImportModal(false)}
        onSubmit={handleSubmitImport}
        isSubmitting={isLoading}
        fields={fields}
      />
    </>
  );
}
