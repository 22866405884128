import React from 'react';
import { Switch as BaseSwitch, FormControlLabel } from '@mui/material';
import './styles.scss';

export const Switch = ({
  className = '',
  disabled,
  checked,
  onChange,
  label,
  ...props
}) => {
  return (
    <FormControlLabel
      className={`custom-switch ${className}`}
      control={
        <BaseSwitch
          checked={checked}
          onChange={onChange}
          inputProps={{ 'aria-label': 'controlled' }}
          {...props}
        />
      }
      disabled={disabled}
      label={label}
    />
  );
};
