import classnames from 'classnames';
import { useRenderPdfToCanvas } from 'hooks/useRenderPdfToCanvas';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Trash } from 'react-feather';
import { dataURLToFile } from 'utilities/fileHelper';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';

export function PhotoSelection({
  vehicle,
  selectedCategory,
  uploadPhoto,
  isUploading,
  checkImageResolution,
  isCheckingImageResolution,
  onUploaded,
}) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [preview, setPreview] = React.useState('');
  const selectAnImageRef = React.useRef(null);

  const { canvasRef } = useRenderPdfToCanvas({
    file: selectedFile?.type === 'application/pdf' ? selectedFile : null,
    scale: 1,
  });

  const renderImagePreview = React.useCallback(
    (file) => {
      if (!file) {
        return (
          <>
            <span className="text-md text-grey mb-0 mt-1">or</span>
            <span className="text-md text-grey mb-0">Drag and Drop File</span>
            <p className="text-sm text-grey-dark mb-0">JPEG</p>
          </>
        );
      }

      return (
        <>
          <img src={preview} className="image-preview" alt="" data-testid="photo-upload-preview" />
          <div className="remove-btn" onClick={removeFile}>
            <Trash className="remove-icon" />
          </div>
        </>
      );

      // if (file.type === 'application/pdf') {
      //   return (
      //     <>
      //       <canvas ref={canvasRef} style={{ maxWidth: '300px' }} />
      //       <div className="remove-btn" onClick={removeFile}>
      //         <Trash className="remove-icon" />
      //       </div>
      //     </>
      //   );
      // } else {
      //   return (
      //     <>
      //       <img
      //         src={preview}
      //         className="image-preview"
      //         alt=""
      //         data-testid="photo-upload-preview"
      //       />
      //       <div className="remove-btn" onClick={removeFile}>
      //         <Trash className="remove-icon" />
      //       </div>
      //     </>
      //   );
      // }
    },
    [preview]
  );

  React.useEffect(() => {
    if (!selectedFile) return;

    if (selectedFile.type !== 'application/pdf') {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPreview(reader.result);
      });
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleUploadFile = async (e) => {
    e.stopPropagation();

    if (selectedFile === null) {
      return selectAnImageRef.current.click();
    }

    try {
      const file =
        selectedFile.type === 'application/pdf'
          ? dataURLToFile(canvasRef.current.toDataURL('image/png'), 'photo.png')
          : selectedFile;
      await uploadPhoto(vehicle, selectedCategory.value, file);

      setSelectedFile(null);
      setPreview('');

      onUploaded?.();
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      const file = files[0];

      if (file) {
        try {
          // if (file.type !== 'application/pdf') {
          //   await checkImageResolution(file);
          // }
          if (file.type !== 'image/jpeg') {
            return Notification(NOTIFICATION_TYPE.DANGER, 'Only JPEG photos are supported');
          }

          await checkImageResolution(file);

          setSelectedFile(file);
        } catch (err) {
          setSelectedFile(null);
          Notification(NOTIFICATION_TYPE.DANGER, err.message);
        }
      }
    },
    multiple: false,
  });

  const removeFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
  };

  return (
    <div className="mb-4">
      <div className="drop-box" {...getRootProps()}>
        <Button
          className={classnames({
            'my-3': selectedFile,
          })}
          type="button"
          disabled={selectedFile && (isUploading || isCheckingImageResolution || !selectedCategory)}
          onClick={handleUploadFile}
        >
          {!selectedFile
            ? 'Select an Image'
            : !selectedCategory
            ? 'Select a category'
            : isUploading
            ? 'Uploading'
            : 'Upload'}
        </Button>
        <div ref={selectAnImageRef}>
          <input {...getInputProps()} accept=".jpeg" id="PhotoUpload" />
        </div>
        {renderImagePreview(selectedFile)}
      </div>
    </div>
  );
}
