import { useQuery } from '@tanstack/react-query';
import { TransportersApi } from 'api';

/**
 *  Custom React Query hook for fetching carriers
 *
 * @param {{page: number, perPage: number, order: string, orderBy: string}} queryObj
 * @param {{ enabled?: boolean }} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetTransporters(queryObj, { enabled = true } = {}) {
  return useQuery({
    queryKey: ['transporters', queryObj],
    queryFn: () => TransportersApi.search(queryObj).then((res) => res.data),
    enabled: !!queryObj && enabled,
  });
}
