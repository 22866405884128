import { DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { Trash } from 'react-feather';

import { Button, Dialog, IconButton, Table } from 'components';

import { CsvFileUpload } from './CsvFileUpload';
import { CsvImportFields } from './CsvImportFields';
import { useCsvImport } from './useCsvImport';

/**
 * @typedef {Object} CsvImportModalProps
 * @property {string} title
 * @property {CsvImportFields} fields
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {(rows: any[]) => void} onSubmit
 * @property {boolean} isSubmitting
 */

/**
 *
 * @param {CsvImportModalProps} props
 * @returns {JSX.Element}
 */
export function CsvImportModal({ title, fields, open, onClose, onSubmit, isSubmitting }) {
  const [parsedData, setParsedData] = React.useState({
    rows: [],
    fields: [],
  });

  /**
   * @type {[Error | null, React.Dispatch<React.SetStateAction<Error | null>>]}
   */
  const [error, setError] = React.useState(null);

  const expectedFields = fields.filter((column) => column.dataIndex !== '');

  const [handleInputChange] = useCsvImport({
    expectedFields,
    onSuccess: (results) => {
      setError(null);
      setParsedData({
        rows: results.data.map((row, index) => ({ ...row, id: row.id || index })),
        fields: results.meta.fields,
      });
    },
    onError: (error) => {
      setError(error);
      setParsedData({
        rows: [],
        fields: [],
      });
    },
  });

  const columns = React.useMemo(() => {
    const fields = parsedData.fields.map((field) => ({
      title: field,
      dataIndex: field,
    }));

    return [
      ...fields,
      {
        title: '',
        dataIndex: '',
        render: (data) => (
          <IconButton
            className="p-2"
            icon={<Trash size={20} />}
            disabled={isSubmitting}
            onClick={() => {
              setParsedData((prevData) => ({
                ...prevData,
                rows: prevData.rows.filter((row) => row.id !== data.id),
              }));
            }}
          />
        ),
      },
    ];
  }, [parsedData, isSubmitting]);

  const handleClose = () => {
    setParsedData({
      rows: [],
      fields: [],
    });
    setError(null);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(parsedData.rows);
  };

  return (
    <Dialog title={title} open={open} onClose={handleClose}>
      <DialogContent>
        <CsvImportFields fields={expectedFields} />
        <CsvFileUpload className="mb-2" onFileInputChange={handleInputChange} />
        {parsedData.rows.length > 0 && <Table columns={columns} data={parsedData.rows} />}
        {error && (
          <div className="mt-4">
            <h6 className="text-red">Import errors:</h6>
            <ul className="text-red">
              {error.message.split('\n').map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="dark" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="button"
          loading={isSubmitting}
          disabled={parsedData.rows.length === 0}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
