import { ObjectHelper } from 'helpers';
import React, { useEffect, useState } from 'react';
import { Eye, Plus } from 'react-feather';
import { Link } from 'react-router-dom';

import { Button, NOTIFICATION_TYPE, Notification, Table } from 'components';
import { CsvExportButton } from 'components/CsvExport/CsvExportButton';
import { SearchBar } from 'features/common/search/SearchBar';

import { useGetUsers } from '../hooks/useGetUsers';
import { UserActionButton } from './UserActionButton';
import { UsersCsvImport } from './UsersCsvImport';

const columns = [
  { title: 'First Name', dataIndex: 'firstName' },
  { title: 'Last Name', dataIndex: 'lastName' },
  { title: 'Email Address', dataIndex: 'email' },
  { title: 'Phone', dataIndex: 'phoneNumber' },
  {
    title: 'Location',
    dataIndex: '',
    render: (importer) => importer.conformityLocation?.title || '',
  },
  {
    title: 'Actions',
    dataIndex: '',
    render: (user) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <Link to={`${user.id}`} className="text-white">
            <Eye size={20} />
          </Link>
          <UserActionButton user={user} />
        </div>
      );
    },
  },
];

export function UsersTable() {
  const [sortOption, setSortOption] = React.useState({
    order: 'desc',
    orderBy: '',
  });
  const [pagination, setPagination] = React.useState({
    page: 0,
    perPage: 10,
  });
  const [search, setSearchTerm] = useState('');

  const queryObj = React.useMemo(() => {
    return ObjectHelper.trimQuery({
      ...pagination,
      ...sortOption,
      search: search,
    });
  }, [pagination, sortOption, search]);

  const { error, data, isLoading, isFetching, refetch } = useGetUsers(queryObj);

  useEffect(() => {
    if (!error) return;
    Notification(NOTIFICATION_TYPE.DANGER, error?.message || 'System Error');
  }, [error]);

  const handleChangePagination = React.useCallback((field, value) => {
    if (field === 'page') {
      setPagination((prev) => ({ ...prev, page: value }));
    } else if (field === 'perPage') {
      setPagination({ page: 0, perPage: value });
    }
  }, []);

  return (
    <>
      <h2 className="mb-4">Users</h2>
      <div className="d-flex gap-3 mb-4">
        <Link to="add">
          <Button className="d-flex gap-2">
            <Plus size={20} />
            Add
          </Button>
        </Link>
        <UsersCsvImport
          columns={columns}
          onCsvImportSuccess={() => handleChangePagination('page', 0)}
        />
        <SearchBar fetch={refetch} onSearch={setSearchTerm} hasBorder={true} />
      </div>
      <Table
        data={(data?.data ?? []).map(({ importer, ...userData }) => ({
          ...userData,
          ...importer,
        }))}
        columns={columns}
        loading={isFetching || isLoading}
        checkable={true}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: data?.totalCount || 0,
          page: pagination.page,
          perPage: pagination.perPage,
          onPageChange: (page) => handleChangePagination('page', page),
          onPerPageChange: (perPage) => handleChangePagination('perPage', perPage),
        }}
        renderCsvExporter={(columns, data) => (
          <CsvExportButton data={data} columns={columns} filename="users.csv">
            Export CSV
          </CsvExportButton>
        )}
      />
    </>
  );
}
