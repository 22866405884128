import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ConfirmModal, MoreMenu, NOTIFICATION_TYPE, Notification } from 'components';
import { useDeleteConformityLocationById } from 'features/conformity-locations/hooks/useDeleteConformityLocationById';

const ConformityLocationMoreButton = ({ conformityLocation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutate: removeConformityLocation, isLoading } = useDeleteConformityLocationById(
    conformityLocation.id,
    {
      onSuccess: () => {
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Conformity Location is removed successfully.');
      },
      onError: (error) => {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      },
    }
  );

  return (
    <>
      <MoreMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          onClick={() => setShowConfirm(true)}
          disabled={isLoading}
          data-testid="delete-conformity-location"
        >
          <DeleteOutlineIcon sx={{ mr: '1rem' }} />
          Remove
        </MenuItem>
        <MenuItem>
          <Link
            to={`edit/${conformityLocation.id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <EditIcon sx={{ mr: '1rem' }} />
            Edit
          </Link>
        </MenuItem>
      </MoreMenu>

      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        title="Remove Conformity Location"
        content="Are you sure you want to remove this Conformity Location?"
        onConfirm={removeConformityLocation}
      />
    </>
  );
};

export default ConformityLocationMoreButton;
