import { ROUTES } from 'constants/routes';
import { TermsAgreementSection } from 'pages/main/ProfileSettings/TermsAgreementSection';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { FocusError } from 'components/FocusError/FocusError';

import { OtpSubmitModal } from './OtpSubmitModal';
import { RegisteredImporterInfo } from './RegisteredImporterInfo/RegisteredImporterInfo';
import { useSignUpForm } from './SignUpForm.hook';

export function SignUpForm() {
  const navigate = useNavigate();
  const { setUser } = React.useContext(AuthContext);

  const {
    form,
    hasReceivedOtp,
    isRequestingOtp,
    isSubmitting,
    otpModalIsOpen,
    setOtpModalIsOpen,
    requestOtp,
  } = useSignUpForm({
    onSuccess: (data) => {
      setUser(data);
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Successfully registered');
      navigate(ROUTES.MAIN.USER_SETTINGS.INDEX);
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  const handleSignUp = async (event) => {
    event.preventDefault();

    const fields = {};
    Object.keys(form.values).forEach((field) => {
      fields[field] = true;
    });
    form.setTouched(fields);

    const errors = await form.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (!hasReceivedOtp) {
      try {
        await requestOtp(form.values.email);
      } catch (error) {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      }
    } else {
      setOtpModalIsOpen(true);
    }
  };

  return (
    <form onSubmit={handleSignUp}>
      <FocusError form={form} />
      <RegisteredImporterInfo form={form} />
      <TermsAgreementSection form={form} />
      <div className="row mb-4">
        <div className="col-md-4" />
        <div className="col-md-4" />
        <div className="col-md-4">
          <Button
            type="submit"
            className="w-100"
            disabled={!form.isValid}
            loading={isRequestingOtp || isSubmitting}
          >
            Register
          </Button>
        </div>
      </div>

      <div className="text-center mt-3">
        Already have an account? Please&nbsp;<Link to={ROUTES.AUTH.LOGIN}>sign in.</Link>
      </div>

      <OtpSubmitModal
        open={otpModalIsOpen}
        onClose={() => setOtpModalIsOpen(false)}
        signupForm={{ form, isRequestingOtp, isSubmitting, requestOtp }}
      />
    </form>
  );
}
