import React from 'react';

import BuyersTable from 'features/buyers/components/table/BuyersTable';

const ListBuyers = () => {
  return (
    <>
      <h2 className="mb-4">Buyers (Importers)</h2>
      <BuyersTable />
    </>
  );
};

export default ListBuyers;
