import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconButton, Input } from 'components';

/**
 * @typedef SearchBarProps
 * @property {Function} fetch
 * @property {(string) => void} onSearch
 * @property {boolean} [hasBorder]
 * @property {string} [className]
 */

/**
 *
 * @param {SearchBarProps} props
 * @returns {JSX.Element}
 */
export function SearchBar({ fetch, onSearch, hasBorder, className }) {
  const [searchParams] = useSearchParams();

  const searchTermParam = searchParams.get('searchTerm') || '';

  const [searchTerm, setSearchTerm] = React.useState(searchTermParam);

  React.useEffect(() => {
    setSearchTerm(searchTermParam);
  }, [searchTermParam]);

  const onChangedDebounce = useDebouncedCallback((value) => {
    onSearch(value);
  });

  return (
    <Input
      className={className}
      hasBorder={hasBorder}
      value={searchTerm}
      onChange={(event) => {
        setSearchTerm(event.target.value);
        onChangedDebounce(event.target.value);
      }}
      endAdornment={
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => fetch()}
          icon={<SearchSharpIcon style={{ fontSize: '20px' }} />}
        />
      }
    />
  );
}
