import Http from '../utilities/http';

export class TransportersApi {
  static search(query = {}) {
    return Http.get('/transporters', query);
  }

  static add(data) {
    return Http.post('/transporters', data);
  }

  static detail(id) {
    return Http.get(`/transporters/${id}`);
  }

  static remove(id) {
    return Http.delete(`/transporters/${id}`);
  }

  static batchAdd(data) {
    return Http.post('/transporters/batch', data);
  }

  static update(id, data) {
    return Http.patch(`/transporters/${id}`, data);
  }
}
