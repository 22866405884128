import React from 'react';

import { Input } from 'components';

import { RangeSettings } from '../range-settings/RangeSettings';
import { useGetCustomsBrokers } from './useGetCustomsBrokers';

export function CustomsRangeSettings({ form }) {
  const { data: customsBrokers, isLoading, isError } = useGetCustomsBrokers();

  const { setFieldValue } = form;

  React.useEffect(() => {
    if (!isLoading && !isError) {
      !form.values.customsLow && setFieldValue('customsLow', customsBrokers[0]?.customsLow || 0);
      !form.values.customsHigh && setFieldValue('customsHigh', customsBrokers[0]?.customsHigh || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customsBrokers, isError, isLoading, setFieldValue]);

  return (
    <RangeSettings form={form} fieldName="customs">
      <Input
        label="Range Low"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('customsLow')}
        error={!!form.errors.customsLow && !!form.touched.customsLow ? form.errors.customsLow : ''}
      />
      <Input
        label="Range High"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('customsHigh')}
        error={
          !!form.errors.customsHigh && !!form.touched.customsHigh ? form.errors.customsHigh : ''
        }
      />
    </RangeSettings>
  );
}
