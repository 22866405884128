import React from 'react';

import { CustomSelect, NOTIFICATION_TYPE, Notification } from 'components';

import { useGetModalData } from './useGetModalData';

export const DataSelect = ({
  apiFieldName,
  form,
  lastSaved,
  isUserSelect,
  isCompanyNameSelect,
  value,
  onChange,
  ...restProps
}) => {
  const {
    data: items = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetModalData(apiFieldName, {
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  const options = React.useMemo(
    () =>
      items.map((item) => {
        let label = item.name || item.address;

        if (isUserSelect) {
          label = `${item.firstName} ${item.lastName}`;
        } else if (isCompanyNameSelect) {
          label = item.companyName;
        }

        return {
          label,
          value: item.id,
        };
      }),
    [items, isUserSelect, isCompanyNameSelect]
  );

  React.useEffect(() => {
    refetch().then((res) => {
      const items = res.data;

      if (lastSaved[apiFieldName] && items?.length > 0) {
        const value = lastSaved[apiFieldName].id;

        form.setFieldValue(`${apiFieldName.toLowerCase()}_code`, value);
        typeof onChange === 'function' && onChange(items.find((item) => item.id === value));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSaved, apiFieldName, refetch]);

  return (
    <CustomSelect
      options={options}
      value={options.find(
        (item) => item.value === form.values[`${apiFieldName.toLowerCase()}_code`]
      )}
      onChange={(v) => {
        form.setFieldValue(`${apiFieldName.toLowerCase()}_code`, v.value);
        typeof onChange === 'function' && onChange(items.find((item) => item.id === v.value));
      }}
      loading={isLoading || isFetching}
      {...restProps}
    />
  );
};
