import classNames from 'classnames';

import { Button } from 'components';

/**
 * @typedef RangeSettingsProps
 * @property {import('formik').FormikProps<any>} form
 * @property {'customs' | 'bond' | 'warranty'} fieldName
 * @property {JSX.Element} children
 * @property {string} [className]
 */

/**
 *
 * @param {RangeSettingsProps} props
 * @returns {JSX.Element}
 */
export function RangeSettings({ form, fieldName, children, className }) {
  const rangeLowField = `${fieldName}Low`;
  const rangeHighField = `${fieldName}High`;

  const hasRangeLowError = !!form.errors[rangeLowField] && !!form.touched[rangeLowField];
  const hasRangeHighError = !!form.errors[rangeHighField] && !!form.touched[rangeHighField];

  return (
    <div className={classNames('d-flex gap-2', className)}>
      {children}
      <Button
        className={classNames(
          hasRangeLowError || hasRangeHighError ? 'align-self-center' : 'align-self-end'
        )}
        onClick={() => {
          form.setFieldValue(rangeLowField, form.initialValues?.[rangeLowField] || 0);
          form.setFieldValue(rangeHighField, form.initialValues?.[rangeHighField] || 0);

          form.setFieldTouched(rangeLowField, false);
          form.setFieldTouched(rangeHighField, false);
        }}
      >
        Reset
      </Button>
    </div>
  );
}
