import { useState } from 'react';
import { VehicleApi } from '../../../../../api';

export const useUpdateStatementConformity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const handleUpdateStatementConformity = async (id, data, { onSuccess, onFailed }) => {
    try {
      setLoading(true);
      const res = await VehicleApi.updateStatementConformity(id, data);
      onSuccess(res.data);
    } catch (e) {
      setError(e);
      onFailed(e);
    }

    setLoading(false);
  };

  return {
    error,
    loading,
    update: handleUpdateStatementConformity,
  };
};
