import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VehicleDocumentApi } from 'api';

export function useUploadVehicleDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['upload-vehicle-document'],
    mutationFn: (data) => VehicleDocumentApi.upload(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['vehicle-documents']);
      queryClient.invalidateQueries(['document-categories']);
    },
  });
}
