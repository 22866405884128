import { useMutation } from '@tanstack/react-query';
import { AuthApi } from 'api';
import { useFormik } from 'formik';
import { riNumberRegex, taxIDRegex } from 'helpers/validators';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  riCompanyName: Yup.string().label('Company Name').required(),
  riNumber: Yup.string()
    .label('RI Number')
    .required()
    .matches(riNumberRegex, 'Invalid RI Number format'),
  firstName: Yup.string().label('First Name').required(),
  lastName: Yup.string().label('Last Name').required(),
  email: Yup.string().label('Email').required().email('Invalid Email'),
  emailConfirm: Yup.string()
    .label('Email Confirmation')
    .required()
    .oneOf([Yup.ref('email')], 'Emails do not match'),
  password: Yup.string().min(8).label('Password').required(),
  passwordConfirm: Yup.string()
    .label('Password Confirmation')
    .required()
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  mobileNumber: Yup.string().label('Mobile Number').required(),
  taxID: Yup.string().label('Tax ID').required().matches(taxIDRegex, 'Invalid Tax ID format'),
  designation: Yup.string().label('Designation').required(),
  termsAgreed: Yup.boolean().oneOf([true], 'Terms must be accepted'),
});

export const useSignUpForm = (
  { onSuccess, onError } = { onSuccess: () => void 0, onError: () => void 0 }
) => {
  const [otpModalIsOpen, setOtpModalIsOpen] = React.useState(false);

  const hasReceivedOtpRef = React.useRef(false);

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation({
    mutationKey: ['signup-submit'],
    mutationFn: (values) =>
      AuthApi.register(values)
        .then((res) => {
          onSuccess?.(res.data);
        })
        .catch((error) => {
          onError?.(error);
        }),
  });

  const { mutateAsync: requestOtp, isLoading: isRequestingOtp } = useMutation({
    mutationKey: ['request-otp'],
    mutationFn: async (email) => {
      try {
        await AuthApi.requestOtp({ email });

        hasReceivedOtpRef.current = true;

        if (!otpModalIsOpen) {
          setOtpModalIsOpen(true);
        }

        return Promise.resolve();
      } catch (error) {
        hasReceivedOtpRef.current = false;
        return Promise.reject(error);
      }
    },
  });

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      riCompanyName: '',
      riNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      emailConfirm: '',
      password: '',
      passwordConfirm: '',
      mobileNumber: '',
      taxID: '',
      designation: '',
      termsAgreed: false,
      otp: '',
    },
  });

  // Allow to request new OTP if email has been changed
  React.useEffect(() => {
    if (form.isValid && hasReceivedOtpRef.current) {
      hasReceivedOtpRef.current = false;
    }
  }, [form.isValid, form.values.email]);

  return {
    form,
    hasReceivedOtp: hasReceivedOtpRef.current,
    isRequestingOtp,
    isSubmitting,
    otpModalIsOpen,
    setOtpModalIsOpen,
    requestOtp,
  };
};
