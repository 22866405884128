const MIN_IMAGE_RESOLUTION = 300; // min resolution for image in pixels

export function download(dataURL, filename) {
  const blob = dataURLToFile(dataURL, filename);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}

export function dataURLToFile(dataURL, fileName) {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new File([uInt8Array], fileName, { type: contentType });
}

export function checkResolutionImage(file, minValue = MIN_IMAGE_RESOLUTION) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(file);
    img.onload = function () {
      if (this.width < minValue || this.height < minValue) {
        reject(`Image resolution is too low. Minimum dimension is ${minValue} X ${minValue}.`);
      }

      _URL.revokeObjectURL(objectUrl);

      resolve();
    };
    img.src = objectUrl;
  });
}

export function getFileTypeFromUrl(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);

    xhr.onload = function () {
      const contentType = xhr.getResponseHeader('Content-Type');
      return resolve(contentType);
    };
    xhr.onerror = function () {
      return resolve('');
    };
    xhr.send();
  });
}
