import { AuthContext } from 'providers/authProvider';
import React from 'react';

import { NOTIFICATION_TYPE, Notification } from 'components';

import { ProfileSettingsForm } from './ProfileSettingsForm';
import { SignatureSection } from './SignatureSection';
import { TermsAgreementSection } from './TermsAgreementSection';
import { useUpdateUserProfile } from './hooks/useUpdateUserProfile';

export function ProfileSettings() {
  const { user, setUser } = React.useContext(AuthContext);

  const mutation = useUpdateUserProfile({
    onSuccess: (data) => {
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Profile settings saved successfully!');
      setUser(data);
    },
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  return (
    <div className="container">
      <h2 className="mb-5">Profile Settings</h2>
      <ProfileSettingsForm
        mutation={mutation}
        inititalValues={user}
        renderAdditionalContent={(form, pad, setPad, handleUploadSignature) => (
          <>
            <SignatureSection
              form={form}
              pad={pad}
              setPad={setPad}
              onUploadSignature={handleUploadSignature}
            />
            <TermsAgreementSection form={form} />
          </>
        )}
      />
    </div>
  );
}
