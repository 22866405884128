import { useQuery } from '@tanstack/react-query';
import { BuyersApi } from 'api';

/**
 * Custom React Query hook to fetch buyer data by id
 *
 * @param {number} id
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetBuyer(id) {
  return useQuery({
    queryKey: ['buyer', id],
    queryFn: () => BuyersApi.getById(id).then((res) => res.data),
  });
}
