import Http from '../utilities/http';

export class BondProviderApi {
    static create(data) {
        return Http.post('/bond-providers', data);
    }

    static get() {
        return Http.get('/bond-providers');
    }
}
