import { AppLogo } from 'components/AppLogo/AppLogo';

import { SignUpForm } from './SignUpForm';
import './styles.scss';

export default function Register() {
  return (
    <div className="register-form">
      <AppLogo className="mb-4 mx-auto d-block" />
      <h1 className="h3 mb-4 fw-normal text-center">Sign Up</h1>
      <SignUpForm />
    </div>
  );
}
