import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConformityLocationApi } from 'api';

/**
 * Custom React Query hook for creating a new conformity location.
 *
 * @see {@link ConformityLocationApi.create}
 *
 * @param {{ onSuccess?: () => void; onError?: (error: any) => void; }} param0
 * @returns {import('@tanstack/react-query').UseMutationResult<any, unknown, any, unknown>}
 */
export function useCreateConformityLocation({ onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-conformity-location'],
    mutationFn: async (data) => ConformityLocationApi.create(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['conformity-locations']);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
