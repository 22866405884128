import { ROUTES } from './routes';

export const SIDEBAR_MENUS = [
  { title: 'Vehicle Info', link: ROUTES.MAIN.VEHICLES.INDEX },
  { title: 'Additional', link: '#' },
  { title: 'Upload Bond Release', link: '#' },
  { title: 'Archived Vehicle Info', link: '#' },
  { title: 'NHTSA Report', link: '#' },
  { title: 'Manage Users', link: '#' },
  { title: 'System', link: '#' },
];
