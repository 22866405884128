import React, { useEffect } from 'react';

import { CustomSelect, Input } from 'components';

import {
  BUS,
  CAR,
  DOT_BOX_ITEMS,
  EPA_CODE_B,
  EPA_CODE_EE,
  MOTORCYCLE,
  MPV_OVER_10000,
  MPV_UNDER_10000,
  SEMI_TRUCK,
  TRAILER,
  TRUCK_OVER_10000,
  TRUCK_UNDER_10000,
  VEHICLE_TYPES,
  VSA080,
  VSA081,
  VSA082,
  VSA083,
} from '../VehicleForm.constants';

const dotBoxOptions = DOT_BOX_ITEMS.map((item) => ({
  label: item,
  value: item,
}));

export const vehicleTypeOptions = VEHICLE_TYPES.map((item) => ({
  label: item,
  value: item,
}));

const getVSACode = (vehicleType) => {
  if (vehicleType === BUS) {
    return VSA081;
  } else if (vehicleType === CAR) {
    return VSA080;
  } else if (vehicleType === MOTORCYCLE) {
    return VSA083;
  } else if (vehicleType === MPV_UNDER_10000) {
    return VSA081;
  } else if (vehicleType === MPV_OVER_10000) {
    return VSA082;
  } else if (vehicleType === SEMI_TRUCK) {
    return VSA082;
  } else if (vehicleType === TRAILER) {
    return VSA083;
  } else if (vehicleType === TRUCK_UNDER_10000) {
    return VSA081;
  } else if (vehicleType === TRUCK_OVER_10000) {
    return VSA082;
  } else {
    return '';
  }
};

const DotInfo = ({ form }) => {
  useEffect(() => {
    form.values.VSACode = getVSACode(form.values.vehicleType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.vehicleType]);

  return (
    <div>
      <h3 className="mb-4">DOT Information</h3>

      <div className="row" id="dotBox">
        <div className="col-md-6 mb-3">
          <div className="dropdown mb-3">
            <CustomSelect
              name="dotBox"
              label="Dot Box *"
              placeholder="Select Dot Box"
              options={dotBoxOptions}
              value={dotBoxOptions.find((item) => item.value === form.values.dotBox)}
              onChange={(v) => {
                form.setFieldValue('dotBox', v.value);
                form.setTouched({ ...form.touched, dotBox: false });
              }}
              error={form.errors.dotBox && form.touched.dotBox ? form.errors.dotBox : ''}
            />
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <Input
            label={`Engine Family ${
              form.values.EPACode === EPA_CODE_B || form.values.EPACode === EPA_CODE_EE ? ' *' : ''
            }`}
            type="text"
            {...form.getFieldProps('engineFamily')}
            error={
              form.errors.engineFamily && form.touched.engineFamily ? form.errors.engineFamily : ''
            }
          />
        </div>
      </div>

      <div className="row" id="vehicleType">
        <div className="col-md-6 mb-3">
          <div className="dropdown mb-3">
            <CustomSelect
              name="vehicleType"
              label="Vehicle Type *"
              placeholder="Select Vehicle Type"
              options={vehicleTypeOptions}
              value={vehicleTypeOptions.find((item) => item.value === form.values.vehicleType)}
              onChange={(v) => {
                form.setFieldValue('vehicleType', v.value);
                form.setTouched({ ...form.touched, vehicleType: false });
              }}
              error={
                form.errors.vehicleType && form.touched.vehicleType ? form.errors.vehicleType : ''
              }
            />
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <Input
            label="VSA Code *"
            type="text"
            error={form.errors.VSACode && form.touched.VSACode ? form.errors.VSACode : ''}
            disabled
            {...form.getFieldProps('VSACode')}
          />
        </div>
      </div>
    </div>
  );
};

export default DotInfo;
