import React from 'react';

/**
 *
 * @param {Function} callback
 * @param {number} [delay=700]
 * @param {Array<any>} dependencies
 * @returns {Function} function to be debounced
 */
const useDebouncedCallback = (callback, delay = 700, dependencies = []) => {
  const timeout = React.useRef();
  // Avoid error about spreading non-iterable (undefined)
  const comboDeps = React.useMemo(
    () => (dependencies ? [callback, delay, ...dependencies] : [callback, delay]),
    [callback, delay, dependencies]
  );

  return React.useCallback((...args) => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      callback(...args);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, comboDeps);
};

export default useDebouncedCallback;
