import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TabMenu } from './TabMenu';
import _variables from '../../styles/_variables.scss';

export function NavTabs({ tabs = [], initialTabIndex, className, tabClassName, panelClassName }) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(initialTabIndex || 0);
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (tabs.length > 0) {
      const index = tabs.findIndex((tab) => pathname.includes(tab.link));

      if (index > -1) {
        setSelectedTabIndex(index);
      }
    }
  }, [pathname, tabs]);

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <TabMenu
      tabIndex={selectedTabIndex}
      onTabChange={handleChange}
      className={className}
      renderTabs={() => {
        return tabs.map((tab, index) => (
          <TabMenu.TabItem
            key={index}
            tab={tab}
            tabIndex={index}
            className={tabClassName}
            component={TabLink}
            to={tab.link}
          />
        ));
      }}
      renderPanels={(selectedTabIndex) => {
        return tabs.map((tab, index) => (
          <TabMenu.TabPanel
            className={panelClassName}
            key={index}
            value={selectedTabIndex}
            index={index}
          >
            {tab.content}
          </TabMenu.TabPanel>
        ));
      }}
    />
  );
}

const TabLink = React.forwardRef((props, ref) => {
  return (
    <NavLink
      ref={ref}
      style={({ isActive }) => {
        return {
          color: isActive ? _variables['color-primary'] : _variables['color-grey'],
        };
      }}
      {...props}
    />
  );
});
