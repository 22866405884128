import Http from '../utilities/http';

export class DownloadFileApi {
  static getFile(url) {
    return Http.post(
      '/download-file',
      { url },
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  }

  static getInlineDoc(url) {
    return Http.get(`/download-file/inline-doc?url=${url}`);
  }
}
