import { VehicleApi } from 'api';
import jsPDF from 'jspdf';
import { GlobalWorkerOptions, getDocument, version } from 'pdfjs-dist';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { createWorker } from 'tesseract.js';

import { NOTIFICATION_TYPE, Notification } from 'components';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;

export function useUploadBondRelease() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [vehicles, setVehicles] = React.useState([]);
  const [isScanning, setIsScanning] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const dropzone = useDropzone({
    onDrop: function (files) {
      setSelectedFile(files[0]);
    },
    multiple: false,
    accept: 'application/pdf',
  });

  const handleReset = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setVehicles([]);
  };

  const processData = React.useCallback(async () => {
    try {
      const data = [];
      const fileContent = await readFileData(selectedFile);
      const pdf = await getDocument(fileContent).promise;
      const canvas = document.createElement('canvas');

      const worker = createWorker();
      await worker.load();
      await worker.loadLanguage('eng');
      await worker.initialize('eng');

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1.8 });
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        const {
          data: { text },
        } = await worker.recognize(canvas.toDataURL('image/jpeg'), {
          rectangle: {
            left: 200,
            top: 700,
            width: 700,
            height: 50,
          },
        });
        const texts = text.split('\n');
        const res = texts.find((t) => t.includes('Vehicle Identification No:'));
        if (res) {
          const strs = res.split(':');
          if (strs[1] && strs[1].trim().length > 0) {
            data.push({
              page: i + 1,
              vin: strs[1].trim(),
            });
          }
        }
      }
      canvas.remove();
      await worker.terminate();
      return data;
    } catch (error) {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    }
  }, [selectedFile]);

  const getBondDocument = React.useCallback(
    async (pageNumber) => {
      try {
        const fileContent = await readFileData(selectedFile);
        const pdf = await getDocument(fileContent).promise;
        const canvas = document.createElement('canvas');
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1.8 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const context = canvas.getContext('2d');
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        const imgData = canvas.toDataURL('image/jpeg');
        const jspdf = new jsPDF();
        jspdf.addImage(imgData, 'JPEG', 0, 0, 210, 200, 'alias');
        canvas.remove();
        const blob = jspdf.output('blob');
        const file = new File([blob], 'bond.pdf', {
          type: blob.type,
          lastModified: new Date().getTime(),
        });

        return file;
      } catch (error) {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      }
    },
    [selectedFile]
  );

  const handleScanVin = React.useCallback(async () => {
    setIsScanning(true);

    const scannedVehicles = await processData();

    if (scannedVehicles.length === 0) {
      setIsScanning(false);
      return Notification(NOTIFICATION_TYPE.DANGER, 'No vin found');
    }

    const promises = await Promise.allSettled(
      scannedVehicles.map(async (item) => {
        return await VehicleApi.getVehicleByVin(item.vin);
      })
    );

    const vehicles = scannedVehicles.map((item, index) => ({
      ...item,
      id: promises[index].status === 'fulfilled' ? promises[index].value.data.id : null,
      isExisted: promises[index].status === 'fulfilled',
      isReleased: promises[index].status === 'fulfilled' && promises[index].value.data.isReleased,
    }));

    setVehicles(vehicles);
    setIsScanning(false);
  }, [processData]);

  const handleUpload = React.useCallback(async () => {
    setIsUploading(true);
    let count = 0;

    for (const vehicle of vehicles.filter((v) => v.isExisted)) {
      try {
        const fd = new FormData();
        fd.append('file', await getBondDocument(vehicle.page));

        await VehicleApi.vehicleBondReleased(vehicle.vin, fd);
        count++;
      } catch (error) {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
        continue;
      }
    }

    setIsUploading(false);

    if (count === 0) {
      return Promise.reject('Error occurred');
    }

    setSelectedFile(null);
    setVehicles([]);

    return Promise.resolve(count);
  }, [getBondDocument, vehicles]);

  return {
    dropzone,
    selectedFile,
    vehicles,
    isScanning,
    isUploading,
    handleReset,
    handleScanVin,
    handleUpload,
  };
}

function readFileData(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}
