import { ROUTES } from 'constants/routes';
import React from 'react';
import { Edit, List } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Loading } from 'components';

import { useGetUser } from '../hooks/useGetUser';

export function ViewUser() {
  const { id } = useParams();
  const { data: importer, isLoading, error } = useGetUser(id);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h2>User Details</h2>
        <div className="d-flex gap-4">
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.ACCOUNTS}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <List size={20} />
            List
          </Link>
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.ACCOUNTS}/edit/${id}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <Edit size={20} />
            Edit
          </Link>
        </div>
      </div>
      <hr />
      <h5>Name</h5>
      <p>{`${importer?.user?.firstName} ${importer?.user?.lastName}`}</p>
      <h5>Email</h5>
      <p>{importer?.user?.email}</p>
      <h5>Phone</h5>
      <p>{importer?.user?.phoneNumber}</p>
      <h5>Conformity Location</h5>
      <p>{importer?.conformityLocation?.title}</p>
    </div>
  );
}
