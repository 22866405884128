import { ROUTES } from 'constants/routes';
import {
  ACCOUNTS,
  BUYERS,
  CARRIERS,
  CONFORMITY_LOCATIONS,
  CONSIGNEES,
  PROFILE,
  SELLERS,
} from 'constants/userSetting';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { Outlet, useLocation } from 'react-router-dom';
import { hasPermission, isRegisteredImporter } from 'services/auth';

import { NavTabs } from 'components/Tabs/NavTabs';

const UserSettings = () => {
  const { user } = React.useContext(AuthContext);

  const settingPages = React.useMemo(
    () => [
      {
        title: CONFORMITY_LOCATIONS,
        link: ROUTES.MAIN.USER_SETTINGS.CONFORMITY_LOCATIONS,
        content: <ContentLayout />,
        disabled: !isRegisteredImporter(user),
      },
      {
        title: BUYERS,
        link: ROUTES.MAIN.USER_SETTINGS.BUYERS,
        content: <ContentLayout />,
        disabled: !hasPermission(user, { resource: 'buyer', action: 'read' }),
      },
      {
        title: SELLERS,
        link: ROUTES.MAIN.USER_SETTINGS.SELLERS,
        content: <ContentLayout />,
        disabled: !hasPermission(user, { resource: 'seller', action: 'read' }),
      },
      {
        title: CARRIERS,
        link: ROUTES.MAIN.USER_SETTINGS.CARRIERS,
        content: <ContentLayout />,
        disabled: !hasPermission(user, { resource: 'carrier', action: 'read' }),
      },
      {
        title: CONSIGNEES,
        link: ROUTES.MAIN.USER_SETTINGS.CONSIGNEES,
        content: <ContentLayout />,
        disabled: !hasPermission(user, { resource: 'consignee', action: 'read' }),
      },
      {
        title: ACCOUNTS,
        link: ROUTES.MAIN.USER_SETTINGS.ACCOUNTS,
        content: <ContentLayout />,
        disabled: !isRegisteredImporter(user),
      },
      {
        title: PROFILE,
        link: ROUTES.MAIN.USER_SETTINGS.PROFILE,
        content: <ContentLayout />,
      },
    ],
    [user]
  );

  const { pathname } = useLocation();

  const authorizedSettingPages = settingPages.filter((page) => !page.disabled);

  const initialTabIndex = authorizedSettingPages.findIndex((page) => pathname.includes(page.link));

  return (
    <div className="container">
      <h2 className="mb-5">User Settings</h2>
      <NavTabs
        tabs={authorizedSettingPages}
        initialTabIndex={initialTabIndex > -1 ? initialTabIndex : 0}
        className="bg-dark-light"
        aria-label="User Settings Pages"
      />
    </div>
  );
};

function ContentLayout() {
  return (
    <Card className="bg-dark-light" body>
      <Outlet />
    </Card>
  );
}

export default UserSettings;
