import Http from '../utilities/http';

export class ConformityLocationApi {
  static create(data) {
    return Http.post('/conformity-locations', data);
  }

  static getAll(query = {}) {
    return Http.get('/conformity-locations', query);
  }

  static get(id) {
    return Http.get(`/conformity-locations/${id}`);
  }

  static update(id, data) {
    return Http.put(`/conformity-locations/${id}`, data);
  }

  static remove(id) {
    return Http.delete(`/conformity-locations/${id}`);
  }
}
