import { CANADA_ALPHA_2, MEXICO_ALPHA_2, USA_ALPHA_2 } from 'constants/common';
import useDebounce from 'hooks/useDebounce';
import React from 'react';

import { CustomSelect } from 'components/CustomSelect';
import { NOTIFICATION_TYPE, Notification } from 'components/Notification';

import { usePlaceAutocomplete } from './usePlaceAutocomplete';

/**
 * @typedef {USA_ALPHA_2 | CANADA_ALPHA_2 | MEXICO_ALPHA_2} ComponentCountry
 */

/**
 * @typedef {Object} PlaceAutoCompleteProps
 * @property {Array<ComponentCountry>} countries
 * @property {(placeId: string) => void} [onSelectPlace]
 */

/**
 *
 * @param {PlaceAutoCompleteProps} props
 * @returns {JSX.Element}
 */
export function PlaceAutocomplete({
  countries = [USA_ALPHA_2, CANADA_ALPHA_2, MEXICO_ALPHA_2],
  onSelectPlace,
}) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedPlaceId, setSelectedPlaceId] = React.useState('');
  const [places, setPlaces] = React.useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm);

  const {
    data: autocompleteData,
    isInitialLoading,
    isFetching,
  } = usePlaceAutocomplete(
    { input: debouncedSearchTerm, countries },
    {
      onError: (error) => {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
      },
    }
  );

  React.useEffect(() => {
    if (!autocompleteData?.predictions) {
      return;
    }

    setPlaces(
      autocompleteData.predictions.map((prediction) => ({
        value: prediction.place_id,
        label: prediction.description,
      }))
    );
  }, [autocompleteData]);

  const handleInputChange = (inputValue, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setSearchTerm(inputValue);
    } else {
      setSelectedPlaceId('');
    }
  };

  const handleChange = (option) => {
    if (option?.value) {
      setSelectedPlaceId(option.value);
      typeof onSelectPlace === 'function' && onSelectPlace(option.value);
    }
  };

  return (
    <CustomSelect
      label="Address"
      placeholder="Type to search for address"
      hasBorder
      options={places}
      value={places.find((item) => item.place_id === selectedPlaceId)}
      onChange={handleChange}
      inputValue={searchTerm}
      onInputChange={handleInputChange}
      isClearable={true}
      isLoading={isInitialLoading || isFetching}
    />
  );
}
