import Http from '../utilities/http';

export class ImportersApi {
  static search(query = {}) {
    return Http.get('/importers', query);
  }

  static getById(id) {
    return Http.get(`/importers/${id}`);
  }

  static update(id, data) {
    return Http.put(`/importers/${id}`, data);
  }

  static add(data) {
    return Http.post('/importers', data);
  }

  static batchAdd(data) {
    return Http.post('/importers/batch', data);
  }

  static remove(id) {
    return Http.delete(`/importers/${id}`);
  }
}
