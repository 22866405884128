import PhotoUploadForm from './PhotoUploadForm';

const PhotoUpload = ({ vehicle, photos, category }) => {
  return (
    <div data-testid="photo-upload">
      <PhotoUploadForm vehicle={vehicle} photos={photos} categoryList={category} />
    </div>
  );
};

export default PhotoUpload;
