import { useQuery } from '@tanstack/react-query';
import { CommonApi } from 'api';
import { HS_TARIFF } from 'constants/common';

/**
 * Custom React Query hook to fetch HS Tariffs.
 *
 * @param {{ onError?: (error: Error) => void }} callbacks
 * @returns {import("@tanstack/react-query").UseQueryResult<any, Error>}
 */
export function useGetHSTariffs({ onError } = {}) {
  return useQuery({
    queryKey: ['hs-tariffs'],
    queryFn: () =>
      CommonApi.searchByTable(HS_TARIFF)
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
    staleTime: Infinity,
  });
}
