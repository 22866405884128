import { useQuery } from '@tanstack/react-query';
import { PlacesApi } from 'api';

/**
 * Custom React Query hook for fetching place details
 *
 * @param {string} id
 * @param {*} query
 * @returns {import('@tanstack/react-query').UseQueryResult<import('api').PlaceDetails, Error>}
 */
export function usePlaceDetails(id, query) {
  return useQuery({
    queryKey: ['place-details', id],
    queryFn: () => PlacesApi.getPlaceDetails(id, query),
    staleTime: Infinity,
    enabled: !!id,
  });
}
