// import { CANADA, CANADA_ALPHA_2, CANADA_ALPHA_3 } from 'constants/common';
import * as Yup from 'yup';

export const importExportersValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required Field'),
  companyPhone: Yup.number().typeError('should be number').required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  phoneNumber: Yup.number().typeError('should be number').required('Required Field'),
  email: Yup.string().required('Required Field').email('Invalid Email'),
  taxID: Yup.string(),
  // Only allow Canada
  street1: Yup.string().required('Required Field'),
  city1: Yup.string().required('Required Field'),
  state1: Yup.string().required('Required Field'),
  zip1: Yup.string().required('Required Field'),
  country1: Yup.string().required('Required Field'),
  // .transform((value) => {
  //   return value !== null ? value.toUpperCase() : value;
  // })
  // .equals([CANADA, CANADA_ALPHA_2, CANADA_ALPHA_3], 'Seller must be from Canada'),
});
