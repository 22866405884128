import classnames from 'classnames';
import React from 'react';

import UnstyledButton from 'components/UnstyledButton';

import './styles.scss';

/**
 * @typedef {Object} IconButtonProps
 * @property {string} [className]
 * @property {React.ReactNode} icon
 * @property {boolean} [disabled]
 */

/**
 *
 * @param {IconButtonProps & React.HTMLAttributes<HTMLButtonElement>} props
 * @returns {JSX.Element}
 */
export const IconButton = ({ className, icon, disabled, ...props }) => {
  return (
    <UnstyledButton
      type="button"
      className={classnames('icon-button', className, {
        disabled: disabled,
      })}
      {...props}
      data-testid="icon-btn"
    >
      {icon}
    </UnstyledButton>
  );
};
