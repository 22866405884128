import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import React from 'react';

import { Button, Input } from 'components';
import { FocusError } from 'components/FocusError/FocusError';
import { AddressInfo } from 'features/common/address-form/AddressInfo';

import useCarrierForm from './CarrierForm.hook';

export function CarrierForm({ action, initialValues, onSuccess, onError }) {
  const [form, isLoading] = useCarrierForm(action, initialValues, onSuccess, onError);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusError form={form} />
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Company Name"
            className="mb-3"
            type="text"
            placeholder="Company Name"
            hasBorder
            {...form.getFieldProps('companyName')}
            error={
              form.errors.companyName && form.touched.companyName ? form.errors.companyName : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Company Phone Number"
            className="mb-3"
            type="text"
            placeholder="Company Phone Number"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.companyPhone || ''}`}
            {...form.getFieldProps('companyPhone')}
            error={
              form.errors.companyPhone && form.touched.companyPhone ? form.errors.companyPhone : ''
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Contact First Name"
            className="mb-3"
            type="text"
            placeholder="Contact First Name"
            hasBorder
            {...form.getFieldProps('firstName')}
            error={form.errors.firstName && form.touched.firstName ? form.errors.firstName : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Last Name"
            className="mb-3"
            type="text"
            placeholder="Contact Last Name"
            hasBorder
            {...form.getFieldProps('lastName')}
            error={form.errors.lastName && form.touched.lastName ? form.errors.lastName : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Phone"
            className="mb-3"
            type="text"
            placeholder="Contact Phone"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.phoneNumber || ''}`}
            {...form.getFieldProps('phoneNumber')}
            error={
              form.errors.phoneNumber && form.touched.phoneNumber ? form.errors.phoneNumber : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Email"
            className="mb-3"
            type="email"
            placeholder="Contact Email"
            hasBorder
            startIcon={<EmailOutlined />}
            iconHref={`mailto:${form.values.email || ''}`}
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />
        </div>
      </div>
      <div className="row">
        <AddressInfo form={form} fieldName="address" />
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="SCAC Code"
            className="mb-3"
            type="text"
            placeholder="SCAC Code"
            hasBorder
            {...form.getFieldProps('SCACCode')}
            error={form.errors.SCACCode && form.touched.SCACCode ? form.errors.SCACCode : ''}
            data-testid="SCACCode"
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Vehicle License Plate Number"
            className="mb-3"
            type="text"
            placeholder="Vehicle License Plate Number"
            hasBorder
            {...form.getFieldProps('plate')}
            error={form.errors.plate && form.touched.plate ? form.errors.plate : ''}
            data-testid="plate"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Transport Truck License Plate Number"
            className="mb-3"
            type="text"
            placeholder="Transport Truck License Plate Number"
            hasBorder
            {...form.getFieldProps('truckPlate')}
            error={form.errors.truckPlate && form.touched.truckPlate ? form.errors.truckPlate : ''}
            data-testid="truckPlate"
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Trailer License Plate Number"
            className="mb-3"
            type="text"
            placeholder="Trailer License Plate Number"
            hasBorder
            {...form.getFieldProps('trailerPlate')}
            error={
              form.errors.trailerPlate && form.touched.trailerPlate ? form.errors.trailerPlate : ''
            }
            data-testid="trailerPlate"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Drivers License Number"
            className="mb-3"
            type="text"
            placeholder="Drivers License Number"
            hasBorder
            {...form.getFieldProps('driversLicense')}
            error={
              form.errors.driversLicense && form.touched.driversLicense
                ? form.errors.driversLicense
                : ''
            }
            data-testid="driversLicense"
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Passport Number"
            className="mb-3"
            type="text"
            placeholder="Passport Number"
            hasBorder
            {...form.getFieldProps('passport')}
            error={form.errors.passport && form.touched.passport ? form.errors.passport : ''}
            data-testid="passport"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Fast ID"
            className="mb-3"
            type="text"
            placeholder="Fast ID"
            hasBorder
            {...form.getFieldProps('fastId')}
            error={form.errors.fastId && form.touched.fastId ? form.errors.fastId : ''}
            data-testid="fastId"
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Organization Code"
            className="mb-3"
            type="text"
            placeholder="Organization Code"
            hasBorder
            {...form.getFieldProps('orgCode')}
            error={form.errors.orgCode && form.touched.orgCode ? form.errors.orgCode : ''}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-4">
          <Button type="submit" className="w-100" loading={isLoading}>
            Save
          </Button>
        </div>
        <div className="col-md-4" />
        <div className="col-md-4" />
      </div>
    </form>
  );
}
