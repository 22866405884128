import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PhotosApi } from 'api';
import React from 'react';
import { checkResolutionImage } from 'utilities/fileHelper';

export function useUploadVehiclePhoto() {
  const [isCheckingImageResolution, setIsCheckingImageResolution] = React.useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['upload-vehicle-photo'],
    mutationFn: (data) => PhotosApi.upload(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['vehicle-photos']);
      queryClient.invalidateQueries(['photo-categories']);
    },
  });

  const uploadPhoto = async (vehicle, categoryId, file) => {
    const formData = new FormData();

    formData.append(`carPhoto`, file);
    formData.append('category', categoryId);
    formData.append('vin', vehicle.vin);
    formData.append('vin_code', vehicle.id);

    return await mutation.mutateAsync(formData);
  };

  const checkImageResolution = async (file) => {
    setIsCheckingImageResolution(true);

    try {
      await checkResolutionImage(file);

      setIsCheckingImageResolution(false);
    } catch (err) {
      setIsCheckingImageResolution(false);
      return Promise.reject({ message: err });
    }
  };

  return {
    ...mutation,
    uploadPhoto,
    checkImageResolution,
    isCheckingImageResolution,
  };
}
