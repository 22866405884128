import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { hasPermission } from 'services/auth';

import { Button, Input, NOTIFICATION_TYPE, Notification } from 'components';
import { CarrierForm } from 'features/carriers/components/form/CarrierForm';
import { CreateDataModal } from 'features/vehicles/components/modals/CreateDataModal';

import { DataSelect } from '../components/DataSelect';

const TransporterInfo = ({ form, lastSaved, setLastSaved }) => {
  const { user } = React.useContext(AuthContext);

  const [showTransporterModal, setShowTransporterModal] = React.useState(false);

  const onSuccess = (data) => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Carrier was created successfully');

    setLastSaved({ Transporter: data });

    setShowTransporterModal(false);
  };

  const onError = (err) => {
    Notification(NOTIFICATION_TYPE.DANGER, err.message);
  };

  return (
    <>
      <div>
        <h3 className="mb-4">Carrier Information</h3>

        <div className="row mb-3">
          <div className="col">
            <label id="transportation-mode" className="mb-1">
              Mode of Transportation *
            </label>
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="transportation-mode"
                  {...form?.getFieldProps('transportationMode')}
                  onChange={(e) => {
                    form.setFieldValue('transportationMode', e.target.value);
                  }}
                >
                  <FormControlLabel value="Truck" control={<Radio />} label="Truck" />
                  <FormControlLabel value="Auto" control={<Radio />} label="Auto" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="row mb-3 align-items-end">
          <div className="col-7 col-md-6" id="transporter_code">
            <DataSelect
              apiFieldName="Transporter"
              label="Carrier Name *"
              placeholder="Select Carrier"
              form={form}
              lastSaved={lastSaved}
              error={
                form.errors.transporter_code && form.touched.transporter_code
                  ? form.errors.transporter_code
                  : ''
              }
              isCompanyNameSelect={true}
              onChange={(data) => data?.SCACCode && form.setFieldValue('SCACCode', data.SCACCode)}
            />
          </div>
          <div className="col-5 col-md-6">
            {hasPermission(user, { resource: 'carrier', action: 'create' }) && (
              <>
                <Button type="button" onClick={() => setShowTransporterModal(true)}>
                  + Add
                </Button>
                <CreateDataModal
                  title="Add Carrier"
                  show={showTransporterModal}
                  handleClose={() => setShowTransporterModal(false)}
                >
                  <CarrierForm action="quick-create" onSuccess={onSuccess} onError={onError} />
                </CreateDataModal>
              </>
            )}
          </div>
        </div>

        <div className="row" id="SCACCode">
          <div className="col-md-6 mb-3">
            <Input
              label="SCAC Code *"
              type="text"
              {...form.getFieldProps('SCACCode')}
              error={form.errors.SCACCode && form.touched.SCACCode ? form.errors.SCACCode : ''}
            />
          </div>

          <div className="col-md-6 mb-3" id="PAPSCode">
            <Input
              label="PAPS Code *"
              type="text"
              {...form.getFieldProps('PAPSCode')}
              error={form.errors.PAPSCode && form.touched.PAPSCode ? form.errors.PAPSCode : ''}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransporterInfo;
