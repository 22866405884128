import { ThirdPartyApi, VehicleRecallApi } from 'api';
import { ROUTES } from 'constants/routes';
import { ObjectHelper } from 'helpers';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { NOTIFICATION_TYPE, Notification } from 'components';
import { AppLogo } from 'components/AppLogo/AppLogo';

import BondReleaseModal from './BondReleaseModal';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import ProfileMenu from './ProfileMenu';
import RecallsModal from './RecallsModal';
import ScanBarcodeModal from './ScanBarcodeModal';
import './styles.scss';

const menus = [
  { title: 'Vehicles', link: ROUTES.MAIN.VEHICLES.INDEX },
  { title: 'Bond Release', link: '#' },
];

const Header = () => {
  const [showVINScanner, setShowVINScanner] = useState(false);
  const [recallDialog, setRecallDialog] = useState(false);
  const [bondReleaseDialog, setBondReleaseDialog] = useState(false);
  const [vehicleRecalls, setVehicleRecalls] = useState([]);

  const findVehicleRecalls = async (vin) => {
    try {
      const { make, model, year } = await ThirdPartyApi.searchVin(vin);
      const query = ObjectHelper.trimQuery({
        modelYear: year,
        make: make,
        model: model,
      });

      VehicleRecallApi.getRecall(query).then((res) => {
        setVehicleRecalls(res.data.results);
      });
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  return (
    <header className="page-header">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/" className="d-flex-center logo">
            <AppLogo className="logo-img" />
          </Link>
          <DesktopNav
            menus={menus}
            setShowVINScanner={setShowVINScanner}
            setBondReleaseDialog={setBondReleaseDialog}
          />
          <ProfileMenu />
          <MobileNav menus={menus} />
        </div>
      </div>

      <ScanBarcodeModal
        show={showVINScanner}
        handleClose={() => setShowVINScanner(false)}
        setRecallDialog={setRecallDialog}
        findVehicleRecalls={findVehicleRecalls}
      />

      <BondReleaseModal show={bondReleaseDialog} handleClose={() => setBondReleaseDialog(false)} />

      <RecallsModal
        show={recallDialog}
        handleClose={() => setRecallDialog(false)}
        recalls={vehicleRecalls}
      />
    </header>
  );
};

export default Header;
