import { USA_ALPHA_2 } from 'constants/common';
import { useFormik } from 'formik';
import { taxIDRegex } from 'helpers/validators';
import * as Yup from 'yup';

import { useCreateConsignees } from 'features/consignees/hooks/useCreateConsignees';
import { useUpdateConsignee } from 'features/consignees/hooks/useUpdateConsignee';

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required Field'),
  companyPhone: Yup.number().typeError('should be number').required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  phoneNumber: Yup.number().typeError('should be number').required('Required Field'),
  email: Yup.string().email('Invalid Email'),
  taxID: Yup.string().matches(taxIDRegex, 'Invalid Tax ID format'),
  orgCode: Yup.string(),
  address: Yup.object().shape({
    street: Yup.string().required('Required Field'),
    city: Yup.string().required('Required Field'),
    state: Yup.string().required('Required Field'),
    zip: Yup.string().required('Required Field'),
    country: Yup.string().required('Required Field'),
  }),
});

export default function useConsigneeForm(
  action = 'create',
  initialValues = {},
  onSuccess,
  onError
) {
  const { mutate: createConsignee, isLoading: isCreating } = useCreateConsignees('single', {
    onSuccess,
    onError,
  });
  const { mutate: updateConsignee, isLoading: isUpdating } = useUpdateConsignee(initialValues.id, {
    onSuccess,
    onError,
  });

  const onSubmit = (values) => {
    if (action === 'create' || action === 'quick-create') {
      createConsignee(values);
    } else if (action === 'edit') {
      updateConsignee(values);
    }
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      companyName: initialValues.companyName || '',
      companyPhone: initialValues.companyPhone || '',
      firstName: initialValues.user?.firstName || '',
      lastName: initialValues.user?.lastName || '',
      phoneNumber: initialValues.user?.phoneNumber || '',
      email: initialValues.user?.email || '',
      address: {
        street: initialValues?.user?.street1 || '',
        city: initialValues?.user?.city1 || '',
        state: initialValues?.user?.state1 || '',
        zip: initialValues?.user?.zip1 || '',
        country: USA_ALPHA_2,
      },
      taxID: initialValues.taxID || '',
      orgCode: initialValues.user?.orgCode || '',
    },
  });

  return [form, isCreating || isUpdating];
}
