import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentCategoryApi } from 'api';

export function useUpdateDocumentCategory({ onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-document-category'],
    mutationFn: ({ id, ...data }) => DocumentCategoryApi.update(id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['document-categories', data.vehicleId.toString()]);
      queryClient.invalidateQueries(['vehicle-documents', data.vehicleId.toString()]);
      typeof onSuccess === 'function' && onSuccess(data);
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
