import React from 'react';
import { Trash } from 'react-feather';

import {
  ConfirmModal,
  CustomSelect,
  IconButton,
  NOTIFICATION_TYPE,
  Notification,
} from 'components';
import { useCreatePhotoCategory } from 'features/photos/useCreatePhotoCategory';
import { useDeletePhotoCategory } from 'features/photos/useDeletePhotoCategory';

export function PhotoCategoriesSelection({
  vehicle,
  categories,
  selectedRequiredCategory,
  selectedAdditionalCategory,
  photos,
  disabled,
  onSelect,
}) {
  const requiredCategoryOptions = React.useMemo(() => {
    return categories
      .filter((c) => c.isRequired)
      .filter((c) => !photos.some((p) => p.category.id === c.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        canDelete: item.canDelete,
      }));
  }, [categories, photos]);

  const additionalCategoryOptions = React.useMemo(() => {
    return categories
      .filter((c) => !c.isRequired)
      .filter((c) => !photos.some((p) => p.category.id === c.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        canDelete: item.canDelete,
      }));
  }, [categories, photos]);

  return (
    <div className="d-flex mb-2" style={{ gap: '8px' }}>
      <CategorySelection
        vehicle={vehicle}
        type="required"
        options={requiredCategoryOptions}
        selectedCategory={selectedRequiredCategory}
        disabled={disabled}
        onChange={(item) => {
          onSelect({ type: 'required', item });
        }}
      />
      <CategorySelection
        vehicle={vehicle}
        type="additional"
        options={additionalCategoryOptions}
        selectedCategory={selectedAdditionalCategory}
        disabled={disabled}
        onChange={(item) => {
          onSelect({ type: 'additional', item });
        }}
      />
    </div>
  );
}

function CategorySelection({ vehicle, type, options, selectedCategory, disabled, onChange }) {
  const [showConfirm, setShowConfirm] = React.useState(false);

  const categoryToDeleteRef = React.useRef(null);

  const { mutateAsync: createPhotoCategory, isLoading } = useCreatePhotoCategory();
  const { mutateAsync: deletePhotoCategory } = useDeletePhotoCategory();

  const handleCreateCategory = async (data) => {
    try {
      await createPhotoCategory(data);
      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `Category "${data.name}" has been created successfully`
      );
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  const handleConfirmDelete = async () => {
    if (!categoryToDeleteRef.current) {
      return;
    }

    try {
      await deletePhotoCategory(categoryToDeleteRef.current.value);

      if (selectedCategory?.id === categoryToDeleteRef.current.value) {
        onChange(null);
      }

      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `Category "${categoryToDeleteRef.current.label}" has been deleted successfully`
      );
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  return (
    <>
      <CustomSelect
        name={`${type}-photo`}
        id={`${type}-photo`}
        label={`${type.charAt(0).toUpperCase() + type.slice(1)} Photos`}
        placeholder="Select a category or type to create a new one"
        options={options}
        formatOptionLabel={(option, metaObj) => (
          <div className="d-flex justify-content-between align-items-center">
            <span>{option.label}</span>
            {!!option.canDelete && metaObj.context === 'menu' && (
              <IconButton
                icon={<Trash />}
                onClick={(event) => {
                  event.stopPropagation();

                  categoryToDeleteRef.current = option;
                  setShowConfirm(true);
                }}
              />
            )}
          </div>
        )}
        isLoading={isLoading}
        value={selectedCategory}
        canCreateable={true}
        isSearchable={true}
        onCreate={(value) =>
          handleCreateCategory({
            name: value,
            isRequired: type === 'required',
            vehicleId: vehicle.id,
          })
        }
        onChange={(value) => {
          onChange(value);
        }}
        disabled={disabled}
        canBeDeleted={true}
      />
      {showConfirm && categoryToDeleteRef.current && (
        <ConfirmModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          title="Delete photo category"
          content={`You're about to delete the photo category "${categoryToDeleteRef.current.label}". Do you want to proceed?`}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
}
