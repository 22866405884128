import { useQuery } from '@tanstack/react-query';
import { ConformityLocationApi } from 'api';

/**
 * Custom React Query hook to fetch conformity locations.
 *
 * @see {@link ConformityLocationApi.getAll}
 *
 * @param {{ order?: string, orderBy?: string, page?: number, perPage?: number }} queryObj
 * @param {{ onError?: (error: Error) => void }} callbacks
 * @returns
 */
export function useGetConformityLocations(queryObj, { onError } = {}) {
  return useQuery({
    queryKey: ['conformity-locations', queryObj],
    queryFn: () =>
      ConformityLocationApi.getAll(queryObj)
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
    placeholderData: {
      items: [],
      totalCount: 0,
    },
  });
}
