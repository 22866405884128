import { ROUTES } from 'constants/routes';
import { getFullAddress } from 'helpers/address';
import React from 'react';
import { Edit, List } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Loading } from 'components';
import { SkeletonRows } from 'components/SkeletonRows/SkeletonRows';
import { useGetBuyer } from 'features/buyers/hooks/useGetBuyer';

export function ViewBuyer() {
  const { id } = useParams();
  const { data: buyer, isLoading, isFetching, isError } = useGetBuyer(id);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h2>Buyer Details</h2>
        <div className="d-flex gap-4">
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.BUYERS}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <List size={20} />
            List
          </Link>
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.BUYERS}/edit/${id}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <Edit size={20} />
            Edit
          </Link>
        </div>
      </div>
      <hr />
      {isFetching && <SkeletonRows rowsCount={7} rowHeight={60} />}
      {!isFetching && !isError && (
        <div>
          <h5>Company Name</h5>
          <p>{buyer?.companyName}</p>
          <h5>Company Phone</h5>
          <p>{buyer?.companyPhone || '-'}</p>
          <h5>Tax ID</h5>
          <p>{buyer?.taxID || '-'}</p>
          <h5>Organization Code</h5>
          <p>{buyer?.user?.orgCode || '-'}</p>
          <h5>Contact Person</h5>
          <p>{`${buyer?.user?.firstName} ${buyer?.user?.lastName}`}</p>
          <h5>Contact Email</h5>
          <p>{buyer?.user?.email}</p>
          <h5>Contact Phone</h5>
          <p>{buyer?.user?.phoneNumber}</p>
          <h5>Contact Address</h5>
          <p>
            {getFullAddress([
              buyer?.user?.street1,
              buyer?.user?.city1,
              buyer?.user?.state1,
              buyer?.user?.zip1,
              buyer?.user?.country1,
            ])}
          </p>
        </div>
      )}
    </div>
  );
}
