import { Checkbox, FormControlLabel } from '@mui/material';

export function SetAsConsignee({ form }) {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={form.values.setAsConsignee} {...form.getFieldProps('setAsConsignee')} />
      }
      label="Set As Consignee"
    />
  );
}
