import { useMutation } from '@tanstack/react-query';
import { AuthApi } from 'api';

/**
 * Custom React Query hook for updating user profile.
 *
 * @param {{ onSuccess: (data: any) => void; onError: (error: Error) => void; }} callbacks
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export function useUpdateUserProfile({ onSuccess, onError } = {}) {
  return useMutation({
    mutationKey: ['update-user-profile'],
    mutationFn: (data) => AuthApi.updateUser(data),
    onSuccess: (res) => {
      typeof onSuccess === 'function' && onSuccess(res.data);
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
