import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BuyersApi } from 'api';

/**
 *  Custom React Query hook to update a buyer
 *
 * @param {number} id
 * @param {{ onSuccess?: () => void; onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export const useUpdateBuyer = (id, { onSuccess, onError } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => BuyersApi.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['buyers']);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
};
