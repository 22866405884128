import { useQuery } from '@tanstack/react-query';
import { ConsigneesApi } from 'api';

/**
 *  Custom React Query hook for fetching consignees.
 *
 * @param {{page: number, perPage: number, order: string, orderBy: string}} queryObj
 * @param @param {{ onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetConsignees(queryObj) {
  return useQuery({
    queryKey: ['consignees', queryObj],
    queryFn: () => ConsigneesApi.search(queryObj).then((res) => res.data),
  });
}
