import { ModalApi } from 'api';
import { useState } from 'react';

import { NOTIFICATION_TYPE, Notification } from 'components';

export const useModalData = ({ name, setLastSaved, lastSaved, handleClose }) => {
  const [modalData, setModalData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const onFieldChange = (field, value) => {
    setModalData({
      ...modalData,
      [field]: value,
    });
  };

  const saveClick = async () => {
    setIsSaving(true);

    try {
      const res = await ModalApi.create(name, modalData);
      setIsSaving(false);
      setModalData({});

      setLastSaved({ [name]: res.data });

      handleClose();
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    modalData,
    setModalData,
    saveClick,
    onFieldChange,
  };
};
