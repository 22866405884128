import EditIcon from '@mui/icons-material/Edit';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { useNavigate } from 'react-router';
import { titleCase } from 'utilities';
import { isNotDisplayedInVehicleProfile, transformVehicleEntries } from 'utilities/vehicle';

import { VehicleProfileColumn } from 'components';

const ProfileColumn = ({ vehicle, className }) => {
  const navigate = useNavigate();

  const vehicleEntries = transformVehicleEntries(vehicle);

  const getValue = (v, index) => {
    if (!isNotDisplayedInVehicleProfile(v)) {
      return (
        <div
          key={v.key}
          className={`vehicle-row-gap d-flex justify-content-between ${index === 0 ? '' : 'mt-4'}`}
          data-testid="vehicle-entry"
        >
          <span>{titleCase(v.key)}</span>
          {v.key === 'nhtsaReady' ? (
            <span className="fw-bolder">{v.value ? 'Ready' : 'Not ready'}</span>
          ) : v.key === 'submittedToBroker' ? (
            <span className="fw-bolder">{v.value ? 'Done' : '-'}</span>
          ) : (
            <span className="fw-bolder">{v.value || '-'}</span>
          )}
        </div>
      );
    }
  };

  const handleEditProfile = () => {
    navigate(ROUTES.MAIN.VEHICLES.EDIT.replace(':id', vehicle.id));
  };

  return (
    <VehicleProfileColumn
      title="Vehicle profile"
      className={className}
      icon={<EditIcon />}
      handleAction={handleEditProfile}
    >
      <div>{vehicleEntries.map((v, index) => getValue(v, index))}</div>
    </VehicleProfileColumn>
  );
};

export default ProfileColumn;
