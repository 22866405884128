import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import AgreeModal from './AgreeModal';

export function TermsAgreementSection({ form }) {
  const [showAgreeModal, setShowAgreeModal] = React.useState(false);

  return (
    <div className="mb-4">
      <FormControlLabel
        control={
          <Checkbox
            checked={form.values.termsAgreed}
            onChange={() => form.setFieldValue('termsAgreed', !form.values.termsAgreed)}
          />
        }
        label={
          <span>
            I have read and agree to the{' '}
            <a
              href="/#"
              className="agree-term-link text-primary"
              onClick={(e) => {
                e.preventDefault();
                setShowAgreeModal(true);
              }}
            >
              terms of service.
            </a>
          </span>
        }
      />

      <AgreeModal show={showAgreeModal} handleClose={() => setShowAgreeModal(false)} />
    </div>
  );
}
