import { DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { Dialog } from 'components/Dialog';
import { useGenerateNHTSAPackage } from 'features/vehicles/hooks/useGenerateNHTSAPackage';

const GenerateNHTSAPackageModal = ({ show, onClose, checkedIds, vehicleList, isResubmission }) => {
  const [error, setError] = useState(null);

  const { mutateAsync: generateNHTSAPackage, isLoading } = useGenerateNHTSAPackage();

  const handleGenerateNHTSAPackage = async () => {
    try {
      await generateNHTSAPackage({
        vehicleList,
        checkedIds,
        isResubmission,
      });

      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `${checkedIds.length} Vin's successfully generated NHTSA Compliance Package`
      );
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog open={show} onClose={onClose} title="NHTSA Compliance">
      <DialogContent sx={{ py: 7.5 }}>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Vin</th>
              <th scope="col">Entry Number</th>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
              <th scope="col">Year</th>
            </tr>
          </thead>
          {vehicleList ? (
            <tbody>
              {vehicleList.map((vehicle, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{vehicle.vin}</td>
                    <td>{vehicle.entryNumber}</td>
                    <td>{vehicle.make}</td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.year}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </table>
        {error && <p className="mb-0 text-red">{error}</p>}
      </DialogContent>

      <DialogActions>
        <Button type="button" variant="dark" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          loading={isLoading}
          onClick={handleGenerateNHTSAPackage}
          disabled={!!error}
        >
          Sign and Generate NHTSA package
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateNHTSAPackageModal;
