import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VehicleDocumentApi } from 'api';

export function useSortVehicleDocuments() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['sort-vehicle-documents'],
    mutationFn: (data) => VehicleDocumentApi.sort(data),
    onMutate: (variables) => {
      const { vehicleId, items } = variables;

      const savedCache = queryClient.getQueryData(['vehicle-documents', vehicleId.toString()]);

      queryClient.setQueryData(['vehicle-documents', vehicleId.toString()], () =>
        savedCache.map((document) => {
          const item = items.find((i) => i.id === document.id);

          return {
            ...document,
            order: item?.order || document.order,
          };
        })
      );

      return () =>
        queryClient.setQueryData(['vehicle-documents', vehicleId.toString()], savedCache);
    },
    onSuccess: (data, variables, restoreCache) => {
      const { vehicleId, items } = variables;

      restoreCache();
      queryClient.setQueryData(['vehicle-documents', vehicleId.toString()], () =>
        data.map((document) => {
          const item = items.find((i) => i.id === document.id);

          return {
            ...document,
            order: item?.order || document.order,
          };
        })
      );
    },
    onSettled: (data, error, variables) => {
      const vehicleId = variables.vehicleId;

      queryClient.invalidateQueries(['vehicle-documents', vehicleId.toString()]);
    },
    onError: (error, variables, restoreCache) => {
      restoreCache();
    },
  });
}
