import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DialogActions, DialogContent } from '@mui/material';
import { Button, Dialog } from '../../../components';
import useVinScanner from '../../../providers/vinScannerProvider';
import { ROUTES } from '../../../constants/routes';

const RecallsModal = ({ show, handleClose, recalls }) => {
  const { vin = '' } = useVinScanner();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Dialog open={show} onClose={handleClose} title="Vehicle Recalls">
      <DialogContent sx={{ py: 7.5 }}>
        {vin && <h6 className="mb-3 text-center">VIN: {vin}</h6>}
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">Manufacturer</th>
                <th scope="col">Model</th>
                <th scope="col">Make</th>
                <th scope="col">Model Year</th>
                <th scope="col">NHTSA Campaign</th>
                <th scope="col">Park IT</th>
                <th scope="col">Park Outside</th>
                <th scope="col">NHTSA Action</th>
                <th scope="col">Report Date</th>
                <th scope="col">Component</th>
              </tr>
            </thead>
            <tbody>
              {recalls.map((item, index) => (
                <tr key={`recalls-${index}`}>
                  <td>{item.Manufacturer}</td>
                  <td>{item.Model}</td>
                  <td>{item.Make}</td>
                  <td>{item.ModelYear}</td>
                  <td>{item.NHTSACampaignNumber}</td>
                  <td>{item.parkIt?.toString()}</td>
                  <td>{item.parkOutSide?.toString()}</td>
                  <td>{item.NHTSAActionNumber}</td>
                  <td>{item.ReportReceivedDate}</td>
                  <td>{item.Component}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="dark" onClick={handleClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            if (location.pathname !== ROUTES.MAIN.VEHICLES.CREATE) {
              navigate(ROUTES.MAIN.VEHICLES.CREATE);
            }
            handleClose();
          }}
        >
          Create Vehicle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecallsModal;
