import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PhotoCategoryApi } from 'api';

export function useDeletePhotoCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => PhotoCategoryApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['photo-categories']);
    },
  });
}
