import DeleteIcon from '@mui/icons-material/Delete';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import React, { useState } from 'react';

import { CustomSelect, InputFile } from 'components';
import ImagePreview from 'components/ImagePreview';

import { EPA_CODES } from '../VehicleForm.constants';
import useDeletePhoto from '../useDeletePhoto';
import useUploadImage from '../useUploadImage';

const EPACodeOptions = EPA_CODES.map((code) => ({
  label: code,
  value: code,
}));

const EPAEmissionInfo = ({ form, vehicle }) => {
  const [images, setImages] = useState({
    EPAEmissionLabel: vehicle?.EPAEmissionLabel,
  });

  const {
    handleDeletePhoto,
    isLoading: deleteIsLoading,
    fieldName: deleteFieldName,
  } = useDeletePhoto(form.values.vehicle_id, setImages);
  const { handleUploadImage, isLoading: uploadIsLoading } = useUploadImage(
    images,
    form.values.vin,
    form.values.vehicle_id,
    setImages
  );

  return (
    <div>
      <h3 className="mb-4">EPA Emission Information</h3>

      <div className="row mb-3" id="EPAEmissionLabel">
        <div className="col-12">
          <p className="mb-3">EPA Emission Label</p>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <InputFile
              error={
                form.errors.EPAEmissionLabel && form.touched.EPAEmissionLabel
                  ? form.errors.EPAEmissionLabel
                  : ''
              }
              className="form-control"
              type="file"
              id="EPAEmissionLabel"
              handleUploadImage={(e) => handleUploadImage('EPAEmissionLabel', e.target.files[0])}
            />
          </div>
          <div className="col-md-6 mb-3">
            {uploadIsLoading && <HourglassBottomIcon className="text-secondary" />}
            <ImagePreview src={images.EPAEmissionLabel} alt="Preview of EPA emission label" />
            {images.EPAEmissionLabel && (
              <div
                className=" text-danger p-1 bg-light image-close"
                onClick={() => handleDeletePhoto('EPAEmissionLabel', 'EPAEmissionLabel')}
              >
                {deleteIsLoading && deleteFieldName === 'EPAEmissionLabel' ? (
                  <HourglassBottomIcon className="text-secondary" />
                ) : (
                  <DeleteIcon className="text-danger" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3" id="EPACode">
          <CustomSelect
            name="EPACode"
            label="EPACode *"
            placeholder="Select EPACode"
            options={EPACodeOptions}
            value={EPACodeOptions.find((item) => item.value === form.values.EPACode)}
            onChange={(v) => {
              form.setFieldValue('EPACode', v.value);
              form.setTouched({ ...form.touched, EPACode: false });
            }}
            error={form.errors.EPACode && form.touched.EPACode ? form.errors.EPACode : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default EPAEmissionInfo;
