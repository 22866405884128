import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { SIDEBAR_MENUS } from '../../../constants/menus';
import './styles.scss';

const Sidebar = ({
  onLinkCallback,
}) => {
  const location = useLocation();

  return (
    <div className="page-sidebar d-flex flex-column">
      <div className="menu-list">
        {
          SIDEBAR_MENUS.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              data-testid={`menu-link-${index}`}
              className={`menu-list__item  ${location.pathname.includes(item.link) ? 'active' : ''}`}
              onClick={() => {
                if (onLinkCallback) onLinkCallback();
              }}
            >
              {item.title}
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default Sidebar;
