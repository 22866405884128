import Http from '../utilities/http';

export class ExportersApi {
  static search(query = {}) {
    return Http.get('/exporters', query);
  }

  static getById(id) {
    return Http.get(`/exporters/${id}`);
  }

  static update(id, data) {
    return Http.put(`/exporters/${id}`, data);
  }

  static add(data) {
    return Http.post('/exporters', data);
  }

  static batchAdd(data) {
    return Http.post('/exporters/batch', data);
  }

  static remove(id) {
    return Http.delete(`/exporters/${id}`);
  }
}
