import { Logout, Settings } from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { AuthApi } from 'api';
import { ROUTES } from 'constants/routes';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar } from 'components';

import './styles.scss';

const ProfileMenu = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    AuthApi.logout()
      .then(() => {
        navigate('/');
        setUser(null);
      })
      .catch((err) => {});
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} data-testid="profile-avatar">
        <Avatar
          className="header-profile-photo"
          name={`${user.firstName} ${user.lastName}`}
          round
          size="2.5rem"
          src={user.profilePhoto}
        />
      </IconButton>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} onClick={handleClose}>
        <MenuItem onClick={() => navigate(ROUTES.MAIN.USER_SETTINGS.INDEX)}>
          <ListItemIcon>
            <Settings fontSize="small" sx={{ color: (theme) => theme.colors.white }} />
          </ListItemIcon>
          User Settings
        </MenuItem>

        <MenuItem onClick={() => navigate(ROUTES.MAIN.PAYMENT.INDEX)}>
          <ListItemIcon>
            <PaymentIcon fontSize="small" sx={{ color: (theme) => theme.colors.white }} />
          </ListItemIcon>
          Payment Settings
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: (theme) => theme.colors.white }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
