import { DialogContent } from '@mui/material';
import React from 'react';

import { Dialog } from 'components';

export function CreateDataModal({ title, show, handleClose, children }) {
  return (
    <Dialog open={show} onClose={handleClose} title={title}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
