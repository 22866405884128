import React from 'react';

export const RIUserAgreement = () => {
    return (
        <div>
            <div className="container">
                <div className="rounded-3 p-4 bg-dark-light">
                    <h1 className="mb-5">RI User Agreement</h1>

                    <p>
                        IMPORTANT – READ CAREFULLY: Next Level Technologies Corp. doing business as VIN2 (“VIN2”, “We”,
                        “we”, “Us”, “us”, “Our” or “our”) is committed to protecting your privacy. This Privacy Policy
                        applies to the collection, use, and disclosure of personal information, including personal
                        information relating to your (“You”, “you”, “Your”, “your”, “user”, or “User”) use and enjoyment
                        of our web-based software and platform that provides a conduit and portal for connecting
                        importers, exporters, carriers, and others involved in the import and export of vehicles
                        (collectively, the “Platform”), which is made available though our website located at the domain
                        <a href="https://vin2.com/"> https://vin2.com/ </a> (the “Website”) or for download for use as
                        mobile application software, whether
                        such personal information is collected through the Website, the Platform, or otherwise. This
                        Privacy Policy also applies to any and all services made available through the Platform
                        including scanning and processing VIN numbers, cataloguing and reporting damage to vehicles, and
                        preparing import-export documentation (the “Services”).
                    </p>

                    <p>
                        READ THIS PRIVACY POLICY CAREFULLY BEFORE MAKING ANY USE OF THE WEBSITE, THE PLATFORM, OR THE
                        SERVICES. BY MAKING ANY USE OF THE WEBSITE OR PLATFORM (INCLUDING REGISTERING FOR AN ACCOUNT) OR
                        THE SERVICES, YOU AGREE AND CONSENT TO THE PRACTICES AND POLICIES DESCRIBED IN THIS PRIVACY
                        POLICY, AND TO THE COLLECTION, USE, AND DISCLOSURE OF YOUR PERSONAL INFORMATION (AS HEREINAFTER
                        DEFINED) BY VIN2 IN ACCORDANCE WITH THE TERMS OF THIS PRIVACY POLICY.
                    </p>

                    <p>
                        You acknowledge and agree that your use of the Services and Platform is also subject to VIN2’s
                        “Terms and Conditions of Use”, which may be accessed at <a
                        href="https://dev.vin2.com/terms-of-use/"> Terms and Conditions </a>
                    </p>

                    <h5 className={"mt-4"}>1. COLLECTION OF PERSONAL INFORMATION</h5>
                    <p>1.1 &emsp; Through the Platform and Services, VIN2 collects information about identifiable individuals
                        (“<b>Personal Information</b>”) relating to use of the Website, the Platform, and the Services
                        by
                        Users, which may include but may not be limited to the following information: </p>

                    <p className={"mx-4"}>
                        (i) &emsp; contact information that you provide us in connection with applying to joint our Waitlist or
                        creating and maintaining an account registered with VIN2 to enable you to use features of the
                        Website, the Platform, and the Services (an “<b>Account</b>”), including the e-mail address(es),
                        name(s), address(es), and telephone number(s) of you, your organization, and/or your
                        organization’s employees who use the Website, the Platform, and/or the Services. When you
                        provide information about others, including about your employees and authorized users who use
                        the Site and/or the Services, we expect that you have the authority to consent to its
                        collection, use, and disclosure as outlined in this Privacy Policy;
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; demographic information that you provide us in connection with creating your Account, which
                        is not unique to you, such as your postal code, age, gender, preferences, interests and
                        favourites;
                    </p>

                    <p className={"mx-4"}>
                        (iii) &emsp; information that you provide in connection with obtaining import and export permits and
                        making submissions to regulatory authorities including the NHTSA, related to your use of the
                        Platform and Services;
                    </p>

                    <p className={"mx-4"}>
                        (iv) &emsp; usage information, including the information created from your use of the Services, the
                        Platform and the Website, including metadata generated from and location information regarding
                        your use of the Website and log data recording when you have used the Website;
                    </p>

                    <p className={"mx-4"}>
                        (v) &emsp; information that you provide to us for the purpose of obtaining or receiving updates about
                        new products, promotions and services, and/or obtaining or receiving notifications, including
                        e-mail notifications;
                    </p>

                    <p className={"mx-4"}>
                        (vi) &emsp; geolocation information including information about your location and time zone through
                        which you access the Platform and Services;
                    </p>

                    <p className={"mx-4"}>
                        (vii) &emsp; contact information and address information associated with social media and other
                        Internet accounts that you provide to us, including web address, profile and related information
                        associated with your social media and other Internet accounts; and
                    </p>

                    <p className={"mx-4"}>
                        (viii) &emsp; information about your online activity, including for example, information about your web
                        browser, Internet Protocol (IP) address, use of the Site, and history. Cookies (small computer
                        files that a website’s server places on your computer) collect information about your online
                        behaviour. You can set your browser to reject cookies, but this may impair our ability to
                        customize and test your experience, like remembering your preferred language or present
                        location-specific information to you. Cookies do not typically contain personal information, but
                        personal information that we store about you may be linked to the information stored in and
                        obtained from cookies.
                    </p>

                    <p>
                        1.2 &emsp; VIN2 only collects such Personal Information as is reasonably necessary for VIN2 to fulfill
                        the purposes as set out in this Privacy Policy, including Personal Information that is relevant
                        to the legitimate purposes of the Platform, the Website, and the Services, and to enable VIN2 to
                        provide the requested Services and content and do business with you.
                    </p>

                    <p>
                        1.3 &emsp; With respect to the Personal Information of any third parties that you may provide to VIN2
                        in connection with your use of the Website, the Platform and/or the Services, you are
                        responsible for ensuring that you have any such third party’s consent to the collection, use,
                        and disclosure of their Personal Information in accordance with the terms of this Privacy
                        Policy, and you hereby represent and warrant that you have such consent.
                    </p>

                    <h5 className={"mt-4"}>2. USE OF PERSONAL INFORMATION</h5>

                    <p>
                        2.1 &emsp; Personal Information submitted to us, whether through the Website, the Platform, the
                        Services, or otherwise, may be used by us for the purposes specified in this Privacy Policy or
                        as specified on the relevant pages of the Website or the Platform or otherwise specified in
                        connection with the Services, and you hereby consent to same. By registering to create an
                        Account or registering to purchase Services by or through the Website and Platform, you consent
                        to the use of your Personal Information as indicated in this Privacy Policy. You may correct,
                        amend, update, or delete your Personal Information and change other privacy preferences at any
                        time by signing into your Account. If at any time you decide to retract your consent to this
                        Privacy Policy, you will no longer be able to make use of VIN2’s Website, Platform, or the
                        Services until you reissue your consent.
                    </p>

                    <p>
                        2.2 &emsp; VIN2 may use your Personal Information to (and you hereby consent to each of the following
                        uses):
                    </p>

                    <p className={"mx-4"}>
                        (i) &emsp; setting up your Account with us;
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; provide, update, maintain, administer and operate the Website and Platform and deliver and
                        enable your use of the Services in connection with your use of the Website and Platform, and
                        process transactions related to the Services and the Platform;
                    </p>

                    <p className={"mx-4"}>
                        (iii) &emsp; develop tools and features through the use of other information to assess trends in use,
                        search functionality, and other features of the Website, the Platform, and Services;
                    </p>

                    <p className={"mx-4"}>
                        (v) &emsp; communicate with Users and contributors;
                    </p>

                    <p className={"mx-4"}>
                        (vi) &emsp; set up Accounts, maintaining Accounts, and related activities;
                    </p>

                    <p className={"mx-4"}>
                        (vii) &emsp; inform you of other products, promotions or services available from VIN2 and its partners;
                    </p>

                    <p className={"mx-4"}>
                        (viii) &emsp; deal with inquiries, complaints, submissions and feedback relating to the Website, the
                        Platform, and/or the Services; and
                    </p>

                    <p className={"mx-4"}>
                        (ix) &emsp; verify compliance with agreements between you and us, including the Terms and Conditions of
                        Use of Service associated with the Website, the Platform, and the Services.
                    </p>

                    <p>
                        2.3 &emsp; With respect to compliance packages offered by VIN2 including in connection with
                        applications to NHTSA, VIN2 offers no representation, warranty, or guarantee that applications
                        will be approved, or that applications are in the correct form for approval.
                    </p>

                    <p>
                        2.4 &emsp; You acknowledge and agree that we may engage other companies and individuals (“<b>Third
                        Party
                        Service Providers</b>”) to perform some or all of the Services on our behalf. These Third Party
                        Service Providers may be provided with access to your Personal Information in order to perform
                        the Services on our behalf. We make commercially reasonable efforts to ensure that all Third
                        Party Service Providers acting on our behalf are obligated to provide a comparable level of
                        protection for your Personal Information to the level of protection that we provide, including
                        using your Personal Information only to the extent necessary to provide the Services on our
                        behalf.
                    </p>

                    <p>
                        2.5 &emsp; Personal Information that we collect may be uploaded, moved, stored, processed in, backed up
                        and/or transferred between any of the countries in which we operate or may operate in order to
                        enable us to collect, use, and/or disclose the Personal Information in accordance with this
                        Privacy Policy. You acknowledge and agree that your Personal Information may be uploaded, moved,
                        stored, processed in, backed up and/or transferred in VIN2’s discretion in Canada or in
                        jurisdictions other than Canada, including the United States, and you consent to the same. You
                        also acknowledge and agree that your Personal Information may be transmitted, uploaded, or moved
                        across international borders in VIN2’s discretion, and you consent to the same.
                    </p>

                    <p>
                        2.6 &emsp; VIN2 may use your email address to notify you about: VIN2-related news, products, offers,
                        surveys or promotions; actions that have been performed on the Website or Platform including
                        interactions with us through the Website or Platform; and/or notices about your Account or
                        Services. You may opt out of receiving such information by: (i) logging into your Account and
                        disabling certain communication options; or (ii) clicking the unsubscribe link at the bottom of
                        any marketing email you receive from VIN2.
                    </p>

                    <h5 className={"mt-4"}>3. DISCLOSURE OF PERSONAL INFORMATION</h5>

                    <p>
                        3.1 &emsp; Any Personal Information provided by you, or that is derived automatically by or through the
                        Website, the Platform, and/or the Services, is not sold, rented, or shared by us with any third
                        party without your consent, except in the circumstances described in this Privacy Policy.
                    </p>

                    <p>
                        3.2 &emsp; We may disclose your Personal Information to any of our employees, officers, insurers,
                        professional advisers, agents, suppliers or subcontractors, to the extent that such disclosure
                        is reasonably necessary for the purposes set out in this Privacy Policy.
                    </p>

                    <p>
                        3.3 &emsp; You acknowledge and agree that We may use and/or disclose your Personal Information, without
                        notice, if legally permitted to do so according to the relevant provisions of the Canada
                        Personal Information Protection and Electronic Documents Act, S.C. 2000, c.5 and the Personal
                        Information Protection Act, S.B.C. 2003, c. 63, and/or any other relevant privacy enactment
                        (collectively, the “<b>Privacy Acts</b>”). The Privacy Acts may permit the use and/or disclosure
                        of
                        Personal Information without notice if, for example: (a) the Personal Information could be
                        useful in the investigation of a contravention of the laws of Canada, a province or a foreign
                        jurisdiction; (b) the Personal Information is used or disclosed for the purpose of acting in
                        respect of an emergency that threatens the life, health or security of an individual; and (c)
                        the Personal Information is disclosed to comply with the edicts of the law, or to comply with a
                        subpoena or warrant issued or an order made by a court. In any event, VIN2 may release personal
                        information when it believes in good faith that such release is necessary to: (i) comply with
                        any law, order, rule, or regulation; (ii) enforce or apply this Privacy Policy or the Terms and
                        Conditions of Use or any other policy of VIN2; (iii) protect the rights (including intellectual
                        property rights), property, or safety of VIN2, its members, employees, contractors, or others;
                        and (iv) to exchange information with other companies and organizations for fraud protection and
                        credit risk reduction or where ordered to by a court.
                    </p>

                    <p>
                        3.4 &emsp; We may disclose your Personal Information:
                    </p>

                    <p className={"mx-4"}>
                        (i) &emsp; in the event that we sell or buy any business or assets, in which case we may disclose your
                        Personal Information to the prospective seller or buyer of such business or assets or (subject
                        to confidentiality restrictions) during the due diligence process, in accordance with applicable
                        law; and
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; if VIN2 or substantially all of its assets are acquired by a third party, in which case
                        Personal Information held by it about its customers will be one of the transferred assets, in
                        accordance with applicable law.
                    </p>

                    <p className={"mx-4"}>
                        3.5 &emsp; We may also disclose your Personal Information to one or more Third Party Service Providers
                        who perform some or all of the Services on our behalf. By way of example:
                    </p>

                    <p className={"mx-4"}>
                        (i) &emsp; One or more outside credit card processing companies may be used by VIN2 to process
                        transactions and bill you for the Services offered by VIN2. Payments for the purchase of VIN2’s
                        Services may be processed through Stripe, and Stripe is responsible for the collection, use and
                        disclosure of your credit card information. For more information on Stripe’s security practices,
                        please see <a
                        href="https://stripe.com/us/checkout/legal">https://stripe.com/us/checkout/legal</a>.
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; One or more outside wire transfer processing companies may be used by VIN2 to process
                        transactions and bill you for the Services offered by VIN2. Payments for the purchase of VIN2’s
                        Services may be processed through Wise, at VIN2’s discretion. For more information on Stripe’s
                        security practices, please see <a
                        href="https://wise.com/terms-and-conditions">https://wise.com/terms-and-conditions</a>.
                    </p>

                    <p className={"mx-4"}>
                        3.6 &emsp; We make every effort to ensure any company with which we may share Personal Information
                        protects that Personal Information and data in a manner similar to this Privacy Policy and to
                        limit the use of such Personal Information to the performance of the services being provided to
                        VIN2.
                    </p>

                    <h5 className={"mt-4"}>
                        4. CONSENT, WITHDRAWAL, AND DATA PROTECTION RIGHTS
                    </h5>

                    <p>4.1 &emsp; You hereby consent to the collection, use, and disclosure of your Personal Information as set
                        out in this Privacy Policy, and acknowledge and agree that your Personal Information may be used
                        by us in accordance with this Privacy Policy.</p>

                    <p>
                        4.2 &emsp; You may withdraw your consent to the collection, use, and disclosure of your Personal
                        Information as set out in this Privacy Policy at any time. Withdrawal of your consent to the
                        collection, use, and/or disclosure of your Personal Information as set out in this Privacy
                        Policy may result in the Website, the Platform, and/or the Services or certain aspects of the
                        Website, the Platform, and/or the Services being unavailable to you. If you withdraw your
                        consent to the collection, use, and/or disclosure of your Personal Information as set out in
                        this Privacy Policy, VIN2 reserves the right to limit your access or prevent you from accessing
                        the Website, the Platform, and/or the Services.
                    </p>

                    <p>
                        4.3 &emsp; Depending on your location and subject to applicable law, you may have additional rights
                        with respect to your Personal Information, including:
                    </p>

                    <p className={"mx-4"}>
                        (i) &emsp; “<b>Right to be forgotten</b>” - the right to request that we erase your Personal Information in
                        certain circumstances provided by applicable law;
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; “<b>Data portability</b>” - the right to request that we send to another company, where
                        technically feasible, your Personal Information; and
                    </p>

                    <p className={"mx-4"}>
                        (iii) &emsp; the right to request that we restrict the processing of your Personal Information in
                        certain circumstances; for example, if you contest the accuracy of the Personal Information
                        while we are verifying the accuracy thereof.
                    </p>

                    <p>
                        In order to exercise these rights, you may contact VIN2’s designated Privacy Officer at [NTD –
                        Insert E-mail address for privacy officer of VIN2]. We will comply with your request to the
                        extent possible and as required by applicable law.
                    </p>

                    <h5 className={"mt-4"}>5. INFORMATION YOU GIVE US OR PUBLICLY DISCLOSE</h5>

                    <p>5.1 &emsp; In addition to the provisions that explicitly relate to the use, collection, and disclosure
                        of your Personal Information in this Privacy Policy, you acknowledge and agree that we may
                        collect and store information from your computer, mobile device or tablet (via your use of the
                        Website, the Platform, and/or the Services), including Personal Information, to assist us in
                        providing, maintaining and modifying the Website, the Platform, and/or the Services, and you
                        consent to same. For example, we may use the information (including Personal Information) that
                        you provide for such purposes as facilitating your use of the Website, the Platform, and/or the
                        Services, responding to your requests, providing the Services, and in other ways in
                        communicating with you. </p>

                    <p>
                        5.2 &emsp; If you disclose Personal Information or personally sensitive data through any public area of
                        the Website, the Platform, and/or the Services, that Personal Information may be collected,
                        used, and/or disclosed by other users. The collection, use, and disclosure of Personal
                        Information and personally sensitive data made available in this fashion cannot be controlled by
                        VIN2, and VIN2 accepts no responsibility or liability for the unauthorized collection, use,
                        and/or disclosure of Personal Information and personally sensitive data that you make available
                        in this fashion.
                    </p>

                    <h5 className={"mt-4"}>6. AUTOMATICALLY GATHERED INFORMATION</h5>

                    <p>
                        6.1 &emsp; We receive and store certain types of computer information whenever you interact with our
                        Website. Examples of the information that we may automatically receive and store may include the
                        Internet protocol (IP) address used to connect your computer to the Internet; computer and
                        connection information such as browser type and version, operating system, and platform; and the
                        full Uniform Resource Locators (URL) click stream to, through, and from our Website, including
                        date and time information relating to your visits. We may use IP addresses to analyze trends,
                        administer the Website, track user's movement, to determine what aspects of the Website,
                        Services, and Platform are the most popular, advertise, and gather broad demographic information
                        for aggregate use.
                    </p>

                    <p>
                        6.2 &emsp; Such automatically gathered information may be used by VIN2 for the operation of the
                        Services provided to you via the Website and/or the Platform, to maintain the quality of the
                        Services provided through the Website and/or the Platform, and to provide VIN2 with general
                        statistics relating to use of the Website and the Platform.
                    </p>

                    <h5 className={"mt-4"}>7. SECURITY AND RETENTION OF PERSONAL INFORMATION</h5>

                    <p>
                        7.1 &emsp; VIN2 secures your Personal Information from unauthorized access, use and disclosure by third
                        parties. You acknowledge and agree that We may, at our sole discretion, use a variety of
                        security technologies and procedures to help protect your Personal Information from unauthorized
                        access, use, and disclosure. For example, VIN2 may secure the Personal Information you provide
                        on computer servers in a controlled, secure environment, protected from unauthorized access, use
                        or disclosure. When Personal Information is transmitted to other web sites or third parties for
                        the purposes specified above, we may also, or in the alternative, protect it through the use of
                        encryption, such as by way of the Secure Socket Layer (SSL) protocol.
                    </p>

                    <p>
                        7.2 &emsp; There are always risks associated with providing Personal Information and sensitive data,
                        whether in person, by phone, mobile, or tablet device, via the Internet or via other
                        technologies. In particular, you acknowledge that the transmission of information and data over
                        the Internet is inherently insecure, and there are no security systems that are completely safe
                        or fool-proof against hacking or tampering. VIN2 endeavours to take commercially reasonable
                        precautions to prevent and minimize such risks in connection with your use of the Website, the
                        Platform, and the Services, but VIN2 offers no (and hereby expressly disclaims any) guarantee,
                        representation, warranty, or covenant of any kind with respect to the same.
                    </p>

                    <p>
                        7.3 &emsp; You are solely responsible for controlling access to your Account(s) associated with the
                        Website and Platform and the Services available through the Website and Platform, and for
                        maintaining the confidentiality and security of your accounts and related password information.
                        You are entirely responsible for all activities that occur on or through your Account(s), and
                        VIN2 shall not be responsible for any losses arising out of the unauthorized use of any of your
                        Account(s) associated with the Website, the Platform, and the Services available through the
                        Website and Platform.
                    </p>

                    <p>
                        7.4 &emsp; Personal Information is not kept by VIN2 for any longer than is necessary for the purposes
                        identified in this Privacy Policy. Personal Information that is no longer required to fulfil the
                        purposes identified in this Privacy Policy will be destroyed, erased, or made anonymous. Without
                        limiting the foregoing or the purposes for which VIN2 may retain Personal Information, VIN2:
                    </p>

                    <p className={"mx-4"}>
                        (i) &emsp; will retain Personal Information relating to your Account(s) for legitimate business and
                        legal purposes including all records related to access and use of the Services by Users;
                    </p>

                    <p className={"mx-4"}>
                        (ii) &emsp; when possible, will restrict the retention of Personal Information to the shortest possible
                        time period; and
                    </p>

                    <p className={"mx-4"}>
                        (iii) &emsp; may pseudonymize some Personal Information for ongoing retention without identifying
                        features.
                    </p>

                    <h5 className={"mt-4"}>
                        8. COOKIES
                    </h5>

                    <p>
                        8.1 &emsp; A “cookie” is a small text file consisting of alphanumeric identifiers that is placed on
                        your hard disk by a Web page server. The identifier is then sent back to the Web page server
                        each time the browser requests a page from the Web page server. Cookies contain information that
                        can later be read by a web server in the domain that issued the cookie to you.
                    </p>

                    <p>
                        8.2 &emsp; We may use cookies on the Website to recognise a computer when a user visits the Website,
                        improve the usability of the Website, sign in to the Website and/or Services, analyse the use of
                        the Website, administer the Website, and personalise the Website for use by you. We may also
                        make use of cookies through the Website for traffic analysis, advertising, and anonymous
                        demographic profiling.
                    </p>

                    <p>
                        8.3 &emsp; Cookies do not typically contain Personal Information, but Personal Information that we
                        store about you may be linked to the information stored in and obtained from cookies.
                    </p>

                    <p>
                        8.4 &emsp; By using and browsing the Website, you consent to cookies being used in accordance with this
                        Privacy Policy. If you do not consent, you must disable cookies or refrain from using the
                        Website.
                    </p>

                    <p>
                        8.5 &emsp; If you do not wish to receive cookies, or want to be notified of when they are placed, you
                        may set your web browser to do so, if your browser so permits. If you wish to delete cookies,
                        you may set your web browser to do so, if your browser so permits. You acknowledge and agree
                        that if cookies are turned off or deleted, you may not be able to view certain parts of the
                        Website or use certain of the Services available through the Website or certain features or
                        functionalities thereof.
                    </p>

                    <p>
                        8.6 &emsp; Third-party product or service providers who have access to the Website may also use
                        cookies, but we have no control of the use of cookies by such third party product or service
                        providers or any other third parties.
                    </p>

                    <h5 className={"mt-4"}>9. CHANGES TO THIS PRIVACY POLICY</h5>

                    <p>
                        This Privacy Policy may be changed, removed, added to or otherwise modified by VIN2 from time to
                        time at VIN2’s sole discretion and without any prior notice or liability to you. The collection,
                        use, and disclosure of Personal Information by VIN2 will be governed by the version of this
                        Privacy Policy in effect at that time. All new, amended, or otherwise modified terms take effect
                        immediately. Your continued use of the Website, the Services, and the Platform subsequent to any
                        modifications to this Privacy Policy signifies that you consent to the collection, use, and
                        disclosure of your Personal Information in accordance with the modified Privacy Policy. You
                        should bookmark this page and periodically review this Privacy Policy to ensure that you are
                        familiar with the most current version of the Privacy Policy
                    </p>

                    <h5 className={"mt-4"}>10. THIRD PARTY PRIVACY POLICIES AND LINKS</h5>

                    <p>
                        The Website and the Platform may include links to, and details of, third party web sites or
                        Internet resources. Any links on the Website or Platform are provided for your convenience. VIN2
                        does not endorse, approve of, verify, attest to, or offer any representation or warranty with
                        VIN2 as to, the accuracy of the content of such web sites that are linked or Internet resources
                        that are linked from the Website and the Platform. If you decide to leave the Website or the
                        Platform and access these third-party sites, you do so at your own risk.
                    </p>

                    <p>
                        When you click on one of those links you are contacting another web site or Internet resource
                        that may collect information about you, including Personal Information, voluntarily or through
                        cookies. The privacy policies of such third parties may not mirror those of VIN2, and you should
                        review the privacy policies of web sites or Internet resources which you choose to access from
                        the Website or the Platform, so that you understand how those web sites or Internet resources
                        collect, use and share your information. VIN2 has no control over other web sites or Internet
                        resources or their policies regarding the collection, use, and disclosure of your Personal
                        Information, and VIN2 accepts no responsibility or liability for the privacy practices of third
                        parties, including any unauthorized collection, use, or disclosure of your Personal Information
                        through third party web sites or Internet resources.
                    </p>

                    <h5 className={"mt-4"}>
                        11. COMMENTS
                    </h5>

                    <p>
                        VIN2 welcomes your comments regarding this Privacy Policy. If you would like more information on
                        this Privacy Policy or VIN2’s organizational privacy policies, or if believe that VIN2 has not
                        adhered to the terms of this Privacy Policy, please contact VIN2’s designated Privacy Officer at
                        [NTD – Insert E-mail address for privacy officer of VIN2]. We will use commercially reasonable
                        efforts to promptly identify and remedy any failure by VIN2 to adhere to the terms of this
                        Privacy Policy. [NTD – Having a designated privacy officer is a requirement under Canadian law.
                        This does not need to be a person who is dedicated to this role, and does not need be an e-mail
                        address directed to a specific person. For example, an email address of “privacy@vin2.com” that
                        directs to a person or team of people would be acceptable.]
                    </p>
                </div>
            </div>
        </div>
    );
}
