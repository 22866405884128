import { useQuery } from '@tanstack/react-query';
import { PlacesApi } from 'api';
import { CANADA_ALPHA_2,MEXICO_ALPHA_2, USA_ALPHA_2 } from 'constants/common';
import { getComponents } from 'services/places';

/**
 * @typedef {USA | CANADA | MEXICO} ComponentCountry
 */

/**
 * Custom React Query hook for the Google Places API.
 *
 * @param {{input: string, radius?: number, countries: Array<ComponentCountry>}} query
 * @param {{onError: (error: Error) => void | undefined }} callbacks
 * @returns {import("@tanstack/react-query").UseQueryResult<import("api").PlaceAutocompleteResponse, Error>}
 */
export function usePlaceAutocomplete(query, { onError }) {
  const { input, radius = 50000, countries = [USA_ALPHA_2, CANADA_ALPHA_2, MEXICO_ALPHA_2] } = query;

  const components = getComponents(countries);

  return useQuery({
    queryKey: ['place-autocomplete', { input, radius, components }],
    queryFn: () => PlacesApi.autocomplete({ input, radius, components }),
    enabled: input !== undefined && input.length > 0, // do not run query until input is provided
    refetchOnWindowFocus: false, // do not refetch on window focus
    retry: false, // do not retry on failure
    staleTime: Infinity,
    // cacheTime: Infinity, // do not cache results indefinitely
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
