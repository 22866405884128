import { USA_ALPHA_2 } from 'constants/common';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { useCreateTransporters } from 'features/carriers/hooks/useCreateCarriers';
import { useUpdateTransporter } from 'features/carriers/hooks/useUpdateCarrier';

export default function useCarrierForm(action = 'create', initialValues = {}, onSuccess, onError) {
  const { mutate: createCarrier, isLoading: isCreating } = useCreateTransporters('single', {
    onSuccess,
    onError,
  });
  const { mutate: updateCarrier, isLoading: isUpdating } = useUpdateTransporter(initialValues.id, {
    onSuccess,
    onError,
  });

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      companyName: Yup.string().required('Required Field'),
      companyPhone: Yup.number().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.number().typeError('should be number').required('Required Field'),
      }),
      firstName: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      lastName: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      phoneNumber: Yup.number().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.number().typeError('should be number').required('Required Field'),
      }),
      email: Yup.string()
        .when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        })
        .email('Invalid Email'),
      address: Yup.object().shape({
        street: Yup.string().when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        }),
        city: Yup.string().when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        }),
        state: Yup.string().when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        }),
        zip: Yup.string().when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        }),
        country: Yup.string().when([], {
          is: () => action === 'create' || action === 'edit',
          then: Yup.string().required('Required Field'),
        }),
      }),
      SCACCode: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      plate: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      truckPlate: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      trailerPlate: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      driversLicense: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      passport: Yup.string().when([], {
        is: () => action === 'create' || action === 'edit',
        then: Yup.string().required('Required Field'),
      }),
      fastId: Yup.string(),
      orgCode: Yup.string(),
    });
  }, [action]);

  const onSubmit = (values) => {
    if (action === 'create' || action === 'quick-create') {
      createCarrier(values);
    } else if (action === 'edit') {
      updateCarrier(values);
    }
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      companyName: initialValues.companyName || '',
      companyPhone: initialValues.companyPhone || '',
      firstName: initialValues.user?.firstName || '',
      lastName: initialValues.user?.lastName || '',
      phoneNumber: initialValues.user?.phoneNumber || '',
      email: initialValues.user?.email || '',
      address: {
        street: initialValues?.user?.street1 || '',
        city: initialValues?.user?.city1 || '',
        state: initialValues?.user?.state1 || '',
        zip: initialValues?.user?.zip1 || '',
        country: USA_ALPHA_2,
      },
      SCACCode: initialValues.SCACCode || '',
      plate: initialValues.plate || '',
      truckPlate: initialValues.truckPlate || '',
      trailerPlate: initialValues.trailerPlate || '',
      driversLicense: initialValues.driversLicense || '',
      passport: initialValues.passport || '',
      fastId: initialValues.fastId || '',
      orgCode: initialValues.user?.orgCode || '',
    },
  });

  return [form, isCreating || isUpdating];
}
