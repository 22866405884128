import { AuthApi } from 'api';
import React, { useEffect, useState } from 'react';

import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';

export const AuthContext = React.createContext({
  user: null,
  setUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    AuthApi.getSession()
      .then((res) => {
        setUser(res.data);
        setIsLoading(false);
        return;
      })
      .catch((e) => {
        setUser(null);
        setIsLoading(false);
        return;
      });
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {isLoading ? (
        <div className="d-flex-center h-screen w-screen" data-testid="loading">
          <div className="spinner-border" role="status" style={{ width: '5rem', height: '5rem' }}>
            <VisuallyHidden>Loading</VisuallyHidden>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
