import { useQuery } from '@tanstack/react-query';
import { BuyersApi } from 'api';

/**
 *  Custom React Query hook for fetching buyers
 *
 * @param {{page: number, perPage: number, order: string, orderBy: string}} queryObj
 * @param {{ enabled?: boolean }} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetBuyers(queryObj, { enabled = true } = {}) {
  return useQuery({
    queryKey: ['buyers', queryObj],
    queryFn: async () => (await BuyersApi.search(queryObj)).data,
    enabled: !!queryObj && enabled,
  });
}
