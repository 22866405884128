import { CANADA_ALPHA_2, MEXICO_ALPHA_2, USA_ALPHA_2 } from 'constants/common';

/**
 * @typedef {{long_name: string, short_name: string, types: string[]}} AddressComponent
 */

/**
 * @typedef {Array<AddressComponent>} AddressComponents
 *
 */

/**
 * Get address fields from Google Places API response data.
 *
 * @param {AddressComponents} addressComponents
 * @returns {{ street: string, city: string, state: string, zip: string, country: string }}
 */
export function getAddressFields(addressComponents) {
  const streetNumber =
    addressComponents.find((component) => component.types.includes('street_number'))?.long_name ||
    '';
  const route =
    addressComponents.find((component) => component.types.includes('route'))?.long_name || '';

  const street = `${streetNumber} ${route}`.trim();

  const city =
    addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
  const state =
    addressComponents.find((component) => component.types.includes('administrative_area_level_1'))
      ?.short_name || '';
  const zip =
    addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';
  const country =
    addressComponents.find((component) => component.types.includes('country'))?.short_name || '';

  return {
    street,
    city,
    state,
    zip,
    country,
  };
}

/**
 * Get components from countries
 * @param {string[]} countries
 * @returns {string}
 */
export function getComponents(countries = []) {
  if (countries.filter((country) => country && country.length > 0) === 0) {
    return [USA_ALPHA_2, CANADA_ALPHA_2, MEXICO_ALPHA_2];
  }

  return countries
    .map((country) => `country:${country}`)
    .join('|');
}
