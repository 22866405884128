import { useQuery } from '@tanstack/react-query';
import { RegisterImporterApi } from 'api';

export function useGetCurrentUserRegisteredImporter({ enabled = true }) {
  return useQuery({
    queryKey: ['current-user-registered-importer'],
    queryFn: () => RegisterImporterApi.getCurrent().then((res) => res.data),
    enabled,
  });
}
