import { useQuery } from '@tanstack/react-query';
import { ThirdPartyApi, VehicleRecallApi } from 'api';
import { ObjectHelper } from 'helpers';
import React from 'react';

export default function useCheckVin(vin, { onError } = {}) {
  const {
    data: vehicleData,
    isInitialLoading: isVehicleLoading,
    isFetching: isVehicleFetching,
  } = useQuery({
    queryKey: ['search-vin', vin],
    queryFn: () =>
      ThirdPartyApi.searchVin(vin).catch(
        (error) => typeof onError === 'function' && onError(error)
      ),
    staleTime: Infinity,
    enabled: !!vin,
  });

  const recallQuery = React.useMemo(() => {
    if (!vin || !vehicleData) return null;

    const { make, model, year } = vehicleData;

    const query = ObjectHelper.trimQuery({
      modelYear: year,
      make: make,
      model: model,
    });

    return query;
  }, [vin, vehicleData]);

  const {
    data: recallData,
    isInitialLoading: isRecallsLoading,
    isFetching: isRecallsFetching,
  } = useQuery({
    queryKey: ['search-recalls', recallQuery],
    queryFn: () =>
      VehicleRecallApi.getRecall(recallQuery)
        .then((res) => res.data)
        .catch((error) => typeof onError === 'function' && onError(error)),
    staleTime: Infinity,
    enabled: !!recallQuery,
  });

  return React.useMemo(
    () => ({
      data: { vehicleData, recallData },
      isLoading: isVehicleLoading || isRecallsLoading,
      isFetching: isVehicleFetching || isRecallsFetching,
    }),
    [
      isRecallsFetching,
      isRecallsLoading,
      isVehicleFetching,
      isVehicleLoading,
      recallData,
      vehicleData,
    ]
  );
}
