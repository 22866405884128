import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loading } from '../../../components';
import { VehicleApi } from '../../../api';
import MultiRILabel from './RILabel';
import { ChevronLeft } from 'react-feather';

const VehicleRILabel = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [vehicleLoading, setVehicleLoading] = useState(true);

  useEffect(() => {
    VehicleApi.getDetail(id, "withImporter=1").then((res) => {
      const detail = res.data;
      setVehicle(detail);
      setVehicleLoading(false);
    });
  }, [id]);

  const navigate = useNavigate();
  if (!vehicle) {
    return null;
  }

  if (vehicleLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="mb-4">
        <Button variant="link" onClick={() => navigate(`/vehicles/${id}`)}>
          <div className="d-flex align-items-center">
            <ChevronLeft className="mr-3" />
            <span className="ms-2">Back</span>
          </div>
        </Button>
      </div>
      <MultiRILabel vehicles={[vehicle]} />
    </div>
  );
};

export default VehicleRILabel;
