import classNames from 'classnames';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { hasPermission } from 'services/auth';

import { IconButton } from '../IconButton';
import './style.scss';

export const VehicleProfileColumn = ({ title, children, icon, handleAction, className }) => {
  const { user } = React.useContext(AuthContext);

  return (
    <div className={classNames('vehicle-profile-column-container', className)}>
      <div className="pb-1 vehicle-profile-column-header d-flex align-items-center justify-content-between">
        <h3>{title}</h3>
        <IconButton
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'update' })}
          onClick={handleAction}
          icon={icon}
        />
      </div>
      <div className="pt-3">{children}</div>
    </div>
  );
};
