import { DialogActions, DialogContent } from '@mui/material';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { Button, Dialog, NOTIFICATION_TYPE, Notification, Table } from 'components';
import { SkeletonRows } from 'components/SkeletonRows/SkeletonRows';
import { useGenerateNHTSAPackage } from 'features/vehicles/hooks/useGenerateNHTSAPackage';

import { useGetNHTSAPackage } from './useGetNHTSAPackage';

const columns = [
  { title: 'Vin', dataIndex: 'vin' },
  {
    title: 'Entry Number',
    dataIndex: '',
    render: (item) => item.VehicleEntryNumber?.fullEntryNumber,
  },
  { title: 'Make', dataIndex: 'make' },
  { title: 'Model', dataIndex: 'model' },
  { title: 'Year', dataIndex: 'year' },
];

export function NHTSARegenrationModal() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [error, setError] = React.useState(null);

  const { data: nhtsaPackage, isLoading, isFetching } = useGetNHTSAPackage(id);
  const { mutateAsync: generateNHTSAPackage, isLoading: isGenerating } = useGenerateNHTSAPackage();

  const [checkedVehicleIds, setCheckedVehicleIds] = React.useState(new Set());

  const handleClose = () => {
    navigate(ROUTES.MAIN.NHTSA.INDEX);
  };

  const handleToggleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckedVehicleIds(new Set(nhtsaPackage.vehicles.map((item) => item.id)));
    } else {
      setCheckedVehicleIds(new Set());
    }
  };

  const handleChangeRowCheck = (item) => {
    if (checkedVehicleIds.has(item.id)) {
      checkedVehicleIds.delete(item.id);
      setCheckedVehicleIds(new Set(checkedVehicleIds));
    } else {
      checkedVehicleIds.add(item.id);
      setCheckedVehicleIds(new Set(checkedVehicleIds));
    }
  };

  const handleReGenerateNHTSAPackage = async () => {
    setError(null);
    try {
      await generateNHTSAPackage({
        vehicleList: nhtsaPackage?.vehicles ?? [],
        checkedIds: Array.from(checkedVehicleIds),
        isResubmission: true,
        originalPackageId: nhtsaPackage?.id,
      });

      Notification(
        NOTIFICATION_TYPE.SUCCESS,
        `${checkedVehicleIds.size} Vin's successfully re-generated NHTSA Compliance Package ${nhtsaPackage.id}`
      );

      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} title="NHTSA Package Re-Generation">
      <DialogContent sx={{ py: 7.5 }}>
        {(isLoading || isFetching) && <SkeletonRows rowsCount={6} />}
        {!isLoading && !isFetching && (
          <div>
            <dl className="d-flex gap-2">
              <dt>Package Number: </dt>
              <dd>{id}</dd>
            </dl>
            <dl className="d-flex gap-2">
              <dt>No. of Resubmissions: </dt>
              <dd>{nhtsaPackage.resubmissionPackages?.length ?? 0}</dd>
            </dl>
            <Table
              columns={columns}
              data={nhtsaPackage?.vehicles ?? []}
              checkable
              onCheckAllChange={handleToggleCheckAll}
              onCheckRowChange={handleChangeRowCheck}
            />
          </div>
        )}
        {error && <p className="mb-0 text-red">{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="button"
          loading={isGenerating}
          onClick={handleReGenerateNHTSAPackage}
          disabled={checkedVehicleIds.size === 0}
        >
          Re-Generate NHTSA package
        </Button>
      </DialogActions>
    </Dialog>
  );
}
