import { Outlet } from 'react-router';

import { NHTSAPackagesTable } from 'features/nhtsa-packages/NHTSAPackagesTable';

export default function NHTSARegenerationPage() {
  return (
    <div>
      <h2 className="mb-5">NHTSA Packages</h2>
      <NHTSAPackagesTable />
      <Outlet />
    </div>
  );
}
