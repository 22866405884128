import React, { useState } from 'react';

import './styles.scss';

export const InputFile = ({
  id,
  label,
  className = '',
  hasBorder = false,
  error = '',
  type,
  startIcon,
  iconHref,
  handleUploadImage,
  ...props
}) => {
  return (
    <div>
      <input
        {...props}
        style={{ border: error ? '1px solid #b00a0a' : '' }}
        className={`form-control `}
        type="file"
        id={id}
        onChange={handleUploadImage}
      />
      {error && <p className="mb-0 text-red">{error}</p>}
    </div>
  );
};
