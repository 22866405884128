import CircleIcon from '@mui/icons-material/Circle';
import { ENTRY_STATUS } from 'constants/vehicle';
import React from 'react';

import VisuallyHidden from 'components/VisuallyHidden';

import './styles.scss';

const EntryStatus = ({ status, statusMessage = null }) => {
  const entryStatus = React.useMemo(() => {
    let color;
    let iconClassName;
    let statusText;

    switch (status) {
      case ENTRY_STATUS.submitted:
        color = '#FF0000';
        iconClassName = 'flashing';
        statusText = 'Submitted to Broker';
        break;
      case ENTRY_STATUS.success:
        color = '#00FF00';
        statusText = 'Success';
        break;
      case ENTRY_STATUS.failed:
        color = '#FFFF00';
        statusText = 'Failed';
        break;
      default:
        color = '#FF0000';
        statusText = 'Not submitted to Broker';
    }

    return { color, iconClassName, statusText };
  }, [status]);

  return (
    <div className="hovertext" data-hover={statusMessage}>
      <CircleIcon sx={{ color: entryStatus.color }} className={entryStatus.iconClassName} />
      <VisuallyHidden>{entryStatus.statusText}</VisuallyHidden>
    </div>
  );
};

export default EntryStatus;
