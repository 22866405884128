import React, { useState } from 'react';
import { TextArea } from '../../../../components';

const CustomerInfo = ({
  form
}) => {
  return (
    <div>
      {/*<h3 className="mb-4">Customer User</h3>*/}

      <div className="mb-3">
        <TextArea
          label="Notes"
          type="text"
          {...form.getFieldProps('notes')}
          error={form.errors.notes && form.touched.notes ? form.errors.notes : '' }
        />
      </div>
    </div>
  );
};

export default CustomerInfo;
