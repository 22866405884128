import { Checkbox, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { isRegisteredImporter } from 'services/auth';
import { BUYER, CARRIER, SELLER } from 'utils';

import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';

import { SharedUsersTable } from './SharedUsersTable';
import { SharedVehiclesTable } from './SharedVehiclesTable';
import { useShareVehicles } from './useShareVehicles';

const USER_TYPES = [BUYER, SELLER, CARRIER];

const ShareVehiclesModal = ({ vehicleList, show, handleClose }) => {
  const [vehicles, setVehicles] = useState([]);
  const [prevVehicleList, setPrevVehicleList] = useState(vehicleList);

  if (prevVehicleList !== vehicleList && show) {
    setPrevVehicleList(vehicleList);
    setVehicles(vehicleList);
  }

  const { user: curUser } = useContext(AuthContext);
  const [types, setType] = useState([BUYER]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { mutateAsync: shareVehicles, isLoading: isSubmitting } = useShareVehicles();

  const onSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    const data = {
      users: selectedUsers,
      ids: vehicles.map((v) => v.id),
    };

    try {
      const res = await shareVehicles(data);
      Notification(NOTIFICATION_TYPE.SUCCESS, res.data.message);
      setSelectedUsers([]);
      handleClose();
    } catch (error) {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    }
  };

  const onRemove = (id) => {
    if (isSubmitting) {
      return;
    }
    const newVehicle = vehicles.filter((vehicle) => vehicle.id !== id);
    setVehicles(newVehicle);
  };

  const handleChangeType = (type) => {
    if (isSubmitting) {
      return;
    }
    const _types = [...types];
    const index = _types.indexOf(type);
    if (index !== -1) {
      _types.splice(index, 1);
    } else {
      _types.push(type);
    }
    setType(_types);
    setSelectedUsers([]);
  };

  const handleToggleUser = (id) => {
    if (isSubmitting) {
      return;
    }
    const _users = [...selectedUsers];
    const index = _users.indexOf(id);
    if (index !== -1) {
      _users.splice(index, 1);
    } else {
      _users.push(id);
    }
    setSelectedUsers(_users);
  };

  return (
    <Dialog open={show} onClose={handleClose} title="Share with user">
      <DialogContent sx={{ py: 7.5 }}>
        <SharedVehiclesTable vehicles={vehicles} onRemove={onRemove} />
        <SharedUsersTable
          types={types}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          onToggleUser={handleToggleUser}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'block!important' }}>
        <div className="row row-cols-1 m-0">
          {isRegisteredImporter(curUser) && (
            <div className="d-flex col">
              {USER_TYPES.map((value) => (
                <FormControlLabel
                  sx={{ color: (theme) => theme.colors.white }}
                  key={value}
                  control={
                    <Checkbox
                      sx={{ color: (theme) => theme.colors.white }}
                      checked={types.includes(value)}
                      onChange={() => handleChangeType(value)}
                    />
                  }
                  label={value}
                />
              ))}
            </div>
          )}

          <div className="d-flex col justify-content-end">
            <Button
              type="button"
              disabled={vehicles.length === 0 || selectedUsers.length === 0}
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Share
            </Button>
            <Button
              className="ms-2"
              type="button"
              disabled={isSubmitting}
              variant="dark"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShareVehiclesModal;
