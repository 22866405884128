export const LETTER = 'letter';
export const _4X3 = '4X3';
export const _4X2dot5 = '4X2.5';
export const PAGE_SIZES = {
  [LETTER]: {
    width: 612,
    height: 792,
  },
  [_4X3]: {
    width: 400,
    height: 300,
  },
  [_4X2dot5]: {
    width: 400,
    height: 250,
  },
};
