import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { BackButton, Loading, NOTIFICATION_TYPE, Notification } from 'components';

import { UserForm } from '../UserForm/UserForm';
import { useGetUser } from '../hooks/useGetUser';

export function EditUser() {
  const { id } = useParams();
  const { data: importer, isLoading, error, isFetching } = useGetUser(id);

  const navigate = useNavigate();

  const onSuccess = () => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Importer was updated successfully');
    navigate(-1);
  };

  const onError = (error) => {
    Notification(NOTIFICATION_TYPE.DANGER, error.message);
  };

  if (isFetching || isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row mb-4">
        <h1>Edit Importer</h1>
      </div>

      <UserForm action="edit" initialValues={importer} onSuccess={onSuccess} onError={onError} />
    </div>
  );
}
