import React from 'react';
import { Box, Dialog as MuiDialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export const Dialog = ({ open, onClose, title, children }) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: useMediaQuery('(min-width: 600px)') ? '80vw' : 'min(450px, 90vw)',
          bgcolor: (theme) => theme.colors.dark,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: (theme) => theme.colors.dark_2,
          borderBottom: '1px solid #13171d',
        }}
      >
        <DialogTitle
          sx={{
            color: (theme) => theme.colors.white,
            py: 4,
          }}
        >
          {title}
        </DialogTitle>

        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            bgcolor: (theme) => theme.colors.white,
            top: '50%',
            transform: 'translateY(-50%)',
            right: '16px',
            color: (theme) => theme.colors.dark,
            '&:hover': {
              bgcolor: '#d7d7d7',
            },
          }}
          data-testid="close-btn"
          onClick={onClose}
        >
          <CloseIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Box>

      {children}
    </MuiDialog>
  );
};
