import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { BackButton, Loading, NOTIFICATION_TYPE, Notification } from 'components';
import { ConsigneeForm } from 'features/consignees/components/form/ConsigneeForm';
import { useGetConsignee } from 'features/consignees/hooks/useGetConsignee';

export function EditConsignee() {
  const { id } = useParams();
  const { data: consignee, isFetching, isLoading, error } = useGetConsignee(id);

  const navigate = useNavigate();

  const onSuccess = () => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Consignee was updated successfully');
    navigate(-1);
  };

  const onError = (error) => {
    Notification(NOTIFICATION_TYPE.DANGER, error.message);
  };

  if (isFetching || isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row mb-4">
        <h1>Edit Consignee</h1>
      </div>

      <ConsigneeForm
        action="edit"
        initialValues={consignee}
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
}
