import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PhotosApi } from 'api';

export function useSortVehiclePhotos() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['sort-vehicle-photos'],
    mutationFn: (data) => PhotosApi.sort(data),
    onMutate: (variables) => {
      const { vehicleId, items } = variables;

      const savedCache = queryClient.getQueryData(['vehicle-photos', vehicleId.toString()]);

      queryClient.setQueryData(['vehicle-photos', vehicleId.toString()], () =>
        savedCache.map((photo) => {
          const item = items.find((i) => i.id === photo.id);

          return {
            ...photo,
            order: item?.order || photo.order,
          };
        })
      );

      return () => queryClient.setQueryData(['vehicle-photos', vehicleId.toString()], savedCache);
    },
    onSuccess: (data, variables, restoreCache) => {
      const { vehicleId, items } = variables;

      restoreCache();
      queryClient.setQueryData(['vehicle-photos', vehicleId.toString()], () =>
        data.map((photo) => {
          const item = items.find((i) => i.id === photo.id);

          return {
            ...photo,
            order: item?.order || photo.order,
          };
        })
      );
    },
    onSettled: (data, error, variables) => {
      const vehicleId = variables.vehicleId;

      queryClient.invalidateQueries(['vehicle-photos', vehicleId.toString()]);
    },
    onError: (error, variables, restoreCache) => {
      restoreCache();
    },
  });
}
