import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ImportersApi } from 'api';

/**
 *  Custom React Query hook to update a user
 *
 * @param {number} id
 * @param {{ onSuccess?: () => void; onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export const useUpdateUser = (id, { onSuccess, onError } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => ImportersApi.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', id]);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
};
