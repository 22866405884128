import { DialogActions, DialogContent } from '@mui/material';
import React from 'react';

import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';
import { OTPInput } from 'components/OTPInput/OTPInput';

export function OtpSubmitModal({ open, onClose, signupForm }) {
  const { form, isRequestingOtp, isSubmitting, requestOtp } = signupForm;

  const otpInputRef = React.useRef();

  const handleResetOtp = () => {
    form.setFieldValue('otp', '');
    otpInputRef.current.focus();
  };

  const handleRequestNewOtp = async (event) => {
    event.preventDefault();

    try {
      await requestOtp(form.values.email);

      handleResetOtp();

      Notification(NOTIFICATION_TYPE.SUCCESS, `A new OTP has been sent to ${form.values.email}`);
    } catch (error) {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} title="OTP Verification">
      <DialogContent>
        <p className="text-center">{`An OTP has been sent to ${form.values.email}. Please enter the OTP to verify your registration.`}</p>
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
          <OTPInput
            ref={otpInputRef}
            value={form.values.otp}
            onChange={(v) => form.setFieldValue('otp', v)}
            autoFocus={true}
            className="p-4"
          />
          <div className="d-flex gap-2">
            <Button disabled={form.values.otp.length === 0} onClick={handleResetOtp}>
              Clear
            </Button>
            <Button loading={isRequestingOtp} onClick={handleRequestNewOtp}>
              Request new OTP
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="dark" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={form.values.otp.length !== 6}
          onClick={form.handleSubmit}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
