import { useQuery } from '@tanstack/react-query';
import { ModalApi } from 'api';

export function useGetModalData(apiFieldName, { onError } = {}) {
  return useQuery({
    queryKey: ['modal', apiFieldName],
    queryFn: () =>
      ModalApi.search([apiFieldName])
        .then((res) => res.data[apiFieldName])
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
    enabled: !!apiFieldName,
  });
}
