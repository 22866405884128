import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useContext } from 'react';

import { Input, Select } from '../../../../components';
import { AuthContext } from '../../../../providers/authProvider';
import HSTariffSelect from './HSTariffSelect';
import PortArrivalCodeSelect from './PortArrivalCodeSelect';

const BrokerInfo = ({ form }) => {
  const { user } = useContext(AuthContext);

  const lastUsedPort = form?.usPortOfArrival
    ? {
        label: `${form?.usPortOfArrival.name} (${form?.usPortOfArrival.value})`,
        value: form?.usPortOfArrival.id,
      }
    : user?.lastUsedPort
    ? {
        label: `${user.lastUsedPort.name} (${user.lastUsedPort.value})`,
        value: user.lastUsedPort.id,
      }
    : null;

  return (
    <div>
      <h3 className="mb-4">Submit Customs Broker Information</h3>

      <div className="row" id="bondProvider">
        <div className="col-md-6 mb-3">
          <Input
            disabled
            label="Bond Provider *"
            name="bondProvider"
            value={form.values.bondProvider}
            error={(form.errors.bondProvider && form.touched.bondProvider) || ''}
          />
        </div>

        <div className="col-md-6 mb-3" id="warrantyProvider">
          <Input
            disabled
            label="Warranty Provider *"
            name="warrantyProvider"
            value={form.values.warrantyProvider}
            error={(form.errors.warrantyProvider && form.touched.warrantyProvider) || ''}
          />
        </div>
      </div>

      <div className="row" id="country">
        <div className="col-md-6 mb-3">
          <Input disabled label="Exporting Country *" name="country" value={'Canada'} />
        </div>
      </div>

      <div className="row" id="tariff_code">
        <div className="col-md-6 mb-3">
          <HSTariffSelect
            defaultValue={form.values.tariff_code}
            onChange={(v) => form.setFieldValue('tariff_code', v.value)}
            error={
              form.errors.tariff_code && form.touched.tariff_code ? form.errors.tariff_code : ''
            }
          />
        </div>

        <div className="col-md-6 mb-3" id="portOfArrival">
          <PortArrivalCodeSelect
            name="portOfArrival"
            defaultValue={lastUsedPort}
            onChange={(v) => form.setFieldValue('portOfArrival', v.value)}
            error={
              form.errors.portOfArrival && form.touched.portOfArrival
                ? form.errors.portOfArrival
                : ''
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label id="theft-protection-label" className="mb-1">
            Theft protection standard applies to this vehicle
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="theft-protection-label"
                {...form?.getFieldProps('theftProtection')}
                onChange={(e) => {
                  form.setFieldValue('theftProtection', e.target.value);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="col">
          <label id="initial-cert-label" className="mb-1">
            Initial Certification
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="initial-cert-label"
                {...form?.getFieldProps('initialCertification')}
                onChange={(e) => {
                  form.setFieldValue('initialCertification', e.target.value);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerInfo;
