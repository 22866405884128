import { ObjectHelper } from 'helpers';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { Eye, Plus } from 'react-feather';
import { Link } from 'react-router-dom';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification, Table } from 'components';
import { CsvExportButton } from 'components/CsvExport/CsvExportButton';
import { useGetBuyers } from 'features/buyers/hooks/useGetBuyers';
import { SearchBar } from 'features/common/search/SearchBar';

import BuyerActionButton from './BuyerActionButton';
import { BuyersCsvImport } from './BuyersCsvImport';
import './styles.scss';

export const columns = [
  { title: 'Company Name', dataIndex: 'companyName', sortable: true },
  { title: 'First Name', dataIndex: 'firstName' },
  { title: 'Last Name', dataIndex: 'lastName' },
  { title: 'Email', dataIndex: 'email', hidden: true },
  { title: 'Street Address', dataIndex: 'street1' },
  { title: 'City', dataIndex: 'city1' },
  { title: 'State', dataIndex: 'state1' },
  { title: 'Zip', dataIndex: 'zip1' },
  { title: 'Country', dataIndex: 'country1' },
  { title: 'Phone Number', dataIndex: 'phoneNumber' },
  { title: 'Tax ID', dataIndex: 'taxID', sortable: true },
  { title: 'Organization Code', dataIndex: 'orgCode', hidden: true },
  {
    title: 'Actions',
    dataIndex: '',
    render: (buyer) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <Link to={`${buyer.id}`} className="text-white">
            <Eye size={20} />
          </Link>
          <BuyerActionButton buyer={buyer} />
        </div>
      );
    },
  },
];

export const defaultSort = {
  order: 'desc',
  orderBy: 'companyName',
};

export const defaultPagination = {
  page: 0,
  perPage: 10,
};

const BuyersTable = () => {
  const { user } = React.useContext(AuthContext);

  const [sortOption, setSortOption] = React.useState(defaultSort);
  const [pagination, setPagination] = React.useState(defaultPagination);
  const [searchTerm, setSearchTerm] = React.useState('');

  const queryObj = React.useMemo(() => {
    return ObjectHelper.trimQuery({
      ...pagination,
      ...sortOption,
      search: searchTerm,
    });
  }, [pagination, searchTerm, sortOption]);

  const { data, isLoading, isFetching, refetch } = useGetBuyers(queryObj, {
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.ERROR, error.message || 'System Error');
    },
  });

  const handleChangePagination = React.useCallback((field, value) => {
    if (field === 'page') {
      setPagination((prev) => ({ ...prev, page: value }));
    } else if (field === 'perPage') {
      setPagination({ page: 0, perPage: value });
    }
  }, []);

  return (
    <>
      <div className="d-flex gap-3 mb-4">
        <Link to="add">
          <Button
            className="d-flex gap-2"
            disabled={!hasPermission(user, { resource: 'buyer', action: 'create' })}
          >
            <Plus size={20} />
            Add
          </Button>
        </Link>
        <BuyersCsvImport
          columns={columns}
          onCsvImportSuccess={() => handleChangePagination('page', 0)}
        />
        <SearchBar fetch={refetch} onSearch={setSearchTerm} hasBorder={true} />
      </div>
      <Table
        data={(data?.data ?? []).map(({ buyer, ...userData }) => ({
          ...userData,
          ...buyer,
        }))}
        columns={columns}
        loading={isFetching || isLoading}
        checkable={true}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: data?.totalCount || 0,
          page: pagination.page,
          perPage: pagination.perPage,
          onPageChange: (page) => handleChangePagination('page', page),
          onPerPageChange: (perPage) => handleChangePagination('perPage', perPage),
        }}
        renderCsvExporter={(columns, data) => (
          <CsvExportButton data={data} columns={columns} filename="buyers.csv">
            Export CSV
          </CsvExportButton>
        )}
      />
    </>
  );
};

export default BuyersTable;
