import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React from 'react';

import { SignatureForm } from 'features/common/signature/SignatureForm';

export function SignatureSection({ form, pad, setPad, onUploadSignature }) {
  const { user } = React.useContext(AuthContext);

  const isSignatureExisted = !!user.registeredImporter?.signature;

  const existingSignature = React.useMemo(() => {
    return `${user.registeredImporter?.signature}`;
  }, [user]);

  return (
    <div className="row mb-3">
      <div className="col-md-6">
        <FormControl>
          <FormLabel id="signature" className="text-white">
            Signature
          </FormLabel>
          <RadioGroup
            aria-labelledby="signature"
            value={form.values.shouldUseExistingSignature ? 'yes' : 'no'}
            onChange={() =>
              form.setFieldValue(
                'shouldUseExistingSignature',
                !form.values.shouldUseExistingSignature
              )
            }
          >
            <FormControlLabel value="yes" control={<Radio />} label="Use existing signature" />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="Create new signature"
              id="create-new-signature"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {!form.values.shouldUseExistingSignature && (
        <SignatureForm
          form={form}
          formControl={
            <FormControl>
              {!form.values.shouldUseExistingSignature && (
                <RadioGroup
                  aria-labelledby="create-new-signature"
                  value={form.values.newSignatureMode}
                  onChange={(e) => form.setFieldValue('newSignatureMode', e.target.value)}
                  className="px-4 mb-3"
                >
                  <FormControlLabel value="draw" control={<Radio />} label="Draw" />
                  <FormControlLabel value="upload" control={<Radio />} label="Upload" />
                </RadioGroup>
              )}
            </FormControl>
          }
          pad={pad}
          setPad={setPad}
          onUploadSignature={onUploadSignature}
        />
      )}
      {form.values.shouldUseExistingSignature && (
        <div style={{ height: '200px' }}>
          {!isSignatureExisted ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                borderRadius: '10px',
              }}
            />
          ) : (
            <img
              src={existingSignature}
              width="100%"
              height="100%"
              alt="registered importer signature"
              style={{ borderRadius: '10px' }}
            />
          )}
        </div>
      )}
    </div>
  );
}
