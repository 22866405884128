import { createContext, useState } from 'react';

export const DEFAULT_SEARCH_VALUES = {
  searchTerm: '',
  filter: {
    page: 0,
    perPage: 10,
  },
  statusFilters: ['All'],
  sortOption: {
    order: 'desc',
    orderBy: 'vin',
  },
};

export const SearchContext = createContext({
  searchValues: {},
  setSearchValues: () => {},
});

export default function SearchContextProvider({ children }) {
  const [value, setValue] = useState(DEFAULT_SEARCH_VALUES);
  return (
    <SearchContext.Provider
      value={{ searchValues: value, setSearchValues: setValue }}
      children={children}
    />
  );
}
