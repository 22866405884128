import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { STATEMENT_COLUMNS } from './utils';
import './style.scss';

const StatementConformityTable = ({
  form,
  data,
  isDisabled = false,
  handleBlur,
}) => {
  return (
    <div className="table-responsive" data-testid="statement-conformity-table">
      <table className="table table-striped table-sm">
        <thead>
        <tr>
          <th scope="col">Standard</th>
          <th scope="col">Description</th>
          <th scope="col">O | M | N</th>
        </tr>
        </thead>
        <tbody>
        {
          STATEMENT_COLUMNS.map((column, i) =>
            <tr key={i}>
              <td>{column.standard}</td>
              <td>{column.name}</td>
              <td>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={data[column.column]}
                    name="radio-buttons-group"
                    className={`d-flex flex-row gap-4 ${isDisabled && 'radio-disable-click'}`}
                    {...form?.getFieldProps(column.column)}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel value="o" control={<Radio/>} label="O"/>
                    <FormControlLabel value="m" control={<Radio/>} label="M"/>
                    <FormControlLabel value="n" control={<Radio/>} label="N"/>
                  </RadioGroup>
                </FormControl>
              </td>
            </tr>)
        }
        </tbody>
      </table>
    </div>
  );
};

export default StatementConformityTable;
