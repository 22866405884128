/**
 * Convert full country name to ISO2
 *
 * @param {string} country
 * @returns {string} country ISO2 or full country name
 */
export function convertCountryToISO2(country) {
  if (country.match(/united states/i)) {
    return 'US';
  }

  return country;
}
