import { USA_ALPHA_2 } from 'constants/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  companyPhone: Yup.number().typeError('Should be number').required('Required Field'),
  contactFirstName: Yup.string().required('Required Field'),
  contactLastName: Yup.string().required('Required Field'),
  contactEmail: Yup.string().required('Required Field').email('Invalid Email'),
  contactPhone: Yup.number().typeError('Should be number').required('Required Field'),
  conformedAddress: Yup.object().shape({
    street: Yup.string().required('Required Field'),
    city: Yup.string().required('Required Field'),
    state: Yup.string().required('Required Field'),
    zip: Yup.string().required('Required Field'),
    country: Yup.string().required('Required Field'),
  }),
  inspectedAddress: Yup.object().shape({
    street: Yup.string().required('Required Field'),
    city: Yup.string().required('Required Field'),
    state: Yup.string().required('Required Field'),
    zip: Yup.string().required('Required Field'),
    country: Yup.string().required('Required Field'),
  }),
});

/**
 *
 * @param {import('@tanstack/react-query').UseMutationResult} mutation
 * @param {object} [initialValues]
 * @returns
 */
export function useConformityLocationForm(mutation, initialValues) {
  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  const form = useFormik({
    validationSchema,
    onSubmit,
    initialValues: {
      title: initialValues?.title || '',
      companyPhone: initialValues?.companyPhone || '',
      contactFirstName: initialValues?.contactFirstName || '',
      contactLastName: initialValues?.contactLastName || '',
      contactEmail: initialValues?.contactEmail || '',
      contactPhone: initialValues?.contactPhone || '',
      conformedAddress: {
        street: initialValues?.conformedStreet || '',
        city: initialValues?.conformedCity || '',
        state: initialValues?.conformedState || '',
        zip: initialValues?.conformedZip || '',
        country: initialValues?.conformedCountry || USA_ALPHA_2,
      },
      inspectedAddress: {
        street: initialValues?.inspectedStreet || '',
        city: initialValues?.inspectedCity || '',
        state: initialValues?.inspectedState || '',
        zip: initialValues?.inspectedZip || '',
        country: initialValues?.inspectedCountry || USA_ALPHA_2,
      },
    },
  });

  return [form, mutation.isLoading];
}
