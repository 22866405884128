import { DESIGNATIONS } from 'constants/userSetting';
import React from 'react';

import { CustomSelect, Input } from 'components';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';

import './styles.scss';

export function RegisteredImporterInfo({ form }) {
  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Company Name"
            className="mb-3"
            type="text"
            placeholder="Company Name"
            hasBorder
            {...form.getFieldProps('riCompanyName')}
            error={
              form.errors.riCompanyName && form.touched.riCompanyName
                ? form.errors.riCompanyName
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="RI Number"
            className="mb-3"
            type="text"
            placeholder="R12345"
            hasBorder
            {...form.getFieldProps('riNumber')}
            error={form.errors.riNumber && form.touched.riNumber ? form.errors.riNumber : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="First Name"
            className="mb-3"
            type="text"
            placeholder="First Name"
            hasBorder
            {...form.getFieldProps('firstName')}
            error={form.errors.firstName && form.touched.firstName ? form.errors.firstName : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Last Name"
            className="mb-3"
            type="text"
            placeholder="Last Name"
            hasBorder
            {...form.getFieldProps('lastName')}
            error={form.errors.lastName && form.touched.lastName ? form.errors.lastName : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Email"
            className="mb-3"
            type="email"
            placeholder="User Email"
            hasBorder
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Email Confirmation"
            className="mb-3"
            type="email"
            placeholder="User Email Confirmation"
            hasBorder
            {...form.getFieldProps('emailConfirm')}
            error={
              form.errors.emailConfirm && form.touched.emailConfirm ? form.errors.emailConfirm : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <PasswordInput
            label="Password"
            className="mb-3"
            placeholder="Password"
            hasBorder
            {...form.getFieldProps('password')}
            error={form.errors.password && form.touched.password ? form.errors.password : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <PasswordInput
            label="Password Confirmation"
            className="mb-3"
            placeholder="Password Confirmation"
            hasBorder
            {...form.getFieldProps('passwordConfirm')}
            error={
              form.errors.passwordConfirm && form.touched.passwordConfirm
                ? form.errors.passwordConfirm
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Mobile Number"
            className="mb-3"
            type="text"
            placeholder="Mobile Number"
            hasBorder
            {...form.getFieldProps('mobileNumber')}
            error={
              form.errors.mobileNumber && form.touched.mobileNumber ? form.errors.mobileNumber : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Tax ID"
            className="mb-3"
            type="text"
            placeholder="12-3456789 or 12-3456789SW or 123-45-6789 or 123456-78901"
            hasBorder
            {...form.getFieldProps('taxID')}
            error={form.errors.taxID && form.touched.taxID ? form.errors.taxID : ''}
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 mb-3">
          <CustomSelect
            name="designation"
            id="designation"
            label="Designation"
            placeholder="Select designation"
            options={DESIGNATIONS}
            hasBorder={true}
            onChange={(v) => form.setFieldValue('designation', v.value)}
            error={
              form.errors.designation && form.touched.designation ? form.errors.designation : ''
            }
            className="mb-3"
          />
        </div>
      </div>
    </>
  );
}
