export const getDefaultFromType = (vehicleType) => {
  let defaultData = {};
  STATEMENT_COLUMNS.forEach((element) => {
    defaultData = { ...defaultData, [element.column]: element.default[vehicleType] };
  });
  return defaultData;
};
export const STATEMENT_COLUMNS = [
  {
    name: 'Control And Displays',
    column: 'control_and_displays',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'm',
      Car: 'm',
      'Semi-Truck': 'm',
      'MPV under 10,000 LBS': 'm',
      Bus: 'm',
      'Truck under 10,000 LBS': 'm',
      'Truck over 10,000 LBS': 'm',
    },
    standard: 101,
  },
  {
    name: 'Transmission Shift Sequence',
    column: 'transmission_Shift_sequence',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'm',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 102,
  },
  {
    name: 'Windshield Defrosting, Defogging',
    column: 'windshield_defrosting_defogging',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 103,
  },
  {
    name: 'Windshield Wiping, Washing',
    column: 'windshield_wiping_washing',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 104,
  },
  {
    name: 'Hydraulic and Electric Brake System',
    column: 'hydraulic_and_electric_brake_system',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'n',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'n',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 105,
  },
  {
    name: 'Brake Hoses',
    column: 'brake_hoses',
    default: {
      Trailer: 'o',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 106,
  },
  {
    name: 'Lamps, Reflective Devices',
    column: 'lamps_reflective_devices',
    default: {
      Trailer: 'o',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 108,
  },
  {
    name: 'New Pneumatic Tires',
    column: 'new_pneumatic_tires',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 109,
  },
  {
    name: 'Tire Selection and Rims',
    column: 'tire_selection_and_rims',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 110,
  },
  {
    name: 'Rearview Mirrors',
    column: 'rearview_mirros',
    default: {
      Trailer: 'n',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 111,
  },
  {
    name: 'Hood Latch System',
    column: 'hoot_latch_system',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 113,
  },
  {
    name: 'Theft Protection',
    column: 'theft_protection',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 114,
  },
  {
    name: 'Motor Vehicle Brake Fluids',
    column: 'motor_vehicle_brake_fluids',
    default: {
      Trailer: 'o',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 116,
  },
  {
    name: 'Power Operated Window Systems',
    column: 'power_operated_window_systems',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 118,
  },
  {
    name: 'New Pneumatic Tires',
    column: 'new_pneumatic_tires2',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 119,
  },
  {
    name: 'Tire Selection and Rims',
    column: 'tire_selection_and_rims2',
    default: {
      Trailer: 'o',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'n',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'n',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 120,
  },
  {
    name: 'Air Brake Systems',
    column: 'air_brake_systems',
    default: {
      Trailer: 'o',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'n',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 121,
  },
  {
    name: 'Motorcycle Brake Systems',
    column: 'motorcycle_brake_systems',
    default: {
      Trailer: 'n',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 122,
  },
  {
    name: 'Motorcycle Controls and Displays',
    column: 'motorcycle_controls_and_displays',
    default: {
      Trailer: 'n',
      Motorcycle: 'm',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 123,
  },
  {
    name: 'Accelerator Control Systems',
    column: 'accelerator_control_systems',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 124,
  },
  {
    name: 'Electronic Stability Control',
    column: 'electronic_stability_control',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 126,
  },
  {
    name: 'Non Pneumatic Tires',
    column: 'non_pneumatic_tires',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 129,
  },
  {
    name: 'School Bus Pedestrian Safety',
    column: 'school_bus_pedestrian_safety',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 131,
  },
  {
    name: 'Light Vehicle Brake System',
    column: 'light_vehicle_brake_system',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 135,
  },
  {
    name: 'Tire Pressure Monitoring System',
    column: 'tire_pressure_monitoring_system',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 138,
  },
  {
    name: 'New Pneumatic Radial Tires',
    column: 'new_pneumatic_radial_tires',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 139,
  },
  {
    name: 'Occupant Protection Interior Impact',
    column: 'occupant_protection_interior_impact',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 201,
  },
  {
    name: 'Head Restraints',
    column: 'head_restraints',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 202,
  },
  {
    name: 'Impact Protection From Steering',
    column: 'impact_protection_from_steering',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 203,
  },
  {
    name: 'Steering Control Rearward',
    column: 'steering_control_rearward',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 204,
  },
  {
    name: 'Glazing Materials',
    column: 'glazing_materials',
    default: {
      Trailer: 'n',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 205,
  },
  {
    name: 'Door Locks And Retention Components',
    column: 'door_locks_and_retention_components',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 206,
  },
  {
    name: 'Seating Systems',
    column: 'seating_systems',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 207,
  },
  {
    name: 'Occupant Crash Protection',
    column: 'occupant_crash_protection',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 208,
  },
  {
    name: 'Seat Belt Assemblies',
    column: 'seat_belt_assemblies',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 209,
  },
  {
    name: 'Seat Belt Assemblies Anchorages',
    column: 'seat_belt_assemblies_anchorages',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 210,
  },
  {
    name: 'Windshield Mounting',
    column: 'windshield_mounting',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 212,
  },
  {
    name: 'Child Restraint Systems',
    column: 'child_restraint_systems',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 213,
  },
  {
    name: 'Side Impact Protection',
    column: 'side_impact_protection',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 214,
  },
  {
    name: 'Roof Crush Resistance',
    column: 'roof_crush_resistance',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 216,
  },
  {
    name: 'Bus Exits, Window Retention, Release',
    column: 'bus_exists_window_retention',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'o',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 217,
  },
  {
    name: 'Windshield Zone Intrusion',
    column: 'windshield_zone_intrusion',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'n',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 219,
  },
  {
    name: 'School Bus Rollover Protection',
    column: 'school_bus_rollover_protection',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 220,
  },
  {
    name: 'School Bus Joint Strength',
    column: 'school_bus_joint_strength',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 221,
  },
  {
    name: 'School Bus Seating/Crash Protection',
    column: 'school_bus_seating_crash_protection',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 222,
  },
  {
    name: 'Rear Impact Guards',
    column: 'rear_impact_guards',
    default: {
      Trailer: 'o',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 223,
  },
  {
    name: 'Rear Impact Protection',
    column: 'rear_impact_protection',
    default: {
      Trailer: 'o',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 224,
  },
  {
    name: 'Child Restraint Anchorage System',
    column: 'child_restraint_anchorage_system',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 225,
  },
  {
    name: 'Ejection Mitigation',
    column: 'ejection_mitigation',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 226,
  },
  {
    name: 'Fuel System Integrity',
    column: 'fuel_system_integrity',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 301,
  },
  {
    name: 'Flammability Of Interior Materials',
    column: 'flammability_of_interior_materials',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 302,
  },
  {
    name: 'Fuel System Integrity Of CNG Vehicle',
    column: 'fuel_system_integrity_of_cng_vehicle',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 303,
  },
  {
    name: 'CNG Fuel Container Integrity',
    column: 'cng_fuel_container_integrity',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 304,
  },
  {
    name: 'Electric-Powered Vehicles',
    column: 'electric_powered_vehicles',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 305,
  },
  {
    name: 'Interior Trunk Release',
    column: 'interior_trunk_release',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 401,
  },
  {
    name: 'Platform Lift Installations',
    column: 'platform_lift_installations',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 404,
  },
  {
    name: 'Low Speed Vehicles',
    column: 'low_speed_vehicles',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'n',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 500,
  },
  {
    name: 'VIN Requirements',
    column: 'vin_requirements',
    default: {
      Trailer: 'o',
      Motorcycle: 'o',
      'MPV over 10,000 LBS': 'o',
      Car: 'o',
      'Semi-Truck': 'o',
      'MPV under 10,000 LBS': 'o',
      Bus: 'o',
      'Truck under 10,000 LBS': 'o',
      'Truck over 10,000 LBS': 'o',
    },
    standard: 565,
  },
  {
    name: 'Certification',
    column: 'certification',
    default: {
      Trailer: 'o',
      Motorcycle: 'm',
      'MPV over 10,000 LBS': 'm',
      Car: 'm',
      'Semi-Truck': 'm',
      'MPV under 10,000 LBS': 'm',
      Bus: 'm',
      'Truck under 10,000 LBS': 'm',
      'Truck over 10,000 LBS': 'm',
    },
    standard: 567,
  },
  {
    name: 'Bumper Standard',
    column: 'bumper_standard',
    default: {
      Trailer: 'n',
      Motorcycle: 'n',
      'MPV over 10,000 LBS': 'n',
      Car: 'o',
      'Semi-Truck': 'n',
      'MPV under 10,000 LBS': 'n',
      Bus: 'n',
      'Truck under 10,000 LBS': 'n',
      'Truck over 10,000 LBS': 'n',
    },
    standard: 581,
  },
];
