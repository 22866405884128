import { USA_ALPHA_2 } from 'constants/common';
import React from 'react';
import { getAddressFields } from 'services/places';

import { Input } from 'components/Input';

import { PlaceAutocomplete } from './PlaceAutocomplete';
import { usePlaceDetails } from './usePlaceDetails';

export function AddressInfo({ form, fieldName, disabled = false, countries = [USA_ALPHA_2] }) {
  const streetFieldName = `${fieldName}.street`;
  const cityFieldName = `${fieldName}.city`;
  const stateFieldName = `${fieldName}.state`;
  const zipFieldName = `${fieldName}.zip`;
  const countryFieldName = `${fieldName}.country`;

  const [placeId, setPlaceId] = React.useState('');

  const { data: placeDetails } = usePlaceDetails(placeId);

  const handleSelectPlace = (placeId) => {
    setPlaceId(placeId);
  };

  React.useEffect(() => {
    if (placeDetails?.result) {
      const { street, city, state, zip, country } = getAddressFields(
        placeDetails.result.address_components
      );

      form.setFieldValue(streetFieldName, street);
      form.setFieldValue(cityFieldName, city);
      form.setFieldValue(stateFieldName, state);
      form.setFieldValue(zipFieldName, zip);
      form.setFieldValue(countryFieldName, country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityFieldName,
    countryFieldName,
    placeDetails,
    stateFieldName,
    streetFieldName,
    zipFieldName,
  ]);

  return (
    <>
      <div className="col-md-6 mb-3">
        <PlaceAutocomplete countries={countries} onSelectPlace={handleSelectPlace} />
      </div>
      <div className="col-md-6 mb-3">
        <Input
          label="Street"
          className="mb-3"
          type="text"
          placeholder="Street"
          hasBorder
          disabled={disabled}
          {...form.getFieldProps(streetFieldName)}
          error={
            form.errors[fieldName]?.street && form.touched[fieldName]?.street
              ? form.errors[fieldName]?.street
              : ''
          }
        />
      </div>
      <div className="col-md-6 mb-3">
        <Input
          label="City"
          className="mb-3"
          type="text"
          placeholder="City"
          hasBorder
          disabled={disabled}
          {...form.getFieldProps(cityFieldName)}
          error={
            form.errors[fieldName]?.city && form.touched[fieldName]?.city
              ? form.errors[fieldName]?.city
              : ''
          }
        />
      </div>
      <div className="col-md-6 mb-3">
        <Input
          label={countries.includes(USA_ALPHA_2) ? 'State' : 'Province'}
          className="mb-3"
          type="text"
          placeholder={countries.includes(USA_ALPHA_2) ? 'State' : 'Province'}
          hasBorder
          disabled={disabled}
          {...form.getFieldProps(stateFieldName)}
          error={
            form.errors[fieldName]?.state && form.touched[fieldName]?.state
              ? form.errors[fieldName]?.state
              : ''
          }
        />
      </div>
      <div className="col-md-6 mb-3">
        <Input
          label={countries.includes(USA_ALPHA_2) ? 'Zip' : 'Postal Code'}
          className="mb-3"
          type="text"
          placeholder={countries.includes(USA_ALPHA_2) ? 'Zip' : 'Postal Code'}
          hasBorder
          disabled={disabled}
          {...form.getFieldProps(zipFieldName)}
          error={
            form.errors[fieldName]?.zip && form.touched[fieldName]?.zip
              ? form.errors[fieldName]?.zip
              : ''
          }
        />
      </div>
      <div className="col-md-6 mb-3">
        <Input
          label="Country"
          className="mb-3"
          type="text"
          placeholder="Country"
          disabled
          hasBorder
          {...form.getFieldProps(countryFieldName)}
          error={
            form.errors[fieldName]?.country && form.touched[fieldName]?.country
              ? form.errors[fieldName]?.country
              : ''
          }
        />
      </div>
    </>
  );
}
