import React, { useState } from 'react';
import BaseCollapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import './style.scss';

export const CollapseMenu = ({title, items}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(!open);
        }}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        data-testid="collapse-title"
        className={`dropdown-item dropdown-toggle cursor-pointer ${open ? 'dropdown-item--open' : ''}`}
      >
        {title}
      </span>
      <BaseCollapse in={open} id="example-collapse-text" className="mt-2">
        <div className="mx-4">
          {
            items.map((item, index) => (
              <Link className="dropdown-item" to={item.link} key={index}>{item.title}</Link>
            ))
          }
        </div>
      </BaseCollapse>
    </>
  );
}
