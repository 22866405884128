import { ROUTES } from 'constants/routes';
import MainLayout from 'layout/MainLayout';
import AddUser from 'pages/main/Accounts/AddUser/AddUser';
import { EditUser } from 'pages/main/Accounts/EditUser/EditUser';
import { UsersTable } from 'pages/main/Accounts/ListUsers/UsersTable';
import { ViewUser } from 'pages/main/Accounts/ViewUser/ViewUser';
import AddVehicleInfo from 'pages/main/AddVehicleInfo';
import AddBuyer from 'pages/main/Buyers/AddBuyer';
import { EditBuyer } from 'pages/main/Buyers/EditBuyer/EditBuyer';
import ListBuyers from 'pages/main/Buyers/ListBuyers/ListBuyers';
import { ViewBuyer } from 'pages/main/Buyers/ViewBuyer/ViewBuyer';
import AddCarrier from 'pages/main/Carriers/AddCarrier';
import EditCarrier from 'pages/main/Carriers/EditCarrier/EditCarrier';
import ListCarriers from 'pages/main/Carriers/ListCarriers/ListCarriers';
import { ViewCarrier } from 'pages/main/Carriers/ViewCarrier/ViewCarrier';
import { AddConformityLocation } from 'pages/main/ConformityLocation/AddConformityLocation/AddConformityLocation';
import { EditConformityLocation } from 'pages/main/ConformityLocation/EditConformityLocation/EditConformityLocation';
import { ListConformityLocations } from 'pages/main/ConformityLocation/ListConformityLocations/ListConformityLocations';
import { ViewConformityLocation } from 'pages/main/ConformityLocation/ViewConformityLocation/ViewConformityLocation';
import AddConsignee from 'pages/main/Consignees/AddConsignee/AddConsignee';
import { EditConsignee } from 'pages/main/Consignees/EditConsignee/EditConsignee';
import ListConsignees from 'pages/main/Consignees/ListConsignees/ListConsignees';
import { ViewConsignee } from 'pages/main/Consignees/ViewConsignee/ViewConsignee';
import EditVehicleInfo from 'pages/main/EditVehicleInfo';
import AddExporter from 'pages/main/Exporters/AddExporter';
import { EditExporter } from 'pages/main/Exporters/EditExporter/EditExporter';
import ListExporters from 'pages/main/Exporters/ListExporters/ListExporters';
import { ViewExporter } from 'pages/main/Exporters/ViewExporter/ViewExporter';
import Payment from 'pages/main/Payment';
import PaymentSuccess from 'pages/main/Payment/PaymentSuccess';
import { ProfileSettings } from 'pages/main/ProfileSettings/ProfileSettings';
import UserSettings from 'pages/main/UserSettings';
import UserSettingsLayout from 'pages/main/UserSettings/UserSettingsLayout';
import VehicleInfo from 'pages/main/VehicleInfo';
import VehicleProfile from 'pages/main/VehicleProfile';
import VehicleRILabel from 'pages/main/VehicleRILabel';
import { AuthContext } from 'providers/authProvider';
import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { hasPermission, isRegisteredImporter } from 'services/auth';

import { NHTSARegenrationModal } from 'features/nhtsa-packages/NHTSARegenerationModal';

import NHTSARegenerationPage from './NHTSARegeneration';

const RequireRegisteredImporterRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (!isRegisteredImporter(user)) {
    return <Navigate to={ROUTES.MAIN.VEHICLES.INDEX} replace />;
  }

  return children ? children : <Outlet />;
};

const RequirePermissionRoute = ({ resource, action, children }) => {
  const { user } = useContext(AuthContext);

  if (!hasPermission(user, { resource, action })) {
    return <Navigate to={ROUTES.MAIN.VEHICLES.INDEX} replace />;
  }

  return children ? children : <Outlet />;
};

const Main = () => {
  const { user } = useContext(AuthContext);

  return (
    <MainLayout>
      <Routes>
        <Route exact path={ROUTES.MAIN.PAYMENT.SUCCESS} element={<PaymentSuccess />} />
        <Route exact path={ROUTES.MAIN.VEHICLES.INDEX} element={<VehicleInfo />} />
        <Route exact path={ROUTES.MAIN.VEHICLES.CREATE} element={<AddVehicleInfo />} />
        <Route exact path={ROUTES.MAIN.VEHICLES.EDIT} element={<EditVehicleInfo />} />
        <Route exact path={ROUTES.MAIN.VEHICLES.PROFILE} element={<VehicleProfile />} />
        <Route exact path={ROUTES.MAIN.VEHICLES.RI_LABEL} element={<VehicleRILabel />} />
        <Route exact path={ROUTES.MAIN.NHTSA.INDEX} element={<NHTSARegenerationPage />}>
          <Route path=":id" element={<NHTSARegenrationModal />} />
        </Route>
        <Route
          path={ROUTES.MAIN.USER_SETTINGS.INDEX}
          element={
            <Navigate
              to={
                isRegisteredImporter(user)
                  ? ROUTES.MAIN.USER_SETTINGS.CONFORMITY_LOCATIONS
                  : ROUTES.MAIN.USER_SETTINGS.PROFILE
              }
              replace
            />
          }
        />
        <Route path={ROUTES.MAIN.USER_SETTINGS.INDEX} element={<UserSettings />}>
          <Route path="*" element={<RequireRegisteredImporterRoute />}>
            <Route path="conformity-locations" element={<UserSettingsLayout />}>
              <Route path="" element={<ListConformityLocations />} />
              <Route path=":id" element={<ViewConformityLocation />} />
              <Route path="add" element={<AddConformityLocation />} />
              <Route path="edit/:id" element={<EditConformityLocation />} />
            </Route>
          </Route>
          <Route path="*" element={<RequirePermissionRoute resource="buyer" action="read" />}>
            <Route path="buyers" element={<UserSettingsLayout />}>
              <Route path="" element={<ListBuyers />} />
              <Route path=":id" element={<ViewBuyer />} />
              <Route path="add" element={<AddBuyer />} />
              <Route path="edit/:id" element={<EditBuyer />} />
            </Route>
          </Route>
          <Route path="*" element={<RequirePermissionRoute resource="seller" action="read" />}>
            <Route path="sellers" element={<UserSettingsLayout />}>
              <Route path="" element={<ListExporters />} />
              <Route path=":id" element={<ViewExporter />} />
              <Route path="add" element={<AddExporter />} />
              <Route path="edit/:id" element={<EditExporter />} />
            </Route>
          </Route>
          <Route path="*" element={<RequirePermissionRoute resource="carrier" action="read" />}>
            <Route path="carriers" element={<UserSettingsLayout />}>
              <Route path="" element={<ListCarriers />} />
              <Route path=":id" element={<ViewCarrier />} />
              <Route path="add" element={<AddCarrier />} />
              <Route path="edit/:id" element={<EditCarrier />} />
            </Route>
          </Route>
          <Route path="*" element={<RequirePermissionRoute resource="consignee" action="read" />}>
            <Route path="consignees" element={<UserSettingsLayout />}>
              <Route path="" element={<ListConsignees />} />
              <Route path=":id" element={<ViewConsignee />} />
              <Route path="add" element={<AddConsignee />} />
              <Route path="edit/:id" element={<EditConsignee />} />
            </Route>
          </Route>
          <Route path="*" element={<RequireRegisteredImporterRoute />}>
            <Route path="accounts" element={<UserSettingsLayout />}>
              <Route path="" element={<UsersTable />} />
              <Route path=":id" element={<ViewUser />} />
              <Route path="add" element={<AddUser />} />
              <Route path="edit/:id" element={<EditUser />} />
            </Route>
          </Route>
          <Route path="profile" element={<ProfileSettings />} />
        </Route>
        <Route exact path={ROUTES.MAIN.PAYMENT.INDEX} element={<Payment />} />
        <Route path="*" element={<Navigate to={ROUTES.MAIN.VEHICLES.INDEX} />} />
      </Routes>
    </MainLayout>
  );
};

export default Main;
