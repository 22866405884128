import React from 'react';
import { Edit, Move, Trash, ZoomIn } from 'react-feather';

import { ConfirmModal, NOTIFICATION_TYPE, Notification } from 'components';
import ImagePreviewModal from 'components/ImagePreviewModal';
import UnstyledButton from 'components/UnstyledButton';
import { useDeleteVehiclePhoto } from 'features/photos/useDeleteVehiclePhoto';

import EditPhotoModal from './EditPhotoModal';

export function PhotoCard({ item, dragHandleProps }) {
  const { id, category, vin_code: vehicleId, url } = item;

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const { mutateAsync: deletePhoto, isLoading } = useDeleteVehiclePhoto();

  const handleDelete = () => {
    deletePhoto(id)
      .then(() => {
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Deleted photo');
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      });
  };

  const handleOpenPreview = () => {
    setShowPreviewModal(true);
  };

  const handleShowConfirm = () => {
    setShowConfirm(true);
  };

  return (
    <div className="photo-card" data-testid="photo-card">
      <h5 className="mb-4">{category.name}</h5>

      <div className="photo-wrapper">
        <div {...dragHandleProps} className="handle">
          <Move className="btn-icon" />
        </div>
        <img className="photo-img" src={url} alt="" />

        {isLoading ? (
          <div className="loading">
            <span className="text-grey">Loading ...</span>
          </div>
        ) : (
          <div className="photo-actions">
            <UnstyledButton
              className="photo-actions__btn me-3"
              aria-label="edit"
              onClick={() => setShowEditModal(true)}
            >
              <Edit className="btn-icon" />
            </UnstyledButton>

            <UnstyledButton
              className="photo-actions__btn me-3"
              aria-label="open preview"
              onClick={handleOpenPreview}
            >
              <ZoomIn className="btn-icon" />
            </UnstyledButton>

            <UnstyledButton
              className="photo-actions__btn"
              aria-label="delete"
              onClick={handleShowConfirm}
            >
              <Trash className="btn-icon" />
            </UnstyledButton>
          </div>
        )}
      </div>

      <EditPhotoModal
        category={category}
        photoId={id}
        vin={vehicleId}
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
      />

      <ImagePreviewModal
        src={url}
        alt={category.name}
        open={showPreviewModal}
        setOpen={setShowPreviewModal}
      />

      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        title="Delete photo"
        content="Are you sure you want to delete this photo?"
        onConfirm={handleDelete}
      />
    </div>
  );
}
