import React from 'react';

export const TermsOfUse = () => {
    return (
        <div>
            <div className="container">
                <div className="rounded-3 p-4 bg-dark-light">
                    <h1 className="mb-5">Terms and Conditions of Use</h1>

                    <p>
                        <b>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY</b>. These Terms and Conditions (the
                        “Terms
                        and Conditions”) constitute a legally binding agreement made between you, whether personally or
                        on behalf of an entity (“you”, “You”, “Your”, “your”, “user” or “User”) and Next Level
                        Technologies Corp. doing business as VIN2, and its licensors and affiliates (collectively, “We”,
                        “we”, “Us”, “us”, “Our”, “our” or " VIN2"). VIN2 makes available a web-based software and
                        platform that provides a conduit and portal for connecting importers, exporters, carriers, and
                        others involved in the import and export of vehicles (collectively, the “Platform”), which is
                        made available at least though our website located at the domain <a
                        href="https://vin2.com/"> https://vin2.com/ </a> (the “Website”). These Terms and Conditions
                        also apply to any and all services made available through
                        the Platform including scanning and processing VIN numbers, cataloguing and reporting damage to
                        vehicles, and preparing import-export documentation (the “Services”). Your access to and use of
                        the Services offered by VIN2 is expressly conditioned on your acceptance without modification of
                        the following Terms and Conditions.
                    </p>

                    <p>
                        <b>
                            If you accept these Terms and Conditions, click on the “I Accept” button below, click on the
                            “I
                            Accept” box, or take other positive step to indicate your agreement to these Terms and
                            Conditions. That action is the equivalent of your signature and indicates your acceptance of
                            these Terms and Conditions and that you intend to be legally bound by them. Your use of the
                            Platform and/or the VIN2 Website constitutes acceptance of these Terms and Conditions.
                        </b>
                    </p>

                    <p>
                        VIN2 may modify, supplement or amend these Terms and Conditions at its sole discretion. If so,
                        you will be notified the next time you use the Platform, and given an opportunity to accept the
                        modifications. Your continued use of the Platform will be evidence that you acknowledge and
                        agree to be bound by the modified Terms and Conditions. If you do not accept the modifications,
                        you are no longer entitled to use the Platform.
                    </p>

                    <p>
                        The Services (as defined herein) and Platform are available only to, and may only be used by
                        persons who can enter into legally binding contracts with VIN2, under applicable law. Without
                        limiting the foregoing, the Services, Platform, and our Website are not available to, and should
                        not be used by, minors.
                    </p>

                    <p>
                        1. <b>Use of the Platform</b> – Subject to all of the terms and conditions of these Terms and
                        Conditions, VIN2 grants you a limited, non-exclusive, non-transferable and personal license to
                        access and use the Platform and have an object code version of the software Platform reproduced
                        on your computer or other applicable device (i.e., your mobile device, if applicable), solely to
                        support User’s use of the Services and Platform and otherwise in accordance with these Terms and
                        Conditions, provided you have created an account through the Platform by registering to use the
                        Platform (an “Account”) or have signed in as a guest and are in compliance with these Terms and
                        Conditions, if applicable. In these Terms and Conditions, reference to “Users” herein shall
                        include both Users who subscribe to and register an Account through the Platform and Users who
                        do not register through the Platform.
                    </p>

                    <p>
                        You agree to provide accurate, current, and complete information required to register or create
                        an Account, and whatever information relating to You is required in connection with the Website,
                        the Platform, and/or the Services (the “Registration Data”). You further agree to maintain and
                        update your Registration Data as required to keep it accurate, current, and
                    </p>

                    <p>
                        complete. VIN2 may terminate your right to access and/or use any or all of the Site, the
                        Platform, and/or the Services, or your Account, if any information you provide is false,
                        inaccurate or incomplete. You agree that VIN2 may store and use the Registration Data you
                        provide for the purposes of maintaining and managing your Account.
                    </p>

                    <p>
                        2. <b>VIN2 Software-as-a-Service (SaaS)</b> – The Platform facilitates Services including the
                        electronic exchange of information between Users, including registered importers and exporters
                        of vehicles, carriers, and others involved in the import and export of vehicles. Users
                        acknowledge that this information exchange is between Users, and that the Platform provides
                        efficient exchange of information. The Services and these Terms and Conditions shall in no way
                        alter the obligations and liability that each of the Users have to one another.
                    </p>

                    <p>
                        3. <b>Security Measures</b> – You will implement and maintain appropriate protection in relation to the
                        security and control of access to your computer through which you access the Platform, and that
                        you will prevent the introduction to the Platform of computer viruses or other similar harmful
                        or inappropriate materials, devices, information or data. You assume all risks associated with
                        the use and storage of information on your personal computer or device, or any other computer or
                        device through which you access the Platform.
                    </p>

                    <p>
                        4. <b>Access at VIN2’s Discretion</b> – In its sole discretion and for any reason, in addition to any
                        other rights or remedies available to VIN2 and without any liability whatsoever, VIN2 at any
                        time and without notice may terminate or restrict your access to the Platform and any component
                        of the Platform.
                    </p>

                    <p>
                        5. <b>Compensation and Payment Terms</b> – The Services and the provision thereof by VIN2 are provided
                        pursuant to the following Terms and Conditions:
                    </p>

                    <p className={"mx-4"}>
                        a. &emsp; All fees, compensation, and other charges payable are exclusive of all taxes, information
                        charges, governmental fees, and similar levies payable in or to any jurisdiction or authority.
                    </p>

                    <p className={"mx-4"}>
                        b. &emsp; VIN2 may suspend the Services if the User’s account is not kept current by the User, with
                        up-to-date address, payment, and contact information. Any such suspension of Services will not
                        give rise to any right of termination by the User nor any claim by the User for breach of VIN2’s
                        obligations under these Terms and Conditions or any other agreement between User and VIN2.
                    </p>

                    <p className={"mx-4"}>
                        c. &emsp; Users may provide their credit card information, and the User hereby authorizes VIN2 (or its
                        designee) to charge the User credit card for all fees incurred in accordance with the applicable
                        Services, where fees are charged and incurred on a per-vehicle basis.
                    </p>

                    <p className={"mx-4"}>
                        d. &emsp; Payments for the purchase of VIN2’s Services may be processed through Stripe, at VIN2’s
                        discretion. For more information on Stripe’s security practices, please see
                        <a href="http://www.stripe.com/us/checkout/legal">https://www.stripe.com/us/checkout/legal</a>.
                        User acknowledges that for certain credit cards, the
                        issuer of User’s credit card may charge a foreign transaction fee or other charges, and User
                        shall be responsible for any such foreign transaction fees.
                    </p>

                    <p className={"mx-4"}>
                        e. &emsp; Payments for the purchase of VIN2’s Services may be processed through Wise, at VIN2’s
                        discretion. For more information on Stripe’s security practices, please see
                        <a href="https://wise.com/terms-and-conditions">https://wise.com/terms-and-conditions</a>.
                    </p>

                    <p className={"mx-4"}>
                        f. &emsp; Changing Credit Card Information - At any time, the User may change their credit card
                        information by entering updated credit card information via the User’s Account page made
                        available through the Platform.
                    </p>

                    <p>
                        6. <b>Nature of Engagement</b> – VIN2 makes available the Platform and Services as an independent
                        contractor. Nothing contained in these Terms and Conditions shall be construed to create or
                        imply a joint venture, partnership, principal-agent, or employment relationship between the User
                        and VIN2. VIN2 shall neither act or purport to be acting as the legal agent of the User, nor
                        enter into any agreement on behalf of the User.
                    </p>

                    <p>
                        7. <b>Access to Services</b> – Each User may access and use the Platform and Services solely for its
                        own benefit or the benefit of its organization, and in accordance with these Terms and
                        Conditions. Use of and access to the Platform and Services is permitted only by the User who
                        registers the Account (or signs in as a guest to use the Platform, if applicable), and such
                        registration is not transferable. User IDs and passwords are granted to individual, named
                        persons and may not be shared. Each User shall keep its user ID and password information
                        strictly confidential and not share such information with any other person, including any
                        unauthorized person not authorized to access and use the Platform and Services. Where multiple
                        persons in an organization use the Platform, each such person shall register separately as a
                        User. Notwithstanding the foregoing, each User (and the User’s organization with which the
                        User’s account is associated or registered, if applicable) will be responsible for any and all
                        actions taken using the User’s Account through the Platform and the User’s user ID and password.
                    </p>

                    <p>
                        8. <b>Confidentiality and Privacy Policy</b> – Each User agrees that VIN2 is not responsible for any
                        information provided by any User. It is each User’s responsibility to safeguard their personal
                        and confidential information. All use, collection, and disclosure of your personal information
                        will be governed by VIN2's Privacy Policy, which is available at <a
                        href="https://dev.vin2.com/ri-user-agreement/"> Privacy Policy </a>. By agreeing to these Terms
                        and Conditions and making use of the Services and Platform,
                        you acknowledge and agree that you have read VIN2's Privacy Policy and that you consent to the
                        collection, use and disclosure of your personal information by VIN2 in accordance with the terms
                        of and for the purposes set out in VIN2's Privacy Policy.
                    </p>

                    <p>
                        9. <b>Disclaimer</b> – Except as expressly set out in these Terms and Conditions, the Platform and
                        Services are provided “as is” and “as available”, without any other representations, warranties
                        or conditions or guarantees, and VIN2 disclaims and will not be bound by any other
                        representations, warranties, conditions, or guarantees whatsoever in connection with the
                        Services and your use and enjoyment thereof, whether express, implied or statutory, including,
                        without limitation, any warranties of accuracy, timeliness, performance, completeness,
                        merchantability, workmanlike quality, title, fitness for a particular purpose or any purpose,
                        and non-infringement of any rights including intellectual property rights of any other party.
                        VIN2 offers no representation, warranty, or guarantee that compliance packages or applications
                        including to the NHTSA will be accepted or approved. Without limiting the generality of the
                        foregoing, VIN2 makes no warranty that: (i) the Website, the Services, the Platform, or any
                        content posted to the Platform will meet the User’s requirements; (ii) the Website, the
                        Services, the Platform, or the content will be uninterrupted, timely, secure or error-free;
                        (iii) the results that may be obtained from your use of the Website, the Services, the Platform,
                        or the content will be accurate or reliable; (iv) the quality of any products, Services,
                        information obtained by the User through the Website, the Services, the Platform, or the content
                        will meet any particular User’s expectations; and (v) any errors in the Services and/or any
                        software operating on the Website or the Platform will be corrected. Any material downloaded or
                        otherwise obtained through the use of the Website, the Platform, or the Services is done at the
                        User’s own discretion and risk. No advice or information, whether oral or written, obtained by
                        the User from the Website or through or from the Platform and Services or the content shall
                        create any warranty or condition not expressly stated in the terms.
                    </p>

                    <p>
                        10. <b>Updates and Maintenance</b> – VIN2 may, at its sole discretion and on a scheduled basis or an
                        emergency basis, provide updates and maintenance on the software, Platform, and/or the Services,
                        without notice. The software, Platform, and/or the Services may be unavailable while VIN2
                        provides updates and maintenance on the software, Platform, and/or the Services.
                    </p>

                    <p>
                        11. <b>Inability to Perform</b> – Certain events beyond our control may make it impossible or not
                        commercially reasonable for VIN2 to fulfill some or all of our obligations and Services under
                        these Terms and Conditions. VIN2 is not legally responsible to the User if any event beyond our
                        control makes it impossible or not commercially reasonable for VIN2 to fulfill some or all of
                        our obligations and Services, and we will endeavour to resume our obligations and Services as
                        soon as we reasonably can. These Terms and Conditions will otherwise remain in full effect.
                    </p>

                    <p>
                        12. <b>Service Availability</b> – The User acknowledges that temporary interruptions, downtime, or lack
                        of availability of the Platform and Services may occur from time to time as normal events.
                        Further, the User acknowledges that VIN2 may cease making any portion of the software, Services,
                        or Platform available at any time and for any reason. Under no circumstances will VIN2 or its
                        suppliers be held liable for any damages or loss suffered or allegedly suffered by you due to
                        such interruptions or lack of availability of the VIN2 software, Services, or Platform.
                    </p>

                    <p>
                        13. <b>Security</b> – VIN2 uses commercially reasonable technical and organizational measures designed
                        to prevent unauthorized access, use, alteration or disclosure of any Service or any data or
                        personal information of Users. However, without limiting the generality of the disclaimers of
                        warranties, representations, and liability set out in these Terms and Conditions, VIN2 will have
                        no responsibility for errors in transmission, unauthorized third-party access or other causes
                        beyond VIN2’s control.
                    </p>

                    <p>14. <b>Exclusions and Limitations of Liability</b> </p>

                    <p className={"mx-4"}>
                        a. &emsp; YOU AGREE THAT WE SHALL NOT, IN ANY EVENT, BE LIABLE TO YOU FOR ANY DAMAGES, INCLUDING ANY
                        LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS DATA AND/OR INFORMATION, LOST SAVINGS, OR
                        OTHER EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR OTHER SIMILAR DAMAGES ARISING
                        OUT OF OR RELATED TO THESE TERMS AND CONDITIONS OR: (1) YOUR USE OR INABILITY TO USE THE
                        PLATFORM, THE SERVICES, THE WEBSITE, OR ANY PART THEREOF; (2) SUSPENSION, LOSS OR INTERRUPTION
                        OF THE PLATFORM AND/OR SERVICES; (3) LOSS OR LIABILITIES RESULTING FROM ACTS OR EVENTS BEYOND
                        OUR CONTROL; (4) DATA NON-DELIVERY, MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODICATIONS
                        TO DATA; OR (5) DAMAGES OR CONSEQUENCES ARISING FROM OR RELATED TO THE INAPPROPRIATE OR
                        UNAUTHORIZED USE OF THE PLATFORM AND/OR THE SERVICES BY YOU OR ANY OTHER USER OF THE PLATFORM
                        AND/OR THE SERVICES; WHETHER BASED ON CONTRACT, TORT, THE BREACH OF ANY EXPRESS OR IMPLIED
                        WARRANTY OR OTHER LEGAL OR EQUITABLE GROUNDS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OR
                        LIKELIHOOD OF THOSE DAMAGES, AND REGARDLESS OF ANY FUNDAMENTAL BREACH HEREOF OR OF ANY OTHER
                        AGREEMENT.
                    </p>

                    <p className={"mx-4"}>
                        b. &emsp; Without limiting the foregoing, you acknowledge that in no event shall VIN2 or any of its
                        suppliers, affiliates, directors, officers, employees, agents or representatives be liable for
                        any loss, damage, cost or injury, arising out of or relating to your use of or reliance upon
                        (for any purpose) any advice or information posted or made available through the Platform, the
                        Site, or the Services, whether by VIN2 or any other users.
                    </p>

                    <p className={"mx-4"}>
                        c. &emsp; OUR TOTAL, MAXIMUM AGGREGATE LIABILITY AND THE TOTAL, MAXIMUM AGGREGATE LIABILITY OF VIN2’S
                        REPRESENTATIVES, ARISING FROM OR RELATING TO THESE TERMS AND CONDITIONS (REGARDLESS OF THE FORM
                        OF ACTION OR CLAIM, E.G., CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER
                        LEGAL THEORY) IS LIMITED TO <b>TEN CANADIAN DOLLARS ($10 CAD)</b>.
                    </p>

                    <p className={"mx-4"}>
                        d. &emsp; THE FOREGOING EXCLUSIONS AND LIMITATIONS OF LIABILITY WILL APPLY TO THE FULLEST EXTENT
                        PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR TERMINATION OF THESE TERMS AND CONDITIONS, ANY
                        ACCOUNT, OR YOUR ABILITY OR RIGHT TO USE THE PLATFORM, THE WEBSITE, AND/OR SERVICES. IF
                        APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE DISCLAIMERS AND EXCLUSIONS AND
                        LIMITATIONS OF LIABILITY TO APPLY TO YOU, THE FOREGOING DISCLAIMERS AND EXCLUSIONS AND
                        LIMITATIONS OF LIABILITY WILL APPLY TO YOU TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                    </p>

                    <p>
                        15. <b>Indemnification</b> – The User shall indemnify VIN2 from and against all losses, damages, costs
                        and expenses, and save VIN2 harmless from and against any and all claims, liabilities, demands,
                        actions, causes of action, lawsuits and proceedings which may be made or brought against or
                        suffered by VIN2, or which VIN2 may suffer or incur as a result of, in respect of or arising out
                        of, the User’s use of the Platform or Services, save and except for any claims, demands,
                        actions, lawsuits or proceedings that arise specifically out of any fraudulent activities or
                        wilful misconduct of VIN2.
                    </p>

                    <p>
                        16. <b>Termination</b> – VIN2 or the User may terminate these Terms and Conditions with thirty (30)
                        days’ written notice to the other Party. Upon termination of these Terms and Conditions for any
                        reason, VIN2 will immediately stop providing Services and discontinue User’s access to the
                        Platform, and User will immediately cease any and all use of the Services and Platform.
                    </p>

                    <p>
                        17. <b>User Cooperation</b> – Each User shall be responsible for its own use of or failure to use the
                        Services, and for the accuracy and completeness of all data and information provided to other
                        Users of VIN2 in connection with the use of the Platform and Services. VIN2 will not be
                        responsible or liable for, nor be considered to be in default under these Terms and Conditions
                        for, any User’s delay or failure to perform any obligation under these Terms and Conditions.
                        VIN2 will also not be responsible or liable for any losses or damage arising from the User’s
                        failure to provide timely cooperation and instructions to other Users or VIN2 as reasonably
                        required to enable other Users or VIN2 to perform their respective obligations, including the
                        User’s failure to: (i) provide accurate and complete information that is relevant to the
                        Services in a reasonably timely manner; (ii) instruct other Users or VIN2 in a reasonably timely
                        manner; and (iii) cooperate with other Users or VIN2 in any other manner as reasonably required
                        to facilitate the exchange of information.
                    </p>

                    <p>
                        18. <b>Unacceptable Use </b>– Each User hereby agrees that they and their employees, agents, and
                        representatives who use and access the Platform and Services will not:
                    </p>

                    <p className={"mx-4"}>
                        a. &emsp; probe, scan, or test the vulnerability of any system or network used with the Platform or
                        Services;
                    </p>

                    <p className={"mx-4"}>
                        b. &emsp; decompile, reverse engineer, disassemble or hack or tamper with the Platform or Services,
                        disable or circumvent any security or authentication measures of the Platform or Services, or
                        attempt to gain or to grant any other person unauthorized access to the Platform or Services (or
                        any portion thereof) or related systems, networks or data;
                    </p>

                    <p className={"mx-4"}>
                        c. &emsp; modify or disable the Platform or Services or use the Platform or Services in any manner that
                        interferes with or disrupts the integrity or performance of the Platform or Services or related
                        systems, network or data;
                    </p>

                    <p className={"mx-4"}>
                        d. &emsp; access or search the Platform or Services by any means other than our publicly supported
                        interfaces, or copy, distribute, or disclose any part of the Platform or Services in any medium,
                        including without limitation by any automated or non-automated “scraping”;
                    </p>

                    <p className={"mx-4"}>
                        e. &emsp; copy, frameset, enclose or otherwise sell, distribute, transmit or broadcast any part of the
                        Service, the Platform, the software, and the Website;
                    </p>

                    <p className={"mx-4"}>
                        f. &emsp; modify or create any derivative work of any part of the Service, the Platform, the software,
                        and the Website;
                    </p>

                    <p className={"mx-4"}>
                        g. &emsp; disable, interfere with, or try to get around any of the features of the Service, the
                        Platform, the software, and the Website related to security, preventing or restricting use or
                        copying of any content, or enforcing the limits on the use of the Service, the Platform, the
                        software, and the Website or the content on the Website; or
                    </p>

                    <p className={"mx-4"}>
                        h. &emsp; use, export or re-export any content or any copy or adaptation of the Service, the Platform,
                        the software, and the content of the Website in violation of any applicable laws or regulations,
                        including, without limitation, data, privacy and export control laws and regulations;
                    </p>

                    <p className={"mx-4"}>
                        i. &emsp; use the Platform or Services to generate or send unsolicited communications, advertising or
                        spam, or otherwise cause VIN2 to become impaired in its ability to send communications on its
                        own behalf or on behalf of other Users, or use the Platform or Services to sell, promote or
                        advertise products or services without appropriate authorization;
                    </p>

                    <p className={"mx-4"}>
                        j. &emsp; misrepresent yourself or disguise the origin of any data, content or other information you
                        submit (including by “spoofing”, “phishing”, manipulating headers or other identifiers,
                        impersonating anyone else, or falsely implying any sponsorship or association with VIN2 or any
                        third party) or access the Platform or Services via another user’s account without their
                        permission;
                    </p>

                    <p className={"mx-4"}>
                        k. &emsp; use the Platform or Services to use, reproduce, distribute, or display content or material
                        that is in a way that is pornographic, obscene, infringing, or defamatory in nature or that
                        would be reasonably likely to bring any person or property into disrepute;
                    </p>

                    <p className={"mx-4"}>
                        l. &emsp; use the Platform or Services to violate the privacy of others, or to collect or gather other
                        users’ personal or confidential information (including account information) from our Platform or
                        Services;
                    </p>

                    <p className={"mx-4"}>
                        m. &emsp; systematically retrieve data or other content from the Platform or Services to create or
                        compile, directly or indirectly, a collection, compilation, database, or directory without
                        written permission from us; or
                    </p>

                    <p className={"mx-4"}>
                        n. &emsp; permit or encourage anyone else to commit any of the actions above.
                    </p>

                    <p className={"mx-4"}>
                        Without affecting any other remedies available to us, VIN2 may permanently or temporarily
                        terminate or suspend a User’s Account or access to the Platform and Services without notice or
                        liability, if VIN2 (in its sole discretion) determines that a User has violated any of these
                        Terms and Conditions.
                    </p>

                    <p>
                        19. <b>Compliance with Laws</b> – User agrees to comply with all applicable laws in its use of the
                        Platform and Services. Without limiting the generality of the foregoing, User will not engage in
                        any unsolicited advertising, marketing, or other activities using the Services, including
                        without limitation any activities that violate the Telephone Consumer Protection Act of 1991,
                        CAN-SPAM Act of 2003, Canada’s Anti-Spam Legislation (CASL), or any other anti-spam laws and
                        regulations.
                    </p>

                    <p>
                        20. <b>Proprietary Rights</b> – The Platform, the software, and our Website contains proprietary and
                        confidential information that is protected by applicable intellectual property and other laws
                        and VIN2 and its suppliers retain all right, title and interest (including all copyright, trade
                        secret, and other rights) in and to the Platform, the software, the Website and all content
                        which is included in the Website (other than user content, which remains the property of the
                        User who supplied it). You may not remove any copyright, watermark, author information or other
                        proprietary notices contained in the Service, the Platform, the software, or the Website. All
                        now known or hereafter known tangible and intangible rights, title, interest, copyrights and
                        moral rights in and to the Services and Platform, and the software and product concept,
                        including but not limited to all text, data, computer code, algorithms, and information, are all
                        owned by VIN2 or its licensors or content suppliers. The Platform and all content included on
                        the Platform is the property of VIN2 or its licensors or content suppliers and protected by
                        Canadian and international copyright laws. You agree not to permit access to the Platform to
                        others, and not to publish, reproduce, sublicense or modify any content appearing on the
                        Platform for any purpose without the prior written consent of VIN2.
                    </p>

                    <p>
                        If you provide feedback regarding the Platform, the Services or our Website, such as
                        recommendations for improvements or features (the “Feedback”), we have the right to use the
                        Feedback in any way. Any development, implementation, or improvement made using any of that
                        Feedback is owned by VIN2 and may become part of the Service, the Platform, the
                    </p>

                    <p>
                        software, and/or our Website, without any compensation to you. We reserve all rights in and to
                        the Service, the Platform, the software, the Website, and all content provided on or through the
                        Platform and Website.
                    </p>

                    <p>
                        All trademarks, brands, product and service images, logos and names used in the Service, the
                        Platform, the VIN2 software, and the Website are the trademarks or service marks of VIN2 or its
                        licensors, as applicable. Nothing in the Service, the Platform, the VIN2 software, or the
                        Website shall be deemed to confer on any person any license or right to use any trademark,
                        brand, product and service image, logo or name.
                    </p>

                    <p>
                        21. <b>Successors and Assigns</b> – All of the provisions of these Terms and Conditions shall be
                        binding upon and inure to the benefit of the parties hereto and their respective lawful
                        successors and permitted assigns.
                    </p>

                    <p>
                        22. <b>Jurisdiction</b> – These Terms and Conditions shall be governed by and construed in accordance
                        with the laws of the Province of British Columbia and the courts of British Columbia and shall
                        have exclusive jurisdiction to entertain applications for injunctive relief and all other
                        actions arising in connection herewith.
                    </p>

                    <p>
                        23. <b>Headings</b> – Section headings are not to be considered a part of these Terms and Conditions
                        and are not intended to be a full and accurate description of the contents hereof.
                    </p>

                    <p>
                        24. <b>Amendment and Waiver</b> – No amendment, change or modification of these Terms and Conditions
                        shall be valid unless in writing signed by the parties hereto. Waiver by one party hereto of
                        breach of any provision of these Terms and Conditions by the other shall not operate or be
                        construed as a continuing waiver.
                    </p>

                    <p>
                        25. <b>Notices</b> – Notices, demands, or other communications required or desired to be given
                        hereunder by any party shall be in writing and shall be validly given or made to another party
                        if personally served, or if deposited in the Canadian mail, certified or registered, postage
                        prepaid, or by electronic mail provided that return receipt is requested and obtained, or
                        facsimile, or as otherwise set out in these Terms and Conditions. You consent to receive
                        communications from VIN2 electronically. You agree that all agreements, notices, disclosures and
                        other communications that VIN2 provides to you to your contact information as provided in the
                        submitted application or in registering for an Account.
                    </p>

                    <p>
                        26. <b>Survival</b> – Any provision of these Terms and Conditions which expressly states that it is to
                        continue in effect after termination or expiration of these Terms and Conditions, or which by
                        its nature would survive the termination or expiration of these Terms and Conditions, shall do
                        so, and Sections 8, 9, 14, 15, 20, and 26, together with all other provisions necessary for
                        their interpretation and application, will survive the termination or expiration of these Terms
                        and Conditions for any reason.
                    </p>

                    <p>
                        27. <b>Unenforceability of Provisions and Severability</b> – If any one or more of the provisions of
                        these Terms and Conditions shall for any reason be held to be invalid, illegal, or unenforceable
                        in any respect, any such provision shall be severable from these Terms and Conditions, in which
                        event these Terms and Conditions shall be construed as if such provision had never been
                        contained herein and the remainder of these Terms and Conditions shall nevertheless remain in
                        full force and effect.
                    </p>

                    <p>
                        28. <b>Entire Understanding</b> – These Terms and Conditions constitute the entire understanding and
                        agreement of the parties, and any and all prior agreements, understandings, and representations
                        are hereby terminated and cancelled in their entirety and are of no further force and effect.
                    </p>

                    <p className={"text-center"}>
                        [End of VIN2 Terms and Conditions of Use]
                    </p>
                </div>
            </div>
        </div>
    );
}
