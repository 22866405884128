import Http from '../utilities/http';

export class DocumentCategoryApi {
  static getAll(vehicleId) {
    return Http.get('/document-category', {
      vehicleId,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  static create(data) {
    return Http.post('/document-category', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  static update(id, data) {
    return Http.patch(`/document-category/${id}`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  static delete(id) {
    return Http.delete(`/document-category/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }
}
