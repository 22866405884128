import React, { useMemo } from 'react';

import { CustomSelect } from 'components';

import { useGetHSTariffs } from './useGetHSTariffs';

const HSTariffSelect = ({ defaultValue, onChange, error }) => {
  const { data: tariffList = [], isLoading, isFetching } = useGetHSTariffs();

  const options = useMemo(() => {
    return tariffList.map((item) => ({
      label: `${item.value} - ${item.name}`,
      value: item.id,
    }));
  }, [tariffList]);

  const defaultHsTariff = options.find((item) => item.value === defaultValue);

  return (
    <CustomSelect
      name="tariff_code"
      id="hs-tariff"
      isLoading={isLoading | isFetching}
      label="HS Tariff *"
      placeholder="Select Tariff"
      options={options}
      isSearchable
      loading={isLoading | isFetching}
      defaultValue={defaultHsTariff}
      onChange={onChange}
      error={error}
    />
  );
};

export default HSTariffSelect;
