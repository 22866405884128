import React from 'react';

const VinScannerContext = React.createContext({
  vin: '',
});

export const VinScannerProvider = ({ children }) => {
  const [vin, setVin] = React.useState('');

  const setData = (data) => {
    setVin(data);
  };

  return (
    <VinScannerContext.Provider value={{ vin, setData }}>{children}</VinScannerContext.Provider>
  );
};

export default function useVinScanner() {
  return React.useContext(VinScannerContext);
}
