import { getIn } from 'formik';
import React from 'react';
import { flattenObjectToPath } from 'utilities/objectUtils';

export function FocusError({
  form: { isSubmitting, touched, isValidating, errors },
  focusDelay = 100,
  onFocus,
}) {
  React.useEffect(() => {
    if (isSubmitting && !isValidating) {
      const flattenedTouched = flattenObjectToPath(touched);

      const errorNames = Object.keys(flattenedTouched).reduce((prev, key) => {
        if (getIn(errors, key)) {
          prev.push(key);
        }
        return prev;
      }, []);

      if (errorNames.length > 0 && typeof document !== 'undefined') {
        let errorElement = null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        // This is to avoid the other components autofocus when submitting
        setTimeout(() => {
          errorElement && errorElement.focus();
          onFocus && onFocus();
        }, focusDelay);
      }
    }
  }, [isSubmitting, isValidating, errors, touched, focusDelay, onFocus]);

  return <React.Fragment />;
}
