import { useLocation, useNavigate } from 'react-router';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ScanIcon from '../../../assets/images/scan-icon.png';
import { Link } from 'react-router-dom';

import './styles.scss';

export function DesktopNav({ menus, setShowVINScanner, setBondReleaseDialog }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <img
        className="vin-button cursor-pointer"
        src={ScanIcon}
        onClick={() => setShowVINScanner(true)}
        alt="scan icon"
      />
      <div className="pc-menus align-items-center">
        <div className="d-flex-center gap-3 mx-5">
          {menus.map((item, index) => {
            if (item.children) {
              return (
                <NavDropdown key={index} title={item.title} id="basic-nav-dropdown">
                  {item.children.map((child, index1) => (
                    <NavDropdown.Item
                      key={`${child.title}_${index1}`}
                      as="span"
                      className={'header-link'}
                      onClick={() => navigate(child.link)}
                    >
                      <Link
                        className={`header-link ${
                          location.pathname.includes(child.link) ? 'active' : ''
                        }`}
                        to={child.link}
                      >
                        {child.title}
                      </Link>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              );
            } else {
              return (
                <Link
                  className={`header-link mx-3 ${
                    location.pathname.includes(item.link) ? 'active' : ''
                  }`}
                  to={item.link}
                  key={index}
                  onClick={() => item.title === 'Bond Release' && setBondReleaseDialog(true)}
                >
                  {item.title}
                </Link>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}
