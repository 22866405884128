import { useMutation } from '@tanstack/react-query';
import { BrokerAPI } from 'api';

export function useSubmitToBroker({ onSuccess, onError } = {}) {
  return useMutation({
    mutationKey: ['submit-to-broker'],
    mutationFn: (data) =>
      BrokerAPI.submit(data)
        .then((res) => {
          typeof onSuccess === 'function' && onSuccess(res.data);
        })
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
  });
}
