import { TablePagination } from '@mui/material';

import { CustomSelect } from 'components/CustomSelect';

const perPageOptions = [
  {
    label: '10 / page',
    value: 10,
  },
  {
    label: '20 / page',
    value: 20,
  },
  {
    label: '50 / page',
    value: 50,
  },
  {
    label: '100 / page',
    value: 100,
  },
];

export function TableFooter({ pagination, otherContent }) {
  return (
    <div className="px-3 d-flex align-items-center justify-content-end gap-4">
      {otherContent}
      {pagination && (
        <>
          <div>
            <CustomSelect
              isSearchable={false}
              menuPlacement="top"
              options={perPageOptions}
              value={perPageOptions.find((option) => option.value === pagination.perPage)}
              onChange={(v) => {
                v.value !== pagination.perPage && pagination.onPerPageChange?.(v.value);
              }}
            />
          </div>
          <TablePagination
            component="div"
            data-testid="pagination"
            count={pagination.total}
            page={pagination.page}
            onPageChange={(e, page) => pagination.onPageChange(page)}
            rowsPerPage={pagination.perPage}
            rowsPerPageOptions={[pagination.perPage]}
            sx={{
              color: (theme) => theme.colors.white,

              '& .MuiTablePagination-displayedRows': {
                mb: 0,
              },

              '& .MuiTablePagination-select': {
                pb: 0,
              },

              '& .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          />
        </>
      )}
    </div>
  );
}
