import { ObjectHelper } from 'helpers';
import { AuthContext } from 'providers/authProvider';
import React, { useEffect, useState } from 'react';
import { Eye, Plus } from 'react-feather';
import { Link } from 'react-router-dom';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification, Table } from 'components';
import { CsvExportButton } from 'components/CsvExport/CsvExportButton';
import { SearchBar } from 'features/common/search/SearchBar';
import { useGetConsignees } from 'features/consignees/hooks/useGetConsignees';

import ConsigneeActionButton from './ConsigneeActionButton';
import { ConsigneesCsvImport } from './ConsigneesCsvImport';

const columns = [
  { title: 'Company Name', dataIndex: 'companyName', sortable: true },
  { title: 'First Name', dataIndex: 'firstName' },
  { title: 'Last Name', dataIndex: 'lastName' },
  { title: 'Email', dataIndex: 'email', hidden: true },
  { title: 'Street Address', dataIndex: 'street1' },
  { title: 'City', dataIndex: 'city1' },
  { title: 'State', dataIndex: 'state1' },
  { title: 'Zip', dataIndex: 'zip1' },
  { title: 'Country', dataIndex: 'country1' },
  { title: 'Phone Number', dataIndex: 'phoneNumber' },
  { title: 'Tax ID', dataIndex: 'taxID', sortable: true },
  { title: 'Organization Code', dataIndex: 'orgCode', hidden: true },
  {
    title: 'Actions',
    dataIndex: '',
    render: (consignee) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <Link to={`${consignee.id}`} className="text-white">
            <Eye size={20} />
          </Link>
          <ConsigneeActionButton consignee={consignee} />
        </div>
      );
    },
  },
];

const ConsigneesTable = () => {
  const { user } = React.useContext(AuthContext);

  const [sortOption, setSortOption] = React.useState({
    order: 'desc',
    orderBy: 'companyName',
  });
  const [pagination, setPagination] = React.useState({
    page: 0,
    perPage: 10,
  });
  const [search, setSearchTerm] = useState('');

  const queryObj = React.useMemo(() => {
    return ObjectHelper.trimQuery({
      ...pagination,
      ...sortOption,
      search: search,
    });
  }, [pagination, sortOption, search]);

  const { error, data, isLoading, isFetching, refetch } = useGetConsignees(queryObj);

  useEffect(() => {
    if (!error) return;
    Notification(NOTIFICATION_TYPE.DANGER, error?.message || 'System Error');
  }, [error]);

  const handleChangePagination = React.useCallback((field, value) => {
    if (field === 'page') {
      setPagination((prev) => ({ ...prev, page: value }));
    } else if (field === 'perPage') {
      setPagination({ page: 0, perPage: value });
    }
  }, []);

  return (
    <>
      <div className="d-flex gap-3 mb-4">
        <Link to="add">
          <Button
            className="d-flex gap-2"
            disabled={!hasPermission(user, { resource: 'consignee', action: 'create' })}
          >
            <Plus size={20} />
            Add
          </Button>
        </Link>
        <ConsigneesCsvImport
          columns={columns}
          onCsvImportSuccess={() => handleChangePagination('page', 0)}
        />
        <SearchBar fetch={refetch} onSearch={setSearchTerm} hasBorder={true} />
      </div>
      <Table
        data={(data?.data ?? []).map(({ consignee, ...data }) => ({
          ...data,
          ...consignee,
        }))}
        columns={columns}
        loading={isFetching || isLoading}
        checkable={true}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: data?.totalCount || 0,
          page: pagination.page,
          perPage: pagination.perPage,
          onPageChange: (page) => handleChangePagination('page', page),
          onPerPageChange: (perPage) => handleChangePagination('perPage', perPage),
        }}
        renderCsvExporter={(columns, data) => (
          <CsvExportButton data={data} columns={columns} filename="consignees.csv">
            Export CSV
          </CsvExportButton>
        )}
      />
    </>
  );
};

export default ConsigneesTable;
