import axios from 'axios';
import $ from 'jquery';

export class ThirdPartyApi {
  static searchVin(value) {
    return axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinextended/${value}?format=json`).then((res) => {
      const vehicleData = res.data.Results;

      return {
        make: vehicleData[7].Value,
        model: vehicleData[9].Value,
        year: vehicleData[10].Value,
        vehicleType: vehicleData[14].Value,
        manufacturer: vehicleData[8].Value,
        countryOfOrigin: vehicleData[15].Value,
      };
    }).catch((err) => {
      console.log(err);
    })
  }

  static searchRecall(year, make, model) {
    $.ajax({
      method: 'get',
      "headers": {
        "Access-Control-Allow-Origin": "*"
      },
      crossDomain: true,
      dataType: 'json',
      url: 'https://api.nhtsa.gov/recalls/recallsByVehicle?make=acura&model=rdx&modelYear=2012',
      success: (data, str, jqxhr) => {
        console.log('search recall success\n', data)
      },
      error: (err) => {
        console.log('searchRecall error\n', err)
      }
    })
  }
}
