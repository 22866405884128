import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConsigneesApi } from 'api';

/**
 * Custom React Query hook that handles the creation of consignees.
 *
 * @param {"single" | "batch"} type
 * @param {{ onSuccess?: () => void; onError?: (error: Error) => void }} options
 * @returns {import('@tanstack/react-query').UseMutationResult<any, unknown, any, unknown>}
 */
export function useCreateConsignees(type = 'single', { onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      if (type === 'batch') {
        return ConsigneesApi.batchAdd(data);
      }

      return ConsigneesApi.add(data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['consignees']);
      typeof onSuccess === 'function' && onSuccess(res.data);
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
