import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import './styles.scss';

export function TabMenu({ tabIndex, onTabChange, className, renderTabs, renderPanels, ...rest }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          className={classNames('tabs-menu', className)}
          value={tabIndex}
          onChange={onTabChange}
          {...rest}
        >
          {renderTabs()}
        </Tabs>
      </Box>
      {renderPanels(tabIndex)}
    </Box>
  );
}

function TabItem({ tab, tabIndex, className, ...rest }) {
  return (
    <Tab
      label={tab.title}
      className={className}
      classes={{
        selected: 'tabs-menu__tab--selected',
      }}
      {...a11yProps(tabIndex)}
      {...rest}
    />
  );
}

function TabPanel({ children, value, index, className, ...rest }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      {value === index && <Box className={className}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

TabMenu.TabItem = TabItem;
TabMenu.TabPanel = TabPanel;
