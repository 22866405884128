import React from 'react';
import './styles.scss';

const AuthLayout = ({ children }) => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 py-3">
      { children }
    </div>
  );
};

export default AuthLayout;
