import React from 'react';

import { useUploadVehiclePhoto } from 'features/photos/useUploadVehiclePhoto';

import { PhotoCategoriesSelection } from './PhotoCategoriesSelection';
import { PhotoSelection } from './PhotoSelection';
import './styles.scss';

const PhotoUploadForm = ({ vehicle, photos, categoryList }) => {
  const [selectedRequiredCategory, setSelectedRequiredCategory] = React.useState(null);
  const [selectedAdditionalCategory, setSelectedAdditionalCategory] = React.useState(null);

  const selectedCategory = selectedRequiredCategory || selectedAdditionalCategory;

  const {
    uploadPhoto,
    checkImageResolution,
    isCheckingImageResolution,
    isLoading: isUploading,
  } = useUploadVehiclePhoto();

  return (
    <>
      <div className="photo-upload-form">
        <h3 className="mb-4">Photos Upload</h3>

        <PhotoCategoriesSelection
          vehicle={vehicle}
          categories={categoryList}
          disabled={isUploading}
          photos={photos}
          selectedRequiredCategory={selectedRequiredCategory}
          selectedAdditionalCategory={selectedAdditionalCategory}
          onSelect={({ type, item }) => {
            if (type === 'required') {
              setSelectedRequiredCategory(item);
              setSelectedAdditionalCategory(null);
            } else if (type === 'additional') {
              setSelectedAdditionalCategory(item);
              setSelectedRequiredCategory(null);
            }
          }}
        />
        <PhotoSelection
          vehicle={vehicle}
          selectedCategory={selectedCategory}
          uploadPhoto={uploadPhoto}
          isUploading={isUploading}
          checkImageResolution={checkImageResolution}
          isCheckingImageResolution={isCheckingImageResolution}
          onUploaded={() => {
            setSelectedRequiredCategory(null);
            setSelectedAdditionalCategory(null);
          }}
        />
      </div>
    </>
  );
};

export default PhotoUploadForm;
