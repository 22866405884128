import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ConfirmModal, MoreMenu, NOTIFICATION_TYPE, Notification } from 'components';

import { useDeleteUser } from '../hooks/useDeleteUser';

export function UserActionButton({ user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutate: deleteUser, isLoading } = useDeleteUser({
    onSuccess: () => {
      setAnchorEl(null);
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Buyer is removed successfully.');
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  return (
    <>
      <MoreMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          onClick={() => setShowConfirm(true)}
          disabled={isLoading}
          data-testid="delete-user"
        >
          <DeleteOutlineIcon sx={{ mr: '1rem' }} />
          Remove
        </MenuItem>
        <MenuItem>
          <Link to={`edit/${user.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <EditIcon sx={{ mr: '1rem' }} />
            Edit
          </Link>
        </MenuItem>
      </MoreMenu>

      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        title="Remove User"
        content="Are you sure you want to remove this user?"
        onConfirm={() => deleteUser(user.id)}
      />
    </>
  );
}
