import { DialogContent } from '@mui/material';
import React from 'react';
import { Trash } from 'react-feather';

import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';
import UnstyledButton from 'components/UnstyledButton';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';

import { BondReleaseTable } from './BondReleaseTable';
import { useUploadBondRelease } from './useUploadBondRelease';

const BondReleaseModal = ({ show, handleClose }) => {
  const {
    dropzone,
    selectedFile,
    isScanning,
    isUploading,
    vehicles,
    handleReset,
    handleScanVin,
    handleUpload,
  } = useUploadBondRelease();

  return (
    <Dialog open={show} onClose={handleClose} title="Upload Bond Release">
      <DialogContent sx={{ py: 6 }} className={'photo-upload-form'}>
        <div className="mb-4">
          <div className="drop-box" {...dropzone.getRootProps()}>
            <input {...dropzone.getInputProps()} />
            {selectedFile ? (
              <div className={'d-flex justify-content-between align-items-center gap-4'}>
                <div>{selectedFile.name}</div>
                <UnstyledButton
                  className="remove-btn position-relative"
                  disabled={isScanning || isUploading}
                  onClick={handleReset}
                >
                  <Trash className="remove-icon" />
                  <VisuallyHidden>Clear file</VisuallyHidden>
                </UnstyledButton>
              </div>
            ) : (
              <>
                <p className="text-md text-grey mb-0">Drag and Drop file</p>
                <p className="text-sm text-grey-dark mb-0">PDF</p>
              </>
            )}
          </div>
        </div>
        {vehicles.length > 0 && <BondReleaseTable vehicles={vehicles} />}
        <Button
          className={'float-end'}
          type="button"
          disabled={!selectedFile || (vehicles.length > 0 && vehicles.every((v) => !v.isExisted))}
          onClick={async () => {
            if (selectedFile && vehicles.length === 0) {
              handleScanVin();
            } else {
              const count = await handleUpload();

              Notification(
                NOTIFICATION_TYPE.SUCCESS,
                `Successfully released ${count} vehicle${count > 1 ? 's' : ''}`
              );
              handleClose();
            }
          }}
          loading={isScanning || isUploading}
        >
          {!selectedFile && 'Select a file'}
          {selectedFile && vehicles.length === 0 && 'Scan Vin'}
          {selectedFile && vehicles.length > 0 && 'Upload'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default BondReleaseModal;
