import { FormControl, FormLabel } from '@mui/material';
import { ROUTES } from 'constants/routes';
import VehicleSearch from 'pages/main/VehicleInfo/VehicleInfoActions/VehicleSearch';
import VehicleStatusFilters from 'pages/main/VehicleInfo/VehicleInfoActions/VehicleStatusFilters';
import { AuthContext } from 'providers/authProvider';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { hasPermission, isRegisteredImporter } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';

const VehicleInfoActions = ({
  checkedIds,
  vehicleListToSubmit,
  setSubmitModal,
  setShareModal,
  setGenerateNHTSAPackageModal,
  setPrintRILabelModal,
}) => {
  const { searchValues } = useSearchVehicles({
    onError: (error) => Notification(NOTIFICATION_TYPE.DANGER, error.message),
  });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmitClick = () => {
    setSubmitModal(true);
  };

  const canSubmitToBroker =
    hasPermission(user, { resource: 'vehicle', action: 'submit_to_broker' }) &&
    checkedIds.length > 0 &&
    !vehicleListToSubmit.some((v) => v.status !== 'Launchpad');

  const handlePrintRILabel = () => {
    if (!isRegisteredImporter(user)) {
      return;
    }
    setPrintRILabelModal(true);
  };

  const handleShareVehicle = () => {
    setShareModal(true);
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-start align-items-center flex-wrap mb-5 gap-4">
        <Button
          onClick={() => navigate(ROUTES.MAIN.VEHICLES.CREATE)}
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'create' })}
        >
          New Import
        </Button>
        {(searchValues.statusFilters.includes('All') ||
          searchValues.statusFilters.includes('Launchpad')) && (
          <Button disabled={!canSubmitToBroker} onClick={onSubmitClick}>
            Send to Broker
          </Button>
        )}
        {(searchValues.statusFilters.includes('All') ||
          searchValues.statusFilters.includes('Submission')) &&
          isRegisteredImporter(user) && (
            <Button disabled={checkedIds.length < 1} onClick={handlePrintRILabel}>
              Print RI Label
            </Button>
          )}

        {isRegisteredImporter(user) &&
          (searchValues.statusFilters.includes('All') ||
            searchValues.statusFilters.includes('Submission')) && (
            <Button
              disabled={
                checkedIds.length < 1 ||
                vehicleListToSubmit.some((v) => !v.nhtsaReady) ||
                vehicleListToSubmit.some((v) => v.status === 'Compliance')
              }
              onClick={() =>
                setGenerateNHTSAPackageModal({
                  open: true,
                  isResubmission: false,
                })
              }
            >
              Generate NHTSA Package
            </Button>
          )}
        {isRegisteredImporter(user) &&
          (searchValues.statusFilters.includes('All') ||
            searchValues.statusFilters.includes('Compliance')) && (
            <Link to={ROUTES.MAIN.NHTSA.INDEX}>
              <Button>Re-Generate NHTSA Package</Button>
            </Link>
          )}
        <Button
          disabled={
            !hasPermission(user, { resource: 'vehicle', action: 'share' }) || checkedIds.length < 1
          }
          onClick={handleShareVehicle}
        >
          Share Vehicle
        </Button>
      </div>
      <FormControl style={{ width: '100%' }}>
        <FormLabel>
          <p className="select-label">Vehicle Status</p>
        </FormLabel>
        <div className="row gap-2 gap-sm-0">
          <div className="col-12 col-sm-6">
            <VehicleStatusFilters />
          </div>
          <div className="col-12 col-sm-6">
            <VehicleSearch />
          </div>
        </div>
      </FormControl>
    </React.Fragment>
  );
};

export default VehicleInfoActions;
