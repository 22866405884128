import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { ConsigneeForm } from 'features/consignees/components/form/ConsigneeForm';
import { CreateDataModal } from 'features/vehicles/components/modals/CreateDataModal';

import { DataSelect } from '../components/DataSelect';

export function ConsigneeInfo({ form, lastSaved, setLastSaved }) {
  const { user } = React.useContext(AuthContext);

  const [showConsigneeModal, setShowConsigneeModal] = React.useState(false);

  const onSuccess = (data) => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Consignee was created successfully');

    setLastSaved({ Consignee: data });
    setShowConsigneeModal(false);
  };

  const onError = (err) => {
    Notification(NOTIFICATION_TYPE.DANGER, err.message);
  };

  return (
    <>
      <div className="col-7 col-md-6">
        <DataSelect
          apiFieldName="Consignee"
          label="Consignee *"
          placeholder="Select Consignee"
          lastSaved={lastSaved}
          isCompanyNameSelect
          form={form}
          error={
            form.errors.consignee_code && form.touched.consignee_code
              ? form.errors.consignee_code
              : ''
          }
        />
      </div>
      <div className="col-5 col-md-6">
        {hasPermission(user, { resource: 'consignee', action: 'create' }) && (
          <>
            <Button type="button" onClick={() => setShowConsigneeModal(true)}>
              + Add
            </Button>
            <CreateDataModal
              title="Add Consignee"
              show={showConsigneeModal}
              handleClose={() => setShowConsigneeModal(false)}
            >
              <ConsigneeForm action="quick-create" onSuccess={onSuccess} onError={onError} />
            </CreateDataModal>
          </>
        )}
      </div>
    </>
  );
}
