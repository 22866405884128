import { useQuery } from '@tanstack/react-query';
import { ImportersApi } from 'api';

/**
 * Custom React Query hook to fetch user data by id
 *
 * @param {number} id
 * @param {{onError?: (error: Error) => void}} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export function useGetUser(id, { onError } = {}) {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () =>
      ImportersApi.getById(id)
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
  });
}
