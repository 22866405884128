import { useState } from 'react';
import ImagePreviewModal from '../ImagePreviewModal/ImagePreviewModal';

const ImagePreview = ({ src, alt }) => {
  const [open, setOpen] = useState(false);

  if (!src || src === 'null' || src === 'undefined') {
    return null;
  }

  return (
    <>
      <img
        className="small-image"
        src={src}
        alt={alt}
        width={100}
        height={60}
        onClick={() => setOpen(true)}
      />
      <ImagePreviewModal src={src} alt={alt} open={open} setOpen={setOpen} />
    </>
  );
};

export default ImagePreview;
