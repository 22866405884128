import Http from '../utilities/http';

export class VehicleDocumentApi {
  static getAllByVehicleId(vehicleId) {
    return Http.get(`/vehicle-document`, {
      vehicleId,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static upload(data) {
    return Http.post('/vehicle-document', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static create(data) {
    return Http.post('/vehicle-document', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static update(data) {
    return Http.put('/vehicle-document', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static delete(id) {
    return Http.delete(`/vehicle-document/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  static sort(data) {
    return Http.post('/vehicle-document/sort', data)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }
}
