import classNames from 'classnames';
import React from 'react';

import { Button } from 'components';

/**
 * @typedef {Object} CsvFileUploadProps
 * @property {React.ChangeEventHandler<HTMLInputElement>} onFileInputChange
 * @property {string} [className]
 */

/**
 *
 * @param {CsvFileUploadProps} props
 * @returns {JSX.Element}
 */
export function CsvFileUpload({ onFileInputChange, className }) {
  const chooseFileInput = React.useRef(null);

  return (
    <div className={classNames('d-flex gap-2 align-items-center', className)}>
      <input
        ref={chooseFileInput}
        id="chooseFile"
        type="file"
        accept=".csv"
        onChange={onFileInputChange}
        className="mb-3"
        hidden
      />
      <Button className="d-flex gap-2">
        <label htmlFor="chooseFile">Choose File</label>
      </Button>
      {chooseFileInput?.current?.files[0]?.name || 'No file chosen'}
    </div>
  );
}
