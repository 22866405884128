import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConformityLocationApi } from 'api';

/**
 * Custom React Query hook that handles the deletion of a conformity location by id.
 *
 * @see {@link ConformityLocationApi.remove}
 *
 * @param {number} id
 * @param {{onSuccess?: () => void, onError?: (error: Error) => void}} callbacks
 * @returns {import("@tanstack/react-query").UseMutationResult<void, unknown, number, unknown>}
 */
export function useDeleteConformityLocationById(id, { onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-conformity-location'],
    mutationFn: () => ConformityLocationApi.remove(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['conformity-locations']);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
