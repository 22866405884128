import React from 'react';
import { configure, BarcodePicker as ScanditBarcodePicker } from 'scandit-sdk';
import { SCANDIT_ENGINE_LOCATION, SCANDIT_KEY } from '../../constants/config';

const configurationPromise = configure(
  SCANDIT_KEY,
  {
    engineLocation: SCANDIT_ENGINE_LOCATION,
  }
);

export const BarcodePicker = ({ onScan, onError, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const ref = React.createRef();

  React.useEffect(() => {
    let _barcodePicker;
    configurationPromise.then(() => {
      setLoading(false);
      ScanditBarcodePicker.create(ref.current, props).then((barcodePicker) => {
        _barcodePicker = barcodePicker;
        onScan && _barcodePicker.on('scan', onScan);
        onError && _barcodePicker.on('scanError', onError);
      })
    }).catch((error) => {
      setLoading(false);
      setError(error.message || 'Failed to load Scandit');
    });

    return () => {
      _barcodePicker && _barcodePicker.destroy();
    };
  }, [onError, onScan, props, ref])

  if (loading) {
    return <div>Initiating...</div>
  }
  if (!loading && error) {
    return <p>{error}</p>
  }
  return (
    <div ref={ref} />
  );
}
