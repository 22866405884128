export const term1Options = [
  {
    value: 0,
    label:
      'The vehicle is not subject to any safety recalls as of the time of such certification, or',
  },
  {
    value: 1,
    label:
      'All noncompliances and defects that are the subject of safely recalls have been remedied.',
  },
];

export const term2Options = [
  {
    value: 0,
    label:
      "The vehicle is not required to comply with the parts marking requirements of the Theft Prevention Standard 'Part 541', or",
  },
  {
    value: 1,
    label:
      'The vehicle, as manufactured or as modified prior to importation, complies with those parts marking requirements.',
  },
];

export const term3Options = [
  {
    value: 0,
    label:
      'I know that the vehicle I am certifying conforms with all applicable Federal motor vehicle safety and bumper standards because I personally witnessed each modification performed on the vehicle to effect compliance, or',
  },
  {
    value: 1,
    label:
      'I know that the vehicle I am certifying conforms with all applicable Federal motor vehicle safety and bumper standards because the person who performed the necessary modifications to the vehicle is an employee of the above named RI and has provided full documentation of the work that I have reviewed, and I am satisfied that the vehicle as modified complies',
  },
];

export const term4Options = [
  {
    value: 0,
    label: 'Destroyed',
  },
  {
    value: 1,
    label: 'Exported, or',
  },
  {
    value: 2,
    label: 'Not Applicable',
  },
];
