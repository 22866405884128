import { DialogContent } from '@mui/material';
import { VehicleApi } from 'api';
import { ROUTES } from 'constants/routes';
import useVinScanner from 'providers/vinScannerProvider';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScanSettings } from 'scandit-sdk';

import { Button, Dialog } from 'components';
import { BarcodePicker } from 'components/BarcodePicker';

const ScanBarcodeModal = ({ show, handleClose, setRecallDialog, findVehicleRecalls }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setData, vin } = useVinScanner();
  const [isScanner, setIsScanner] = useState(false);
  const [isNotExistingVin, setIsNotExistingVin] = useState(false);

  useEffect(() => {
    if (show) {
      setIsScanner(true);
      setIsNotExistingVin(false);
    }
  }, [show]);

  const findVehicleByVin = async (vin) => {
    try {
      const res = await VehicleApi.getVehicleByVin(vin);
      if (res.data.id) {
        const pathArray = window.location.pathname.split('/').pop();
        if (pathArray !== res.data.id) {
          navigate(ROUTES.MAIN.VEHICLES.PROFILE.replace(':id', res.data.id));
        }
        handleClose();
      }
    } catch (err) {
      setIsNotExistingVin(true);
    }
  };

  return (
    <Dialog open={show} onClose={handleClose} title="Powered by Scandit">
      <DialogContent sx={{ py: 6 }}>
        {show && isScanner && (
          <BarcodePicker
            playSoundOnScan={true}
            vibrateOnScan={true}
            hideLogo={true}
            enableTapToFocus={true}
            accessCamera={true}
            scanSettings={
              new ScanSettings({
                enabledSymbologies: [
                  'qr',
                  'ean8',
                  'ean13',
                  'upca',
                  'upce',
                  'code128',
                  'code39',
                  'code93',
                  'itf',
                  'data-matrix',
                ],
                codeDuplicateFilter: 1000,
              })
            }
            onScan={async (scanResult) => {
              const vin = scanResult.barcodes[0].data;
              setData(vin);
              await findVehicleByVin(vin);
              setIsScanner(false);
            }}
            onError={(error) => {
              console.error(error.message);
            }}
          />
        )}
        {isNotExistingVin && (
          <>
            <h6 className="text-center">VIN: {vin}</h6>
            <div className="d-flex align-items-center justify-content-end gap-5 mt-4">
              <Button
                className="w-50"
                onClick={() => {
                  if (location.pathname !== ROUTES.MAIN.VEHICLES.CREATE) {
                    navigate(ROUTES.MAIN.VEHICLES.CREATE);
                  }
                  handleClose();
                }}
              >
                Create Vehicle
              </Button>
              <Button
                className="w-50"
                onClick={() => {
                  setRecallDialog(true);
                  findVehicleRecalls(vin);
                  handleClose();
                }}
              >
                Recall Lookup
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ScanBarcodeModal;
