import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import { Button, Input } from 'components';
import { FocusError } from 'components/FocusError/FocusError';
import { AddressInfo } from 'features/common/address-form/AddressInfo';

import { useConformityLocationForm } from './ConformityLocationForm.hook';

export function ConformityLocationForm({ mutation, initialValues = {} }) {
  const [form, isLoading] = useConformityLocationForm(mutation, initialValues);
  const [shouldUseSameAddress, setShouldUseSameAddress] = React.useState(false);

  React.useEffect(() => {
    form.setFieldValue(
      'inspectedAddress.street',
      shouldUseSameAddress
        ? form.values.conformedAddress.street
        : form.values.inspectedAddress.street
    );
    form.setFieldValue(
      'inspectedAddress.city',
      shouldUseSameAddress ? form.values.conformedAddress.city : form.values.inspectedAddress.city
    );
    form.setFieldValue(
      'inspectedAddress.state',
      shouldUseSameAddress ? form.values.conformedAddress.state : form.values.inspectedAddress.state
    );
    form.setFieldValue(
      'inspectedAddress.zip',
      shouldUseSameAddress ? form.values.conformedAddress.zip : form.values.inspectedAddress.zip
    );
    form.setFieldValue(
      'inspectedAddress.country',
      shouldUseSameAddress
        ? form.values.conformedAddress.country
        : form.values.inspectedAddress.country
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.conformedAddress, shouldUseSameAddress]);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusError form={form} />
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Title"
            className="mb-3"
            type="text"
            placeholder="Title"
            hasBorder
            {...form.getFieldProps('title')}
            error={form.errors.title && form.touched.title ? form.errors.title : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Company Phone Number"
            className="mb-3"
            type="text"
            placeholder="Company Phone Number"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.companyPhone || ''}`}
            {...form.getFieldProps('companyPhone')}
            error={
              form.errors.companyPhone && form.touched.companyPhone ? form.errors.companyPhone : ''
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Contact First Name"
            className="mb-3"
            type="text"
            placeholder="Contact First Name"
            hasBorder
            {...form.getFieldProps('contactFirstName')}
            error={
              form.errors.contactFirstName && form.touched.contactFirstName
                ? form.errors.contactFirstName
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Last Name"
            className="mb-3"
            type="text"
            placeholder="Contact Last Name"
            hasBorder
            {...form.getFieldProps('contactLastName')}
            error={
              form.errors.contactLastName && form.touched.contactLastName
                ? form.errors.contactLastName
                : ''
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Phone Number"
            className="mb-3"
            type="text"
            placeholder="Contact Phone Number"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.contactPhone || ''}`}
            {...form.getFieldProps('contactPhone')}
            error={
              form.errors.contactPhone && form.touched.contactPhone ? form.errors.contactPhone : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Email"
            className="mb-3"
            type="email"
            placeholder="Contact Email Address"
            hasBorder
            startIcon={<EmailOutlined />}
            iconHref={`mailto:${form.values.contactEmail || ''}`}
            {...form.getFieldProps('contactEmail')}
            error={
              form.errors.contactEmail && form.touched.contactEmail ? form.errors.contactEmail : ''
            }
          />
        </div>
      </div>

      <h3>Address Where Vehicle Can Be Conformed</h3>
      <div className="row">
        <AddressInfo form={form} fieldName="conformedAddress" />
      </div>

      <h3>Address Where Vehicle Can Be Inspected</h3>
      <div className="row">
        <div className="col-md-12 mb-3">
          <FormControlLabel
            control={<Checkbox onChange={() => setShouldUseSameAddress(!shouldUseSameAddress)} />}
            label="Same As Conformed Address"
          />
        </div>
        <AddressInfo form={form} fieldName="inspectedAddress" disabled={shouldUseSameAddress} />
      </div>
      <div className="row mb-4">
        <div className="col-md-4" />
        <div className="col-md-4" />
        <div className="col-md-4">
          <Button type="submit" className="w-100" loading={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
}
