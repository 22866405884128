import { useQuery } from '@tanstack/react-query';
import { VehicleApi } from 'api';
import { VEHICLE_STATUS } from 'constants/vehicle';
import { ObjectHelper } from 'helpers';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_SEARCH_VALUES, SearchContext } from '../contexts/SearchContext';

/**
 * Custom hook for searching vehicles using React Query
 *
 * @param {object} searchValues
 * @param {{ onError?: (error: Error) => void  }} callbacks
 * @returns
 */
export function useSearchVehicles({ onError } = {}) {
  const { searchValues, setSearchValues } = React.useContext(SearchContext);

  const query = React.useMemo(
    () =>
      ObjectHelper.trimQuery({
        ...searchValues.filter,
        ...searchValues.sortOption,
        statusFilters: formatFilters(searchValues.statusFilters),
        searchTerm: searchValues.searchTerm,
      }),
    [searchValues]
  );

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const searchTerm = searchParams.get('searchTerm');
    const page = Number(searchParams.get('page')) || DEFAULT_SEARCH_VALUES.filter.page;
    const perPage = Number(searchParams.get('perPage')) || DEFAULT_SEARCH_VALUES.filter.perPage;
    const order = searchParams.get('order') || DEFAULT_SEARCH_VALUES.sortOption.order;
    const orderBy = searchParams.get('orderBy') || DEFAULT_SEARCH_VALUES.sortOption.orderBy;
    const statusFilters = searchParams.getAll('statusFilters');

    setSearchValues((prevValues) => ({
      ...prevValues,
      searchTerm: searchTerm || '',
      filter: { page, perPage },
      sortOption: { order, orderBy },
      statusFilters:
        statusFilters?.length > 0 ? statusFilters : DEFAULT_SEARCH_VALUES.statusFilters,
    }));
  }, [searchParams, setSearchValues]);

  return {
    ...useQuery({
      queryKey: ['vehicles', query],
      queryFn: () => VehicleApi.search(query).catch((error) => onError?.(error)),
    }),
    searchValues,
  };
}

function formatFilters(filters) {
  if (filters.length === 0 || filters[0] === 'All') {
    return VEHICLE_STATUS;
  }
  return filters;
}
