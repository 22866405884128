import Http from 'utilities/http';

export class PlacesApi {
  static async autocomplete({ input = '', radius = 50000, components = '', types = 'address' }) {
    return Http.get('/places', {
      input,
      radius,
      components,
      types,
    }).then((res) => res.data);
    // return Http.get(
    //   `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&radius=${radius}&components=${components}&types=${types}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    // ).then((res) => res.data);
  }

  static async getPlaceDetails(id, query) {
    return Http.get(`/places/${id}`, query).then((res) => res.data);
    // return Http.get(
    //   `https://maps.googleapis.com/maps/api/place/details/json?place_id=${id}&fields=${fields}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    // ).then((res) => res.data);
  }
}
