import { DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { checkResolutionImage } from 'utilities/fileHelper';

import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';
import { useUploadVehiclePhoto } from 'features/photos/useUploadVehiclePhoto';

export default function EditPhotoModal({ category, vin, photoId, show, handleClose }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [checkingImage, setCheckingImage] = useState(false);

  const { mutateAsync: uploadVehiclePhoto, isLoading } = useUploadVehiclePhoto();

  const saveHandler = () => {
    const formData = new FormData();

    formData.append(`carPhoto`, selectedFile);
    formData.append('category', category.id);
    formData.append('vin', vin);
    formData.append('photoId', photoId);

    uploadVehiclePhoto(formData)
      .then(() => {
        setSelectedFile(null);
        handleClose();
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Saved photo successfully');
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      });
  };

  const onSelectFile = (file) => {
    setSelectedFile(file);
    setCheckingImage(true);
    checkResolutionImage(file)
      .then(() => {
        setCheckingImage(false);
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err);
        setSelectedFile(null);
        setCheckingImage(false);
      });
  };

  return (
    <Dialog open={show} onClose={handleClose} title={`Change ${category.name} Photo`}>
      <DialogContent sx={{ py: 7.5 }}>
        <div>
          <label htmlFor="PhotoFileUpload" className="custom-input-label">
            Upload photo
          </label>
          <input
            className="form-control"
            type="file"
            id="PhotoFileUpload"
            accept=".png, .jpeg, .jpg, .gif"
            name="carPhoto"
            onChange={(event) => onSelectFile(event.target.files[0])}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="dark" onClick={handleClose}>
          Close
        </Button>
        <Button
          loading={isLoading}
          onClick={saveHandler}
          disabled={selectedFile === null || checkingImage}
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
