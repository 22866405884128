/**
 * @typedef SkeletonRowsProps
 * @property {number} rowsCount
 * @property {number} rowHeight
 */
import { Skeleton } from '@mui/material';

/**
 *
 * @param {SkeletonRowsProps} props
 * @returns {JSX.Element}
 */
export function SkeletonRows({ rowsCount = 3, rowHeight = 30 }) {
  return Array.from({ length: rowsCount }).map((_, index) => (
    <Skeleton key={index} width="100%" height={rowHeight} animation="pulse" />
  ));
}
