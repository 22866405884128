import { ObjectHelper } from 'helpers';
import React from 'react';
import { Eye, Plus } from 'react-feather';
import { Link } from 'react-router-dom';

import { Button, NOTIFICATION_TYPE, Notification, Table } from 'components';
import { SearchBar } from 'features/common/search/SearchBar';
import { useGetConformityLocations } from 'features/conformity-locations/hooks/useGetConformityLocations';

import ConformityLocationMoreButton from './ConformityLocationMoreButton';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    sortable: true,
  },
  {
    title: 'First Name',
    dataIndex: 'contactFirstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'contactLastName',
  },
  {
    title: 'Street',
    dataIndex: 'conformedStreet',
  },
  {
    title: 'City',
    dataIndex: 'conformedCity',
  },
  {
    title: 'State',
    dataIndex: 'conformedState',
  },
  {
    title: 'Zip',
    dataIndex: 'conformedZip',
  },
  {
    title: 'Country',
    dataIndex: 'conformedCountry',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
  },
  {
    title: 'Actions',
    dataIndex: '',
    render: (conformityLocation) => {
      return (
        <div className="d-flex align-items-center gap-2">
          <Link to={`${conformityLocation.id}`} className="text-white">
            <Eye size={20} />
          </Link>
          <ConformityLocationMoreButton conformityLocation={conformityLocation} />
        </div>
      );
    },
  },
];

export function ConformityLocationsTable() {
  const [sortOption, setSortOption] = React.useState({
    order: 'desc',
    orderBy: 'title',
  });
  const [pagination, setPagination] = React.useState({
    page: 0,
    perPage: 10,
  });
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleChangePagination = React.useCallback((field, value) => {
    if (field === 'page') {
      setPagination((prev) => ({ ...prev, page: value }));
    } else if (field === 'perPage') {
      setPagination({ page: 0, perPage: value });
    }
  }, []);

  const queryObj = React.useMemo(
    () =>
      ObjectHelper.trimQuery({
        ...pagination,
        ...sortOption,
        search: searchTerm,
      }),
    [pagination, searchTerm, sortOption]
  );

  const { data, isLoading, isFetching, refetch } = useGetConformityLocations(queryObj, {
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  return (
    <>
      <div className="d-flex mb-4 gap-2">
        <Link to="add">
          <Button className="d-flex gap-2">
            <Plus size={20} />
            Add
          </Button>
        </Link>
        <SearchBar fetch={refetch} onSearch={setSearchTerm} hasBorder={true} />
      </div>
      <Table
        data={data.items}
        columns={columns}
        loading={isLoading || isFetching}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: data.totalCount,
          page: pagination.page,
          perPage: pagination.perPage,
          onPageChange: (page) => handleChangePagination('page', page),
          onPerPageChange: (perPage) => handleChangePagination('perPage', perPage),
        }}
      />
    </>
  );
}
