import React from 'react';

import { CustomSelect, NOTIFICATION_TYPE, Notification } from 'components';

import { useGetWarrantyProviders } from './useGetWarrantyProviders';

export function WarrantyProviderSelect({ form, ...rest }) {
  const {
    data: warrantyProviders = [],
    isLoading,
    isFetching,
  } = useGetWarrantyProviders({
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  const options = React.useMemo(() => {
    return warrantyProviders.map((item) => ({
      label: item.companyName,
      value: item.id,
    }));
  }, [warrantyProviders]);

  return (
    <CustomSelect
      label="Select RI Warranty Provider"
      options={options}
      value={options.find((item) => item.value === form.values.warrantyProvider)}
      onChange={(v) => form.setFieldValue('warrantyProvider', v.value)}
      error={
        form.errors.warrantyProvider && form.touched.warrantyProvider
          ? form.errors.warrantyProvider
          : ''
      }
      placeholder="Select RI Warranty Provider"
      loading={isLoading || isFetching}
      hasBorder
      {...rest}
    />
  );
}
