import { useQuery } from '@tanstack/react-query';
import { NHTSAPackageApi } from 'api/nhtsa-package.api';

export function useGetNHTSAPackages(queryObj) {
  return useQuery({
    queryKey: ['nhtsa-packages', queryObj],
    queryFn: () => NHTSAPackageApi.search(queryObj),
    staleTime: 5 * 60 * 1000,
  });
}
