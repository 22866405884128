import React from 'react';
import { Dialog } from '../Dialog';
import ScrollContainer from 'react-indiana-drag-scroll';
import UnstyledButton from '../UnstyledButton';
import { ZoomIn, ZoomOut } from 'react-feather';

import './style.scss';

const ImagePreviewModal = ({ src, alt, open, setOpen }) => {
  const [scale, setScale] = React.useState(1);

  const handleClose = () => {
    setOpen(false);
    setScale(1);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  return (
    <Dialog open={open} onClose={handleClose} title={alt}>
      <div className="position-relative">
        <ScrollContainer className="scroll-container">
          <div style={{ transform: `scale(${scale})` }}>
            <img src={src} alt={alt} width="100%" height="100%" />
          </div>
        </ScrollContainer>
        <div className="zoom-btn-group">
          <UnstyledButton aria-label="zoom in" onClick={handleZoomIn} className="zoom-btn">
            <ZoomIn />
          </UnstyledButton>
          <UnstyledButton aria-label="zoom out" onClick={handleZoomOut} className="zoom-btn">
            <ZoomOut />
          </UnstyledButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ImagePreviewModal;
