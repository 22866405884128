import Http from '../utilities/http';

export class WarrantyProviderApi {
  static create(data) {
    return Http.post('/warranty-providers', data);
  }

  static get() {
    return Http.get('/warranty-providers');
  }
}
