export const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/login',
    REGISTER: '/register',
    PASSWORD_RESET: '/password-reset/:token',
  },
  PUBLIC: {
    RI_USER_AGREEMENT: '/ri-user-agreement',
    TERMS_OF_USE: '/terms-of-use',
    PRICING_AGREEMENT: '/pricing-agreement',
    CUSTOMS_BROKER_RELEASE: '/customs-broker-release',
  },
  MAIN: {
    PREFIX: '/main',
    VEHICLES: {
      PREFIX: '/vehicles',
      INDEX: '/vehicles',
      CREATE: '/vehicles/create',
      EDIT: '/vehicles/edit/:id',
      PROFILE: '/vehicles/:id',
      RI_LABEL: '/vehicles/:id/ri-label',
    },
    PHOTOS: {
      PREFIX: '/photos',
      DETAIL: '/photos/:vin',
    },
    BONDS: {
      INDEX: '/bonds',
    },
    USER_SETTINGS: {
      INDEX: '/settings',
      CONFORMITY_LOCATIONS: '/settings/conformity-locations',
      BUYERS: '/settings/buyers',
      SELLERS: '/settings/sellers',
      CARRIERS: '/settings/carriers',
      CONSIGNEES: '/settings/consignees',
      ACCOUNTS: '/settings/accounts',
      PROFILE: '/settings/profile',
    },
    PAYMENT: {
      INDEX: '/payment',
      SUCCESS: '/payment/success/:id',
    },
    NHTSA: {
      INDEX: '/nhtsa-regeneration',
      DETAIL: '/nhtsa-regeneration/:id',
    },
  },
};
