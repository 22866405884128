import { useMutation } from '@tanstack/react-query';
import { PhotosApi } from 'api';
import _ from 'lodash';
import { useState } from 'react';

import { NOTIFICATION_TYPE, Notification } from 'components';

export default function useUploadImage(images, vin, vehicle_id, setImages) {
  const [fieldName, setFieldName] = useState(null);
  const mutation = useMutation({
    mutationKey: ['uploadImageVehicleForm'],
    mutationFn: async ({ vehicle_id, fieldName, file }) => {
      const formData = new FormData();
      formData.append('vin', vin);
      formData.append('category', _.capitalize(fieldName));
      formData.append('carPhoto', file);
      formData.append('vin_code', vehicle_id);
      await PhotosApi.upload(formData);
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
    onSuccess: (data, variables, context) => {
      Notification(NOTIFICATION_TYPE.SUCCESS, `Successfully uploaded ${variables.fieldName}`);
      setImages((prevImages) => ({ ...prevImages, [variables.fieldName]: null }));
    },
  });

  const handleUploadImage = async (fieldName, file) => {
    if (!vin || !vehicle_id) {
      return Notification(NOTIFICATION_TYPE.DANGER, 'Please provide a VIN to upload images');
    }

    if (mutation.isLoading) {
      return Notification(NOTIFICATION_TYPE.SUCCESS, 'Uploading image...');
    }

    setFieldName(fieldName);
    await mutation.mutateAsync({ vehicle_id, fieldName, file });
    if (images[fieldName]) {
      URL.revokeObjectURL(images[fieldName]);
    }

    const url = URL.createObjectURL(file);
    setImages((prevImages) => ({ ...prevImages, [fieldName]: url }));
  };

  return {
    handleUploadImage,
    isLoading: mutation.isLoading,
    fieldName,
  };
}
