// Referenced from https://github.com/devfolioco/react-otp-input
import classNames from 'classnames';
import React from 'react';

import './styles.scss';

export const OTPInput = React.forwardRef(
  ({ value, onChange, numOfDigits = 6, autoFocus = false, className, ...props }, ref) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const inputRefs = React.useRef([]);

    const otpArr = React.useMemo(() => (value ? value.toString().split('') : []), [value]);

    React.useEffect(() => {
      if (autoFocus) {
        inputRefs.current[0]?.focus();
      }
    }, [autoFocus]);

    const focusInput = React.useCallback(
      (index) => {
        const activeInput = Math.max(Math.min(numOfDigits - 1, index), 0);

        if (inputRefs.current[activeInput]) {
          inputRefs.current[activeInput].focus();
          inputRefs.current[activeInput].select();
          setActiveIndex(activeInput);
        }
      },
      [numOfDigits]
    );

    const handleChange = (event) => {
      const { value } = event.target;

      // Focus next input
      if (isValidInputValue(value)) {
        changeValueAtFocus(value);
        focusInput(activeIndex + 1);
      }
    };

    const handleKeyDown = (event) => {
      if ([event.code, event.key].includes('Backspace')) {
        event.preventDefault();
        changeValueAtFocus('');
        focusInput(activeIndex - 1);
      } else if (event.code === 'Delete') {
        event.preventDefault();
        changeValueAtFocus('');
      } else if (event.code === 'ArrowLeft') {
        event.preventDefault();
        focusInput(activeIndex - 1);
      } else if (event.code === 'ArrowRight') {
        event.preventDefault();
        focusInput(activeIndex + 1);
      }
      // React does not trigger onChange when the same value is entered
      // again. So we need to focus the next input manually in this case.
      else if (event.key === otpArr[activeIndex]) {
        event.preventDefault();
        focusInput(activeIndex + 1);
      } else if (
        event.code === 'Spacebar' ||
        event.code === 'Space' ||
        event.code === 'ArrowUp' ||
        event.code === 'ArrowDown'
      ) {
        event.preventDefault();
      }
    };

    const handleFocus = (index) => {
      inputRefs.current[index].select();
      setActiveIndex(index);
    };

    const changeValueAtFocus = (value) => {
      otpArr[activeIndex] = value;
      onChange(otpArr.join(''));
    };

    const handlePaste = (event) => {
      event.preventDefault();

      let nextActiveIndex = activeIndex;

      // Get pastedData in an array of max size (num of inputs - current position)
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, numOfDigits - activeIndex)
        .split('');

      // Prevent pasting if the clipboard data contains non-numeric values for number inputs
      if (pastedData.some((value) => isNaN(Number(value)))) {
        return;
      }

      // Paste data from focused input onwards
      for (let pos = 0; pos < numOfDigits; ++pos) {
        if (pos >= activeIndex && pastedData.length > 0) {
          otpArr[pos] = pastedData.shift() ?? '';
          nextActiveIndex++;
        }
      }

      focusInput(nextActiveIndex);
      onChange(otpArr.join(''));
    };

    // Expose focus method to parent
    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => focusInput(0),
      }),
      [focusInput]
    );

    return (
      <div className={classNames('otp-input', className)}>
        {Array.from({ length: numOfDigits }).map((_, index) => (
          <React.Fragment key={index}>
            <input
              className="otp-digit"
              type="number"
              autoComplete="off"
              inputMode="numeric"
              maxLength={1}
              aria-label={`Please enter OTP character ${index + 1}`}
              value={otpArr[index] || ''}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={() => handleFocus(index)}
              onPaste={handlePaste}
              ref={(elm) => (inputRefs.current[index] = elm)}
              {...props}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }
);

function isValidInputValue(value) {
  return value.trim().length === 1;
}
