import { useQuery } from '@tanstack/react-query';
import { ConformityLocationApi } from 'api';

/**
 * Custom React Query hook for fetching a conformity location by id.
 *
 * @see {@link ConformityLocationApi.get}
 *
 * @param {number} id
 * @param {{ onError?: (error: Error) => void  }} callbacks
 * @returns
 */
export function useGetConformityLocationById(id, { onError } = {}) {
  return useQuery({
    queryKey: ['conformity-location', id],
    queryFn: () =>
      ConformityLocationApi.get(id)
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
  });
}
