import React from 'react';
import ReactAvatar from 'react-avatar';

export const Avatar = ({
  src,
  name,
  size = 'md',
  round,
  maxInitials,
  textSizeRatio,
  className,
  ...props
}) => {
  return (
    <ReactAvatar
      className={className}
      src={src}
      name={name}
      size={size}
      round={round}
      maxInitials={maxInitials}
      textSizeRatio={textSizeRatio}
      {...props}
    />
  );
};
