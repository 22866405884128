import React, { useEffect, useMemo, useState } from 'react';

import { CommonApi } from '../../../../api';
import { CustomSelect, NOTIFICATION_TYPE, Notification } from '../../../../components';
import { US_PORT_OF_ARRIVAL } from '../../../../constants/common';

const PortArrivalCodeSelect = (props) => {
  const [portList, setPortList] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    return portList
      .sort((a, b) => b.usage_count - a.usage_count)
      .map((item) => ({
        label: `${item.name} (${item.value})`,
        value: item.id,
      }));
  }, [portList]);

  useEffect(() => {
    const loadPortList = async () => {
      setLoading(true);
      try {
        const res = await CommonApi.searchByTable(US_PORT_OF_ARRIVAL);
        setPortList(res.data);
        setLoading(false);
      } catch (e) {
        Notification(NOTIFICATION_TYPE.DANGER, e.message);
        setLoading(false);
      }
    };

    loadPortList();
  }, []);

  return (
    <CustomSelect
      {...props}
      id="us-port-of-arrival-code"
      isLoading={loading}
      label="Us Port of Arrival Code *"
      placeholder="Select Port of Arrival"
      options={options}
      isSearchable
    />
  );
};

export default PortArrivalCodeSelect;
