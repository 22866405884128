import Http from '../utilities/http';

export class CustomsBrokerApi {
  static search(query = {}) {
    return Http.get('/customs-broker', query);
  }

  static create(data) {
    return Http.post('/customs-broker', data);
  }

  static get() {
    return Http.get('/customs-broker/get');
  }
}
