import { Checkbox, Table as MuiTable, Paper, TableCell, TableContainer } from '@mui/material';
import React from 'react';

import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import TableHeader from './TableHeader';
import './style.scss';

export const Table = React.forwardRef(
  (
    {
      columns = [],
      data = [],
      pagination,
      sortOption = {},
      setSortOption,
      loading,
      checkable,
      onCheckRowChange,
      onCheckAllChange,
      onCellClick,
      renderCsvExporter,
    },
    ref
  ) => {
    const visibleColumns = columns.filter((column) => !column.hidden);

    const [checkedIds, setCheckedIds] = React.useState(new Set());

    // Expose a method to reset the rows check
    React.useImperativeHandle(
      ref,
      () => ({
        resetRowsCheck: () => {
          setCheckedIds(new Set());
        },
      }),
      []
    );

    const onSortChange = (property) => {
      const isAsc = sortOption.orderBy === property && sortOption.order === 'asc';

      if (setSortOption) {
        setSortOption({
          order: isAsc ? 'desc' : 'asc',
          orderBy: property,
        });
      }
    };

    const handleToggleCheckAll = (e) => {
      if (e.target.checked) {
        setCheckedIds(new Set(data.map((item) => item.id)));
      } else {
        setCheckedIds(new Set());
      }
    };

    const handleToggleCheckRow = (id) => {
      if (checkedIds.has(id)) {
        checkedIds.delete(id);
        setCheckedIds(new Set(checkedIds));
      } else {
        checkedIds.add(id);
        setCheckedIds(new Set(checkedIds));
      }
    };

    return (
      <Paper
        sx={{
          bgcolor: (theme) => theme.colors.dark_light,
          color: (theme) => theme.colors.white,
        }}
      >
        <TableContainer>
          <MuiTable
            sx={{
              minWidth: 650,
            }}
          >
            <TableHeader
              columns={visibleColumns}
              order={sortOption?.order}
              orderBy={sortOption?.orderBy}
              onSortChange={onSortChange}
              checkable={checkable}
              allChecked={
                checkedIds.size > 0 &&
                data.length === checkedIds.size &&
                data.every((item) => checkedIds.has(item.id))
              }
              onCheckAllChange={(e) => {
                handleToggleCheckAll(e);
                typeof onCheckAllChange === 'function' && onCheckAllChange(e);
              }}
            />
            <TableBody
              isLoading={loading}
              columns={visibleColumns}
              rows={data}
              checkable={checkable}
              renderCheckboxes={(row) => (
                <TableCell padding="checkbox" sx={{ border: 'none' }}>
                  <Checkbox
                    color="primary"
                    value={row.id}
                    onChange={() => {
                      handleToggleCheckRow(row.id);
                      typeof onCheckRowChange === 'function' && onCheckRowChange(row);
                    }}
                    checked={checkedIds.has(row.id)}
                    data-testid={`checkbox-${row.id}`}
                    sx={{ color: (theme) => theme.colors.white }}
                  />
                </TableCell>
              )}
              pagination={pagination}
              onCellClick={onCellClick}
            />
          </MuiTable>
        </TableContainer>
        <TableFooter
          pagination={pagination}
          otherContent={
            <>
              {checkable && data.length > 0 && <div>{checkedIds.size} rows selected</div>}
              {data.length > 0 && typeof renderCsvExporter === 'function'
                ? renderCsvExporter(
                    columns,
                    checkable ? data.filter((row) => checkedIds.has(row.id)) : data
                  )
                : null}
            </>
          }
        />
      </Paper>
    );
  }
);
