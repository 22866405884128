import { VEHICLE_STATUS } from 'constants/vehicle';
import React from 'react';
import { Edit2 } from 'react-feather';
import { formatDate } from 'utilities';

import { IconButton, VehicleProfileColumn } from 'components';

const TimelineColumn = ({ vehicle, timeline, className }) => {
  return (
    <VehicleProfileColumn title="Vehicle timeline" className={className}>
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
              <th scope="col" style={{ textAlign: 'center' }}>
                {' '}
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            <>
              {timeline.map((tl) => (
                <tr key={tl.id}>
                  <td>{tl.item}</td>
                  <td>{formatDate(tl.date, 'MM/DD/YYYY')}</td>
                  <td>
                    <IconButton icon={<Edit2 />} />
                  </td>
                </tr>
              ))}
              {/* Display notes if vehicle is active */}
              {vehicle?.status !== VEHICLE_STATUS[0] && (
                <tr>
                  <td>Notes</td>
                  <td>{vehicle.notes || '-'}</td>
                  <td>
                    <IconButton icon={<Edit2 />} />
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
      </div>
    </VehicleProfileColumn>
  );
};

export default TimelineColumn;
