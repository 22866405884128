import React, { useState } from 'react';

import SearchContextProvider from 'features/vehicles/contexts/SearchContext';
import ShareVehiclesModal from 'features/vehicles/share-vehicles/ShareVehiclesModal';
import { SubmitToBrokerModal } from 'features/vehicles/submit-to-broker/SubmitToBrokerModal';

import GenerateNHTSAPackageModal from './GenerateNHTSAPackageModal/GenerateNHTSAPackageModal';
import MultiRILabelModal from './MultiRILabelModal';
import VehicleInfoActions from './VehicleInfoActions';
import VehicleTable from './VehicleTable';

export default function VehicleInfo() {
  const [vehicleListToSubmit, setVehicleListToSubmit] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const [generateNHTSAPackageModal, setGenerateNHTSAPackageModal] = useState({
    open: false,
    isResubmission: false,
  });
  const [shareModal, setShareModal] = useState(false);
  const [printRILabelModal, setPrintRILabelModal] = useState(false);

  const tableRef = React.useRef(null);

  const onCheckAllChange = (vehicleList) => {
    if (allChecked) {
      setCheckedIds([]);
      setVehicleListToSubmit([]);
    } else {
      setCheckedIds(vehicleList.map((item) => item.id));
      setVehicleListToSubmit(vehicleList);
    }
    setAllChecked(!allChecked);
  };

  const onCheckRowChange = (vehicle) => {
    if (checkedIds.includes(vehicle.id)) {
      const newCheckedIds = checkedIds.filter((item) => item !== vehicle.id);

      if (newCheckedIds.length === 0) {
        setAllChecked(false);
      }

      setCheckedIds(newCheckedIds);
      const newVehicles = vehicleListToSubmit.filter((v) => v.id !== vehicle.id);
      setVehicleListToSubmit(newVehicles);
    } else {
      setCheckedIds([...checkedIds, vehicle.id]);
      setVehicleListToSubmit([...vehicleListToSubmit, vehicle]);
    }
  };

  return (
    <div className="row">
      <div className="col">
        <h2 className="mb-5">Vehicles</h2>
        <SearchContextProvider>
          <VehicleInfoActions
            checkedIds={checkedIds}
            vehicleListToSubmit={vehicleListToSubmit}
            setCheckedIds={setCheckedIds}
            setSubmitModal={setSubmitModal}
            setShareModal={setShareModal}
            setGenerateNHTSAPackageModal={setGenerateNHTSAPackageModal}
            setPrintRILabelModal={setPrintRILabelModal}
          />

          <ShareVehiclesModal
            vehicleList={vehicleListToSubmit}
            checkedVehicleIds={checkedIds}
            show={shareModal}
            handleClose={() => setShareModal(false)}
          />

          <SubmitToBrokerModal
            vehicleListToSubmit={vehicleListToSubmit}
            setVehicleListToSubmit={setVehicleListToSubmit}
            show={submitModal}
            handleClose={() => setSubmitModal(false)}
            onSuccess={() => {
              setCheckedIds([]);
              setVehicleListToSubmit([]);
              tableRef?.current?.resetRowsCheck();
            }}
          />

          <GenerateNHTSAPackageModal
            vehicleList={vehicleListToSubmit}
            checkedIds={checkedIds}
            show={generateNHTSAPackageModal.open}
            isResubmission={generateNHTSAPackageModal.isResubmission}
            onClose={() =>
              setGenerateNHTSAPackageModal((prevState) => ({ ...prevState, open: false }))
            }
          />
          <MultiRILabelModal
            show={printRILabelModal}
            onClose={() => setPrintRILabelModal(false)}
            checkedVehicles={vehicleListToSubmit}
          />
          <div className="mt-4">
            <VehicleTable
              ref={tableRef}
              onCheckRowChange={onCheckRowChange}
              onCheckAllChange={onCheckAllChange}
            />
          </div>
        </SearchContextProvider>
      </div>
    </div>
  );
}
