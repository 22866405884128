import classNames from 'classnames';
import React from 'react';

import './styles.scss';

/**
 * @typedef {Object} UnstyledButtonProps
 * @property {string} [className]
 * @property {React.ReactNode} children
 */

/**
 *
 * @param {UnstyledButtonProps & React.HTMLAttributes<HTMLButtonElement>} props
 * @returns {JSX.Element}
 */
const UnstyledButton = ({ children, className, ...props }) => {
  return (
    <button className={classNames(`unstyled-btn ${className}`)} {...props}>
      {children}
    </button>
  );
};

export default UnstyledButton;
