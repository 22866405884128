import { Checkbox } from '@mui/material';
import React from 'react';

import './style.scss';

const VehicleRecalls = ({
  isCheckBox = false,
  checkedAll,
  handleCheckedAll,
  handleChecked,
  vehicleRecalls,
  loading = false,
}) => {
  return (
    <div className="table-responsive vehicle-recalls-container" data-testid="vehicle-recalls">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            {isCheckBox && (
              <th scope="col">
                <Checkbox
                  sx={{
                    color: 'white',
                  }}
                  disabled={loading}
                  checked={checkedAll}
                  onChange={handleCheckedAll}
                  inputProps={{ 'data-testid': 'checkall-vehicle-recalls' }}
                />
              </th>
            )}
            <th scope="col">Manufacturer</th>
            <th scope="col">Model</th>
            <th scope="col">Make</th>
            <th scope="col">Model Year</th>
            <th scope="col">NHTSA Campaign Number</th>
            <th scope="col">Park IT</th>
            <th scope="col">Park Outside</th>
            <th scope="col">NHTSA Action Number</th>
            <th scope="col">Report Received Date</th>
            <th scope="col">Component</th>
          </tr>
        </thead>
        <tbody>
          {vehicleRecalls?.length === 0 ? (
            <tr className="text-center"></tr>
          ) : (
            vehicleRecalls.map((item, index) => (
              <tr className="position-relative" key={item.id ? item.id : index}>
                {isCheckBox && (
                  <td>
                    <Checkbox
                      sx={{
                        color: 'white',
                      }}
                      checked={item.isChecked}
                      disabled={loading}
                      onChange={() => handleChecked(index)}
                      inputProps={{ 'data-testid': `check-vehicle-recall-${item.id}` }}
                    />
                  </td>
                )}
                <td>{item.Manufacturer}</td>
                <td>{item.Model}</td>
                <td>{item.Make}</td>
                <td>{item.ModelYear}</td>
                <td>{item.NHTSACampaignNumber}</td>
                <td>{item.parkIt?.toString()}</td>
                <td>{item.parkOutSide?.toString()}</td>
                <td>{item.NHTSAActionNumber}</td>
                <td>{item.ReportReceivedDate}</td>
                <td>{item.Component}</td>
                {item.isChecked && <td className="strike" />}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleRecalls;
