import { Drawer as MuiDrawer } from '@mui/material';
import Logo from 'assets/images/vin2_logo.svg';
import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';

import Sidebar from '../Sidebar';
import './styles.scss';

const MobileSidebar = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="indicator-wrapper" onClick={() => setShow(true)} data-testid="indicator-icon">
        <ChevronRight className="indicator-icon" />
      </div>

      <MuiDrawer anchor="left" open={show} onClose={() => setShow(false)}>
        <div className="mobile-sidebar">
          <div className="d-flex-center mb-3">
            <img className="logo-img" src={Logo} alt="logo" />
          </div>

          <div className="sidebar-menus-wrapper">
            <Sidebar onLinkCallback={() => setShow(false)} />
          </div>
        </div>
      </MuiDrawer>
    </>
  );
};

export default MobileSidebar;
