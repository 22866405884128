import colors from './colors';

const components = {
  MuiDialogContent: {
    styleOverrides: {
      root: {
        background: colors.dark,
        color: colors.white,
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        background: colors.dark,
      },
    },
  },

  MuiDrawer: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          background: colors.dark_light,
        },
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          background: colors.dark,
        },

        '& .MuiList-root': {
          background: colors.dark,
          color: colors.white,
        },
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:nth-of-type(2n + 1)': {
          background: colors.dark,
        },

        '&:last-child td, &:last-child th': { border: 0 },
      },

      head: {
        '&:nth-of-type(2n + 1)': {
          background: colors.dark_light,
        },
      },
    },
  },

  MuiTableCell: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      head: {
        color: colors.white,
        fontWeight: 'bold',
        border: 'none',
      },
      root: {
        color: colors.white,
        border: 'none',
      },
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      root: {
        borderTop: `1px solid ${colors.dark}`,
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.grey_1,
      },
    },
  },

  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.grey_1,
      },
    },
  },
};

export default components;
