import clsx from 'clsx';
import { Droppable } from 'react-beautiful-dnd';
import ScrollContainer from 'react-indiana-drag-scroll';

import './styles.scss';

export function DroppableZone({
  droppableId,
  type,
  direction = 'horizontal',
  className,
  children,
}) {
  return (
    <Droppable droppableId={droppableId} type={type} direction={direction}>
      {(provided, snapshot) => {
        return (
          <ScrollContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={clsx('dropper', {
              dropOver: snapshot.isDraggingOver && snapshot.draggingFromThisWith === null,
            })}
            hideScrollbars={false}
          >
            <div
              className={clsx('items-list align-items-center', className, {
                'is-dragging': snapshot.isDraggingOver,
              })}
            >
              {children}
              {provided.placeholder}
            </div>
          </ScrollContainer>
        );
      }}
    </Droppable>
  );
}
