import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';

import { Button } from 'components/Button';

export function CsvExportButton({
  data = [],
  columns,
  filename = 'data.csv',
  children,
  className,
}) {
  return (
    <CSVLink
      className={className}
      data={data}
      headers={columns
        .filter((column) => column.dataIndex)
        .map((column) => ({
          label: column.title,
          key: column.dataIndex,
        }))}
      filename={filename}
    >
      <Button
        variant="link"
        disabled={data.length === 0}
        title={data.length === 0 ? 'Select at least one item to export' : undefined}
        className="d-flex gap-2"
      >
        <Download size={20} />
        {children}
      </Button>
    </CSVLink>
  );
}
