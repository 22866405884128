import React from 'react';

import ExportersTable from 'features/sellers/components/table/ExportersTable';

const ListExporters = () => {
  return (
    <>
      <h2 className="mb-4">Sellers (Exporters)</h2>
      <ExportersTable />
    </>
  );
};

export default ListExporters;
