import Http from '../utilities/http';

export class NHTSAPackageApi {
  static search(query = {}) {
    return Http.get('/nhtsa-packages', query).then((res) => res.data);
  }

  static getPackage(id) {
    return Http.get(`/nhtsa-packages/${id}`).then((res) => res.data);
  }
}
