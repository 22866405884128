import { ROUTES } from 'constants/routes';
import { getFullAddress } from 'helpers/address';
import React from 'react';
import { Edit, List } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Loading } from 'components';
import { useGetTransporter } from 'features/carriers/hooks/useGetCarrier';

export function ViewCarrier() {
  const { id } = useParams();
  const { data: carrier, isLoading, error } = useGetTransporter(id);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h2>Carrier Details</h2>
        <div className="d-flex gap-4">
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CARRIERS}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <List size={20} />
            List
          </Link>
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CARRIERS}/edit/${id}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <Edit size={20} />
            Edit
          </Link>
        </div>
      </div>
      <hr />
      <h5>Company Name</h5>
      <p>{carrier?.companyName}</p>
      <h5>Company Phone</h5>
      <p>{carrier?.companyPhone || '-'}</p>
      <h5>Contact Person</h5>
      <p>{`${carrier?.user?.firstName} ${carrier?.user?.lastName}`}</p>
      <h5>Contact Email</h5>
      <p>{carrier?.user?.email}</p>
      <h5>Contact Phone</h5>
      <p>{carrier?.user?.phoneNumber}</p>
      <h5>Contact Address</h5>
      <p>
        {getFullAddress([
          carrier?.user?.street1,
          carrier?.user?.city1,
          carrier?.user?.state1,
          carrier?.user?.zip1,
          carrier?.user?.country1,
        ])}
      </p>
      <h5>SCAC Code</h5>
      <p>{carrier?.SCACCode || '-'}</p>
      <h5>Vehicle License Plate Number</h5>
      <p>{carrier?.plate || '-'}</p>
      <h5>Transport Truck License Plate Number</h5>
      <p>{carrier?.truckPlate || '-'}</p>
      <h5>Trailer License Plate Number</h5>
      <p>{carrier?.trailerPlate || '-'}</p>
      <h5>Drivers License Number</h5>
      <p>{carrier?.driversLicense || '-'}</p>
      <h5>Passport Number</h5>
      <p>{carrier?.passport || '-'}</p>
      <h5>Fast ID</h5>
      <p>{carrier?.fastId || '-'}</p>
      <h5>Organization Code</h5>
      <p>{carrier?.user?.orgCode || '-'}</p>
    </div>
  );
}
