import { PhotoCategoryApi } from 'api';
import React from 'react';

import { NOTIFICATION_TYPE, Notification, VehicleProfileColumn } from 'components';

import PhotoUpload from '../../PhotoUpload';
import { UploadedPhotos } from './UploadedPhotos';

const PhotosColumn = ({ vehicle, photos, categories, onUpdatePhoto, onSetPhoto, className }) => {
  const handleUpdateCategory = (category) => {
    PhotoCategoryApi.update(category.id, {
      isRequired: true,
    })
      .then(() => {
        const updatedPhoto = photos.find((d) => d.category.id === category.id);
        onUpdatePhoto({
          ...updatedPhoto,
          category: {
            ...updatedPhoto.category,
            isRequired: true,
          },
        });
        Notification(
          NOTIFICATION_TYPE.SUCCESS,
          `Successfully move "${category.name}" to NHTSA package.`
        );
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      });
  };

  return (
    <VehicleProfileColumn title="Photos" className={className}>
      <PhotoUpload vehicle={vehicle} photos={photos} category={categories} />
      <hr />
      {vehicle.vin && (
        <UploadedPhotos
          photos={photos}
          onUpdateCategory={handleUpdateCategory}
          onSetPhoto={onSetPhoto}
        />
      )}
    </VehicleProfileColumn>
  );
};

export default PhotosColumn;
