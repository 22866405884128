import { Dialog, DialogContent } from '@mui/material';

import MultiRILabel from '../../VehicleRILabel/RILabel';

const MultiRILabelModal = ({ show, onClose, checkedVehicles }) => {
  return (
    <Dialog open={show} onClose={onClose} title="Print RI Label" fullWidth={true} maxWidth={'xl'}>
      <DialogContent sx={{ py: 7.5, height: '80vh' }}>
        <MultiRILabel vehicles={checkedVehicles} />
      </DialogContent>
    </Dialog>
  );
};

export default MultiRILabelModal;
