import { ROUTES } from 'constants/routes';
import React from 'react';
import { Edit, List } from 'react-feather';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Loading, NOTIFICATION_TYPE, Notification } from 'components';
import { SkeletonRows } from 'components/SkeletonRows/SkeletonRows';
import { ConformityLocationDetails } from 'features/conformity-locations/components/ConformityLocationDetails';
import { useGetConformityLocationById } from 'features/conformity-locations/hooks/useGetConformityLocationById';

export function ViewConformityLocation() {
  const { id } = useParams();

  const {
    data: conformityLocation,
    isLoading,
    isFetching,
    isError,
  } = useGetConformityLocationById(id, {
    onError: (error) => Notification(NOTIFICATION_TYPE.DANGER, error.message),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h2>Conformity Location Details</h2>
        <div className="d-flex gap-4">
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CONFORMITY_LOCATIONS}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <List size={20} />
            List
          </Link>
          <Link
            to={`${ROUTES.MAIN.USER_SETTINGS.CONFORMITY_LOCATIONS}/edit/${id}`}
            className="text-white d-flex align-items-center gap-2"
          >
            <Edit size={20} />
            Edit
          </Link>
        </div>
      </div>
      <hr />
      {isFetching && <SkeletonRows rowsCount={7} rowHeight={60} />}
      {!isFetching && !isError && (
        <ConformityLocationDetails conformityLocation={conformityLocation} />
      )}
    </div>
  );
}
