import { AuthApi } from 'api';
import { ROUTES } from 'constants/routes';
import { useFormik } from 'formik';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Input, NOTIFICATION_TYPE, Notification } from 'components';
import { AppLogo } from 'components/AppLogo/AppLogo';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';

import './styles.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required Field').email('Invalid Email'),
  password: Yup.string().required('Required Field'),
});

const Login = () => {
  const { setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isForgotModal, setIsForgotModal] = useState(false);

  const onSubmit = (values) => {
    const { email, password } = values;
    setLoading(true);

    AuthApi.login({ email, password })
      .then(({ data }) => {
        setUser(data);
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
  });

  const sendMail = (e) => {
    e.preventDefault();

    if (!form.values.email) {
      form.setFieldTouched('email', true, true);
      return;
    }

    setLoading(true);
    AuthApi.ResetPassword({ email: form.values.email })
      .then(({ data }) => {
        Notification(NOTIFICATION_TYPE.SUCCESS, data.message);
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err.message);
      })
      .finally(() => {
        setLoading(false);
        setIsForgotModal(false);
      });
  };
  return (
    <div className="login-form">
      <form onSubmit={form.handleSubmit}>
        <AppLogo className="mb-4 mx-auto d-block" />
        <h1 className="h3 mb-5 fw-normal text-center">Sign In</h1>
        <Input
          className="mb-4"
          label="Email Address"
          placeholder="name@example.com"
          data-testid="email"
          hasBorder
          {...form.getFieldProps('email')}
          error={form.errors.email && form.touched.email ? form.errors.email : ''}
        />
        <PasswordInput
          className="mb-4"
          label="Password"
          placeholder="Password"
          hasBorder
          {...form.getFieldProps('password')}
          error={form.errors.password && form.touched.password ? form.errors.password : ''}
          data-testid="password"
        />
        <div className={'d-flex justify-content-between mb-4'}>
          <div className="form-check">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" value="remember-me" /> Remember me
            </label>
          </div>

          <label className="cursor-pointer" onClick={() => setIsForgotModal(true)}>
            Forgot password?
          </label>
        </div>
        <div className="d-flex justify-content-end">
          <Button type="submit" className="w-50" loading={loading}>
            Sign In
          </Button>
        </div>
        <div className="text-center mt-3">
          Don't you have account? Please&nbsp;<Link to={ROUTES.AUTH.REGISTER}>sign up.</Link>
        </div>
        <p className="mt-3 mb-3 text-muted text-center">© 2017–{new Date().getFullYear()}</p>
      </form>

      <Modal show={isForgotModal} onHide={() => setIsForgotModal(false)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            className="mb-4"
            label="Email Address"
            placeholder="name@example.com"
            hasBorder
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />

          <div className="d-flex justify-content-center gap-3">
            <Button loading={loading} onClick={sendMail}>
              Send
            </Button>

            <Button disabled={loading} onClick={() => setIsForgotModal(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
