export const VEHICLE_TYPES = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Initiation',
    value: 'Initiation',
  },
  {
    label: 'Launchpad',
    value: 'Launchpad',
  },
  {
    label: 'Submission',
    value: 'Submission',
  },
  {
    label: 'Compliance',
    value: 'Compliance',
  },
  {
    label: 'Vaulted',
    value: 'Vaulted',
  },
  {
    label: 'Resubmission',
    value: 'Resubmission',
  },
];

export const VEHICLE_STATUS = [
  'Initiation',
  'Launchpad',
  'Submission',
  'Compliance',
  'Vaulted',
  'Resubmission',
];

export const ENTRY_STATUS = {
  submitted: 'SUBMITTED',
  success: 'SUCCESS',
  failed: 'FAILED',
};
