import './styles.scss';

/**
 * @typedef {Array<{title: string, alias?: string, dataIndex: string, isRequired?: boolean}>} CsvImportFields
 */

/**
 * @typedef {Object} CsvImportFieldsProps
 * @property {CsvImportFields} fields
 * @property {string} [className]
 */

/**
 * @param {{fields: CsvImportFields}} props
 */
export function CsvImportFields({ fields, className }) {
  return (
    <div className={className}>
      <h6>Expected fields (Required fields are marked with *)</h6>
      <ul className="expected-fields">
        {fields.map((field, index) => (
          <li key={index} className="expected-fields__item">
            {`${field.title}${field.alias ? `/${field.alias}` : ''}${field.isRequired ? '*' : ''}`}
          </li>
        ))}
      </ul>
    </div>
  );
}
