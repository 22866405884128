import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { hasPermission } from 'services/auth';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import { ExporterForm } from 'features/sellers/components/form/ExporterForm';
import { CreateDataModal } from 'features/vehicles/components/modals/CreateDataModal';

import { DataSelect } from '../components/DataSelect';

export function ExporterInfo({ form, lastSaved, setLastSaved }) {
  const { user } = React.useContext(AuthContext);

  const [showExporterModal, setShowExporterModal] = React.useState(false);

  const onSuccess = (data) => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Exporter was created successfully');

    setLastSaved({ Exporter: data });
    setShowExporterModal(false);
  };

  const onError = (err) => {
    Notification(NOTIFICATION_TYPE.DANGER, err.message);
  };

  return (
    <>
      <div className="col-7 col-md-6">
        <DataSelect
          apiFieldName="Exporter"
          label="Exporter *"
          placeholder="Select Exporter"
          lastSaved={lastSaved}
          isCompanyNameSelect
          form={form}
          error={
            form.errors.exporter_code && form.touched.exporter_code ? form.errors.exporter_code : ''
          }
        />
      </div>
      <div className="col-5 col-md-6">
        {hasPermission(user, { resource: 'seller', action: 'create' }) && (
          <>
            <Button type="button" onClick={() => setShowExporterModal(true)}>
              + Add
            </Button>
            <CreateDataModal
              title="Add Exporter"
              show={showExporterModal}
              handleClose={() => setShowExporterModal(false)}
            >
              <ExporterForm action="quick-create" onSuccess={onSuccess} onError={onError} />
            </CreateDataModal>
          </>
        )}
      </div>
    </>
  );
}
