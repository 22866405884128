import { USA_ALPHA_2 } from 'constants/common';
import { useFormik } from 'formik';
import { riNumberRegex, taxIDRegex } from 'helpers/validators';
import * as Yup from 'yup';

/**
 *
 * @param {import('@tanstack/react-query').UseMutationResult} mutation
 * @param {object} initialValues
 * @returns {[import('formik').FormikProps<any>, boolean]} [form, isLoading]
 */
export const useProfileSettingsForm = (mutation, initialValues) => {
  const validationSchema = Yup.object().shape({
    riCompanyName: Yup.string().label('Company Name').required(),
    riNumber: Yup.string()
      .label('RI Number')
      .required()
      .matches(riNumberRegex, 'Invalid RI Number format'),
    firstName: Yup.string().label('First Name').required(),
    lastName: Yup.string().label('Last Name').required(),
    designation: Yup.string().label('Designation').required(),
    email: Yup.string().label('Email').required().email('Invalid Email'),
    password: Yup.string().label('Password').min(8),
    passwordConfirm: Yup.string()
      .when('password', {
        is: (val) => val && val.length > 0,
        then: Yup.string()
          .oneOf([Yup.ref('password')], 'Passwords do not match')
          .required('Passwords do not match'),
      })
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
    mobileNumber: Yup.number().label('Mobile Number').typeError('should be number').required(),
    taxID: Yup.string().label('Tax ID').required().matches(taxIDRegex, 'Invalid Tax ID format'),
    orgCode: Yup.string(),
    customsBroker: Yup.number().label('Customs Broker').required(),
    customsLow: Yup.number().required().min(0, 'Require non-negative number'),
    customsHigh: Yup.number()
      .required()
      .min(Yup.ref('customsLow'), 'Custom Range must be greater or equal to lower value'),
    bondProvider: Yup.number().label('Bond Provider').required(),
    bondLow: Yup.number().label('Bond Low').required().min(0, 'Require non-negative number'),
    bondHigh: Yup.number()
      .label('Bond High')
      .required()
      .min(Yup.ref('bondLow'), 'Bond Range must be greater or equal to lower value')
      .max(999999, 'Bond Range must be less than or equal to 999999'),
    warrantyProvider: Yup.number().label('Warranty Provider').required(),
    warrantyLow: Yup.number()
      .label('Warranty Low')
      .required()
      .min(0, 'Require non-negative number'),
    warrantyHigh: Yup.number()
      .label('Warranty High')
      .required()
      .min(Yup.ref('warrantyLow'), 'Warranty Range must be greater or equal to lower value'),
    primaryAddress: Yup.object().shape({
      street: Yup.string().label('Street').required(),
      city: Yup.string().label('City').required(),
      state: Yup.string().label('State').required(),
      zip: Yup.string().label('Zip').required(),
      country: Yup.string().label('Country').required(),
    }),
    secondaryAddress: Yup.object().shape({
      street: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
      country: Yup.string(),
    }),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    for (const key in values) {
      if (
        key !== 'termsAgreed' &&
        key !== 'shouldUseExistingSignature' &&
        key !== 'newSignatureMode'
      ) {
        if (key === 'primaryAddress' || key === 'secondaryAddress') {
          for (const addressKey in values[key]) {
            formData.append(`${key}[${addressKey}]`, values[key][addressKey]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    }

    mutation.mutate(formData);
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      riCompanyName: initialValues?.registeredImporter?.companyName || '',
      riNumber: initialValues?.registeredImporter?.RINumber || '',
      firstName: initialValues?.firstName || '',
      lastName: initialValues?.lastName || '',
      designation: initialValues?.designation || '',
      email: initialValues?.email || '',
      password: '',
      passwordConfirm: '',
      mobileNumber: initialValues?.mobileNumber || '',
      taxID: initialValues?.registeredImporter?.taxID || '',
      orgCode: initialValues?.orgCode || '',
      customsBroker: initialValues?.customsBrokerId || '',
      customsLow: initialValues?.rangeSettings?.customsLow || 0,
      customsHigh: initialValues?.rangeSettings?.customsHigh || 0,
      bondProvider: initialValues?.bondProvider?.id || '',
      bondLow: initialValues?.rangeSettings?.bondLow || 0,
      bondHigh: initialValues?.rangeSettings?.bondHigh || 0,
      warrantyProvider: initialValues?.warrantyProvider?.id || '',
      warrantyLow: initialValues?.rangeSettings?.warrantyLow || 0,
      warrantyHigh: initialValues?.rangeSettings?.warrantyHigh || 0,
      primaryAddress: {
        street: initialValues?.primaryAddress?.street || '',
        city: initialValues?.primaryAddress?.city || '',
        state: initialValues?.primaryAddress?.state || '',
        zip: initialValues?.primaryAddress?.zip || '',
        country: USA_ALPHA_2,
      },
      secondaryAddress: {
        street: initialValues?.secondaryAddress?.street || '',
        city: initialValues?.secondaryAddress?.city || '',
        state: initialValues?.secondaryAddress?.state || '',
        zip: initialValues?.secondaryAddress?.zip || '',
        country: USA_ALPHA_2,
      },
      profilePhoto: null,
      termsAgreed: false,
      shouldUseExistingSignature: true,
      newSignatureMode: 'draw',
      signature: null,
    },
  });

  return [form, mutation.isLoading];
};
