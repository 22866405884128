import moment from 'moment';

export const formatDate = (date, format = 'yyyy-MM-dd') => {
  const dt = moment.utc(date);
  if (dt.isValid()) {
    return dt.format(format);
  }
  return '-';
};

export const titleCase = (str) =>
  str
    .replace(/(_|-)/g, ' ')
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

export const serialize = (data) => {
  let obj = {};

  for (let [key, value] of data) {
    if (obj[key] !== undefined) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }

  return obj;
};

export const convertToMiles = (value) => {
  return Math.round((value / 1.6 + Number.EPSILON) * 100) / 100;
};

export const convertToKM = (value) => {
  return Math.round((value * 1.6 + Number.EPSILON) * 100) / 100;
};

export const checkDuplicatePropertyValues = (array, property) => {
  const unique = new Set(array.map((item) => item[property]));

  if (unique.size === 1 && (unique.has(undefined) || unique.has(null))) {
    return false;
  }

  if (unique.size !== array.length) {
    return true;
  }

  return false;
};
