import { ROUTES } from 'constants/routes';
import { ObjectHelper } from 'helpers';
import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'components';
import { SearchBar } from 'features/common/search/SearchBar';

import { useGetNHTSAPackages } from './useGetNHTSAPackages';

const columns = [
  {
    title: 'Package Number',
    dataIndex: '',
    sortable: true,
    render: (item) => (
      <Link to={ROUTES.MAIN.NHTSA.DETAIL.replace(':id', item.id)} state={{ nhtsaPackage: item }}>
        {item.id}
      </Link>
    ),
  },
  {
    title: 'Vehicles',
    dataIndex: '',
    render: (item) => (
      <ul className="d-flex flex-column justify-content-between gap-2 list-unstyled mt-3">
        {item.vehicles.map((vehicle) => (
          <li key={vehicle.vin}>
            <Link to={ROUTES.MAIN.VEHICLES.PROFILE.replace(':id', vehicle.id)}>{vehicle.vin}</Link>
          </li>
        ))}
      </ul>
    ),
  },
  {
    title: 'Submission date',
    dataIndex: 'createdAt',
    render: (item) => new Date(item.createdAt).toLocaleDateString(),
  },
  {
    title: 'No. of Resubmissions',
    dataIndex: '',
    render: (item) => item.resubmissionPackages?.length || 0,
  },
];

export function NHTSAPackagesTable() {
  const [sortOption, setSortOption] = React.useState({
    order: 'desc',
    orderBy: 'createdAt',
  });
  const [pagination, setPagination] = React.useState({
    page: 0,
    perPage: 10,
  });
  const [searchTerm, setSearchTerm] = React.useState('');

  const queryObj = React.useMemo(() => {
    return ObjectHelper.trimQuery({
      ...pagination,
      ...sortOption,
      searchTerm: searchTerm,
    });
  }, [pagination, sortOption, searchTerm]);

  const { data, isLoading, isFetching, refetch } = useGetNHTSAPackages(queryObj);

  const handleChangePagination = React.useCallback((field, value) => {
    if (field === 'page') {
      setPagination((prev) => ({ ...prev, page: value }));
    } else if (field === 'perPage') {
      setPagination({ page: 0, perPage: value });
    }
  }, []);

  return (
    <>
      {/* <div className="row">
        <div className="col-sm-6 mb-4">
          <SearchBar fetch={refetch} onSearch={setSearchTerm} hasBorder={true} />
        </div>
        <div className="col-sm-6"></div>
      </div> */}
      <Table
        columns={columns}
        data={data?.data ?? []}
        loading={isFetching || isLoading}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: data?.totalCount || 0,
          page: pagination.page,
          perPage: pagination.perPage,
          onPageChange: (page) => handleChangePagination('page', page),
          onPerPageChange: (perPage) => handleChangePagination('perPage', perPage),
        }}
      />
    </>
  );
}
