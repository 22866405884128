import React from 'react';
import { dataURLToFile } from 'utilities/fileHelper';

import { SignaturePad } from 'components';
import { ImageDropzone } from 'components/ImageDropzone/ImageDropzone';

export function SignatureForm({ form, formControl, pad, setPad, onUploadSignature }) {
  const hasError = !!form.touched.signature && !!form.errors.signature;

  const isValidPad = form.values.newSignatureMode === 'draw' && !!pad && !pad.isEmpty();

  React.useEffect(() => {
    if (isValidPad) {
      form.setErrors({ signature: null });
      const signatureUrl = pad.toDataURL();
      const signature = dataURLToFile(signatureUrl, 'signature.png');
      form.setFieldValue('signature', signature);
    } else {
      form.setFieldValue('signature', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPad]);

  const handleBeginStroke = React.useCallback(() => {
    if (form.values.newSignatureMode === 'draw') {
      form.setFieldTouched('signature', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row mb-3">
      <div className="col-md-6">{formControl}</div>
      <div style={{ height: '200px' }}>
        {form.values.newSignatureMode === 'draw' && (
          <SignaturePad
            pad={pad}
            setPad={setPad}
            className={hasError ? 'border-red border-2' : ''}
            onBeginStroke={handleBeginStroke}
            onClear={() => form.setFieldValue('signature', null)}
          />
        )}
        {form.values.newSignatureMode === 'upload' && (
          <ImageDropzone
            placeholder="Drag and drop, or click the button to select a signature image file"
            className={hasError ? 'border-red border-2 rounded' : ''}
            onUploadFile={onUploadSignature}
          />
        )}
      </div>
      {hasError && <p className="text-red mt-1">{form.errors.signature}</p>}
    </div>
  );
}
