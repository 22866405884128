export function ConformityLocationDetails({ conformityLocation }) {
  return (
    <div>
      <h5>Title</h5>
      <p>{conformityLocation.title}</p>
      <h5>Company Phone</h5>
      <p>{conformityLocation.companyPhone}</p>
      <h5>Contact Person</h5>
      <p>{`${conformityLocation.contactFirstName} ${conformityLocation.contactLastName}`}</p>
      <h5>Contact Email</h5>
      <p>{conformityLocation.contactEmail}</p>
      <h5>Contact Phone</h5>
      <p>{conformityLocation.contactPhone}</p>
      <h5>Conformity Address</h5>
      <p>{`${conformityLocation.conformedStreet}, ${conformityLocation.conformedCity}, ${conformityLocation.conformedState}, ${conformityLocation.conformedZip}, ${conformityLocation.conformedCountry}`}</p>
      <h5>Inspection Address</h5>
      <p>{`${conformityLocation.inspectedStreet}, ${conformityLocation.inspectedCity}, ${conformityLocation.inspectedState}, ${conformityLocation.inspectedZip}, ${conformityLocation.inspectedCountry}`}</p>
    </div>
  );
}
