import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConsigneesApi } from 'api';

/**
 * Custom React Query hook to delete a consignee
 *
 * @param {{ onSuccess?: () => void; onError?: (error: Error) => void }} options
 * @returns {import("@tanstack/react-query").UseMutationResult}
 */
export function useDeleteConsignee({ onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => ConsigneesApi.remove(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['consignees']);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
