import Http from '../utilities/http';

export class AuthApi {
  static login(data) {
    return Http.post('/auth/login', data);
  }

  static requestOtp(data) {
    return Http.post('/auth/request-otp', data);
  }

  static register(data) {
    return Http.post('/auth/register', data);
  }

  static updateUser(data) {
    return Http.put('/auth/user', data);
  }

  static getSession() {
    return Http.get('/auth/session');
  }

  static getPermissions() {
    return Http.get('/auth/permissions');
  }

  static logout() {
    return Http.post('/auth/logout');
  }

  static ResetPassword(data) {
    return Http.post('/auth/password-reset', data);
  }

  static ChangePassword(token, data) {
    return Http.post(`/auth/change-password/${token}`, data);
  }
}
