import { createTheme } from '@mui/material/styles';

import colors from './colors';
import components from './components';

const theme = {
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
  },
  colors,
  components,
  spacing: (factor) => `${0.25 * factor}rem`,
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Helvetica'
  }
};

export default createTheme(theme);
