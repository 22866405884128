import classnames from 'classnames';
import React from 'react';

import './styles.scss';

export const Button = ({
  type = 'button',
  className = '',
  variant = 'standard',
  children,
  loading = false,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={classnames(`custom-btn ${className}`, {
        'link-btn': variant === 'link',
        'dark-btn': variant === 'dark',
        loading: loading,
      })}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <div className="spinner-border" data-testid="loading-indicator" />}

      {children}
    </button>
  );
};
