import { ConfirmModal } from 'components';

export function ChangeCategoryConfirmModal({
  show,
  onClose,
  type = 'document',
  categoryName,
  isCategoryRequired,
  onConfirm,
}) {
  return (
    <ConfirmModal
      show={show}
      handleClose={onClose}
      title={`Move ${type} to ${isCategoryRequired ? 'additional' : 'required'} NHTSA ${type}s`}
      content={`You are moving "${categoryName}" ${
        isCategoryRequired ? 'out of NHTSA' : 'to be sent to NHTSA'
      }. Do you want to proceed?`}
      onConfirm={onConfirm}
    />
  );
}
