import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { useCreateUsers } from '../hooks/useCreateUsers';
import { useUpdateUser } from '../hooks/useUpdateUser';

export default function useImporterForm(action = 'create', initialValues = {}, onSuccess, onError) {
  const { mutate: createUser, isLoading: isCreating } = useCreateUsers('single', {
    onSuccess,
    onError,
  });
  const { mutate: updateUser, isLoading: isUpdating } = useUpdateUser(initialValues.id, {
    onSuccess,
    onError,
  });

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().label('First Name').required(),
        lastName: Yup.string().label('Last Name').required(),
        email: Yup.string().label('Email').email('Invalid email address').required(),
        password: Yup.string()
          .label('Password')
          .when([], {
            is: () => action === 'create',
            then: Yup.string().min(8).required(),
          }),
        phoneNumber: Yup.number().label('Phone Number').typeError('should be number').required(),
        location: Yup.number().label('Location').required(),
      }),
    [action]
  );

  const onSubmit = (values) => {
    if (action === 'create' || action === 'quick-create') {
      createUser(values);
    } else if (action === 'edit') {
      updateUser(values);
    }
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      firstName: initialValues.user?.firstName || '',
      lastName: initialValues.user?.lastName || '',
      email: initialValues.user?.email || '',
      phoneNumber: initialValues.user?.phoneNumber || '',
      location: initialValues.conformityLocationId || '',
      password: '',
      permissionIds: initialValues.user?.permissions?.map((p) => p.id) || [],
    },
  });

  return [form, isCreating || isUpdating];
}
