import { VehicleApi } from 'api';
import React, { useEffect, useState } from 'react';

import { NOTIFICATION_TYPE, Notification, VehicleProfileColumn } from 'components';

import VehicleRecalls from './VehicleRecalls';

const RecallsColumn = ({ vehicle, setRecallCheckedAll, className }) => {
  const [vehicleRecalls, setVehicleRecalls] = useState(vehicle?.recalls || []);
  const [updateStatusRecall, setUpdateStatusRecall] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    setRecallCheckedAll(true);
    setCheckedAll(true);
    for (const vr of vehicleRecalls) {
      if (!vr.isChecked) {
        setRecallCheckedAll(false);
        setCheckedAll(false);
        break;
      }
    }
  }, [setRecallCheckedAll, vehicleRecalls]);

  const handleChecked = async (index) => {
    const _vehicleRecalls = [...vehicleRecalls];
    const recall = _vehicleRecalls[index];
    if (recall) {
      setUpdateStatusRecall(true);
      try {
        const { data } = await VehicleApi.updateStatusRecall(vehicle.id, { id: recall.id });
        recall.isChecked = data.isChecked;
        setVehicleRecalls([..._vehicleRecalls]);
        setUpdateStatusRecall(false);
      } catch (error) {
        Notification(NOTIFICATION_TYPE.DANGER, error.message);
        setUpdateStatusRecall(false);
      }
    }
  };

  const handleCheckedAll = async () => {
    setUpdateStatusRecall(true);
    try {
      await VehicleApi.updateStatusRecall(vehicle.id, { checkedAll: !checkedAll });
      const data = [...vehicleRecalls];
      for (const d of data) {
        d.isChecked = !checkedAll;
      }
      setVehicleRecalls(data);
      setCheckedAll(!checkedAll);
      setUpdateStatusRecall(false);
    } catch (error) {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
      setUpdateStatusRecall(false);
    }
  };

  return (
    <VehicleProfileColumn title="Recalls" className={className}>
      <VehicleRecalls
        loading={updateStatusRecall}
        isCheckBox={true}
        vehicleRecalls={vehicleRecalls}
        checkedAll={checkedAll}
        handleCheckedAll={handleCheckedAll}
        handleChecked={handleChecked}
      />
    </VehicleProfileColumn>
  );
};

export default RecallsColumn;
