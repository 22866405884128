import React from 'react';
import { useNavigate } from 'react-router';

import { BackButton, NOTIFICATION_TYPE, Notification } from 'components';
import { ConformityLocationForm } from 'features/conformity-locations/components/form/ConformityLocationForm';
import { useCreateConformityLocation } from 'features/conformity-locations/hooks/useCreateConformityLocation';

export function AddConformityLocation() {
  const navigate = useNavigate();

  const mutation = useCreateConformityLocation({
    onSuccess: () => {
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Conformity Location was created successfully');
      navigate(-1);
    },
    onError: (error) => Notification(NOTIFICATION_TYPE.ERROR, error.message),
  });

  return (
    <div>
      <BackButton />
      <h2 className="mb-4">Add Conformity Location</h2>
      <ConformityLocationForm mutation={mutation} />
    </div>
  );
}
