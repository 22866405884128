import React from 'react';
import classnames from 'classnames';
import './styles.scss';

export const TextArea = ({
  label,
  className = '',
  error = '',
  hasBorder = false,
  rows = 4,
  ...props
}) => {
  return (
    <div
      className={classnames(`custom-textarea-container ${className}`, {
        bordered: hasBorder,
        error: !!error,
      })}
    >
      {label && <p className="custom-input-label">{label}</p>}

      <div className="textarea-wrapper">
        <textarea className="custom-textarea" rows={rows} {...props} />
      </div>

      {error && <p className="mb-0 text-red">{error}</p>}
    </div>
  );
};
