import Papa from 'papaparse';

/**
 * @typedef {Array<{title: string, dataIndex: string, isRequired?: boolean>} CsvImportFields
 */

/**
 * @typedef {Object} CsvImportParams
 * @property {CsvImportFields} fields
 * @property {(results: import('papaparse').ParseResult) => void} onSuccess
 * @property {(error: Error) => void} onError
 */

/**
 *
 * @param {CsvImportParams} params
 * @returns {[(event: React.ChangeEvent<HTMLInputElement>) => void]}
 */
export function useCsvImport({ expectedFields = [], onSuccess, onError } = {}) {
  const handleFileInputChange = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const parsedFields = results.meta.fields;

        // Validate fields
        const errors = [];
        if (parsedFields.length > expectedFields.length) {
          errors.push('Fields length is greater than the expected fields');
        }

        for (const field of parsedFields) {
          if (expectedFields.findIndex((f) => f.title === field || f.alias === field) < 0) {
            errors.push(`Field "${field}" is not expected`);
          }
        }

        for (const field of expectedFields) {
          if (
            field.isRequired &&
            !parsedFields.includes(field.title) &&
            !parsedFields.includes(field.alias)
          ) {
            errors.push(`Field "${field.title}" is missing`);
          }
        }

        if (errors.length > 0) {
          return onError(new Error(errors.join('\n')));
        }

        typeof onSuccess === 'function' && onSuccess(results);
      },
    });
  };

  return [handleFileInputChange];
}
