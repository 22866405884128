import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import React from 'react';

import { Button, Input } from 'components';
import { FocusError } from 'components/FocusError/FocusError';
import { AddressInfo } from 'features/common/address-form/AddressInfo';

import useConsigneeForm from './ConsigneeForm.hook';

export function ConsigneeForm({ action, initialValues, onSuccess, onError }) {
  const [form, isLoading] = useConsigneeForm(action, initialValues, onSuccess, onError);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusError form={form} />
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Company Name"
            className="mb-3"
            type="text"
            placeholder="Company Name"
            hasBorder
            {...form.getFieldProps('companyName')}
            error={
              form.errors.companyName && form.touched.companyName ? form.errors.companyName : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Company Phone Number"
            className="mb-3"
            type="text"
            placeholder="Company Phone Number"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.companyPhone || ''}`}
            {...form.getFieldProps('companyPhone')}
            error={
              form.errors.companyPhone && form.touched.companyPhone ? form.errors.companyPhone : ''
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Contact First Name"
            className="mb-3"
            type="text"
            placeholder="Contact First Name"
            hasBorder
            {...form.getFieldProps('firstName')}
            error={form.errors.firstName && form.touched.firstName ? form.errors.firstName : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Last Name"
            className="mb-3"
            type="text"
            placeholder="Contact Last Name"
            hasBorder
            {...form.getFieldProps('lastName')}
            error={form.errors.lastName && form.touched.lastName ? form.errors.lastName : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Phone"
            className="mb-3"
            type="text"
            placeholder="Contact Phone"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.phoneNumber || ''}`}
            {...form.getFieldProps('phoneNumber')}
            error={
              form.errors.phoneNumber && form.touched.phoneNumber ? form.errors.phoneNumber : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Contact Email Address"
            className="mb-3"
            type="email"
            placeholder="Contact Email Address"
            hasBorder
            startIcon={<EmailOutlined />}
            iconHref={`mailto:${form.values.email || ''}`}
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />
        </div>
      </div>
      <div className="row">
        <AddressInfo form={form} fieldName="address" />
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Tax ID"
            className="mb-3"
            type="text"
            placeholder="12-3456789 or 12-3456789SW or 123-45-6789 or 123456-78901"
            hasBorder
            {...form.getFieldProps('taxID')}
            error={form.errors.taxID && form.touched.taxID ? form.errors.taxID : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Organization Code"
            className="mb-3"
            type="text"
            placeholder="Organization Code"
            hasBorder
            {...form.getFieldProps('orgCode')}
            error={form.errors.orgCode && form.touched.orgCode ? form.errors.orgCode : ''}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-4">
          <Button type="submit" className="w-100" loading={isLoading}>
            Save
          </Button>
        </div>
        <div className="col-md-4" />
        <div className="col-md-4" />
      </div>
    </form>
  );
}
