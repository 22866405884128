import React from 'react';

import { Button } from 'components';

import { DataSelect } from '../components/DataSelect';
import AddManufacturerModal from '../components/modals/AddManufacturerModal';

export function ManufacturerInfo({ form, manufacturer, lastSaved, setLastSaved }) {
  const [showManufacturerModal, setShowManufacturerModal] = React.useState(false);

  return (
    <div className="col-md-6 d-flex flex-wrap align-items-end justify-content-between gap-4">
      <DataSelect
        className="w-100"
        apiFieldName="Manufacturer"
        label="Manufacturer *"
        placeholder="Select Manufacturer"
        lastSaved={lastSaved}
        value={manufacturer}
        form={form}
        error={
          form.errors.manufacturer_code && form.touched.manufacturer_code
            ? form.errors.manufacturer_code
            : ''
        }
      />
      <Button type="button" onClick={() => setShowManufacturerModal(true)}>
        + Add
      </Button>
      <AddManufacturerModal
        show={showManufacturerModal}
        handleClose={() => setShowManufacturerModal(false)}
        name="Manufacturer"
        setLastSaved={setLastSaved}
        lastSaved={lastSaved}
      />
    </div>
  );
}
