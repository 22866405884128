import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

import { Input } from '../../../../components';

export const months = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const ManufacturerInfo = ({ form }) => {
  const convertToKGS = (value) => {
    return Math.round((value / 2.20462 + Number.EPSILON) * 100 / 100);
  };

  const convertToLBS = (value) => {
    return Math.round((value * 2.20462 + Number.EPSILON) * 100 / 100);
  };

  return (
    <div>
      <h3 className="mb-4">Vehicle Manufacturer Information</h3>

      <h5 className="mb-3">Manufacturer's Date</h5>

      <div className="row" id="manufacturerMonth">
        <div className="col-md-6 mb-3">
          <Input
            label="Month *"
            placeholder="MM"
            maxLength={2}
            {...form.getFieldProps('manufacturerMonth')}
            error={
              form.errors.manufacturerMonth && form.touched.manufacturerMonth
                ? form.errors.manufacturerMonth
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3" id="manufacturerYear">
          <Input
            label="Year *"
            placeholder="YY"
            maxLength={2}
            {...form.getFieldProps('manufacturerYear')}
            error={
              form.errors.manufacturerYear && form.touched.manufacturerYear
                ? form.errors.manufacturerYear
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label id="weights-on-manufacturer-label" className="mb-1">
            Weights on Manufacturer’s Label *
          </label>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="weights-on-manufacturer-label"
                {...form?.getFieldProps('weightOnLabel')}
                onChange={(e) => {
                  form.setFieldValue('weightOnLabel', e.target.value);
                }}
              >
                <FormControlLabel value="LBS" control={<Radio />} label="LBS" />
                <FormControlLabel value="KGS" control={<Radio />} label="KGS" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row">
        <h6 className="mb-2 fw-bold">GVWR</h6>
        <div className="col-md-6 mb-3" id="GVWRLBS">
          <Input
            type="number"
            label="LBS *"
            name="GVWRLBS"
            value={form.values.GVWRLBS}
            onChange={(e) => {
              form.setFieldValue('GVWRLBS', Math.round(e.target.value));
              form.setFieldValue('GVWRKGS', convertToKGS(Math.round(e.target.value)));
            }}
            error={form.errors.GVWRLBS && form.touched.GVWRLBS ? form.errors.GVWRLBS : ''}
          />
        </div>

        <div className="col-md-6 mb-3" id="GVWRKGS">
          <Input
            type="number"
            label="KGS *"
            name="GVWRKGS"
            value={form.values.GVWRKGS}
            onChange={(e) => {
              form.setFieldValue('GVWRKGS', Math.round(e.target.value));
              form.setFieldValue('GVWRLBS', convertToLBS(Math.round(e.target.value)));
            }}
            error={form.errors.GVWRKGS && form.touched.GVWRKGS ? form.errors.GVWRKGS : ''}
          />
        </div>
      </div>

      <div className="row">
        <h6 className="mb-2 fw-bold">Front GAWR</h6>
        <div className="col-md-6 mb-3">
          <Input
            type="number"
            label="LBS *"
            name="frontGAWRLBS"
            value={form.values.frontGAWRLBS}
            onChange={(e) => {
              form.setFieldValue('frontGAWRLBS', Math.round(e.target.value));
              form.setFieldValue('frontGAWRKGS', convertToKGS(Math.round(e.target.value)));
            }}
            error={
              form.errors.frontGAWRLBS && form.touched.frontGAWRLBS ? form.errors.frontGAWRLBS : ''
            }
          />
        </div>

        <div className="col-md-6 mb-3">
          <Input
            type="number"
            label="KGS *"
            name="frontGAWRKGS"
            value={form.values.frontGAWRKGS}
            onChange={(e) => {
              form.setFieldValue('frontGAWRKGS', Math.round(e.target.value));
              form.setFieldValue('frontGAWRLBS', convertToLBS(Math.round(e.target.value)));
            }}
            error={
              form.errors.frontGAWRKGS && form.touched.frontGAWRKGS ? form.errors.frontGAWRKGS : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <h6 className="mb-2 fw-bold">Rear GAWR</h6>
        <div className="col-md-6 mb-3">
          <Input
            type="number"
            label="LBS *"
            name="rearGAWRLBS"
            value={form.values.rearGAWRLBS}
            onChange={(e) => {
              form.setFieldValue('rearGAWRLBS', Math.round(e.target.value));
              form.setFieldValue('rearGAWRKGS', convertToKGS(Math.round(e.target.value)));
            }}
            error={
              form.errors.rearGAWRLBS && form.touched.rearGAWRLBS ? form.errors.rearGAWRLBS : ''
            }
          />
        </div>

        <div className="col-md-6 mb-3">
          <Input
            type="number"
            label="KGS *"
            name="rearGAWRKGS"
            value={form.values.rearGAWRKGS}
            onChange={(e) => {
              form.setFieldValue('rearGAWRKGS', Math.round(e.target.value));
              form.setFieldValue('rearGAWRLBS', convertToLBS(Math.round(e.target.value)));
            }}
            error={
              form.errors.rearGAWRKGS && form.touched.rearGAWRKGS ? form.errors.rearGAWRKGS : ''
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ManufacturerInfo;
