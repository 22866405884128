import { useQuery } from '@tanstack/react-query';
import { CustomsBrokerApi } from 'api';

/**
 * Custom React Query hook to fetch customs brokers list.
 *
 * @param {{ onError?: (error: Error) => void }} callbacks
 * @returns {import("@tanstack/react-query").UseQueryResult<any, Error>}
 */
export function useGetCustomsBrokers({ onError } = {}) {
  return useQuery({
    queryKey: ['customs-broker'],
    queryFn: () =>
      CustomsBrokerApi.search()
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
    staleTime: Infinity,
    placeholderData: [],
  });
}
