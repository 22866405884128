import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { BackButton, NOTIFICATION_TYPE, Notification } from 'components';
import { SkeletonRows } from 'components/SkeletonRows/SkeletonRows';
import { ConformityLocationForm } from 'features/conformity-locations/components/form/ConformityLocationForm';
import { useGetConformityLocationById } from 'features/conformity-locations/hooks/useGetConformityLocationById';
import { useUpdateConformityLocationById } from 'features/conformity-locations/hooks/useUpdateConformityLocationById';

export function EditConformityLocation() {
  const { id } = useParams();

  const {
    data: confomityLocation,
    isLoading,
    isFetching,
    isError,
  } = useGetConformityLocationById(id);

  const mutation = useUpdateConformityLocationById(id, {
    onSuccess: () => {
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Conformity Location was updated successfully');
      navigate(-1);
    },
    onError: (error) => Notification(NOTIFICATION_TYPE.ERROR, error.message),
  });

  const navigate = useNavigate();

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row">
        <h2>Edit Conformity Location</h2>
      </div>
      <hr />
      {(isFetching || isLoading) && <SkeletonRows rowsCount={7} rowHeight={60} />}
      {!isFetching && !isFetching && !isError && (
        <ConformityLocationForm mutation={mutation} initialValues={confomityLocation} />
      )}
    </div>
  );
}
