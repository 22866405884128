import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import { DESIGNATIONS } from 'constants/userSetting';
import React from 'react';

import { CustomSelect } from 'components/CustomSelect';
import { Input } from 'components/Input';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { AddressInfo } from 'features/common/address-form/AddressInfo';
import { BondProviderSelect } from 'features/common/bond-provider/BondProviderSelect';
import { BondRangeSettings } from 'features/common/bond-provider/BondRangeSettings';
import { CustomsBrokerSelect } from 'features/common/customs-broker/CustomsBrokerSelect';
import { CustomsRangeSettings } from 'features/common/customs-broker/CustomsRangeSettings';
import { WarrantyProviderSelect } from 'features/common/warranty-provider/WarrantyProviderSelect';
import { WarrantyRangeSettings } from 'features/common/warranty-provider/WarrantyRangeSettings';

import './styles.scss';

export function RegisteredImporterInfo({ form }) {
  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Company Name"
            className="mb-3"
            type="text"
            placeholder="Company Name"
            hasBorder
            {...form.getFieldProps('riCompanyName')}
            error={
              form.errors.riCompanyName && form.touched.riCompanyName
                ? form.errors.riCompanyName
                : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="RI Number"
            className="mb-3"
            type="text"
            placeholder="R12345"
            hasBorder
            {...form.getFieldProps('riNumber')}
            error={form.errors.riNumber && form.touched.riNumber ? form.errors.riNumber : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="First Name"
            className="mb-3"
            type="text"
            placeholder="First"
            hasBorder
            {...form.getFieldProps('firstName')}
            error={form.errors.firstName && form.touched.firstName ? form.errors.firstName : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Last Name"
            className="mb-3"
            type="text"
            placeholder="Last"
            hasBorder
            {...form.getFieldProps('lastName')}
            error={form.errors.lastName && form.touched.lastName ? form.errors.lastName : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <CustomSelect
            name="designation"
            id="designation"
            label="Designation"
            placeholder="Select designation"
            options={DESIGNATIONS}
            defaultValue={
              form.values.designation
                ? { label: form.values.designation, value: form.values.designation }
                : null
            }
            hasBorder={true}
            onChange={(v) => form.setFieldValue('designation', v.value)}
            error={
              form.errors.designation && form.touched.designation ? form.errors.designation : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Email"
            className="mb-3"
            type="email"
            placeholder="User Email"
            hasBorder
            startIcon={<EmailOutlined />}
            iconHref={`mailto:${form.values.email || ''}`}
            {...form.getFieldProps('email')}
            error={form.errors.email && form.touched.email ? form.errors.email : ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <PasswordInput
            label="Password"
            className="mb-3"
            placeholder="Password"
            hasBorder
            {...form.getFieldProps('password')}
            error={form.errors.password && form.touched.password ? form.errors.password : ''}
          />
        </div>
        <div className="col-md-6 mb-3">
          <PasswordInput
            label="Password Confirmation"
            className="mb-3"
            placeholder="Password Confirmation"
            hasBorder
            {...form.getFieldProps('passwordConfirm')}
            error={
              form.errors.passwordConfirm && form.touched.passwordConfirm
                ? form.errors.passwordConfirm
                : ''
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Mobile Number"
            className="mb-3"
            type="text"
            placeholder="Mobile Number"
            hasBorder
            startIcon={<PhoneOutlined />}
            iconHref={`tel:${form.values.mobileNumber || ''}`}
            {...form.getFieldProps('mobileNumber')}
            error={
              form.errors.mobileNumber && form.touched.mobileNumber ? form.errors.mobileNumber : ''
            }
          />
        </div>
        <div className="col-md-6 mb-3">
          <Input
            label="Tax ID"
            className="mb-4"
            type="text"
            placeholder="12-3456789 or 12-3456789SW or 123-45-6789 or 123456-78901"
            hasBorder
            {...form.getFieldProps('taxID')}
            error={form.errors.taxID && form.touched.taxID ? form.errors.taxID : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <Input
            label="Organization Code"
            className="mb-3"
            type="text"
            placeholder="Organization Code"
            hasBorder
            {...form.getFieldProps('orgCode')}
            error={form.errors.orgCode && form.touched.orgCode ? form.errors.orgCode : ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <CustomsBrokerSelect className="mb-3" form={form} />
        </div>
        <div className="col-md-6 mb-3">
          <CustomsRangeSettings form={form} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <BondProviderSelect className="mb-3" form={form} />
        </div>
        <div className="col-md-6 mb-3">
          <BondRangeSettings form={form} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <WarrantyProviderSelect className="mb-3" form={form} />
        </div>
        <div className="col-md-6 mb-3">
          <WarrantyRangeSettings form={form} />
        </div>
      </div>
      <hr />
      <h1 className="h3 mb-4 fw-normal">Primary RI Address</h1>
      <div className="row">
        <AddressInfo form={form} fieldName="primaryAddress" />
      </div>

      <h1 className="h3 fw-normal">Secondary RI Address</h1>
      <div className="row">
        <AddressInfo form={form} fieldName="secondaryAddress" />
      </div>
    </>
  );
}
