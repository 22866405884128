import React from 'react';

import { ConformityLocationsTable } from 'features/conformity-locations/components/table/ConformityLocationsTable';

export function ListConformityLocations() {
  return (
    <>
      <h2 className="mb-4">Conformity Locations</h2>
      <ConformityLocationsTable />
    </>
  );
}
