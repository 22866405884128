import { VEHICLE_TYPES } from 'constants/vehicle';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CustomSelect } from 'components';
import { DEFAULT_SEARCH_VALUES, SearchContext } from 'features/vehicles/contexts/SearchContext';

import './styles.scss';

const VehicleStatusFilters = () => {
  const { searchValues } = useContext(SearchContext);

  const selectedFilters = VEHICLE_TYPES.filter((item) =>
    searchValues.statusFilters.find((v) => v === item.value)
  );

  const [, setSearchParams] = useSearchParams();

  const handleUpdateStatusFilter = (statusFilters) => {
    setSearchParams({
      searchTerm: searchValues.searchTerm,
      page: 0,
      perPage: searchValues.filter.perPage,
      statusFilters: statusFilters,
      ...searchValues.sortOption,
    });
  };

  const handleChangeFilter = ({ action, option, removedValue }) => {
    if (action === 'clear') return handleUpdateStatusFilter(DEFAULT_SEARCH_VALUES.statusFilters);
    if (action === 'remove-value') {
      const updateRemovedValue = searchValues.statusFilters.filter(
        (filter) => filter !== removedValue.value
      );
      return handleUpdateStatusFilter(updateRemovedValue);
    }
    if (option.value === 'All')
      return handleUpdateStatusFilter(DEFAULT_SEARCH_VALUES.statusFilters);
    const newFilters = [...searchValues.statusFilters, option.value];
    const removeAllActive = newFilters.filter((filter) => filter !== 'All');
    return handleUpdateStatusFilter(removeAllActive);
  };

  return (
    <CustomSelect
      options={VEHICLE_TYPES}
      value={selectedFilters}
      onChange={(_, event) => handleChangeFilter(event)}
      isMulti={true}
    />
  );
};

export default VehicleStatusFilters;
