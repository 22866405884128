import Http from '../utilities/http';

export class VehicleApi {
  /**
   *
   * @param {{ page: number, perPage: number, order: string, orderBy: string, searchTerm: string, statusFilters: string[]  }} query
   * @returns {Promise<{ data: object[], totalCount: number }>}
   */
  static search(query = {}) {
    return Http.get('/vehicles', query).then((res) => res.data);
  }

  static create(data) {
    return Http.post('/vehicles', data, { Accept: 'application/json' });
  }

  static activate(id) {
    return Http.patch(`/vehicles/${id}`, { isDraft: false });
  }

  static update(id, data) {
    return Http.patch(`/vehicles/${id}`, data);
  }

  static delete(id) {
    return Http.delete(`/vehicles/${id}`);
  }

  static getDetail(id, queryParams) {
    return Http.get(`/vehicles/${id}?${queryParams}`);
  }

  static getVehicleByVin(vin) {
    return Http.get(`/vehicles/vin/${vin}`);
  }

  static vehicleBondReleased(vin, data) {
    return Http.patch(`/vehicles/vin/${vin}/released`, data);
  }

  static getTimeline(id) {
    return Http.get(`/vehicles/${id}/timeline`);
  }

  static updateCountdown(id, data) {
    return Http.post(`/vehicles/${id}/countdown`, data);
  }

  static checkNHTSAPackageNumber() {
    return Http.get('/vehicles/check-nhtsa-package-number');
  }

  static generateNHTSAPackage(data) {
    return Http.post('/vehicles/generate-nhtsa-package', data, {}, { responseType: 'arraybuffer' });
  }

  static toggleNHTSAReady(id) {
    return Http.get(`/vehicles/${id}/toggle-nhtsa-ready`);
  }

  static generateRILabel(ids) {
    return Http.post('/vehicles/generate-ri-label', { ids });
  }

  static shareVehicle(data) {
    return Http.post('/vehicles/share', data);
  }

  static updateStatusRecall(id, data) {
    return Http.post(`/vehicles/${id}/update-status-recall`, data);
  }

  static signDocs(data) {
    return Http.post(`/vehicles/sign-docs`, data);
  }

  static updateStatementConformity(id, data) {
    return Http.patch(`/vehicles/${id}/statement-conformity`, data);
  }

  static deleteVehiclePhoto(vehicle_id, photoCategory) {
    return Http.delete(`/vehicles/${vehicle_id}/photo/${photoCategory}`);
  }
}
