import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VehicleApi } from '../../../api';
import { BackButton, Loading, NOTIFICATION_TYPE, Notification } from '../../../components';
import VehicleForm from '../VehicleForm/VehicleForm';

export default function EditVehicleInfo() {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    VehicleApi.getDetail(id)
      .then((res) => {
        const detail = res.data;
        setVehicle(detail);
        setLoading(false);
      })
      .catch((err) => {
        Notification(NOTIFICATION_TYPE.DANGER, err);
      });
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row">
        <h1>Update Import Vehicle</h1>
      </div>

      <hr />

      <VehicleForm action="edit" vehicle={vehicle} setVehicle={setVehicle} />
    </div>
  );
}
