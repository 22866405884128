import React from 'react';

import { Input } from 'components';

import { RangeSettings } from '../range-settings/RangeSettings';
import { useGetWarrantyProviders } from './useGetWarrantyProviders';

export function WarrantyRangeSettings({ form }) {
  const { data: warrantyProviders, isLoading, isError } = useGetWarrantyProviders();

  const { setFieldValue } = form;

  React.useEffect(() => {
    if (!isLoading && !isError) {
      !form.values.warrantyLow &&
        setFieldValue('warrantyLow', warrantyProviders[0]?.customsLow || 0);
      !form.values.warrantyHigh &&
        setFieldValue('warrantyHigh', warrantyProviders[0]?.customsHigh || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warrantyProviders, isError, isLoading, setFieldValue]);

  return (
    <RangeSettings form={form} fieldName="customs">
      <Input
        label="Range Low"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('warrantyLow')}
        error={
          !!form.errors.warrantyLow && !!form.touched.warrantyLow ? form.errors.warrantyLow : ''
        }
      />
      <Input
        label="Range High"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('warrantyHigh')}
        error={
          !!form.errors.warrantyHigh && !!form.touched.warrantyHigh ? form.errors.warrantyHigh : ''
        }
      />
    </RangeSettings>
  );
}
