import Http from '../utilities/http';

export class PaymentApi {
    static createPayment(data) {
        return Http.post('/payment/', data);
    }

    static secret() {
        return Http.get('/payment/secret');
    }

    static setup(data) {
        return Http.post('/payment/setup', data);
    }
}
