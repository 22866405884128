const colors = {
  white: '#fff',
  black: '#000',

  primary: '#4B81EB',
  dark: '#191f27',
  dark_1: '#28364c',
  dark_2: '#12161b',
  grey: '#F2F7FF',
  grey_1: '#747474',
  grey_2: '#6b6b6b',
  grey_dark: '#343434',
  dark_light: '#212833',
  blue: '#007AFF',
  blue_dark: '#4C7FEB',
  red: '#b00a0a',
  yellow: '#ff9800',
};

export default colors;
