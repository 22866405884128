import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';

import { LETTER, PAGE_SIZES } from './RILabelDocument.constants';

const MultiRILabelDocument = ({
  vehicles,
  withTireInfo = true,
  pageSize = LETTER,
  rotate = false,
}) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      page: {
        position: 'relative',
      },
      container: {
        position: 'absolute',
        flexDirection: 'column',
        fontSize: !rotate || pageSize === LETTER ? 10 : 8,
        padding: 8,
        textTransform: 'uppercase',
        transform: rotate ? 'rotate(270deg)' : 'rotate(0deg)',
        transformOrigin: 'top',
        width: rotate ? PAGE_SIZES[pageSize].height : '100%',
        left: rotate ? -PAGE_SIZES[pageSize].height / 2 : 0,
        top: rotate ? PAGE_SIZES[pageSize].height / 2 : 0,
        bottom: rotate ? -PAGE_SIZES[pageSize].height : 0,
      },
      row: {
        display: 'flex',
        margin: 4,
        flexDirection: 'row',
      },
      column: {
        margin: 4,
        padding: 4,
        flexGrow: 1,
        textAlign: 'center',
      },
      header: {
        textAlign: 'center',
      },
      bold: {
        fontFamily: 'Times-Bold',
      },
    });
  }, [pageSize, rotate]);

  return (
    <Document>
      <Page size={PAGE_SIZES[pageSize]} style={styles.page}>
        {vehicles.map((vehicle, index) => {
          return (
            <View key={index} style={styles.container} break={index !== 0}>
              <View style={styles.header}>
                <Text>manufactured by {vehicle.manufacturer?.name || ''}</Text>
              </View>
              <View style={styles.header}>
                <Text>imported by {vehicle.User.register_importer.companyName}</Text>
              </View>
              <View style={styles.header}>
                <Text>
                  date of manufacture{' '}
                  {`${`0${vehicle.manufacturerMonth}`.slice(-2)}/${vehicle.manufacturerYear}`}
                </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>gvwr</Text>
                </View>
                <View style={styles.column}>
                  <Text>{vehicle.GVWRKGS} kg</Text>
                  <Text>({vehicle.GVWRLBS} lb)</Text>
                </View>
                <View style={styles.column}>
                  <Text>gawr front</Text>
                  <Text>gawr rear</Text>
                </View>
                <View style={styles.column}>
                  <Text>
                    {vehicle.frontGAWRKGS} kg ({vehicle.frontGAWRLBS} lb)
                  </Text>
                  <Text>
                    {vehicle.rearGAWRKGS} kg ({vehicle.rearGAWRLBS} lb)
                  </Text>
                </View>
              </View>
              {withTireInfo ? (
                <>
                  <View style={styles.row}>
                    <View style={styles.column}>
                      <Text>with</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>tires</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>rims</Text>
                      <Text>at</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>{vehicle.frontRimSize}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>psi cold</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>{vehicle.frontColdPSI}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={styles.column}>
                      <Text>with</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>tires</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>rims</Text>
                      <Text>at</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>{vehicle.rearRimSize}</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>psi cold</Text>
                    </View>
                    <View style={styles.column}>
                      <Text>{vehicle.rearColdPSI}</Text>
                    </View>
                  </View>
                </>
              ) : null}
              <View style={styles.row}>
                <Text>
                  this vehicle conforms to all applicable u.s. federal motor vehicle safety
                  standards in effect on the date of manufacture shown above.
                </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text>
                    vin <Text style={styles.bold}>{vehicle.vin}</Text>
                  </Text>
                </View>
                <View style={styles.column}>
                  <Text>type {vehicle.vehicleType || ''}</Text>
                </View>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default MultiRILabelDocument;
