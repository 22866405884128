import { DownloadFileApi } from 'api';
import React from 'react';

import { SkeletonRows } from 'components/SkeletonRows/SkeletonRows';

import { Dialog } from '../Dialog';
import { NOTIFICATION_TYPE, Notification } from '../Notification';

const PDFViewerModal = ({ file, open, setOpen }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [signedUrl, setSignedUrl] = React.useState(null);

  React.useEffect(() => {
    if (open && file) {
      setIsLoading(true);

      DownloadFileApi.getInlineDoc(file)
        .then((res) => {
          setSignedUrl(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          Notification(NOTIFICATION_TYPE.DANGER, err.message);
          setIsLoading(false);
        });
    }
  }, [open, file]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} title="PDF Preview">
      {isLoading ? (
        <SkeletonRows rowsCount={10} rowHeight={80} width="100%" />
      ) : (
        <object data={signedUrl} type="application/pdf" download="" width="100%" height="800px">
          <p className="text-center text-white mt-4">
            PDF cannot be displayed in your browser. Please download to view it:{' '}
            <a href={file}>Download PDF</a>
          </p>
        </object>
      )}
    </Dialog>
  );
};

export default PDFViewerModal;
