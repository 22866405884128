import Http from '../utilities/http';

export class BuyersApi {
  static search(query = {}) {
    return Http.get('/buyers', query);
  }

  static getById(id) {
    return Http.get(`/buyers/${id}`);
  }

  static update(id, data) {
    return Http.put(`/buyers/${id}`, data);
  }

  static add(data) {
    return Http.post('/buyers', data);
  }

  static batchAdd(data) {
    return Http.post('/buyers/batch', data);
  }

  static remove(id) {
    return Http.delete(`/buyers/${id}`);
  }
}
