import React, { useState } from 'react';
import { DialogContent } from '@mui/material';
import { ROUTES } from '../../../../constants/routes';
import { CUSTOMS_BROKER_RELEASE, PRICING_AGREEMENT, PRIVACY_POLICY, TERMS_OF_USE } from "./termsUtils";
import { Dialog } from '../../../../components';
import './styles.scss';

const list = [
  { title: 'Privacy policy', link: ROUTES.PUBLIC.RI_USER_AGREEMENT, key: 'policy' },
  { title: 'Terms of use', link: ROUTES.PUBLIC.TERMS_OF_USE, key: 'terms' },
  { title: 'Pricing their agreement', link: ROUTES.PUBLIC.PRICING_AGREEMENT, key: 'agreement' },
  {
    title: 'Customs-Broker Release of Information to Vin2 Software',
    link: ROUTES.PUBLIC.CUSTOMS_BROKER_RELEASE,
    key: 'customs'
  },
];

const AgreeModal = ({
  show,
  handleClose,
}) => {
  const [policyDialog, setPolicyDialog] = useState(false);
  const [policyData, setPolicyData] = useState(null);

  const handlePolicyClick = (policy) => {
    setPolicyDialog(true)
    if (policy.key === 'terms') {
      setPolicyData(TERMS_OF_USE)
    } else if (policy.key === 'policy') {
      setPolicyData(PRIVACY_POLICY)
    } else if (policy.key === 'agreement') {
      setPolicyData(PRICING_AGREEMENT)
    } else if (policy.key === 'customs') {
      setPolicyData(CUSTOMS_BROKER_RELEASE)
    }
  }

  return (
    <>
      <Dialog open={show} onClose={handleClose} title="Terms of service">
        <DialogContent sx={{ py: 7.5 }}>
          <div>
            <ul>
              {
                list.map((item, index) => (
                  <li key={index} data-bs-dismiss="modal">
                    <a
                      onClick={() => handlePolicyClick(item)}
                      className="agree-link cursor-pointer"
                      data-testid={`agree-link-${item.title}`}
                    >
                      {item.title}
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={policyDialog} onClose={() => {setPolicyDialog(false)}} title={policyData?.title}>
        {policyData && <DialogContent sx={{ py: 7.5 }}>
          {policyData.content}
        </DialogContent>}
      </Dialog>
    </>
  );
};

export default AgreeModal;
