import React from 'react';
import { useNavigate } from 'react-router';

import { BackButton, NOTIFICATION_TYPE, Notification } from 'components';
import { ConsigneeForm } from 'features/consignees/components/form/ConsigneeForm';

export default function AddConsignee() {
  const navigate = useNavigate();

  const onSuccess = () => {
    Notification(NOTIFICATION_TYPE.SUCCESS, 'Consignee was created successfully');
    navigate(-1);
  };

  const onError = (error) => {
    Notification(NOTIFICATION_TYPE.DANGER, error.message);
  };

  return (
    <div>
      <div className="mb-4">
        <BackButton />
      </div>

      <div className="row mb-4">
        <h1>Add Consignee</h1>
      </div>

      <ConsigneeForm action="create" onSuccess={onSuccess} onError={onError} />
    </div>
  );
}
