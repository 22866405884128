import React from 'react';

import { Input } from 'components';

import { RangeSettings } from '../range-settings/RangeSettings';
import { useGetBondProviders } from './useGetBondProviders';

export function BondRangeSettings({ form }) {
  const { data: bondProviders, isLoading, isError } = useGetBondProviders();

  const { setFieldValue } = form;

  React.useEffect(() => {
    if (!isLoading && !isError) {
      !form.values.bondLow && setFieldValue('bondLow', bondProviders[0]?.customsLow || 0);
      !form.values.bondHigh && setFieldValue('bondHigh', bondProviders[0]?.customsHigh || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bondProviders, isError, isLoading, setFieldValue]);

  return (
    <RangeSettings form={form} fieldName="customs">
      <Input
        label="Range Low"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('bondLow')}
        error={!!form.errors.bondLow && !!form.touched.bondLow ? form.errors.bondLow : ''}
      />
      <Input
        label="Range High"
        type="number"
        hasBorder
        disabled={isLoading}
        {...form.getFieldProps('bondHigh')}
        error={!!form.errors.bondHigh && !!form.touched.bondHigh ? form.errors.bondHigh : ''}
      />
    </RangeSettings>
  );
}
