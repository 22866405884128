import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VehicleApi } from 'api';

export function useToggleNHTSAReady(id) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['toggle-vehicle-nhtsa-ready'],
    mutationFn: () => VehicleApi.toggleNHTSAReady(id),
    onMutate: () => {
      const savedVehicleCache = queryClient.getQueryData(['vehicle-detail', id]);

      queryClient.setQueryData(['vehicle-detail', id], () => ({
        ...savedVehicleCache,
        nhtsaReady: !savedVehicleCache.nhtsaReady,
      }));

      return () => queryClient.setQueryData(['vehicle-detail', id], savedVehicleCache);
    },
    // onSuccess: (data, variables, restoreCache) => {
    //   restoreCache();

    //   queryClient.setQueryData(['vehicle-detail', id], (detail) => {
    //     console.log(detail);
    //     return {
    //       ...detail,
    //       nhtsaReady: data.nhtsaReady,
    //     };
    //   });
    // },
    onSettled: () => {
      queryClient.invalidateQueries(['vehicle-detail', id]);
    },
    onError: (error, variables, restoreCache) => {
      restoreCache();
    },
  });
}
