/* EPA Codes */
export const EPA_CODE_A = '(A) Imported by an ICI for modifications';
export const EPA_CODE_B = '(B) US EPA certified and in original EPA configuration';
export const EPA_CODE_C =
  '(C) Imported by an ICI for modification and testing (vehicle or engine at least 6 yars old)';
export const EPA_CODE_D =
  '(D) Imported by an ICI for modification and testing (vehicle or engine less than 6 years old)';
export const EPA_CODE_E = '(E) Vehicle or heavy-duty engine is 21 years old or older';
export const EPA_CODE_EE = '(EE) Identical in all material respect to a US certified version';
export const EPA_CODE_F =
  '(F) US EPA certified at time of manufacture, but subsequently altered or modified';
export const EPA_CODE_FF = '(FF) Canadian "identical" models imported for resale or lease';
export const EPA_CODE_G = '(G) Imported solely for repair or alteration';
export const EPA_CODE_H = '(H) Imported, owned and controlled directly by an OEM';
export const EPA_CODE_I = '(I) Imported for testing purposes';
export const EPA_CODE_J = '(J) Imported by an ICI for pre-certification testing';
export const EPA_CODE_K = '(K) Imported for display';
export const EPA_CODE_L = '(L) Determined to be a racing vehicle';
export const EPA_CODE_M = '(M) Misc. exemption';
export const EPA_CODE_N = '(N) Imported by member of the armed forces or a foreign government';
export const EPA_CODE_O = '(O) Imported by nonresident for personal use by the importer';
export const EPA_CODE_R = '(R) Light-duty gasoline-fueled vehicle manufactured prior to 1/1/68';
export const EPA_CODE_S = '(S) Light-duty diesel-fueled vehicle manufactured prior to 1/1/75';
export const EPA_CODE_T = '(T) Light-duty diesel-fueled truck manufactured prior to 1/1/76';
export const EPA_CODE_U = '(U) Motorcycle';
export const EPA_CODE_V =
  '(V) Gasoline or diesel fueled heavy duty engine manufactured propr to 1/1//70';
export const EPA_CODE_W =
  '(W) Non-chassis-mounted engine for use in light duty vehicle or motorcycle';
export const EPA_CODE_X = '(X) "Off road" vehicle';
export const EPA_CODE_Y = '(Y) Vehicle or heavy-duty engine that uses unregulated fuel';
export const EPA_CODE_Z =
  '(Z) Imported by an ICI for purpose of modifying to be identical to OEM version';

export const EPA_CODES = [
  EPA_CODE_A,
  EPA_CODE_B,
  EPA_CODE_C,
  EPA_CODE_D,
  EPA_CODE_E,
  EPA_CODE_EE,
  EPA_CODE_F,
  EPA_CODE_FF,
  EPA_CODE_G,
  EPA_CODE_H,
  EPA_CODE_I,
  EPA_CODE_J,
  EPA_CODE_K,
  EPA_CODE_L,
  EPA_CODE_M,
  EPA_CODE_N,
  EPA_CODE_O,
  EPA_CODE_R,
  EPA_CODE_S,
  EPA_CODE_T,
  EPA_CODE_U,
  EPA_CODE_V,
  EPA_CODE_W,
  EPA_CODE_X,
  EPA_CODE_Y,
  EPA_CODE_Z,
];

/* DOT Box */
export const DOT_BOX_01 = '(01) Vehicle is at least 25 years old';
export const DOT_BOX_2A = '(2A) Vehicle meets standards, has certification levels';
export const DOT_BOX_2B = '(2B) Vehicle meets standards, needs certification levels';
export const DOT_BOX_03 = '(03) Imported under Registered Importer program';
export const DOT_BOX_04 = '(04) Vehicle is intended for Export';
export const DOT_BOX_05 = '(05) Non-Resident of the US, vehicle is registered other than in the US';
export const DOT_BOX_06 = '(06) Member of a foreign government';
export const DOT_BOX_07 = '(07) Imported for a temporary period';
export const DOT_BOX_08 = '(08) Vehicle is not subject to FMVSS standards';
export const DOT_BOX_09 = '(09) Vehicle requires further manufacturing operations';
export const DOT_BOX_10 = '(10) Reserved';
export const DOT_BOX_11 = '(11) Equipment is subject to Theft Prevention Standard';
export const DOT_BOX_12 = '(12) Member of armed forces of a foreign government';
export const DOT_BOX_13 = '(13) Petition is being filed to permit entry';

export const DOT_BOX_ITEMS = [
  DOT_BOX_01,
  DOT_BOX_2A,
  DOT_BOX_2B,
  DOT_BOX_03,
  DOT_BOX_04,
  DOT_BOX_05,
  DOT_BOX_06,
  DOT_BOX_07,
  DOT_BOX_08,
  DOT_BOX_09,
  DOT_BOX_10,
  DOT_BOX_11,
  DOT_BOX_12,
  DOT_BOX_13,
];

/* Vehicle Types */
export const BUS = 'Bus';
export const CAR = 'Car';
export const MOTORCYCLE = 'Motorcycle';
export const MPV_UNDER_10000 = 'MPV under 10,000 LBS';
export const MPV_OVER_10000 = 'MPV over 10,000 LBS';
export const SEMI_TRUCK = 'Semi-Truck';
export const TRAILER = 'Trailer';
export const TRUCK_UNDER_10000 = 'Truck under 10,000 LBS';
export const TRUCK_OVER_10000 = 'Truck over 10,000 LBS';

export const VEHICLE_TYPES = [
  BUS,
  CAR,
  MOTORCYCLE,
  MPV_UNDER_10000,
  MPV_OVER_10000,
  SEMI_TRUCK,
  TRAILER,
  TRUCK_UNDER_10000,
  TRUCK_OVER_10000,
];

/* VSA Codes */
export const VSA080 = 'VSA080';
export const VSA081 = 'VSA081';
export const VSA082 = 'VSA082';
export const VSA083 = 'VSA083';
