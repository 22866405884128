import React from 'react';
import { IconButton, Menu } from '@mui/material';
import { MoreVert as MoreIcon } from '@mui/icons-material';

export const MoreMenu = ({ children, anchorEl, setAnchorEl }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ color: (theme) => theme.colors.white }}
        data-testid="more-icon"
        aria-label="menu"
      >
        <MoreIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};
