import Http from '../utilities/http';

export class BrokerAPI {
  static submit(data) {
    return Http.post('/broker/submit', data);
  }

  static add(data) {
    return Http.post('/broker/add', data);
  }

  static checkEntryNumber(data) {
    return Http.post('/broker/check-entry-numbers', data);
  }
}
