import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { hasPermission } from 'services/auth';

import { ConfirmModal, MoreMenu, NOTIFICATION_TYPE, Notification } from 'components';
import { useDeleteConsignee } from 'features/consignees/hooks/useDeleteConsignee';

const ConsigneeActionButton = ({ consignee }) => {
  const { user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutate: deleteConsignee, isLoading } = useDeleteConsignee({
    onSuccess: () => {
      setAnchorEl(null);
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Consignee is removed successfully.');
    },
    onError: (err) => {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    },
  });

  return (
    <>
      <MoreMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          onClick={() => setShowConfirm(true)}
          disabled={!hasPermission(user, { resource: 'consignee', action: 'delete' }) || isLoading}
          data-testid="delete-consignee"
        >
          <DeleteOutlineIcon sx={{ mr: '1rem' }} />
          Remove
        </MenuItem>
        <MenuItem disabled={!hasPermission(user, { resource: 'consignee', action: 'update' })}>
          <Link to={`edit/${consignee.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <EditIcon sx={{ mr: '1rem' }} />
            Edit
          </Link>
        </MenuItem>
      </MoreMenu>

      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        title="Remove Consignee"
        content="Are you sure you want to remove this consignee?"
        onConfirm={() => deleteConsignee(consignee.id)}
      />
    </>
  );
};

export default ConsigneeActionButton;
