import { useQuery } from '@tanstack/react-query';
import { AuthApi } from 'api';

/**
 * Custom React Query hook to fetch all permissions
 *
 * @param {{onError?: (error: any) => void}} callbacks
 * @returns {import("@tanstack/react-query").UseQueryResult}
 */
export function useGetAllPermissions({ onError } = {}) {
  return useQuery({
    queryKey: ['permissions'],
    queryFn: () =>
      AuthApi.getPermissions()
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
  });
}
