import React, { useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Checkbox, FormControlLabel } from '@mui/material';
import { LETTER, _4X2dot5, _4X3 } from './RILabelDocument.constants';
import { Button } from '../../../components';
import MultiRILabelDocument from './RILabelDocument';

const MultiRILabel = ({ vehicles }) => {
  const [withTireInfo, setWithTireInfo] = useState(true);
  const [pageSize, setPageSize] = useState(LETTER);
  const [shouldRotate90Deg, setRotate90Deg] = useState(false);

  
  const handlePrint = () => {
    const $docViewer = document.getElementById('document-viewer');

    if ($docViewer) {
      $docViewer.contentWindow.print();
    }
  };

  if (!vehicles.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="mb-3">
        <PDFViewer
          style={{ width: '100%', height: '420px' }}
          id="document-viewer"
          showToolbar={false}
        >
          <MultiRILabelDocument
            vehicles={vehicles}
            withTireInfo={withTireInfo}
            pageSize={pageSize}
            rotate={shouldRotate90Deg}
          />
        </PDFViewer>
      </div>

      <div className="d-flex flex-column align-items-center gap-2">
        {/* Tire Info selection */}
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="tireInfo"
              id="withTireInfo"
              value="yes"
              checked={withTireInfo}
              onChange={() => setWithTireInfo(true)}
            />
            <label className="form-check-label" htmlFor="withTireInfo">
              Label With Tire Information
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="tireInfo"
              id="withoutTireInfo"
              value="no"
              checked={!withTireInfo}
              onChange={() => setWithTireInfo(false)}
            />
            <label className="form-check-label" htmlFor="withoutTireInfo">
              Label Without Tire Information
            </label>
          </div>
        </div>

        {/* Page Size selection */}
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="pageSize"
              id="letter"
              value={LETTER}
              checked={pageSize === LETTER}
              onChange={() => setPageSize(LETTER)}
            />
            <label className="form-check-label" htmlFor="letter">
              Letter
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="pageSize"
              id="fourThree"
              value={_4X3}
              checked={pageSize === _4X3}
              onChange={() => setPageSize(_4X3)}
            />
            <label className="form-check-label" htmlFor="fourThree">
              4 X 3
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="pageSize"
              id="fourTwoAndAHalf"
              value={_4X2dot5}
              checked={pageSize === _4X2dot5}
              onChange={() => setPageSize(_4X2dot5)}
            />
            <label className="form-check-label" htmlFor="fourTwoAndAHalf">
              4 X 2.5
            </label>
          </div>
        </div>

        {/* Page Rotation */}
        <FormControlLabel
          control={
            <Checkbox
              sx={{ color: (theme) => theme.colors.white }}
              checked={shouldRotate90Deg}
              onChange={(e) => setRotate90Deg(e.target.checked)}
            />
          }
          label="Rotate 90 degrees"
        />
        <Button onClick={handlePrint}>Print</Button>
      </div>
    </div>
  );
};

export default MultiRILabel;
