import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConformityLocationApi } from 'api';

/**
 * Custom React Query hook to update a conformity location.
 *
 * @see {@link ConformityLocationApi.update}
 *
 * @param {number} id
 * @param {{ onSuccess?: () => void, onError?: (error: Error) => void }} callbacks
 * @returns
 */
export function useUpdateConformityLocationById(id, { onSuccess, onError } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-conformity-location', id],
    mutationFn: async (data) => ConformityLocationApi.update(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['conformity-locations']);
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      typeof onError === 'function' && onError(error);
    },
  });
}
